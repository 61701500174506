import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,

  Typography
} from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { SettingsItem } from './SettingsItem';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '3rem 2rem'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  content: { padding: '0', marginBottom: theme.spacing(2) },
  field: {
    margin: 'auto auto',
    maxWidth: '50ch',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    padding: '16px 24px'
  }
}));








export const SettingsDialog = ({ databaseName, tableName, open, onClose }) => {
  const classes = useStyles();
  const dataObservabilityApi = useApi(dataObservabilityApiRef);
  const [search, setSearch] = useState('');

  const [{ value: settings, loading: loadingSettings }, refresh] = useAsyncFn(async () => {
    return await dataObservabilityApi.listDataMonitoringSettingsByTableV2(databaseName, tableName);
  }, [databaseName, tableName]);

  const filteredSettings = search
    ? settings?.filter(setting => setting.name.toLocaleLowerCase().startsWith(search))
    : settings;

  useEffect(() => {
    if (open) {
      refresh();
    }
  }, [refresh, open]);

  const navigate = useNavigate();

  return (
    React.createElement(Dialog, { maxWidth: "lg", className: classes.container, open: open,}
      , React.createElement(DialogTitle, null
        , React.createElement(Box, { className: classes.header,}
          , React.createElement(Typography, { variant: "h5",}, "Select data monitoring setting"   )
          , React.createElement(IconButton, { 'aria-label': "delete", onClick: onClose,}
            , React.createElement(ClearIcon, { fontSize: "small", color: "secondary",} )
          )
        )
      )
      , React.createElement(DialogContent, { className: classes.content,}
        , React.createElement(Box, {
          height: "400px",
          width: "700px",
          display: "flex",
          flexDirection: "column",
          padding: "0 1.5rem 1.5rem"  ,
          style: {
            gap: '0.5rem'
          },}
        
          , loadingSettings ? (
            React.createElement(CircularProgress, null )
          ) : (
            React.createElement(React.Fragment, null
              , React.createElement(Box, { display: "flex", justifyContent: "space-between",}
                , React.createElement(Button, {
                  style: { width: 'fit-content' },
                  color: "primary",
                  onClick: () => navigate(`/observability/tables/${databaseName}.${tableName}/data-monitoring`),
                  startIcon: React.createElement(AddIcon, null ),}
                , "Create new setting"

                )
                , React.createElement(TextField, {
                  onChange: e => {
                    setSearch(e.target.value);
                  },
                  placeholder: "Filter Settings" ,
                  InputProps: {
                    startAdornment: (
                      React.createElement(InputAdornment, { position: "start",}
                        , React.createElement(SearchIcon, { color: "primary",} )
                      )
                    ),
                    endAdornment: (
                      React.createElement(IconButton, { onClick: () => setSearch(''), disabled: search === '',}
                        , React.createElement(ClearIcon, { fontSize: "small",} )
                      )
                    )
                  },}
                )
              )

              , filteredSettings &&
                filteredSettings.map(setting => React.createElement(SettingsItem, { key: setting.id, setting: setting, refresh: refresh,} ))
              , filteredSettings?.length === 0 && search && (
                React.createElement(Box, { height: "100%", display: "flex", justifyContent: "center", alignItems: "center",}
                  , React.createElement(Typography, null, "There is no setting that match filter criteria"       )
                )
              )
            )
          )
        )
      )
    )
  );
};
