import React from 'react';
import { ServiceApiPage, ServiceLayout } from '@ring/plugin-catalog';

export const ServicePage = () => {
  return (
    React.createElement(ServiceLayout, null
      , React.createElement(ServiceLayout.Route, { path: "/", title: "Overview",}
        , React.createElement(ServiceApiPage, null )
      )
    )
  );
};
