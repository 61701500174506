import React from 'react';
import { Content, ContentHeader, } from '@backstage/core-components';
import { CatalogLayout, FilteredEntityLayout, EntityListContainer, FilterContainer } from '../../../common/components';
import { ApplicationsTable } from '../ApplicationsTable';
import { ApplicationListProvider } from '../../hooks/useApplicationListProvider';
import { ApplicationTypePicker } from '../ApplicationTypePicker';

import { ApplicationOwnerPicker } from '../ApplicationOwnerPicker';
import { ApplicationUserListPicker } from '../ApplicationUserListPicker';
import { CreateApplicationButton } from '../CreateApplicationButton';






export const ApplicationIndex = ({ columns, actions }) => {
  return (
    React.createElement(CatalogLayout, null
      , React.createElement(Content, null
        , React.createElement(ApplicationListProvider, null
          , React.createElement(ContentHeader, { title: "Application",}
            , React.createElement(CreateApplicationButton, null )
          )
          , React.createElement(FilteredEntityLayout, null
            , React.createElement(FilterContainer, null
              , React.createElement(ApplicationTypePicker, null )
              , React.createElement(ApplicationUserListPicker, null )
              , React.createElement(ApplicationOwnerPicker, null )
            )
            , React.createElement(EntityListContainer, null
              , React.createElement(ApplicationsTable, { columns: columns, actions: actions,} )
            )
          )
        )
      )
    )
  );
};
