import { Box, FormHelperText, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CronExpressionInput } from './CronExpressionInput';
import cronstrue from 'cronstrue/i18n';

export const CronExpressionConfig = () => {
  const [translatedCron, setTranslatedCron] = useState('');
  const {
    setValue,
    getValues,
    formState: { errors },
    watch
  } = useFormContext();
  watch('sla.cron_expression_manual');

  const actualCron = getValues('sla.schedule_expression');

  const onExpressionChange = (newCron) => {
    setValue('sla.schedule_expression', newCron, { shouldValidate: true });
  };

  React.useEffect(() => {
    try {
      setTranslatedCron(cronstrue.toString(actualCron));
    } catch (error) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      setTranslatedCron(message);
    }
  }, [actualCron]);

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: 8 },}
      , React.createElement(Typography, { style: { fontWeight: 'bolder' },}, "Table Run Schedule"  )
      , React.createElement(Box, { display: "flex", flexDirection: "column",}
        , React.createElement(CronExpressionInput, { cronExpInput: actualCron, setCronExpInput: onExpressionChange,} )

        , errors?.sla?.schedule_expression ? (
          React.createElement(FormHelperText, { error: true,}, errors.sla.schedule_expression.message)
        ) : (
          React.createElement(Box, null, translatedCron)
        )
      )
    )
  );
};
