
import * as yup from 'yup';
import { ValidationError } from '../validation';









const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  owner: yup.string().required(),
  type: yup.string().required()
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function cast(data) {
  try {
    return validationSchema.validateSync(data, { abortEarly: false });
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      const fieldErrors = error.inner.map(innerError => ({
        fieldName: innerError.path || '',
        description: innerError.message
      }));
      throw new ValidationError(fieldErrors, error.message);
    } else {
      throw error;
    }
  }
}
