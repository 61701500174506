import React from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';







export const TableHeader = ({ title, type, onAddTable }) => {
  return (
    React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
      , React.createElement(Box, { whiteSpace: "nowrap",}, title)
      , React.createElement(Box, { ml: 3,}
        , React.createElement(Grid, { container: true, direction: "row", justifyContent: "center", alignItems: "center",}
          , type === 'dashboardTables' && (
            React.createElement(Button, { color: "primary", onClick: onAddTable, startIcon: React.createElement(AddIcon, null ),}, "Add data monitoring setting"

            )
          )
        )
      )
    )
  );
};
