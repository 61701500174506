import { createApiRef, } from '@backstage/core-plugin-api';

export const sdkCommitmentToolApiRef = createApiRef({
    id: 'plugin.sdk-commitment-tool.service',
    description: 'Used to make requests towards the sdk-commitment-tool backend',
});

export function isOk(res) {
    return res.status >= 200 && res.status < 300;
}

export class SdkCommitmentToolApi {
      identityApi;
      discoveryApi;

    commonHeaders = {
        'Content-Type': 'application/json',
    };

    async getBaseUrl() {
        const baseUrl = await this.discoveryApi.getBaseUrl('sdk-commitment-tool');
        return `${baseUrl}/v1`;
    }

    constructor(options) {
        this.identityApi = options.identityApi;
        this.discoveryApi = options.discoveryApi;
    }

    async get(url) {
        const token = await this.identityApi.getIdToken();
        const email = this.getUserEmail();
        const headers = {
            ...(email && { 'X-User-Email': email }),
            ...(token && { Authorization: `Bearer ${token}` }),
            ...this.commonHeaders,
        };
        return fetch(`${await this.getBaseUrl()}${url}`, {
            headers: headers,
        });
    }

    async post(url, body) {
        const token = await this.identityApi.getIdToken();
        const email = this.getUserEmail();
        const headers = {
            ...(email && { 'X-User-Email': email }),
            ...(token && { Authorization: `Bearer ${token}` }),
            ...this.commonHeaders,
        };

        const res = await fetch(`${await this.getBaseUrl()}${url}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        });

        return res;
    }

    async postFile(url, body) {
        const token = await this.identityApi.getIdToken();
        const email = this.getUserEmail();
        const headers = {
            ...(email && { 'X-User-Email': email }),
            ...(token && { Authorization: `Bearer ${token}` }),
        };

        const res = await fetch(`${await this.getBaseUrl()}${url}`, {
            method: 'POST',
            headers,
            body,
        });

        return res;
    }

    async patch(url, body) {
        const token = await this.identityApi.getIdToken();
        const email = this.getUserEmail();
        const headers = {
            ...(email && { 'X-User-Email': email }),
            ...(token && { Authorization: `Bearer ${token}` }),
            ...this.commonHeaders,
        };

        const res = await fetch(`${await this.getBaseUrl()}${url}`, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(body),
        });

        return res;
    }

    async delete(url) {
        const token = await this.identityApi.getIdToken();
        const email = this.getUserEmail();
        const headers = {
            ...(email && { 'X-User-Email': email }),
            ...(token && { Authorization: `Bearer ${token}` }),
            ...this.commonHeaders,
        };

        const res = await fetch(`${await this.getBaseUrl()}${url}`, {
            method: 'DELETE',
            headers,
        });

        return res;
    }

    getUserEmail() {
        return this.identityApi.getProfile().email;
    }
}
