import { Box, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { CustomSwitch } from '@ring/plugin-common';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { getHumanizeBoolean } from '../../helpers';
import { COLUMN_VALIDATIONS_KEY, } from '../../types';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';

export const ColPairRoundValuesAGreaterThanB = ({
  fieldIndex,
  tableColumns,
  isSummaryVersionActive
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = (field) => {
    return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
  };

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Grid, { container: true, spacing: 2,}
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(TextField, {
            select: true,
            ...register(`${fieldPath}.column_A` ),
            label: "Column A" ,
            fullWidth: true,
            defaultValue: "",
            helperText: getErrorMessageByField('column_A'),
            error: !!getErrorMessageByField('column_A'),}
          
            , tableColumns.map((o, index) => (
              React.createElement(MenuItem, { key: o + index, value: o,}
                , o
              )
            ))
          )
        )
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(TextField, {
            select: true,
            label: "Column B" ,
            fullWidth: true,
            defaultValue: "",
            ...register(`${fieldPath}.column_B` ),
            helperText: getErrorMessageByField('column_B'),
            error: !!getErrorMessageByField('column_B'),}
          
            , tableColumns.map((o, index) => (
              React.createElement(MenuItem, { key: o + index, value: o,}
                , o
              )
            ))
          )
        )
        , React.createElement(Grid, { item: true, sm: 3,}
          , React.createElement(Controller, {
            control: control,
            name: `${fieldPath}.or_equal` ,
            defaultValue: false,
            render: ({ field: { value, ...rest } }) => {
              return React.createElement(FormControlLabel, { control: React.createElement(CustomSwitch, { checked: value, ...rest,} ), label: "Or Equal" ,} );
            },}
          )
        )
        , React.createElement(Grid, { item: true, sm: 4,}
          , React.createElement(TextField, {
            variant: "outlined",
            size: "small",
            label: "Expected success rate"  ,
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.mostly` ),
            helperText: getErrorMessageByField('mostly'),
            error: !!getErrorMessageByField('mostly'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 4,}
          , React.createElement(TextField, {
            variant: "outlined",
            size: "small",
            label: "Round N digits"  ,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.round_ndigits` ),
            helperText: getErrorMessageByField('round_ndigits'),
            error: !!getErrorMessageByField('round_ndigits'),}
          )
        )

        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
        )
      )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();

  const { column_A, column_B, or_equal, round_ndigits, mostly, slack_enabled, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "grid", gridGap: "1rem", gridTemplateColumns: "1fr 2fr" ,}
      , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: 'span 2' },}
        , React.createElement('strong', null, "Column A:" ), " " , column_A
      )
      , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: 'span 2' },}
        , React.createElement('strong', null, "Column B:" ), " " , column_B
      )
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Or Equal:" ), " " , getHumanizeBoolean(or_equal)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Expected success rate:"  ), " " , mostly
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Round N digits:"  ), " " , round_ndigits
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
