/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useApi } from '@backstage/core-plugin-api';
import { Header, HomepageTimer, Page } from '@backstage/core-components';
import React from 'react';
import { getTimeBasedGreeting } from './utils/timeUtil';
import { identityApiRingRef } from '@ring/plugin-common';





const CatalogLayout = ({ children }) => {
  const greeting = getTimeBasedGreeting();
  const profile = useApi(identityApiRingRef).getProfile();
  const userId = useApi(identityApiRingRef).getUserId();

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, {
        title: `${greeting.greeting}, ${profile.displayName || userId}!`,
        subtitle: "Wildlife Engineering Application Catalog"   ,
        tooltip: greeting.language,
        pageTitleOverride: "Home",}
      
        , React.createElement(HomepageTimer, null )
      )
      , children
    )
  );
};

export default CatalogLayout;
