/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useCallback, useEffect } from 'react';

import { scaffolderApiRef } from '../../../api';
import { useAsync } from 'react-use';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';

function splitFormData(url) {
  let host = undefined;
  let owner = undefined;
  let repo = undefined;
  let organization = undefined;

  try {
    if (url) {
      const parsed = new URL(`https://${url}`);
      host = parsed.host;
      owner = parsed.searchParams.get('owner') || undefined;
      repo = parsed.searchParams.get('repo') || undefined;
      // This is azure dev ops specific. not used for any other provider.
      organization = parsed.searchParams.get('organization') || undefined;
    }
  } catch {
    /* ok */
  }

  return { host, owner, repo, organization };
}

function serializeFormData(data) {
  if (!data.host) {
    return undefined;
  }
  const params = new URLSearchParams();
  if (data.owner) {
    params.set('owner', data.owner);
  }
  if (data.repo) {
    params.set('repo', data.repo);
  }
  if (data.organization) {
    params.set('organization', data.organization);
  }

  return `${data.host}?${params.toString()}`;
}

export const RepoUrlPicker = ({ onChange, uiSchema, rawErrors, formData }) => {
  const api = useApi(scaffolderApiRef);
  const allowedHosts = uiSchema['ui:options']?.allowedHosts ;

  const { value: integrations, loading } = useAsync(async () => {
    return await api.getIntegrationsList({ allowedHosts });
  });

  const { host, owner, repo, organization } = splitFormData(formData);
  const updateHost = useCallback(
    (evt) =>
      onChange(
        serializeFormData({
          host: evt.target.value ,
          owner,
          repo,
          organization
        })
      ),
    [onChange, owner, repo, organization]
  );

  const updateOwner = useCallback(
    (evt) =>
      onChange(
        serializeFormData({
          host,
          owner: evt.target.value ,
          repo,
          organization
        })
      ),
    [onChange, host, repo, organization]
  );

  const updateRepo = useCallback(
    (evt) =>
      onChange(
        serializeFormData({
          host,
          owner,
          repo: evt.target.value ,
          organization
        })
      ),
    [onChange, host, owner, organization]
  );

  const updateOrganization = useCallback(
    (evt) =>
      onChange(
        serializeFormData({
          host,
          owner,
          repo,
          organization: evt.target.value 
        })
      ),
    [onChange, host, owner, repo]
  );

  useEffect(() => {
    if (host === undefined && integrations?.length) {
      onChange(
        serializeFormData({
          host: integrations[0].host,
          owner,
          repo,
          organization
        })
      );
    }
  }, [onChange, integrations, host, owner, repo, organization]);

  if (loading) {
    return React.createElement(Progress, null );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(FormControl, { margin: "normal", required: true, error: rawErrors?.length > 0 && !host,}
        , React.createElement(InputLabel, { htmlFor: "hostInput",}, "Host")
        , React.createElement(Select, { native: true, id: "hostInput", onChange: updateHost, value: host,}
          , integrations ? (
            integrations
              .filter(i => allowedHosts?.includes(i.host))
              .map(i => (
                React.createElement('option', { key: i.host, value: i.host,}
                  , i.title
                )
              ))
          ) : (
            React.createElement('p', null, "loading")
          )
        )
        , React.createElement(FormHelperText, null, "The host where the repository will be created"       )
      )
      , host === 'dev.azure.com' && (
        React.createElement(FormControl, { margin: "normal", required: true, error: rawErrors?.length > 0 && !organization,}
          , React.createElement(InputLabel, { htmlFor: "repoInput",}, "Organization")
          , React.createElement(Input, { id: "repoInput", onChange: updateOrganization, value: organization,} )
          , React.createElement(FormHelperText, null, "The name of the organization"    )
        )
      )
      , React.createElement(FormControl, { margin: "normal", required: true, error: rawErrors?.length > 0 && !owner,}
        , React.createElement(InputLabel, { htmlFor: "ownerInput",}, "Owner")
        , React.createElement(Input, { id: "ownerInput", onChange: updateOwner, value: owner,} )
        , React.createElement(FormHelperText, null, "The organization, user or project that this repo will belong to"          )
      )
      , React.createElement(FormControl, { margin: "normal", required: true, error: rawErrors?.length > 0 && !repo,}
        , React.createElement(InputLabel, { htmlFor: "repoInput",}, "Repository")
        , React.createElement(Input, { id: "repoInput", onChange: updateRepo, value: repo,} )
        , React.createElement(FormHelperText, null, "The name of the repository"    )
      )
    )
  );
};
