
















import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { identityApiRingRef } from '@ring/plugin-common';
import { useAsync } from 'react-use';


/**
 * Get the catalog User entity (if any) that matches the logged-in user.
 */
export function useOwnUser() {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRingRef);

  // TODO: get the full entity (or at least the full entity name) from the
  // identityApi
  return useAsync(
    () =>
      catalogApi.getEntityByName({
        kind: 'User',
        namespace: 'default',
        name: identityApi.getUserId()
      }) ,
    [catalogApi, identityApi]
  );
}
