import React from 'react';
import { HeaderIconLinkRow, } from '@backstage/core-components';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { default as DatadogIcon } from '../../../public/datadog_icon.svg';
import { default as LogzIcon } from '../../../public/logz_icon.png';
import { useApplication } from '@ring/plugin-catalog';
import { ApplicationsEnum } from '../../models/apps';

const datadogURL = (app, cluster, appType) => {
  const url = new URL('https://app.datadoghq.com/');

  if (appType === ApplicationsEnum.GO || appType === ApplicationsEnum.BACKEND) {
    url.pathname = 'dashboard/8j3-wf6-r4r/';
  } else if (appType === ApplicationsEnum.PYTHON) {
    url.pathname = 'dashboard/6qp-7pe-6dy/';
  } else {
    throw new Error('Unknow Application Type');
  }

  url.searchParams.set('tpl_var_application', app);
  url.searchParams.set('tpl_var_clustername', cluster);
  return url.toString();
};

const logzURL = (app, cluster) => {
  const url = 'https://app.logz.io/#/dashboard/kibana/discover';
  const kibanaQuery = `(columns:!(message),filters:!(('$state':(store:appState),
meta:(alias:!n,disabled:!f,index:'logzioCustomerIndex*',key:cluster,negate:!f,params:(query:${cluster}),type:phrase),
query:(match_phrase:(cluster:${cluster}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'logzioCustomerIndex*',
key:kubernetes.namespace,negate:!f,params:(query:${app}),type:phrase),query:(match_phrase:(kubernetes.namespace:${app})))),
index:'logzioCustomerIndex*',interval:auto,query:(language:lucene,query:''),sort:!(!('@timestamp',desc)))&_g=(filters:!())&
accountIds=151493&switchToAccountId=151493`;
  return `${url}?_a=${kibanaQuery}`;
};

const EntityObservabilityCard = () => {
  const { application } = useApplication();
  const app = application?.name || '';
  const appType = application?.type || '';

  const viewDatadogStag = {
    title: 'Datadog Staging Dashboard',
    label: 'View Metrics Dashboard Staging',
    icon: React.createElement('img', { src: DatadogIcon, height: "24", alt: "Datadog Staging Dashboard Link"   ,} ),
    onClick: () => window.open(datadogURL(app, 'kube-stag-us-cilium.tfgco.com', appType), '_blank')
  };

  const viewLogzStag = {
    title: 'Logz Dashboard',
    label: 'View Logs Dashboard Staging',
    icon: React.createElement('img', { src: LogzIcon, height: "24", alt: "Logz Staging Dashboard Link"   ,} ),
    onClick: () => window.open(logzURL(app, 'kube-stag-us-cilium.tfgco.com'), '_blank')
  };

  const viewDatadogProd = {
    title: 'Datadog Production Dashboard',
    label: 'View Metrics Dashboard Production',
    icon: React.createElement('img', { src: DatadogIcon, height: "24", alt: "Datadog Production Dashboard Link"   ,} ),
    onClick: () => window.open(datadogURL(app, 'kube-prod-us-cilium.tfgco.com', appType), '_blank')
  };

  const viewLogzProd = {
    title: 'Logz Dashboard',
    label: 'View Logs Dashboard Production',
    icon: React.createElement('img', { src: LogzIcon, height: "24", alt: "Logz Production Dashboard Link"   ,} ),
    onClick: () => window.open(logzURL(app, 'kube-prod-us-cilium.tfgco.com'), '_blank')
  };

  return (
    React.createElement(Card, null
      , React.createElement(CardHeader, {
        title: "Observability",
        subheader: React.createElement(HeaderIconLinkRow, { links: [viewDatadogStag, viewLogzStag, viewDatadogProd, viewLogzProd],} ),}
      )
    )
  );
};

export const ObservabilityPage = (
  React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
    , React.createElement(Grid, { item: true, md: 8, xs: 12,}
      , React.createElement(EntityObservabilityCard, null )
    )
  )
);
