import React from 'react';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';


const YellowStar = withStyles({
  root: {
    color: '#f3ba37'
  }
})(Star);

export const favouriteEntityTooltip = (isStarred) =>
  isStarred ? 'Remove from favorites' : 'Add to favorites';

export const favouriteEntityIcon = (isStarred) => (isStarred ? React.createElement(YellowStar, null ) : React.createElement(StarBorder, null ));







export const FavouriteEntity = ({ entity, isStarred, toggleStarredEntity }) => {
  return (
    React.createElement(IconButton, { color: "inherit", onClick: () => toggleStarredEntity(entity),}
      , React.createElement(Tooltip, { title: favouriteEntityTooltip(isStarred),}, favouriteEntityIcon(isStarred))
    )
  );
};
