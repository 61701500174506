import CodeIcon from '@material-ui/icons/Code';
import GitHubIcon from '@material-ui/icons/GitHub';
import React from 'react';

export const ScmIntegrationIcon = ({ type }) => {
  switch (type) {
    case 'github':
      return React.createElement(GitHubIcon, null );
    default:
      return React.createElement(CodeIcon, null );
  }
};
