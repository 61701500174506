import React, { useEffect, useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';

import { DateTime } from 'luxon';
import { TablesDeliveriresChart } from './TablesDeliveriresChart';
import { TablesValidationsChart } from './TablesValidationsChart';
import { TablesOverview } from './TablesOverview';

import ReplayIcon from '@material-ui/icons/Replay';

import { DashboardReport } from './DashboardReport';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  }
}));

const getActualTime = () => {
  return DateTime.utc().toFormat('yyyy-MM-dd hh:mm a');
};










export const DataQuality = ({
  title = 'My Tables',
  type = 'applicationTables',
  dashboard,
  overviewFn,
  deliveriesFn,
  validationsFn
}) => {
  const classes = useStyles();
  const [lastUpdatedDate, setLastUpdatedDate] = useState(getActualTime());

  const [overviewValues, doReloadOverview] = overviewFn;
  const [deliveriesValues, doReloadDeliveries] = deliveriesFn;
  const [validationsValues, doReloadValidations] = validationsFn;

  const handleReload = () => {
    doReloadOverview();
    doReloadDeliveries();
    doReloadValidations();
    setLastUpdatedDate(getActualTime());
  };

  useEffect(() => {
    doReloadOverview();
    doReloadDeliveries();
    doReloadValidations();
  }, [doReloadDeliveries, doReloadOverview, doReloadValidations]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement('div', { className: classes.header,}
        , React.createElement(Typography, { variant: "h5",}, title)
        , React.createElement(Typography, null, "Last updated: "
            , React.createElement('span', { style: { fontWeight: 500 },}, lastUpdatedDate), ' '
          , React.createElement('span', { style: { fontWeight: 700 },}, "(UTC)")
        )
        , React.createElement(IconButton, {
          style: { padding: 0 },
          'aria-label': "refresh",
          onClick: () => {
            handleReload();
          },
          color: "primary",}
        
          , React.createElement(ReplayIcon, null )
        )
      )
      , React.createElement(DashboardReport, { dashboard: dashboard, edit: !!dashboard?.notification_group_id,} )
      , React.createElement(TablesOverview, { data: overviewValues, type: type, dashboard: dashboard, reload: handleReload,} )
      , React.createElement(Box, { py: "2rem",}
        , React.createElement(TablesDeliveriresChart, { data: deliveriesValues,} )
      )
      , React.createElement(Box, { py: "2rem",}
        , React.createElement(TablesValidationsChart, { data: validationsValues,} )
      )
    )
  );
};
