import React, { useEffect, useMemo, useState } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useAsync } from 'react-use';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowButton } from './ArrowButton';
import { Autocomplete } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import { useCoreBusinessUnits, useSquads } from '@ring/plugin-common';

import { FieldsTable } from './FieldsTable';







const format = (value) => {
  return `0${value}`.slice(-2);
};

export const TableSetup = ({ database, tables, onFail }) => {
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const alertApi = useApi(alertApiRef);

  const { squads = [], loading: loadingSquads } = useSquads();
  const squadsNames = useMemo(() => squads.map(({ name }) => name), [squads]);

  const { value: businessUnits = [] } = useCoreBusinessUnits();
  const [currentTableIndex, setCurrentTableIndex] = useState(0);

  const { setValue, trigger, getValues, formState } = useFormContext();
  const isSubmitting = formState.isSubmitting;

  const {
    value: tablesInfo,
    loading,
    error
  } = useAsync(async () => {
    const response = await dataCatalogApi.getBulkImportTables(database, tables);
    const formedPayload = response.map(table => ({
      database_name: database,
      table_name: table.name,
      metadata: {
        description: '',
        owner: '',
        business_unit: '',
        checkbox: false,
        tags: null,
        schema_metadata: table.schema.map(schema => ({
          name: schema,
          description: '',
          tags: null
        }))
      }
    }));

    setValue('tables', formedPayload);
    return response;
  }, [database, tables]);

  const handleSquadChange = (_, value) => {
    setValue(`tables[${currentTableIndex}].metadata.owner`, value, { shouldValidate: true });
  };

  const handleBusinessUnitChange = (_, value) => {
    setValue(`tables[${currentTableIndex}].metadata.business_unit`, value, { shouldValidate: true });
  };

  const setAllTablesField = (type) => {
    const actualValue = getValues(`tables[${currentTableIndex}].metadata.${type}`);
    if (!actualValue || actualValue === '') return;
    const newTables = getValues().tables.map((table) => ({
      ...table,
      metadata: { ...table.metadata, [type]: actualValue }
    }));
    setValue('tables', newTables);
    trigger();
    alertApi.post({
      severity: 'success',
      message: `All tables ${type === 'owner' ? 'Owner' : 'Buissnes Unit'} were set to ${actualValue}`
    });
  };

  const handleBack = () => {
    if (currentTableIndex !== 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentTableIndex < tables.length - 1) {
      setCurrentTableIndex(currentTableIndex + 1);
    }
  };

  const handleCheckbox = (_, checked) => {
    setValue(`tables[${currentTableIndex}].metadata.checkbox`, checked, {
      shouldValidate: true
    });
    if (checked) {
      const allColumns = getValues(`tables[${currentTableIndex}].metadata.schema_metadata`);
      const newColumns = allColumns.map(column => ({ ...column, tags: [] }));
      setValue(`tables[${currentTableIndex}].metadata.schema_metadata`, newColumns);
    }
  };

  const getCheckboxValue = () => {
    return getValues(`tables[${currentTableIndex}].metadata.checkbox`);
  };

  useEffect(() => {
    if (error) {
      alertApi.post({
        severity: 'error',
        message: `${error}`
      });
      onFail();
    }
  }, [alertApi, error, onFail]);

  if (loading || !tablesInfo || isSubmitting) {
    return (
      React.createElement(Box, { display: "grid", justifyContent: "center", padding: "4rem",}
        , React.createElement(CircularProgress, { size: "2rem",} )
      )
    );
  }
  return (
    React.createElement(Container, { maxWidth: "md",}
      , React.createElement(Grid, { container: true, alignItems: "center",}
        , React.createElement(Grid, { item: true, xs: 2,}
          , React.createElement(ArrowButton, { type: "back", onClick: handleBack, disabled: currentTableIndex === 0,} )
        )
        , React.createElement(Grid, { item: true, xs: 8,}
          , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '1rem' },}
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' },}
              , React.createElement(Typography, { variant: "h5",}, tablesInfo[currentTableIndex]?.name)
              , React.createElement(Box, { display: "flex", alignItems: "center", style: { gap: '4px' },}
                , React.createElement(InfoOutlinedIcon, { color: "primary", fontSize: "small",} )
                , React.createElement(Typography, { variant: "body2",}, "Please define an owner to the table and the privacy policy of its columns"

                )
              )
            )
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' },}
              , React.createElement(Autocomplete, {
                options: squadsNames,
                loading: loadingSquads,
                value: getValues(`tables[${currentTableIndex}].metadata.owner`),
                onChange: handleSquadChange,
                renderInput: params => React.createElement(TextField, { required: true, ...params, label: "Owner",} ),}
              )
              , React.createElement(Button, {
                variant: "text",
                color: "primary",
                startIcon: React.createElement(ControlPointDuplicate, null ),
                style: { width: 'fit-content', paddingLeft: '4px' },
                onClick: () => {
                  setAllTablesField('owner');
                },}
              , "Apply owner to all tables"

              )
            )
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' },}
              , React.createElement(Autocomplete, {
                options: businessUnits.map(b => b.name),
                value: getValues(`tables[${currentTableIndex}].metadata.business_unit`),
                onChange: handleBusinessUnitChange,
                renderInput: params => React.createElement(TextField, { required: true, ...params, label: "Business Unit" ,} ),}
              )
              , React.createElement(Button, {
                variant: "text",
                color: "primary",
                startIcon: React.createElement(ControlPointDuplicate, null ),
                style: { width: 'fit-content', paddingLeft: '4px' },
                onClick: () => {
                  setAllTablesField('business_unit');
                },}
              , "Apply business unit to all tables"

              )
            )
            , React.createElement(TextField, {
              key: currentTableIndex,
              placeholder: "Description",
              fullWidth: true,
              variant: "outlined",
              multiline: true,
              minRows: 2,
              defaultValue: getValues(`tables[${currentTableIndex}].metadata.description`),
              onChange: e => {
                setValue(`tables[${currentTableIndex}].metadata.description`, e.target.value);
              },}
            )
            , React.createElement(Box, { py: "2rem",}
              , React.createElement(Typography, { style: { fontWeight: 'bolder' },}, "Data privacy" )
              , React.createElement(FormControlLabel, {
                control: 
                  React.createElement(Checkbox, { checked: getCheckboxValue(), onChange: handleCheckbox, name: "checkedB", color: "primary",} )
                ,
                style: { marginTop: '-5px', marginBottom: '-5px' },
                label: "This table doesn't cointain PII data"     ,}
              )
              , React.createElement(Typography, { variant: "body2",}, "If your table contains private data, assign the columns below"         )
            )
            , React.createElement(FieldsTable, { currentTableIndex: currentTableIndex,} )
            , React.createElement(Box, { display: "flex", justifyContent: "center", my: "1rem",}
              , React.createElement(Chip, {
                label: `${format(currentTableIndex + 1)}/${format(tables.length)}`,
                color: "primary",
                variant: "outlined",}
              )
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 2,}
          , React.createElement(ArrowButton, { type: "next", onClick: handleNext, disabled: currentTableIndex === tables.length - 1,} )
        )
      )
    )
  );
};
