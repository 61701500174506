import React, { Fragment } from 'react';
import { Card, List, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .11)',
    boxShadow: 'none',
    margin: theme.spacing(1, 0, 1, 0)
  },
  title: {
    margin: theme.spacing(1, 0, 0, 1),
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold'
  },
  listIcon: {
    minWidth: 30,
    color: theme.palette.text.primary
  },
  menuItem: {
    minHeight: theme.spacing(6)
  },
  groupWrapper: {
    margin: theme.spacing(1, 1, 2, 1)
  }
}));










function getFilterGroups(orgName) {
  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'owned',
          label: 'Owned',
          icon: SettingsIcon
        },
        {
          id: 'starred',
          label: 'Starred',
          icon: StarIcon
        }
      ]
    },
    {
      name: orgName ?? 'Company',
      items: [
        {
          id: 'all',
          label: 'All'
        }
      ]
    }
  ];
}






export const UserListPicker = ({ onChange, selected }) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const filterGroups = getFilterGroups(orgName);

  return (
    React.createElement(Card, { className: classes.root,}
      , filterGroups.map(group => (
        React.createElement(Fragment, { key: group.name,}
          , React.createElement(Typography, { variant: "subtitle2", className: classes.title,}
            , group.name
          )
          , React.createElement(Card, { className: classes.groupWrapper,}
            , React.createElement(List, { disablePadding: true, dense: true,}
              , group.items.map(item => (
                React.createElement(MenuItem, {
                  key: item.id,
                  button: true,
                  divider: true,
                  onClick: () => onChange(item.id),
                  selected: item.id === selected,
                  className: classes.menuItem,}
                
                  , item.icon && (
                    React.createElement(ListItemIcon, { className: classes.listIcon,}
                      , React.createElement(item.icon, { fontSize: "small",} )
                    )
                  )
                  , React.createElement(ListItemText, null
                    , React.createElement(Typography, { variant: "body1", 'data-testid': `user-picker-${item.id}`,}
                      , item.label
                    )
                  )
                )
              ))
            )
          )
        )
      ))
    )
  );
};
