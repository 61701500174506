export var Roles; (function (Roles) {
  const ADMIN = 0; Roles[Roles["ADMIN"] = ADMIN] = "ADMIN";
})(Roles || (Roles = {}));





export var ServicesEnum; (function (ServicesEnum) {
  const DOCKER_REGISTRY_SERVICE = 'docker_registry_service'; ServicesEnum["DOCKER_REGISTRY_SERVICE"] = DOCKER_REGISTRY_SERVICE;
  const INFRASTRUCTURE_REPOSITORY_SERVICE = 'infrastructure_repository_service'; ServicesEnum["INFRASTRUCTURE_REPOSITORY_SERVICE"] = INFRASTRUCTURE_REPOSITORY_SERVICE;
  const VAULT_INTEGRATION_SERVICE = 'vault_integration_service'; ServicesEnum["VAULT_INTEGRATION_SERVICE"] = VAULT_INTEGRATION_SERVICE;
  const POSTGRES_DATABASE_SERVICE = 'postgres_database_service'; ServicesEnum["POSTGRES_DATABASE_SERVICE"] = POSTGRES_DATABASE_SERVICE;
  const FLUX_INTEGRATION_SERVICE = 'flux_integration_service'; ServicesEnum["FLUX_INTEGRATION_SERVICE"] = FLUX_INTEGRATION_SERVICE;
  const ECR_REPOSITORY_SERVICE = 'ecr_repository_service'; ServicesEnum["ECR_REPOSITORY_SERVICE"] = ECR_REPOSITORY_SERVICE;
  const DNS_SERVICE = 'dns_service'; ServicesEnum["DNS_SERVICE"] = DNS_SERVICE;
  const DEPLOYMENT_SCOPE_SERVICE = 'deployment_scope_service'; ServicesEnum["DEPLOYMENT_SCOPE_SERVICE"] = DEPLOYMENT_SCOPE_SERVICE;
})(ServicesEnum || (ServicesEnum = {}));

export var ServiceStatus; (function (ServiceStatus) {
  const ERROR = 'error'; ServiceStatus["ERROR"] = ERROR;
  const PENDING_CREATING = 'pending-creating'; ServiceStatus["PENDING_CREATING"] = PENDING_CREATING; // The service was created on the catalog database
  const CREATING = 'creating'; ServiceStatus["CREATING"] = CREATING; // The service is being created externally
  const PENDING_RESOURCES = 'resource-pending'; ServiceStatus["PENDING_RESOURCES"] = PENDING_RESOURCES; // The service was created but it's resources had not been stored on the data lake yet
  const REMOVING_RESOURCES = 'resource-removing'; ServiceStatus["REMOVING_RESOURCES"] = REMOVING_RESOURCES; // The service is being deleted and it's resources have not been removed from the data lake yet
  const ACTIVE = 'active'; ServiceStatus["ACTIVE"] = ACTIVE; // The service was created and it's resources were stored in the data lake successfully
  const DELETING = 'deleting'; ServiceStatus["DELETING"] = DELETING; // The service is being deleted
  const DELETED = 'deleted'; ServiceStatus["DELETED"] = DELETED; // The service was deleted successfully
})(ServiceStatus || (ServiceStatus = {}));

export var ServiceManager; (function (ServiceManager) {
  const USER = 'user'; ServiceManager["USER"] = USER;
  const RING = 'ring'; ServiceManager["RING"] = RING;
})(ServiceManager || (ServiceManager = {}));
