import React, { useCallback } from 'react';
import { ActionsHeader, SquadsProvider, useMixpanel } from '@ring/plugin-common';
import { Box, Button, Container, Grid, makeStyles, } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TableSetup } from './TableSetup';
import { TableTags } from '../../types';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useNavigate, useParams } from 'react-router';

function noOnePiiTag(value) {
  return value.every((item) => {
    return item.tags === null || item.tags?.length === 0;
  });
}

function validatePiiData(value, testContext) {
  const { parent } = testContext;
  const noOnePiiTagItem = parent?.schema_metadata && noOnePiiTag(parent.schema_metadata);

  if (value) {
    return noOnePiiTagItem;
  }
  return !noOnePiiTagItem;
}

function getTableTags(prevTags, noPiiColumn) {
  const setTags = new Set(prevTags);
  if (noPiiColumn) {
    setTags.delete(TableTags.PII_DATA);
  } else {
    setTags.add(TableTags.PII_DATA);
  }
  return Array.from(setTags);
}

const schema = yup
  .object()
  .shape({
    table: yup.object().shape({
      database_name: yup.string().required(),
      table_name: yup.string().required(),
      metadata: yup
        .object()
        .shape({
          description: yup.string(),
          owner: yup.string().required(),
          business_unit: yup.string().required(),
          checkbox: yup
            .boolean() // @ts-ignore - override correct yup type
            .test('PII DATA', 'at least one table column should be checked as PII DATA', validatePiiData),
          tags: yup.array().nullable(true),
          schema_metadata: yup.array(
            yup.object().shape({
              name: yup.string().required(),
              description: yup.string(),
              tags: yup.array().nullable(true)
            })
          )
        })
        .required()
    })
  })
  .required();





const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '1rem'
    },
    paddingBottom: '1rem'
  },
  stepper: {
    background: 'none',
    border: 'none',
    padding: '2rem 30vw'
  }
}));





export const EditTableMetadataPage = ({ editMode }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tableName } = useParams();
  const { track } = useMixpanel();
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);

  const selectedDatabase = tableName.split('.', 2)[0];
  const selectedTable = tableName.split('.', 2)[1];

  const methods = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({})
  });

  const { handleSubmit, formState } = methods;

  const isSubmitting = formState.isSubmitting;
  const disabledCondition = !formState.isValid;

  const onSubmit = useCallback(
    async (data) => {
      const noPiiColumn = noOnePiiTag(data.table.metadata.schema_metadata);
      const prevTags = data.table.metadata.tags || [];
      const newMetadata = { ...data.table.metadata, tags: getTableTags(prevTags, noPiiColumn) };
      const { checkbox, ...filteredMetadata } = newMetadata;
      const updateTablesPayload = [{ ...data.table, metadata: filteredMetadata }];
      try {
        const { success, failure } = await dataCatalogApi.bulkImportTables({ tables: updateTablesPayload });
        const numberOfErrors = failure.length;
        const numberOfSuccesses = success.length;

        track('Edit table metadata', data);

        if (numberOfErrors === 0 && numberOfSuccesses !== 0) {
          alertApi.post({
            severity: 'success',
            message: 'Metadata successfully updated'
          });
        } else if (numberOfErrors !== 0 && numberOfSuccesses !== 0) {
          alertApi.post({
            severity: 'warning',
            message: `Metadata failed to be updated`
          });
        }
        navigate(`/observability/tables/${selectedDatabase}.${selectedTable}/metadata/view`);
      } catch (error) {
        errorApi.post(error );
      }
    },
    [dataCatalogApi, navigate, track, selectedDatabase, selectedTable, alertApi, errorApi]
  );

  const onEdit = useCallback(async () => {
    navigate(`/observability/tables/${selectedDatabase}.${selectedTable}/metadata/edit`);
  }, [navigate, selectedDatabase, selectedTable]);

  const saveOrEditButton = (edit) => {
    if (edit) {
      return (
        React.createElement(Container, { maxWidth: "lg",}
          , React.createElement(Grid, { container: true,}
            , React.createElement(Grid, { item: true, xs: 11,}
              , React.createElement('div', { className: classes.actions,}
                , React.createElement('span', null
                  , React.createElement(Button, {
                    variant: "contained",
                    color: "primary",
                    disabled: disabledCondition || isSubmitting,
                    onClick: handleSubmit(onSubmit),}
                  , "Save"

                  )
                )
              )
            )
          )
        )
      );
    }
    return (
      React.createElement(Container, { maxWidth: "lg",}
        , React.createElement(Grid, { container: true,}
          , React.createElement(Grid, { item: true, xs: 2,}
            , React.createElement('div', { className: classes.actions,}
              , React.createElement('span', null
                , React.createElement(Button, { variant: "contained", color: "primary", onClick: onEdit,}, "Edit"

                )
              )
            )
          )
        )
      )
    );
  };

  return (
    React.createElement(SquadsProvider, null
      , React.createElement(ActionsHeader, { title: "Table metadata" ,} )
      , React.createElement(Box, { display: "flex", flexDirection: "column",}
        , React.createElement(FormProvider, { ...methods,}
          , React.createElement('form', { style: { flex: 1 },}
            , React.createElement(TableSetup, { database: selectedDatabase, table: selectedTable, editMode: editMode,} )
            , saveOrEditButton(editMode)
          )
        )
      )
    )
  );
};
