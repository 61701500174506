import React, { } from 'react';
import { ColumnExpectationNames, TableExpectationNames } from '../../../typesV2';
import {
  ColCountDistinctValuesToBeBetween,
  ColPairRoundValuesAGreaterThanB,
  ColRoundValuesNotBeZero,
  ColValuesNotBeNull,
  ColValuesToBeBetween,
  ColValuesToBeInSet,
  ColValuesToBeUnique,
  ColValuesToMatchRegex
} from './columnValidationFields';

import {
  TableCustomValidation,
  TableCustomValidationGroupedResults,
  TableRowCount,
  CompoundColsToBeUnique
} from './tableValidationFields';

export const FieldsByExpectName


 = {
  [ColumnExpectationNames.COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN]: {
    component: (props) => React.createElement(ColCountDistinctValuesToBeBetween, { ...props,} ),
    title: 'Expect count distinct values to be between'
  },
  [ColumnExpectationNames.COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B]: {
    component: (props) => React.createElement(ColPairRoundValuesAGreaterThanB, { ...props,} ),
    title: 'Expect round values column A to be greater than B'
  },
  [ColumnExpectationNames.COL_ROUND_VALUES_NOT_BE_ZERO]: {
    component: (props) => React.createElement(ColRoundValuesNotBeZero, { ...props,} ),
    title: 'Expect round values to not be zero'
  },
  [ColumnExpectationNames.COL_VALUES_NOT_BE_NULL]: {
    component: (props) => React.createElement(ColValuesNotBeNull, { ...props,} ),
    title: 'Expect values to not be null'
  },
  [ColumnExpectationNames.COL_VALUES_TO_BE_BETWEEN]: {
    component: (props) => React.createElement(ColValuesToBeBetween, { ...props,} ),
    title: 'Expect values to be between'
  },
  [ColumnExpectationNames.COL_VALUES_TO_BE_IN_SET]: {
    component: (props) => React.createElement(ColValuesToBeInSet, { ...props,} ),
    title: 'Expect values to be in set'
  },
  [ColumnExpectationNames.COL_VALUES_TO_BE_UNIQUE]: {
    component: (props) => React.createElement(ColValuesToBeUnique, { ...props,} ),
    title: 'Expect values to be unique'
  },
  [ColumnExpectationNames.COL_VALUES_TO_MATCH_REGEX]: {
    component: (props) => React.createElement(ColValuesToMatchRegex, { ...props,} ),
    title: 'Expect column values to match regex'
  },
  [TableExpectationNames.COMP_COLS_TO_BE_UNIQUE]: {
    component: (props) => React.createElement(CompoundColsToBeUnique, { ...props,} ),
    title: 'Expect compound columns to be unique'
  },
  [TableExpectationNames.TABLE_ROW_COUNT]: {
    component: (props) => React.createElement(TableRowCount, { ...props,} ),
    title: 'Expect row count to be between'
  },
  [TableExpectationNames.CUSTOM_VALIDATION]: {
    component: (props) => React.createElement(TableCustomValidation, { ...props,} ),
    title: 'Custom validation'
  },
  [TableExpectationNames.CUSTOM_VALIDATION_GROUPED_RESULTS]: {
    component: (props) => React.createElement(TableCustomValidationGroupedResults, { ...props,} ),
    title: 'Custom validation grouped results'
  }
};
