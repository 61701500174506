import { createApiFactory, createPlugin, discoveryApiRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { MachineLearningExperimentsApiClient, machineLearningExperimentsApiRef } from './api';
import { identityApiRingRef } from '@ring/plugin-common';

export const machineLearningExperimentsPlugin = createPlugin({
  id: 'machine-learning-experiments',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: machineLearningExperimentsApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) =>
        new MachineLearningExperimentsApiClient({ discoveryApi, identityApi: identityApiRing })
    })
  ]
});
