import { useApi } from '@backstage/core-plugin-api';
import { Header, HomepageTimer, Page } from '@backstage/core-components';
import React from 'react';
import { getTimeBasedGreeting } from './utils/timeUtil';
import { identityApiRingRef } from '@ring/plugin-common';





export const CatalogLayout = ({ children }) => {
  const greeting = getTimeBasedGreeting();
  const profile = useApi(identityApiRingRef).getProfile();
  const userId = useApi(identityApiRingRef).getUserId();

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, {
        title: `${greeting.greeting}, ${profile.displayName || userId}!`,
        subtitle: "Wildlife Engineering Application Catalog"   ,
        tooltip: greeting.language,
        pageTitleOverride: "Home",}
      
        , React.createElement(HomepageTimer, null )
      )
      , children
    )
  );
};
