import React from 'react';
import { Box, Chip, TextField, Typography, makeStyles, MenuItem } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { OpsgeniePriorityOptions } from '../types';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 0
  }
}));

export const NotificationSettings = () => {
  const classes = useStyles();
  const { control, formState } = useFormContext();

  const { errors } = formState;
  return (
    React.createElement(Box, { py: "2rem", display: "flex", flexDirection: "column", style: { gap: '8px' },}
      , React.createElement(Typography, { style: { fontWeight: 'bolder', marginBottom: '1rem' },}, "Notification Settings" )
      , React.createElement(Box, null
        , React.createElement(Typography, { variant: "subtitle1",}, "Slack Channels to notify"   )
        , React.createElement(Controller, {
          name: "notifications.slack_channels",
          render: ({ field: { onChange, ...fieldProps } }) => (
            React.createElement(Autocomplete, {
              multiple: true,
              freeSolo: true,
              options: [],
              disableClearable: true,
              onChange: (_, data) => onChange(data),
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  React.createElement(Chip, { size: "small", variant: "outlined", label: option, ...getTagProps({ index }),} )
                ))
              ,
              renderInput: params => (
                React.createElement(TextField, {
                  ...params,
                  variant: "outlined",
                  FormHelperTextProps: {
                    classes: {
                      root: classes.root
                    }
                  },
                  InputLabelProps: { shrink: true },
                  placeholder: "# Slack Group"  ,
                  helperText: "You can add multiple chanels by typing the chanel name and pressing enter"            ,}
                )
              ),
              ...fieldProps,}
            )
          ),
          control: control,}
        )
      )
      , React.createElement(Box, null
        , React.createElement(Typography, { variant: "subtitle1",}, "OpsGenie groups to notify"   )
        , React.createElement(Controller, {
          name: "notifications.opsgenie_groups",
          render: ({ field: { onChange, ...fieldProps } }) => (
            React.createElement(Autocomplete, {
              multiple: true,
              freeSolo: true,
              options: [],
              disableClearable: true,
              onChange: (_, data) => onChange(data),
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  React.createElement(Chip, {
                    key: option + index,
                    size: "small",
                    variant: "outlined",
                    label: option,
                    ...getTagProps({ index }),}
                  )
                ))
              ,
              renderInput: params => (
                React.createElement(TextField, {
                  ...params,
                  variant: "outlined",
                  FormHelperTextProps: {
                    classes: {
                      root: classes.root
                    }
                  },
                  InputLabelProps: { shrink: true },
                  placeholder: "Ops Genie Groups"  ,
                  helperText: "You can add multiple groups by typing the group name and pressing enter"            ,}
                )
              ),
              ...fieldProps,}
            )
          ),
          control: control,}
        )
      )

      , React.createElement(Controller, {
        name: "notifications.opsgenie_priority",
        render: ({ field }) => (
          React.createElement(TextField, {
            select: true,
            label: "Opsgenie Priority" ,
            error: !!errors?.notifications?.opsgenie_priority,
            helperText: errors?.notifications?.opsgenie_priority?.message,
            ...field,}
          
            , Object.entries(OpsgeniePriorityOptions).map(([key, value]) => (
              React.createElement(MenuItem, { key: key, value: key,}
                , value
              )
            ))
          )
        ),
        control: control,}
      )
    )
  );
};
