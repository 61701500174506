import { provisioningApiRef, stagingProvisioiningApiRef } from '../api';
import { useApi } from '@backstage/core-plugin-api';

import { useApplication } from '@ring/plugin-catalog';

export const useProvisioningApi = () => {
  const { application } = useApplication();
  return useApi(
    application?.template?.toString().endsWith('staging') ? stagingProvisioiningApiRef : provisioningApiRef
  );
};

export const getSystemFromEntity = (entity) => {
  const systems = entity.relations
    ?.filter(relation => relation.type === 'partOf' && relation.target.kind === 'system')
    .map(system => system.target.name);
  return systems ? systems[0] : undefined;
};
