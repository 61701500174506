import { createPlugin, createRoutableExtension, createRouteRef } from '@backstage/core-plugin-api';

export const systemCatalogRouteRef = createRouteRef({
  path: 'systems',
  title: 'System Catalog'
});

export const createSystemRouteRef = createRouteRef({
  path: 'systems/create',
  title: 'Create System'
});

export const viewSystemRouteRef = createRouteRef({
  path: 'systems/:namespace/:kind/:name',
  title: 'View System'
});

export const systemCatalogPlugin = createPlugin({
  id: 'system-catalog',
  routes: {
    root: systemCatalogRouteRef
  }
});

export const SystemCatalogPage = systemCatalogPlugin.provide(
  createRoutableExtension({
    component: () => import('./Router').then(m => m.Router),
    mountPoint: systemCatalogRouteRef
  })
);
