import { useApi } from '@backstage/core-plugin-api';
import { mixpanelApiRef } from '../apis';

export const useMixpanel = () => {
  const mixpanelApi = useApi(mixpanelApiRef);

  const track = (name, props) => {
    mixpanelApi.track(name, props);
  };

  return { track };
};
