import React, { forwardRef } from 'react';
import { Link, } from '@backstage/core-components';









export const EntityRefLink = forwardRef((props, ref) => {
  const { entityRef, children, name, displayName, forceRef, ...linkProps } = props;

  if (!name) return null;

  const link = forceRef ? `${forceRef}/${name}` : `/catalog/${entityRef}/${name}`;

  return (
    React.createElement(Link, { ...linkProps, ref: ref, to: link,}
      , children
      , !children && displayName ? displayName : name
    )
  );
});
