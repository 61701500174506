import React from 'react';
import {

  Typography,
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { dataObservabilityApiRef } from '../../../../dataObservabilityApi';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '2rem'
  },
  exampleItem: {
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: '1rem 0'
  },
  exampleItemField: {
    margin: '0 1rem'
  }
}));






export const SQLMacrosDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const dataObservabilityClient = useApi(dataObservabilityApiRef);

  const {
    value: macros,
    loading,
    error
  } = useAsync(() => dataObservabilityClient.getMacros(), [dataObservabilityClient]);

  if (loading) {
    return React.createElement(Progress, null );
  } else if (error) {
    return React.createElement(ResponseErrorPanel, { error: error,} );
  }

  return (
    React.createElement(Dialog, { fullWidth: true, open: open, onClose: onClose,}
      , React.createElement(DialogContent, { className: classes.dialog,}
        , React.createElement(Typography, { variant: "h5",}, "Macros' Examples" )
        , React.createElement(Table, { size: "small", 'aria-label': "",}
          , React.createElement(TableHead, null
            , React.createElement(TableRow, null
              , React.createElement(TableCell, null, "Macro")
              , React.createElement(TableCell, null, "Description")
            )
          )
          , React.createElement(TableBody, null
            , macros &&
              Object.entries(macros).map(([key, value], index) => (
                React.createElement(TableRow, { key: key + index,}
                  , React.createElement(TableCell, { style: { wordBreak: 'keep-all' }, size: "medium",}, `'{{${key}}}'`)
                  , React.createElement(TableCell, null, value)
                )
              ))
          )
        )
      )
    )
  );
};
