import { Box, Chip, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { getHumanizeBoolean } from '../../helpers';
import { COLUMN_VALIDATIONS_KEY, ignoreIfOptions } from '../../types';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';

export const CompoundColsToBeUnique = ({
  fieldIndex,
  tableColumns,
  isSummaryVersionActive
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    register
  } = useFormContext();

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = (field) => {
    return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
  };

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Grid, { container: true, spacing: 4,}
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(Controller, {
            name: `${fieldPath}.column_list` ,
            render: ({ field: { onChange, value, ...fieldProps } }) => (
              React.createElement(Autocomplete, {
                ...fieldProps,
                multiple: true,
                freeSolo: true,
                options: tableColumns,
                disableClearable: true,
                value: value ?? [],
                onChange: (_, data) => onChange(data),
                renderTags: (v, getTagProps) =>
                  v.map((option, index) => (
                    React.createElement(Chip, {
                      key: option + index,
                      size: "small",
                      variant: "outlined",
                      label: option,
                      ...getTagProps({ index }),}
                    )
                  ))
                ,
                renderInput: params => (
                  React.createElement(TextField, {
                    ...params,
                    InputLabelProps: { shrink: true },
                    placeholder: "Columns",
                    helperText: getErrorMessageByField('column_list') ?? 'You can select multiple columns',
                    error: !!getErrorMessageByField('column_list'),}
                  )
                ),
                ...fieldProps,}
              )
            ),
            control: control,}
          )
        )
        , React.createElement(Grid, { item: true, sm: 6,}
          , React.createElement(TextField, {
            select: true,
            label: "Ignore Row If"  ,
            fullWidth: true,
            defaultValue: getValues(`${fieldPath}.ignore_row_if`) ?? 'never',
            ...register(`${fieldPath}.ignore_row_if` ),
            helperText: getErrorMessageByField('ignore_row_if'),
            error: !!getErrorMessageByField('ignore_row_if'),}
          
            , Object.values(ignoreIfOptions).map((o, index) => (
              React.createElement(MenuItem, { key: o + index, value: o,}
                , o
              )
            ))
          )
        )
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
        )
      )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();

  const { column_list, slack_enabled, ignore_row_if, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "grid", gridGap: "1rem", gridTemplateColumns: "1fr 2fr" ,}
      , React.createElement(Typography, { style: { gridColumnStart: 'span 2' }, variant: "body2",}
        , React.createElement('strong', null, "Columns:"), " " , column_list?.join(', ')
      )
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ignore if:" ), " " , ignore_row_if
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
