import { RequestError } from '@ring/errors';

export async function processResponseError(response, options) {
  if (response.ok) throw new Error('the response was successful');

  if (options && response.status === 404) return options.notFoundValue;

  let body;

  try {
    body = await response.json();
  } catch {
    body = { message: 'Empty response.' };
  }

  throw new RequestError(response.status, response.statusText, body.message);
}
