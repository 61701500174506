import { createApiRef, } from '@backstage/core-plugin-api';
import { DatabaseStatus, } from './types';
import axios from 'axios';

export const databaseApiRef = createApiRef({
  id: 'plugin.database.service',
  description: 'Used to make requests towards the ring core service'
});

export function isOk(res) {
  return res.status >= 200 && res.status < 300;
}

export class DatabaseApi {
    identityApi;
    discoveryApi;

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getDatabasesTemplates() {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/service-types/database`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async getDatabases(application_id) {
    if (!application_id) {
      throw new Error('Application id is not defined');
    }

    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/applications/${application_id}/services?service_type=database`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async getDatabase(service_id) {
    if (!service_id) {
      throw new Error('Service id is not defined');
    }

    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services/${service_id}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async createNewDatabase({
    name,
    environment,
    tier,
    application_id
  }




) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services`;

    const response = await axios({
      method: 'post',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: {
        application_id,
        type: 'database',
        version: '1.0',
        name,
        status: 'creating',
        environment_type_name: environment,
        meta: {
          tier
        }
      } 
    });

    return response.data;
  }

  async editDatabase({ service_id, tier }) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services/${service_id}`;

    const response = await axios({
      method: 'patch',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: {
        meta: {
          tier
        }
      }
    });

    return response.data;
  }

  async deleteDatabase(service_id) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services/${service_id}`;

    const response = await axios({
      method: 'patch',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: {
        status: DatabaseStatus.DELETING
      }
    });

    return response.data;
  }
}
