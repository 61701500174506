import { createApiFactory, createPlugin, discoveryApiRef } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { DataWorkflowApiClient, dataWorkflowApiRef } from './api';
import { rootRouteRef } from './routes';

export const dataProductDeploymentPlugin = createPlugin({
  id: 'data-product-deployment',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: dataWorkflowApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) =>
        new DataWorkflowApiClient({ discoveryApi, identityApi: identityApiRing })
    })
  ]
});
