import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Edit from '@material-ui/icons/Edit';
import { Table, Link, } from '@backstage/core-components';


import OpenInNew from '@material-ui/icons/OpenInNew';

const fullNameOfDataEnvironment = (data_environment_slug) => {
  return data_environment_slug === 'dev' ? 'development' : 'production';
};

const columns = [
  {
    title: 'DAG ID',
    field: 'dag_id',
    render: rowData => React.createElement('span', null, rowData.dag_id)
  },
  {
    title: 'DATA ENVIRONMENT',
    field: 'environment_id',
    render: rowData => React.createElement('span', null, fullNameOfDataEnvironment(rowData.data_environment_slug))
  },
  {
    title: 'BUNDLE TYPE',
    field: 'id',
    render: rowData => React.createElement('span', null, rowData.bundle_type)
  },
  {
    title: 'BUNDLE VERSION',
    field: 'id',
    render: rowData => React.createElement('span', null, rowData.bundle_version)
  }
];








export const DeploymentsTable = ({ workflows, onTriggerRun, emptyContent }) => {
  const navigate = useNavigate();

  const actions = [
    (rowData) => {
      return {
        icon: () => (
          React.createElement(Link, { to: rowData.dag_url, target: "_blank",}
            , React.createElement(OpenInNew, { fontSize: "small",} )
          )
        ),
        tooltip: 'View on Airflow',
        onClick: () => {}
      };
    },
    (rowData) => {
      return {
        icon: () => React.createElement(PlayCircleOutlineIcon, null ),
        tooltip: 'Trigger manual run',
        onClick: (event, _2) => {
          onTriggerRun(event, rowData);
        }
      };
    },
    (rowData) => {
      return {
        icon: () => React.createElement(Edit, null ),
        tooltip: 'Edit Workflow',
        onClick: () =>
          navigate('new-deployment', {
            state: rowData
          })
      };
    }
  ];

  return (
    React.createElement(Table, {
      options: {
        paging: false,
        search: false,
        showTitle: false,
        toolbar: false,
        actionsColumnIndex: -1,
        draggable: false
      },
      emptyContent: emptyContent,
      data: workflows,
      columns: columns,
      actions: actions,}
    )
  );
};
