import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

export const CreateApplicationButton = () => {
  return (
    React.createElement(Button, { component: RouterLink, variant: "contained", color: "primary", to: "/scaffoldervtwo/create",}, "new Application"

    )
  );
};
