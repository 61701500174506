import { createApiRef, } from '@backstage/core-plugin-api';









export const dataWorkflowApiRef = createApiRef({
  id: 'plugin.data-product-deployment.service',
  description: 'Used to make requests towards the data workflow backend v2'
});











export class DataWorkflowApiClient  {
    discoveryApi;
    identityApi;

  constructor(options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getWorkflows(applicationID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/workflows`;

    const response = await fetch(url, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();
    const workflows = [];

    for (const deployment of body.workflows) {
      workflows.push({
        application_id: applicationID,
        ...deployment
      });
    }

    return workflows;
  }

  async getBundles(applicationID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/bundles`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();
    const bundles = [];

    for (const bundle of body.bundles) {
      bundles.push({
        name: bundle.bundle_version,
        name_pretty: bundle.bundle_ref_name,
        bundle_uri: bundle.bundle_s3_path,
        category: bundle.bundle_type
      });
    }

    return bundles;
  }

  async deployMulti(applicationID, args) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/workflows`;
    const requestBody = {
      dag_name: args.dag_name,
      dag_definition: args.dag_definition,
      bundle_type: args.bundle_type,
      bundle_version: args.bundle_version,
      data_environment_slug: args.data_environment_slug,
      data_environment_is_production: args.data_environment_is_production
    };
    const responseCreateTable = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(requestBody)
    });

    if (responseCreateTable.status !== 201) {
      const status = `${responseCreateTable.status} ${responseCreateTable.statusText}`;
      const body = await responseCreateTable.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async editWorkflow(applicationID, workflowID, args) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/workflows/${workflowID}`;
    const requestBody = {
      dag_name: args.dag_name,
      dag_definition: args.dag_definition,
      bundle_type: args.bundle_type,
      bundle_version: args.bundle_version,
      data_environment_slug: args.data_environment_slug,
      data_environment_is_production: args.data_environment_is_production
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(requestBody)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async trigger(applicationID, workflowID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/workflows/${workflowID}/run`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    return body ;
  }

  async getEconomicTags(applicationID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/economic_tags`;

    const response = await fetch(url, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();
    const economic_tags = {
      org_squad: body.org_squad,
      business_unit: body.business_unit
    };

    return economic_tags;
  }

  async getDeployMode(applicationID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-workflow-api/v2/applications/${applicationID}/advanced_deploy`;

    const response = await fetch(url, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    return body ;
  }
}
