import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { AboutField } from '../../../common/components/AboutCard';
import { EntityRefLink } from '../../../common/components/EntityRefLink';
import { getSquadName } from '../../../common/utils';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word'
  }
});





export const ApplicationAboutContent = ({ application }) => {
  const classes = useStyles();

  return (
    React.createElement(Grid, { container: true,}
      , React.createElement(AboutField, { label: "Description", gridSizes: { xs: 12 },}
        , React.createElement(Typography, { variant: "body2", paragraph: true, className: classes.description,}
          , application?.description || 'No description'
        )
      )
      , React.createElement(AboutField, { label: "Owner", gridSizes: { xs: 12, sm: 6, lg: 4 },}
        , React.createElement(EntityRefLink, { forceRef: "/workforce/squad", name: getSquadName(application?.owner),} )
      )
      , React.createElement(AboutField, { label: "System", value: "No System" , gridSizes: { xs: 12, sm: 6, lg: 4 },}
        , application?.system && React.createElement(EntityRefLink, { entityRef: "system", name: application?.system,} )
      )

      , React.createElement(AboutField, { label: "Type", value: application?.type, gridSizes: { xs: 12, sm: 6, lg: 4 },} )

      , React.createElement(AboutField, { label: "Criticality", value: application?.criticality, gridSizes: { xs: 12, sm: 6, lg: 4 },} )

      , React.createElement(AboutField, { label: "Lifecycle", value: application?.lifecycle, gridSizes: { xs: 12, sm: 6, lg: 4 },} )

      , React.createElement(AboutField, { label: "Demo", value: `${application?.demo}`, gridSizes: { xs: 12, sm: 6, lg: 4 },} )
    )
  );
};
