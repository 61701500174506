import React from 'react';
import { Container } from '@material-ui/core';
import { OwnershipSettings } from './OwnershipSettings';
import { UpdateSettings } from './UpdateSettings';
import { DateTimeSettings } from './DateTimeSettings';
import { DataFilters } from './DataFilters';
import { NotificationSettings } from './NotificationSettings';

import { Versioning } from './Versioning';









export const DataMonitoringStep1 = ({
  partitionCols,
  standardCols,
  onChangeVersion,
  settingVersions,
  selectedVersion
}) => {
  return (
    React.createElement(Container, { maxWidth: "md",}
      , React.createElement(Versioning, { versions: settingVersions, selectedVersion: selectedVersion, onChange: onChangeVersion,} )
      , React.createElement(OwnershipSettings, null )
      , React.createElement(UpdateSettings, null )
      , React.createElement(DateTimeSettings, { partitionCols: partitionCols, standardCols: standardCols,} )
      , React.createElement(DataFilters, null )
      , React.createElement(NotificationSettings, null )
    )
  );
};
