

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeVerticalFilter } from '@ring/clients';

export class VerticalApiClient extends BaseClient {
  constructor(discovery, identityApi) {
    super(getPluginBaseUrl('verticals', discovery), serializeVerticalFilter, getToken(identityApi));
  }
}
