import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { IssueItem } from './IssueItem';

import { v4 as uuidv4 } from 'uuid';





export const IssuesBanner = ({ data = [] }) => {
  if (data.length === 0) {
    return (
      React.createElement(Box, { p: "2rem",}
        , React.createElement(Typography, null, "No issues found"  )
      )
    );
  }

  return (
    React.createElement(Box, { display: "flex", style: { gap: '2rem' }, flexDirection: "column",}
      , React.createElement(Typography, { variant: "h6",}, "Today's issues" )
      , data.map(issue => (
        React.createElement(IssueItem, { key: uuidv4(), ...issue,} )
      ))
    )
  );
};
