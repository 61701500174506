import React, { useEffect, useState } from 'react';
import { Table, } from '@backstage/core-components';
import { Box, Button, } from '@material-ui/core';
import { useAsyncFn } from 'react-use';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AlertDialog } from '@ring/plugin-common';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router';




const defaultColumns = [
  {
    title: 'Name',
    field: 'name'
  }
];
export const NotificationGroupPage = ({}) => {
  const dataObservabilityApi = useApi(dataObservabilityApiRef);
  const alertApi = useApi(alertApiRef);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState();

  const [{ value: notifications, loading }, refresh] = useAsyncFn(
    async () => await dataObservabilityApi.getNotificationGroups()
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const actions = [
    (rowData) => ({
      icon: () => React.createElement(EditIcon, { color: "primary",} ),
      tooltip: 'Edit Notification Group',
      onClick: () => {
        navigate(`/observability/notification/edit/${rowData.id}`);
      }
    }),
    (rowData) => ({
      icon: () => React.createElement(DeleteIcon, { color: "secondary",} ),
      hidden: true,
      tooltip: 'Delete Notification Group',
      onClick: () => {
        setGroupToDelete(rowData);
        setOpen(true);
      }
    })
  ];

  const onAdd = () => {
    navigate('/observability/notification/new');
  };

  const deleteGroup = async (rowData) => {
    try {
      await dataObservabilityApi.deleteNotificationGroup(rowData.id);
      setOpen(false);
      alertApi.post({
        severity: 'success',
        message: `Notification Group ${rowData.name} was deleted successfully`
      });
      refresh();
    } catch (error) {
      alertApi.post({
        severity: 'error',
        message: `Failed to delete notification group ${rowData.name}`
      });
      setOpen(false);
    }
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Table, {
        isLoading: loading,
        columns: defaultColumns,
        options: {
          loadingType: 'overlay',
          paging: true,
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          actionsColumnIndex: -1,
          actionsCellStyle: {
            minWidth: '100px'
          }
        },
        actions: actions,
        title: 
          React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
            , React.createElement(Box, { whiteSpace: "nowrap",}, "Notifications Groups" )
            , React.createElement(Box, { ml: 3,}
              , React.createElement(Button, { color: "primary", onClick: onAdd, startIcon: React.createElement(AddIcon, null ),}, "New Notification Group"

              )
            )
          )
        ,
        data: notifications || [],}
      )
      , React.createElement(AlertDialog, {
        open: open,
        title: "Delete Notification Group"  ,
        message: `You are going to delete ${groupToDelete?.name}. Are you sure?`,
        confirmButtonLeyend: "Keep",
        cancelButtonLeyend: "Delete",
        onClose: () => {
          deleteGroup(groupToDelete);
        },
        onConfirm: () => setOpen(false),}
      )
    )
  );
};
