import React, { useMemo } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useAsync } from 'react-use';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCoreBusinessUnits, useSquads } from '@ring/plugin-common';

import { FieldsTable } from './FieldsTable';







export const TableSetup = ({ database, table, editMode }) => {
  const dataCatalogApi = useApi(dataCatalogApiRef);

  const { squads = [], loading: loadingSquads } = useSquads();
  const squadsNames = useMemo(() => squads.map(({ name }) => name), [squads]);

  const { value: businessUnits = [] } = useCoreBusinessUnits();
  const disabledForm = editMode ? false : true;

  const { setValue, getValues, formState } = useFormContext();
  const isSubmitting = formState.isSubmitting;

  const { value: tablesInfo, loading } = useAsync(async () => {
    const response = await dataCatalogApi.getTableByName(`${database}.${table}`);
    const formedPayload = {
      database_name: response.database,
      table_name: response.name,
      metadata: {
        description: response.description || '',
        owner: response.owner || '',
        business_unit: response.business_unit || '',
        checkbox: (response.tags || []).includes('PII_DATA') ? false : true,
        tags: response.tags,
        schema_metadata: response.schema.map(schema => ({
          name: schema.name,
          description: schema.description || '',
          tags: schema.tags
        }))
      }
    };
    setValue('table', formedPayload, { shouldValidate: true });
    return response;
  }, [database, table]);

  const handleSquadChange = (_, value) => {
    setValue(`table.metadata.owner`, value, { shouldValidate: true });
  };

  const handleBusinessUnitChange = (_, value) => {
    setValue(`table.metadata.business_unit`, value, { shouldValidate: true });
  };

  const handleCheckbox = (_, checked) => {
    setValue(`table.metadata.checkbox`, checked, {
      shouldValidate: true
    });
    if (checked) {
      const allColumns = getValues(`table.metadata.schema_metadata`);
      const newColumns = allColumns.map(column => ({ ...column, tags: [] }));
      setValue(`table.metadata.schema_metadata`, newColumns);
    }
  };

  const getCheckboxValue = () => {
    return getValues(`table.metadata.checkbox`);
  };

  if (loading || !tablesInfo || isSubmitting) {
    return (
      React.createElement(Box, { display: "grid", justifyContent: "center", padding: "4rem",}
        , React.createElement(CircularProgress, { size: "2rem",} )
      )
    );
  }

  return (
    React.createElement(Container, null
      , React.createElement(Grid, { container: true, alignItems: "center",}
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '1rem' },}
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' }, my: "1rem",}
              , React.createElement(Typography, { variant: "h5",}
                , tablesInfo.database, ".", tablesInfo.name
              )
            )
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' },}
              , React.createElement(Autocomplete, {
                options: squadsNames,
                loading: loadingSquads,
                value: getValues(`table.metadata.owner`),
                onChange: handleSquadChange,
                renderInput: params => React.createElement(TextField, { required: true, ...params, label: "Owner",} ),
                disabled: disabledForm,}
              )
            )
            , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '4px' },}
              , React.createElement(Autocomplete, {
                options: businessUnits?.map(b => b.name),
                value: getValues(`table.metadata.business_unit`),
                onChange: handleBusinessUnitChange,
                renderInput: params => React.createElement(TextField, { required: true, ...params, label: "Business Unit" ,} ),
                disabled: disabledForm,}
              )
            )
            , React.createElement(TextField, {
              placeholder: "Description",
              fullWidth: true,
              variant: "outlined",
              multiline: true,
              minRows: 2,
              defaultValue: getValues(`table.metadata.description`),
              onChange: e => {
                setValue(`table.metadata.description`, e.target.value);
              },
              disabled: disabledForm,}
            )
            , React.createElement(Box, { py: "2rem",}
              , React.createElement(Typography, { style: { fontWeight: 'bolder' },}, "Data privacy" )
              , React.createElement(FormControlLabel, {
                control: React.createElement(Checkbox, { checked: getCheckboxValue(), onChange: handleCheckbox, name: "checkedB",} ),
                style: { marginTop: '-5px', marginBottom: '-5px' },
                label: "This table doesn't cointain PII data"     ,
                disabled: disabledForm,}
              )
              , React.createElement(Typography, { variant: "body2",}, "If your table contains private data, assign the columns below"         )
            )
            , React.createElement(Box, { my: "1rem",}
              , React.createElement(FieldsTable, { editMode: editMode,} )
            )
          )
        )
      )
    )
  );
};
