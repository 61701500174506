

export function getIsPIIDatafromTags(tags) {
  if (tags.includes('PII_DATA')) {
    return true;
  }
  return false;
}

export function getPrivacyClassificationfromTags(tags) {
  if (tags.includes('PII_DATA')) {
    return 'PII data';
  }
  return '';
}

export function getOutputTableFormWithTags(values) {
  const tableTags = new Set(values.tags || []);

  if (values.deprecated) {
    tableTags.add('DEPRECATED');
  } else {
    tableTags.delete('DEPRECATED');
  }

  if (values.intermediate_table) {
    tableTags.add('INTERMEDIATE_TABLE');
  } else {
    tableTags.delete('INTERMEDIATE_TABLE');
  }

  const allColumnsTags = new Set();
  values.schema.forEach(column => {
    const columnTags = new Set(column.tags || []);

    if (column.is_pii_data) {
      columnTags.add('PII_DATA');
    } else {
      columnTags.delete('PII_DATA');
    }

    columnTags.forEach(tag => allColumnsTags.add(tag));

    column.tags = Array.from(columnTags);
  });

  if (allColumnsTags.has('PII_DATA')) {
    tableTags.add('PII_DATA');
  } else {
    tableTags.delete('PII_DATA');
  }

  values.tags = Array.from(tableTags);

  return values;
}
