import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getHumanizeBoolean } from '../../helpers';
import { COLUMN_VALIDATIONS_KEY, } from '../../types';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';

export const TableRowCount = ({ fieldIndex, isSummaryVersionActive }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = (field) => {
    return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
  };

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Grid, { container: true, spacing: 4,}
        , React.createElement(Grid, { item: true, sm: 3,}
          , React.createElement(TextField, {
            label: "Minimum",
            size: "small",
            variant: "outlined",
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.min_value` ),
            helperText: getErrorMessageByField('min_value'),
            error: !!getErrorMessageByField('min_value'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 3,}
          , React.createElement(TextField, {
            label: "Maximum",
            size: "small",
            variant: "outlined",
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.max_value` ),
            helperText: getErrorMessageByField('max_value'),
            error: !!getErrorMessageByField('max_value'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
        )
      )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();

  const { min_value, max_value, slack_enabled, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "grid", gridGap: "1rem", gridTemplateColumns: "1fr 2fr" ,}
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Min Value:" ), " " , min_value
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Max Value:" ), " " , max_value
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
