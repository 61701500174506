

export function getPluginBaseUrl(pluginName, discoveryApi) {
  return async () => await discoveryApi.getBaseUrl(pluginName);
}

export function getToken(
  identityApi
) {
  return async () => await identityApi.getIdToken();
}
