import React, { useState, useEffect } from 'react';
import { ActionsHeader, SquadsProvider, useMixpanel } from '@ring/plugin-common';
import { DataMonitoringStep1 } from './DataMonitoringStep1';
import { DataMonitoringStep2 } from './DataMonitoringStep2';
import { DataMonitoringStep3 } from './DataMonitoringStep3';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Step,
  StepLabel,
  Stepper,

} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {


  ListDataMonitoringSettingsVersions
} from './types';
import { Content } from '@backstage/core-components';
import { getExistingSettingOrDefault } from './helpers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import luxonUtils from '@date-io/luxon';
import { useNavigate, useParams } from 'react-router';
import { useValidationSchema, useTableColumns, useDataMonitoringSetting } from './hooks';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { getPayload } from './formDataToSettings';
import { useAsync, useAsyncFn } from 'react-use';

const useListDataMonitoringSettings = (settingId) => {
  const dataObservabilityClient = useApi(dataObservabilityApiRef);
  const { value } = useAsync(
    () => dataObservabilityClient.listDataMonitoringSettingsVersionsV2(settingId),
    [dataObservabilityClient, settingId]
  );
  return value || new ListDataMonitoringSettingsVersions(new Array());
};





const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '1rem'
    },
    paddingBottom: '1rem'
  },
  stepper: {
    background: 'none',
    border: 'none',
    padding: '2rem 30vw'
  }
}));

export const DataMonitoringPageV2 = ({ edit }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { track } = useMixpanel();

  const alertApi = useApi(alertApiRef);
  const dataObservabilityApi = useApi(dataObservabilityApiRef);

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState();
  const { tableName, settingId } = useParams();
  const { currentValidationSchema, fullValidationSchema } = useValidationSchema(activeStep);
  const { partitionColumnsFormData, standardColumns } = useTableColumns(tableName);
  const setting = useDataMonitoringSetting(settingId);
  const initialFormData = getExistingSettingOrDefault(setting, fullValidationSchema, tableName);
  const settingVersions = useListDataMonitoringSettings(settingId);

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema) ,
    mode: 'onChange',
    defaultValues: initialFormData
  });

  const { handleSubmit, reset, trigger } = methods;

  const initialFormDataString = JSON.stringify(initialFormData);

  useEffect(() => {
    reset(JSON.parse(initialFormDataString));
  }, [initialFormDataString, reset]);

  const [_, onChangeVersion] = useAsyncFn(
    async (version) => {
      setLoading(true);
      setSelectedVersion(version);
      const value = await dataObservabilityApi.getDataMonitoringSettingByVersionV2(settingId, version);
      const newForm = getExistingSettingOrDefault(value, fullValidationSchema, tableName);
      methods.reset(newForm);
      setLoading(false);
    },
    [partitionColumnsFormData, fullValidationSchema]
  );

  useEffect(() => {
    setSelectedVersion(setting?.version);
  }, [setting?.version]);

  const steps = ['General', 'ETA', 'Data Quality'];
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          React.createElement(DataMonitoringStep1, {
            onChangeVersion: onChangeVersion,
            settingVersions: settingVersions,
            selectedVersion: selectedVersion,
            partitionCols: partitionColumnsFormData,
            standardCols: standardColumns,}
          )
        );
      case 1:
        return React.createElement(DataMonitoringStep2, null );
      case 2:
        return React.createElement(DataMonitoringStep3, { partitionCols: partitionColumnsFormData, standardCols: standardColumns,} );
      case 3:
      default:
        return 'Unknown step';
    }
  }

  const onSubmit = async (data) => {
    const payload = getPayload(data);

    try {
      setLoading(true);
      if (edit) {
        await dataObservabilityApi.updateDataMonitoringSettingV2(settingId, payload);
      } else {
        await dataObservabilityApi.createDataMonitoringSettingV2(payload);
      }
      alertApi.post({
        severity: 'success',
        message: `Deployment Successful`
      });
      track('Data monitoring form submitted', { table: tableName });
      // reset the form to initial no setting data
      reset(getExistingSettingOrDefault(undefined, fullValidationSchema, tableName));
      navigate('/observability/global/inventory');
    } catch (e) {
      setLoading(false);
      alertApi.post({
        severity: 'error',
        message: `There was an error during the Deployment:\n ${e}`
      });
    }
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    React.createElement(MuiPickersUtilsProvider, { utils: luxonUtils,}
      , React.createElement(SquadsProvider, null
        , React.createElement(ActionsHeader, { title: "Monitors configuration" ,} )
        , React.createElement(Box, { display: "flex", flexDirection: "column",}
          , React.createElement(FormProvider, { ...methods,}
            , React.createElement('form', { style: { flex: 1 },}
              , React.createElement(Stepper, {
                classes: {
                  root: classes.stepper
                },
                activeStep: activeStep,}
              
                , Object.values(steps).map(step => {
                  return (
                    React.createElement(Step, { key: step,}
                      , React.createElement(StepLabel, null, step)
                    )
                  );
                })
              )
              , React.createElement(Content, null, " " , getStepContent(activeStep))
              , React.createElement(Container, { maxWidth: activeStep === 2 ? 'xl' : 'md',}
                , React.createElement('div', { className: classes.actions,}
                  , activeStep !== 0 && (
                    React.createElement(Button, { disabled: loading, variant: "outlined", color: "primary", onClick: handleBack,}, "back"

                    )
                  )
                  , (activeStep === steps.length - 1 || edit) && (
                    React.createElement(Button, {
                      endIcon: loading && React.createElement(CircularProgress, { size: 16,} ),
                      disabled: loading,
                      variant: "contained",
                      color: "primary",
                      onClick: handleSubmit(onSubmit),}
                    , "save setting"

                    )
                  )
                  , activeStep !== steps.length - 1 && (
                    React.createElement(Button, { disabled: loading, variant: "contained", color: "primary", onClick: handleNext,}, "next"

                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
};
