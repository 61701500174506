import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

// TODO: is it possible to listen particular events?.

const useStateSearchParams = (


) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true }) ;
  }, [search]);

  const setQueryParams = (
    update
  ) =>
    navigate({
      search: qs.stringify(typeof update === 'function' ? update(queryParams) : update, { addQueryPrefix: true })
    });

  return { queryParams, setQueryParams };
};

export { useStateSearchParams };
