import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box, makeStyles, Paper, } from '@material-ui/core';
import { EtaStatus } from '../../types';






const useStyles = makeStyles(theme => ({
  root: {
    height: 'fit-content',
    marginLeft: theme.spacing(2),
    transform: `translate(0,${theme.spacing(1)}px)`
  }
}));

// TODO: grab these colors from theme colors
const colorsByStatus = {
  [EtaStatus.success]: '#1DAC15',
  [EtaStatus.delayed_success]: '#eefa6a',
  [EtaStatus.failure]: '#E72318',
  [EtaStatus.pending]: '#E17A19',
  [EtaStatus.not_configured]: 'grey'
};

const getLabel = {
  [EtaStatus.success]: 'Success',
  [EtaStatus.delayed_success]: 'Delayed Success',
  [EtaStatus.failure]: 'Failure',
  [EtaStatus.pending]: 'Pending',
  [EtaStatus.not_configured]: 'Not configured'
};

const LabelContent = ({ status }) => (
  React.createElement(React.Fragment, null
    , React.createElement(FiberManualRecordIcon, { fontSize: "small", style: { color: colorsByStatus[status] },} )
    , React.createElement(Box, { lineHeight: "20px", ml: 1, fontWeight: "bold",}
      , getLabel[status] ?? status
    )
  )
);

export const TableStatusLabel = ({ status, variant }) => {
  const classes = useStyles();

  if (!status) {
    return null;
  }

  if (variant === 'paper') {
    return (
      React.createElement(Paper, { className: classes.root,}
        , React.createElement(Box, { p: "6px 10px" , flexDirection: "row", display: "flex", alignItems: "center",}
          , React.createElement(LabelContent, { status: status,} )
        )
      )
    );
  }

  return (
    React.createElement(Box, { flexDirection: "row", display: "flex",}
      , React.createElement(LabelContent, { status: status,} )
    )
  );
};
