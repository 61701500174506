

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeSystemFilter } from '@ring/clients';

export class SystemApiClient extends BaseClient {
  constructor(discovery, identityApi) {
    super(getPluginBaseUrl('systems', discovery), serializeSystemFilter, getToken(identityApi));
  }
}
