import { Header, Page, RoutedTabs } from '@backstage/core-components';
import { attachComponentData, useElementFilter } from '@backstage/core-plugin-api';



import React from 'react';
import { ServicePageTitle } from './ServicePageTitle';









const dataKey = 'plugin.catalog.serviceLayoutRoute';

const Route = () => null;
attachComponentData(Route, dataKey, true);

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);





export const ServiceLayout = ({ children }) => {
  const routes = useElementFilter(children, elements =>
    elements
      .selectByComponentData({
        key: dataKey,
        withStrictError: 'Child of EntityLayout must be an ServiceLayout.Route'
      })
      .getElements()
      .flatMap(({ props }) => {
        return [
          {
            path: props.path,
            title: props.title,
            children: props.children,
            tabProps: props.tabProps
          }
        ];
      })
  );

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, { title: React.createElement(ServicePageTitle, { title: "testAPi",} ), pageTitleOverride: "testAPi", type: "openAPi",} )

      , React.createElement(RoutedTabs, { routes: routes,} )
      /*   {loading && <Progress />} */

      /* {error && (
        <Content>
          <Alert severity="error">{error?.toString()}</Alert>
        </Content>
      )} */
    )
  );
};

ServiceLayout.Route = Route;
