

export function serializeFilter(filter) {
  if (!filter || filter.isEmpty()) return '';

  const stringArr = [];

  if (filter?.ids) stringArr.push(`ids=${filter.ids.join(',')}`);
  if (filter?.names) stringArr.push(`names=${filter.names.join(',')}`);
  if (filter?.firstName) stringArr.push(`firstName=${filter.firstName}`);
  if (filter?.lastName) stringArr.push(`lastName=${filter.lastName}`);
  if (filter?.displayName) stringArr.push(`displayName=${filter.displayName}`);
  if (filter?.email) stringArr.push(`email=${filter.email}`);
  if (filter?.position) stringArr.push(`position=${filter.position}`);
  if (filter?.squad) stringArr.push(`squad=${filter.squad}`);
  if (filter?.manager) stringArr.push(`manager=${filter.manager}`);
  if (filter?.bambooId) stringArr.push(`bambooId=${filter.bambooId.toString()}`);
  if (filter?.external) stringArr.push(`external=${filter.external ? 'true' : 'false'}`);

  if (!stringArr.length) return '';

  return stringArr.join(',');
}
