import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { DatabaseApi, databaseApiRef } from './api';

import { rootRouteRef } from './routes';

export const databasePlugin = createPlugin({
  id: 'database',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: databaseApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) => new DatabaseApi({ discoveryApi, identityApi: identityApiRing })
    })
  ]
});

export const DatabaseRouter = databasePlugin.provide(
  createRoutableExtension({
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef
  })
);
/* export const DatabasePage = databasePlugin.provide(
  createRoutableExtension({
    name: 'DatabasePage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);
 */
