import React, { useCallback, useState } from 'react';

import { withTheme, } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import { Table, } from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { dataWorkflowApiRef } from '../../api';
import { Backdrop, Button, CircularProgress, Dialog, Grid, LinearProgress } from '@material-ui/core';
import { DeploymentTaskDialog } from './DeploymentTaskDialog';
import {
  DATABRICKS_OPERATOR_DISPLAY_NAME,
  DATABRICKS_PYTHON_OPERATOR,
  DATABRICKS_POOLS_DISPLAY_NAME,
  DATABRICKS_POOLS_OPERATOR,
  DELTA_TABLE_PARTITION_SENSOR_OPERATOR,
  DELTA_PARTITION_SENSOR_DISPLAY_NAME,
  HIVE_TABLE_PARTITION_SENSOR_OPERATOR,
  HIVE_PARTITION_SENSOR_DISPLAY_NAME,
  EXTERNAL_TASK_SENSOR_OPERATOR,
  EXTERNAL_TASK_SENSOR_DISPLAY_NAME,
  AIRFLOW_TASK_SENSOR_OPERATOR,
  AIRFLOW_TASK_SENSOR_DISPLAY_NAME
} from './DeploymentCommon';
import { isEmpty, omit } from 'lodash';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, } from '@material-ui/core/styles';
import { useDeploymentSchema } from './DeploymentSchemas';
import { useEnvironments } from '../hooks/useEnvironments';
import { useEconomicTags } from '../hooks/useEconomicTags';


import { bundle_exist, useApplicationBundles } from '../hooks/useApplicationBundles';

const NewDeployForm = withTheme(MuiTheme);

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
      }
    })
);

function convertItemsToDisplay(tasksDictionary) {
  const tasks = Object.values(tasksDictionary);
  // @ts-ignore
  const tasksToDisplay = tasks.map((t) => ({
    id: t.id,
    operator: t.conditional.operator,
    task_name: t.conditional.task_name,
    task_deps: t.conditional.dependencies
  }));
  return tasksToDisplay;
}

function getTasksFrom(aList) {
  return aList.map((i) => i.task_name);
}

const columns = [
  {
    title: 'Task Name',
    field: 'conditional.task_name'
  },
  {
    title: 'Operator',
    field: 'conditional.operator'
  },
  {
    title: 'Depends On',
    field: 'conditional.dependencies',
    render: (row_data) => row_data?.conditional?.dependencies?.join(', ')
  }
];

export const NewDeployment = ({ entity }) => {
  const alertApi = useApi(alertApiRef);
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const nav = useNavigate();
  const bundles = useApplicationBundles(entity.metadata.name);

  const { environments } = useEnvironments();
  const today = new Date();

  const initialValues = {
    version: 'sandbox|master_6f7ed0e6',
    startDate: today.toISOString().split('T')[0],
    schedule: '@daily',
    environment: environments[0].const
  };

  const getDeploymentData = (state) => {
    let currentVersion = '';
    const tasks_typed = state.dag_definition.tasks;

    for (const [_, value] of Object.entries(tasks_typed)) {
      if (value.operator === DATABRICKS_OPERATOR_DISPLAY_NAME || value.operator === DATABRICKS_PYTHON_OPERATOR) {
        currentVersion = `${value.version_type}|${value.version_name}`;
        break;
      }
    }

    const environment = environments.filter((env) => {
      const environment_object = JSON.parse(env.const);
      return environment_object.slug === state.data_environment_slug;
    });
    const dag_opsgenie_team_name = state.dag_definition.default_args.params?.opsgenie_team_name;
    delete state.dag_definition.default_args.params?.opsgenie_team_name;

    const deployment_info = {
      dag_name: state.dag_name,
      schedule: state.dag_definition.schedule_interval,
      startDate: state.dag_definition.default_args.start_date,
      endDate: state.dag_definition.end_date,
      version: currentVersion,
      catchup: state.dag_definition.catchup,
      concurrency: state.dag_definition.concurrency,
      params: state.dag_definition.default_args.params,
      max_active_runs: state.dag_definition.max_active_runs,
      environment: environment[0].const,
      opsgenie_team_name: dag_opsgenie_team_name,
      on_failure_callback: state.dag_definition.default_args.on_failure_callback,
      on_success_callback: state.dag_definition.default_args.on_success_callback
    };

    return deployment_info;
  };

  const getTaskData = (state) => {
    const taskState = {};

    const task_definitions = state.dag_definition.tasks;

    for (const [key, taskvalue] of Object.entries(task_definitions)) {
      const taskId = uuidv4();
      const new_conditional = taskvalue;

      if (new_conditional.operator === DATABRICKS_PYTHON_OPERATOR) {
        new_conditional.operator = DATABRICKS_OPERATOR_DISPLAY_NAME;
        delete new_conditional.version_name;
        delete new_conditional.version_type;
        delete new_conditional.application_id;
      }
      if (new_conditional.operator === DATABRICKS_POOLS_OPERATOR) {
        new_conditional.operator = DATABRICKS_POOLS_DISPLAY_NAME;
        delete new_conditional.version_name;
        delete new_conditional.version_type;
        delete new_conditional.application_id;
      }
      if (taskvalue.operator === DELTA_TABLE_PARTITION_SENSOR_OPERATOR) {
        new_conditional.operator = DELTA_PARTITION_SENSOR_DISPLAY_NAME;
      }
      if (taskvalue.operator === HIVE_TABLE_PARTITION_SENSOR_OPERATOR) {
        new_conditional.operator = HIVE_PARTITION_SENSOR_DISPLAY_NAME;
      }
      if (taskvalue.operator === EXTERNAL_TASK_SENSOR_OPERATOR) {
        new_conditional.operator = EXTERNAL_TASK_SENSOR_DISPLAY_NAME;
      }
      if (taskvalue.operator === AIRFLOW_TASK_SENSOR_OPERATOR) {
        new_conditional.operator = AIRFLOW_TASK_SENSOR_DISPLAY_NAME;
      }
      new_conditional.dependencies = new_conditional.dependencies;
      new_conditional.task_name = key;

      delete new_conditional.id;

      const conditional = { conditional: new_conditional, id: taskId };
      taskState[taskId] = conditional;
    }

    return taskState;
  };

  const { state: deployState } = useLocation();

  const [currentEditingTask, setCurrentEditingTask] = useState(null);

  const [formState, setFormState] = useState(isEmpty(deployState) ? initialValues : getDeploymentData(deployState));
  const isEditAction = !isEmpty(deployState) ? true : false;

  const dag_id = isEditAction ? (deployState ).dag_id : '';
  const workflow_id = isEditAction ? (deployState ).workflow_id : '';

  const { deploymentSchema } = useDeploymentSchema(entity, isEditAction, dag_id);
  const { economic_tags } = useEconomicTags(entity.metadata.name);

  const [isNewTaskModalOpen, setNewTaskModalOpen] = useState(false);
  const [taskById, setTaskById] = useState(isEmpty(deployState) ? {} : getTaskData(deployState));

  const dataWorkflowApi = useApi(dataWorkflowApiRef);

  const taskDependencies =
    !isEmpty(currentEditingTask) && currentEditingTask
      ? getTasksFrom(convertItemsToDisplay(taskById)).filter(
          // @ts-ignore: Object is possibly 'null'.
          dep => dep !== currentEditingTask.conditional.task_name
        )
      : getTasksFrom(convertItemsToDisplay(taskById));
  const application_id = entity.metadata.name;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const deploy = (deploymentGeneralInfo) => {
    const dag_definition = buildDeployJsonForTheApiCall(deploymentGeneralInfo);
    const promise = dataWorkflowApi.deployMulti(application_id, dag_definition);
    setOpen(true);
    promise
      .then(_ => {
        alertApi.post({
          severity: 'success',
          message: `Deployment Successful`
        });
        navigate(-1);
      })
      .catch(e => {
        setOpen(false);
        alertApi.post({
          severity: 'error',
          message: `There was an error during the Deployment:\n ${e}`
        });
      });
  };

  const edit = (deploymentGeneralInfo) => {
    const dag_definition = buildDeployJsonForTheApiCall(deploymentGeneralInfo);
    const promise = dataWorkflowApi.editWorkflow(application_id, workflow_id, dag_definition);
    setOpen(true);
    promise
      .then(_ => {
        alertApi.post({
          severity: 'success',
          message: `Deployment Successful`
        });
        navigate(-1);
      })
      .catch(e => {
        setOpen(false);
        alertApi.post({
          severity: 'error',
          message: `There was an error during the Deployment:\n ${e}`
        });
      });
  };

  function buildDatabricksTask(t, _application_id, _version_name, _version_type) {
    const databricksOperatorJson = {
      operator: DATABRICKS_PYTHON_OPERATOR,
      application_id: _application_id,
      version_name: _version_name,
      version_type: _version_type,
      script_name: t.conditional.script_name,
      driver_node_type: t.conditional.driver_node_type,
      worker_node_type: t.conditional.worker_node_type,
      num_workers: t.conditional.num_workers,
      args: t.conditional.args,
      timeout_seconds: t.conditional.timeout_seconds,
      execution_timeout: t.conditional.execution_timeout,
      dependencies: t.conditional.dependencies,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      spark_env_vars: t.conditional.spark_env_vars,
      spark_conf: t.conditional.spark_conf,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback,
      tags: economic_tags
    };

    return databricksOperatorJson;
  }

  function buildDatabricksPoolsTask(
    t,
    _application_id,
    _version_name,
    _version_type
  ) {
    const databricksOperatorJson = {
      operator: DATABRICKS_POOLS_OPERATOR,
      application_id: _application_id,
      version_name: _version_name,
      version_type: _version_type,
      pool_id: t.conditional.pool_id,
      script_name: t.conditional.script_name,
      num_workers: t.conditional.num_workers,
      args: t.conditional.args,
      timeout_seconds: t.conditional.timeout_seconds,
      execution_timeout: t.conditional.execution_timeout,
      dependencies: t.conditional.dependencies,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      spark_env_vars: t.conditional.spark_env_vars,
      spark_conf: t.conditional.spark_conf,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback,
      tags: economic_tags
    };

    return databricksOperatorJson;
  }

  function buildPartitionSensorTask(t) {
    const partitionSensorOperatorJson = {
      operator: DELTA_TABLE_PARTITION_SENSOR_OPERATOR,
      database_name: t.conditional.database_name,
      table_name: t.conditional.table_name,
      partition: t.conditional.partition,
      dependencies: t.conditional.dependencies,
      poke_interval: t.conditional.poke_interval,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      timeout: t.conditional.timeout,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback
    };

    return partitionSensorOperatorJson;
  }

  function buildHiveTablePartionSensorTask(t) {
    const hiveSensorTask = {
      operator: HIVE_TABLE_PARTITION_SENSOR_OPERATOR,
      database: t.conditional.database,
      table: t.conditional.table,
      partition_name: t.conditional.partition_name,
      fail_on_empty: t.conditional.fail_on_empty,
      dependencies: t.conditional.dependencies,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      timeout: t.conditional.timeout,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback
    };

    return hiveSensorTask;
  }

  function buildExternalSensorTask(t) {
    const externalSensorTask = {
      operator: EXTERNAL_TASK_SENSOR_OPERATOR,
      external_dag_id: t.conditional.external_dag_id,
      external_task_id: t.conditional.external_task_id,
      execution_delta_days: t.conditional.execution_delta_days,
      dependencies: t.conditional.dependencies,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      timeout: t.conditional.timeout,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback
    };

    return externalSensorTask;
  }

  function buildTaskSensor(t) {
    const taskSensorTask = {
      operator: AIRFLOW_TASK_SENSOR_OPERATOR,
      external_dag_id: t.conditional.external_dag_id,
      external_task_id: t.conditional.external_task_id,
      execution_delta: t.conditional.execution_delta,
      on_failure_callback: t.conditional.on_failure_callback,
      on_success_callback: t.conditional.on_success_callback,
      dependencies: t.conditional.dependencies,
      retries: t.conditional.retries,
      task_concurrency: t.conditional.task_concurrency,
      timeout: t.conditional.timeout
    };

    return taskSensorTask;
  }

  function parseBundleVersion(versionAndType) {
    const splited = versionAndType.split('|');
    return { version_type: splited[0], version_name: splited[1] };
  }

  const tasks = Object.values(taskById);
  function buildDeployJsonForTheApiCall(generalInfoFormState) {
    const parsedVersion = parseBundleVersion(generalInfoFormState.version);
    const version_name = parsedVersion.version_name;
    const version_type = parsedVersion.version_type;

    const tasksToDisplay = {};
    tasks.map(t => {
      const task_name = t.conditional.task_name;

      if (t.conditional.operator === DATABRICKS_OPERATOR_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildDatabricksTask(t, application_id, version_name, version_type);
      }
      if (t.conditional.operator === DATABRICKS_POOLS_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildDatabricksPoolsTask(t, application_id, version_name, version_type);
      }
      if (t.conditional.operator === HIVE_PARTITION_SENSOR_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildHiveTablePartionSensorTask(t);
      }
      if (t.conditional.operator === DELTA_PARTITION_SENSOR_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildPartitionSensorTask(t);
      }
      if (t.conditional.operator === EXTERNAL_TASK_SENSOR_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildExternalSensorTask(t);
      }
      if (t.conditional.operator === AIRFLOW_TASK_SENSOR_DISPLAY_NAME) {
        tasksToDisplay[task_name] = buildTaskSensor(t);
      }
    });

    const dag_definition = {
      default_args: {
        owner: 'data-platform',
        start_date: generalInfoFormState.startDate,
        retries: 1,
        retry_delay_sec: 300,
        params: { ...generalInfoFormState.params, opsgenie_team_name: generalInfoFormState.opsgenie_team_name },
        on_failure_callback: generalInfoFormState.opsgenie_team_name ? generalInfoFormState.on_failure_callback : null,
        on_success_callback: generalInfoFormState.opsgenie_team_name ? generalInfoFormState.on_success_callback : null
      },
      tags: [],
      concurrency: generalInfoFormState.concurrency,
      max_active_runs: generalInfoFormState.max_active_runs,
      catchup: generalInfoFormState.catchup,
      schedule_interval: generalInfoFormState.schedule,
      end_date: generalInfoFormState.endDate,
      tasks: tasksToDisplay
    };

    const environment = JSON.parse(generalInfoFormState.environment);

    return {
      dag_definition: dag_definition,
      bundle_type: version_type,
      bundle_version: version_name,
      data_environment_slug: environment.slug,
      data_environment_is_production: environment.is_production,
      dag_name: generalInfoFormState.dag_name
    };
  }

  const deleteTask = (row_data) => {
    // check if other tasks has dependencies on me
    const _task = omit(taskById, row_data.id);
    const hasDependantsTaks = Object.values(_task).some(task => {
      if (task.conditional.dependencies) {
        return task.conditional.dependencies.includes(row_data.conditional.task_name);
      }
      return false;
    });

    if (!hasDependantsTaks) setTaskById(prev => omit(prev, row_data.id));
    else
      alertApi.post({
        severity: 'warning',
        message: 'You can not delete this task. check dependencies.'
      });
  };

  const actions = [
    row_data => {
      return {
        icon: () => React.createElement(Edit, { fontSize: "small",} ),
        tooltip: 'Edit',
        disabled: false,
        onClick: () => {
          setCurrentEditingTask(row_data);
        }
      };
    },
    row_data => {
      return {
        icon: () => React.createElement(Delete, { fontSize: "small",} ),
        tooltip: 'Delete',
        disabled: false,
        onClick: () => deleteTask(row_data)
      };
    }
  ];

  const onCloseNewTaskDialog = () => {
    setNewTaskModalOpen(false);
    setCurrentEditingTask(null);
  };

  const handleChange = useCallback(
    (e) => {
      setFormState({
        ...e.formData,
        dag_id: isEditAction
          ? (deployState ).dag_id
          : `${application_id}_${JSON.parse(e.formData?.environment).slug}`
      });
    },
    [isEditAction, deployState, application_id]
  );

  if (!bundle_exist(bundles)) {
    return (
      React.createElement('div', null, "Waiting for bundle to be created. Please refresh this page in a few minutes."

        , React.createElement(LinearProgress, { 'data-testid': "loading-progress",} )
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Backdrop, { className: classes.backdrop, open: open,}
        , React.createElement(CircularProgress, null )
      )
      , React.createElement(NewDeployForm, {
        formData: formState,
        onChange: handleChange,
        onSubmit: _ => {
          return isEditAction ? edit(formState) : deploy(formState);
        },
        schema: deploymentSchema,
        liveValidate: true,}
      
        , React.createElement(Dialog, {
          open: isNewTaskModalOpen || !isEmpty(currentEditingTask),
          onClose: onCloseNewTaskDialog,
          maxWidth: "lg",}
          // padding="2rem"
        
          , React.createElement(DeploymentTaskDialog, {
            setTaskById: setTaskById,
            onClose: onCloseNewTaskDialog,
            taskDeps: taskDependencies,
            editingState: currentEditingTask,
            newTask: isEmpty(currentEditingTask),}
          )
        )
        , React.createElement(Table, {
          isLoading: false,
          columns: columns,
          options: {
            paging: false,
            pageSize: 5,
            actionsColumnIndex: -1,
            loadingType: 'linear',
            padding: 'dense',
            pageSizeOptions: [20, 50, 100]
          },
          title: "Tasks",
          data: tasks ?? [],
          actions: actions,}
        )
        , React.createElement(Grid, null
          , React.createElement(Grid, { container: true, item: true,}
            , React.createElement(Grid, { item: true,}
              , React.createElement(Button, { variant: "contained", onClick: () => nav(-1),}, "Back"

              )
            )
            , React.createElement(Grid, { item: true,}
              , React.createElement(Button, { color: "primary", variant: "contained", onClick: () => setNewTaskModalOpen(true),}, "Add tasks"

              )
            )
            , React.createElement(Grid, { item: true,}
              , React.createElement(Button, { variant: "contained", type: "submit",}, "Deploy"

              )
            )
          )
        )
      )
    )
  );
};
