import React, { useState } from 'react';
import { Table, Link, } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { Grid, Dialog, DialogActions, DialogTitle, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddAlert from '@material-ui/icons/AddAlert';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import InsertChart from '@material-ui/icons/InsertChart';

import { makeStyles, createStyles, } from '@material-ui/core/styles';

import { machineLearningDeploymentApiRef } from '../../api';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

const columns = [
  {
    title: 'RUN ID',
    field: 'run_id',
    render: rowData => (
      React.createElement(Link, { to: rowData.mlflow_run_url, target: "_blank",}
        , rowData.run_id
      )
    ),
    customFilterAndSearch: (term, rowData) => `${rowData.run_id}.${rowData.environment}`.includes(term)
  },
  {
    title: 'ENVIRONMENT',
    field: 'environment'
  }
];








export const MachineLearningDeploymentsTable = ({
  applicationID,
  deployments,
  emptyContent,
  deploymentsCacheKey
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [deploymentValues, setDeploymentValues] = useState({} );
  const navigate = useNavigate();
  const machineLearningDeploymentApi = useApi(machineLearningDeploymentApiRef);

  const DeleteDeploymentConfirmationDialog = ({
    isOpen,
    onClose,
    onClick,
    styles,
    isOpenBackdrop
  }





) => {
    return (
      React.createElement(Dialog, {
        open: isOpen,
        onClose: onClose,
        'aria-labelledby': "alert-dialog-title",
        'aria-describedby': "alert-dialog-description",}
      
        , React.createElement(DialogTitle, { id: "alert-dialog-title",}, "Are you sure you want to remove deployment "
                  , deploymentValues.run_id, " from "  , deploymentValues.environment, "?"
        )
        , React.createElement(DialogActions, null
          , React.createElement(Button, { onClick: onClose, color: "primary",}, "Cancel"

          )
          , React.createElement(Button, { onClick: onClick, color: "primary",}, "Confirm"

          )
          , React.createElement(Backdrop, { className: styles.backdrop, open: isOpenBackdrop,}
            , React.createElement(CircularProgress, null )
          )
        )
      )
    );
  };

  const onSubmitDelete = async () => {
    try {
      setOpenBackdrop(true);
      await machineLearningDeploymentApi.removeDeploymentMlflow(applicationID, deploymentValues);
      await mutate(deploymentsCacheKey, deployments);
      setOpenBackdrop(false);
      setOpen(false);
    } catch (e) {
      setOpenBackdrop(false);
      throw new Error(e);
    }
  };

  const handleClickOpen = (values) => {
    setDeploymentValues(values);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actions = [
    (rowData) => {
      const dataDogUrl = `https://app.datadoghq.com/dashboard/gjj-pay-egt/ml-platform-model-metrics`;

      return {
        icon: () => React.createElement(InsertChart, null ),
        tooltip: 'Open Monitoring Metrics',
        onClick: () => {
          window.open(
            `${dataDogUrl}` +
              `?tpl_var_model=ring-${rowData.run_id}` +
              `&tpl_var_owner=${applicationID}` +
              `&tpl_var_environment=${rowData.environment.toLowerCase()}`
          );
        }
      };
    },
    (rowData) => {
      const accountId = rowData.environment === 'PRODUCTION' ? '151741' : '156951';
      const logzioUrl =
        'https://app.logz.io/#/dashboard/kibana/discover?_a=(' +
        "columns:!(message),filters:!(),index:'logzioCustomerIndex*',interval:auto,query:(" +
        "language:lucene,query:'kubernetes.namespace:%20mle-serving%20AND" +
        `%20kubernetes.pod.name:%20ring-${rowData.run_id}*'))` +
        `&accountIds=${accountId}&switchToAccountId=${accountId}`;

      return {
        icon: () => React.createElement(LibraryBooks, null ),
        // Check Assignment icon
        tooltip: 'Open Logs',
        onClick: () => {
          window.open(logzioUrl);
        }
      };
    },
    (rowData) => {
      return {
        icon: () => React.createElement(AddAlert, null ),
        tooltip: 'Open Alerts',
        onClick: () => navigate(`alerts/${rowData.environment.toLowerCase()}/${rowData.run_id}`)
      };
    },
    (rowData) => {
      return {
        icon: () => React.createElement(Edit, null ),
        tooltip: 'Edit Deployment',
        onClick: () => navigate(`edit/${rowData.environment.toLowerCase()}/${rowData.run_id}`)
      };
    },
    (rowData) => {
      return {
        icon: () => React.createElement(Delete, null ),
        tooltip: 'Remove Deployment',
        onClick: () => handleClickOpen(rowData)
      };
    }
  ];

  return (
    React.createElement(Grid, null
      , React.createElement(Table, {
        title: "Deployment Instances" ,
        options: {
          paging: true,
          search: true,
          actionsColumnIndex: -1,
          draggable: false
        },
        emptyContent: emptyContent,
        data: deployments,
        columns: columns,
        actions: actions,}
      )
      , React.createElement(DeleteDeploymentConfirmationDialog, {
        isOpen: open,
        onClose: handleClose,
        onClick: onSubmitDelete,
        styles: classes,
        isOpenBackdrop: openBackdrop,}
      )
    )
  );
};
