import { Box, Grid, Link, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { COLUMN_VALIDATIONS_KEY, } from '../../types';
import { getHumanizeBoolean } from '../../helpers';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { SQLMacrosDialog } from './SQLMacrosDialog';

export const TableCustomValidationGroupedResults = ({
  fieldIndex,
  isSummaryVersionActive
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const theme = useTheme();

  const [isMacrosDialogOpen, setIsMacrosDialogOpen] = useState(false);

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = useCallback(
    (field) => {
      return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
    },
    [errors, fieldIndex]
  );

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Box, { display: "flex", flexDirection: "column",}
        , React.createElement(Grid, { container: true, spacing: 4,}
          , React.createElement(Grid, { item: true, sm: 12,}
            , React.createElement(TextField, {
              label: "Validation Name" ,
              size: "small",
              variant: "outlined",
              required: true,
              type: "text",
              fullWidth: true,
              ...register(`${fieldPath}.name` ),
              helperText: getErrorMessageByField('name'),
              error: !!getErrorMessageByField('name'),}
            )
          )
          , React.createElement(Grid, { item: true, sm: 12,}
            , React.createElement(TextField, {
              label: "Description",
              size: "small",
              variant: "outlined",
              required: true,
              type: "text",
              fullWidth: true,
              ...register(`${fieldPath}.description` ),
              helperText: getErrorMessageByField('description'),
              error: !!getErrorMessageByField('description'),}
            )
          )

          , React.createElement(Grid, { item: true, sm: 12,}
            , React.createElement(Controller, {
              control: control,
              name: `${fieldPath}.query` ,
              render: ({ field, fieldState: { error } }) => (
                React.createElement(React.Fragment, null
                  , React.createElement(AceEditor, {
                    ...field,
                    height: "100px",
                    width: "100%",
                    showPrintMargin: false,
                    mode: "sql",
                    theme: theme?.palette?.type === 'dark' ? 'monokai' : 'github',
                    debounceChangePeriod: 1000,
                    setOptions: {
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true
                    },
                    editorProps: { $blockScrolling: true },
                    annotations: !!error?.message ? [{ row: 0, type: 'error', text: error.message }] : undefined,}
                  )
                )
              ),}
            )
            , React.createElement(Box, { display: "flex", alignItems: "center", p: "1rem 0" ,}
              , React.createElement(InfoOutlinedIcon, { color: "primary",} )
              , React.createElement(Typography, { style: { paddingLeft: '.5rem' }, variant: "body2",}, "You can use macros in your SQL statement. If you need help,"
                           , ' '
                , React.createElement(Link, { onClick: () => setIsMacrosDialogOpen(true),}, "check our cheatsheet."  )
              )
            )
          )
          , React.createElement(Grid, { item: true, sm: 12,}
            , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
            , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
          )
        )
      )
      , React.createElement(SQLMacrosDialog, { open: isMacrosDialogOpen, onClose: () => setIsMacrosDialogOpen(false),} )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();
  const theme = useTheme();

  const { name, description, query, slack_enabled, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "grid", gridGap: "1rem", gridTemplateColumns: "1fr 2fr" ,}
      , React.createElement(Typography, { style: { gridColumnStart: 'span 2' }, variant: "body2",}
        , React.createElement('strong', null, "Validation Name:" ), " " , name
      )
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { style: { gridColumnStart: 'span 2' }, variant: "body2",}
          , React.createElement('strong', null, "Description:"), " " , description
        )
        , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gridColumnStart: 'span 2' },}
          , React.createElement(Typography, { variant: "body2",}
            , React.createElement('strong', null, "Custom condition:" )
          )
          , React.createElement(AceEditor, {
            value: query,
            readOnly: true,
            height: "100px",
            width: "100%",
            showPrintMargin: false,
            mode: "sql",
            theme: theme?.palette?.type === 'dark' ? 'monokai' : 'github',
            editorProps: { $blockScrolling: true },}
          )
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
