
















import React from 'react';
import { apiDocsConfigRef } from '../../config';
import { useApi } from '@backstage/core-plugin-api';

export const ApiTypeTitle = ({ apiEntity }) => {
  const config = useApi(apiDocsConfigRef);
  const definition = config.getApiDefinitionWidget(apiEntity);
  const type = definition ? definition.title : apiEntity.spec.type;

  return React.createElement('span', null, type);
};
