/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Content, ContentHeader, } from '@backstage/core-components';

import {
  EntityKindPicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,

  UserListPicker
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { CatalogTable } from '../CatalogTable';

import { CreateComponentButton } from '../CreateComponentButton';
import { FilteredEntityLayout, EntityListContainer, FilterContainer } from '../FilteredEntityLayout';
import CatalogLayout from './CatalogLayout';







export const CatalogPage = ({ columns, actions, initiallySelectedFilter = 'owned' }) => {
  return (
    React.createElement(CatalogLayout, null
      , React.createElement(Content, null
        , React.createElement(ContentHeader, { title: "Application",}
          , React.createElement(CreateComponentButton, null )
        )
        , React.createElement(EntityListProvider, null
          , React.createElement(FilteredEntityLayout, null
            , React.createElement(FilterContainer, null
              , React.createElement(EntityKindPicker, { initialFilter: "component", hidden: true,} )
              , React.createElement(EntityTypePicker, null )
              , React.createElement(UserListPicker, { initialFilter: initiallySelectedFilter,} )
              , React.createElement(EntityOwnerPicker, null )
              , React.createElement(EntityTagPicker, null )
            )
            , React.createElement(EntityListContainer, null
              , React.createElement(CatalogTable, { columns: columns, actions: actions,} )
            )
          )
        )
      )
    )
  );
};
