

export const CRITICALITY_OPTIONS = [
  {
    name: 'low',
    description:
      'On app failure it will have no meaningful impact to the business, or its impact will be constrained to the operation of only one squad.'
  },
  {
    name: 'med',
    description:
      'On app failure it will not affect company revenue, but will have impact on external users and/or the operation of several internal squads.'
  },
  { name: 'high', description: 'On app failure it will affect the ability of the company to generate revenue.' }
];
