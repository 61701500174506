import { Page, Pageable } from '@ring/model';
import { processResponseError } from '@ring/utils';
import fetch from 'cross-fetch';

export class BaseClient {
    getBaseUrl;

    serializeFilter;

    getToken;

  constructor(
    getBaseUrl,
    serializeFilter,
    getToken
  ) {
    this.getBaseUrl = getBaseUrl;
    this.serializeFilter = serializeFilter;
    this.getToken = getToken;
  }

   async findByName(name, options) {
    const token = await this.getToken(options?.overrideToken);
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/${name}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) return processResponseError(response, { notFoundValue: undefined });

    const body = (await response.json()) ;

    return body;
  }

   async existsByName(name, options) {
    const entity = await this.findByName(name, options);
    return entity !== undefined;
  }

   async findAll(options) {
    const filterString = this.serializeFilter(options?.filter);
    const token = await this.getToken(options?.overrideToken);
    const pageable = options?.pageable ? options.pageable : Pageable.UNPAGED;
    let baseUrl = await this.getBaseUrl();
    baseUrl = `${baseUrl}?offset=${pageable.page}&size=${pageable.size}`;

    const url =
      options?.filter?.isEmpty() || filterString === '' ? baseUrl : `${baseUrl}&filter=${filterString}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) return processResponseError(response);

    const body = await response.json();

    const entityPage = Page.parse(body);

    return entityPage;
  }

   async create(entity, options) {
    return this.save('POST', entity, options);
  }

   async update(entity, options) {
    return this.save('PUT', entity, options);
  }

   async save(
    method,
    user,
    options
  ) {
    const token = await this.getToken(options?.overrideToken);
    const baseUrl = await this.getBaseUrl();
    let url = method === 'POST' ? baseUrl : `${baseUrl}/${user.name}`;

    if (options?.queryParams) url = `${url}?${options.queryParams}`;

    const response = await fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(user)
    });

    if (!response.ok) return processResponseError(response);

    const body = (await response.json()) ;

    return body;
  }
}
