
import { SystemFilter } from '../systemFilter';

export class SystemFilterBuilder  {
   _names;
   _description;
   _owners;
   _ownerIds;
   _type;
   _costCenter;

  build() {
    return new SystemFilter(this);
  }

  get names() {
    return this._names;
  }

  get description() {
    return this._description;
  }

  get owners() {
    return this._owners;
  }

  get ownerIds() {
    return this._ownerIds;
  }

  get type() {
    return this._type;
  }

  get costCenter() {
    return this._costCenter;
  }

  setNames(value) {
    this._names = value;
    return this;
  }

  setDescription(value) {
    this._description = value;
    return this;
  }

  setOwners(value) {
    this._owners = !value ? value : value.map(v => (v.startsWith('squad:') ? v : `squad:${v}`));
    return this;
  }

  setOwnerIds(value) {
    this._ownerIds = value;
    return this;
  }

  setType(value) {
    this._type = value;
    return this;
  }

  setCostCenter(value) {
    this._costCenter = value;
    return this;
  }
}
