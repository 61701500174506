















import React from 'react';
import { EntityPicker } from '../EntityPicker';

export const OwnerPicker = ({
  schema: { title = 'Owner', description = 'The owner of the component' },
  uiSchema,
  ...props
}) => {
  const ownerUiSchema = {
    ...uiSchema,
    'ui:options': {
      allowedKinds: (uiSchema['ui:options']?.allowedKinds || ['Group', 'User']) ,
      defaultKind: 'Group'
    }
  };

  return React.createElement(EntityPicker, { ...props, schema: { title, description }, uiSchema: ownerUiSchema,} );
};
