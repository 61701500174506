import React, { useState } from 'react';
import { Content, ContentHeader } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { CreateResources } from '../CreateResources';
import { ModuleOutputsTable } from '../ModuleOutputsTable';


import { DeploymentScopeTable } from '../Deployments/DeploymentScopeTable';

export const InfraPage = ({
  project,
  application,
  service
}



) => {
  const [isCreating, setIsCreating] = useState(false);

  if (!project) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error fetching infrastructure data\""   )
      )
    );
  }

  if (service.spec.service_name === 'flux_integration_service') {
    return React.createElement(DeploymentScopeTable, { service: service,} );
  }

  const title = service.spec.bundle_name.toLowerCase();

  if (!isCreating) {
    return (
      React.createElement(Content, null
        , React.createElement(ContentHeader, { title: title.charAt(0).toUpperCase() + title.slice(1),} )
        , React.createElement(ModuleOutputsTable, { bundleName: service.spec.bundle_name, application: application,} )
      )
    );
  }

  return (
    React.createElement(Content, null
      , React.createElement(CreateResources, {
        project: project,
        application: application,
        service: service,
        onCancel: () => setIsCreating(false),}
      )
    )
  );
};
