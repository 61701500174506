import * as yup from 'yup';
import { ignoreIfOptions } from '../../types';
import { TableExpectationNames, ColumnExpectationNames } from '../../../../typesV2';
import {
  maxValueErrorMessage,
  minOrMaxSet,
  minValueErrorMessage,
  mustBeNumberMessage,
  notEmptyMessage
} from './messages';

const minValidationYup = yup.mixed().test('testForMin', '', (val, ctx) => {
  if ((ctx.parent.max_value === '' || ctx.parent.max_value === null) && (val === '' || val === null)) {
    return ctx.createError({ message: minOrMaxSet });
  } else if (ctx.parent.max_value !== '' && ctx.parent.max_value !== null && val !== '' && val !== null) {
    return +val <= +ctx.parent.max_value || ctx.createError({ message: minValueErrorMessage });
  }
  return true;
});

const maxValidationYup = yup.mixed().test('testForMax', '', (val, ctx) => {
  if ((ctx.parent.min_value === '' || ctx.parent.min_value === null) && (val === '' || val === null)) {
    return ctx.createError({ message: minOrMaxSet });
  } else if (ctx.parent.min_value !== '' && ctx.parent.min_value !== null && val !== '' && val !== null) {
    return +val >= +ctx.parent.min_value || ctx.createError({ message: maxValueErrorMessage });
  }
  return true;
});

const compColsToBeUniqueSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  ignore_row_if: yup.string().oneOf(Object.values(ignoreIfOptions)),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});
const colValuesToBeUniqueSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const colPairRoundValuesAGreaterThanBSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const colValuesToBeBetweenSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  min_value: minValidationYup,
  max_value: maxValidationYup,
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(true)
});

const colCountDistinctValuesToBeBetweenSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  min_value: minValidationYup,
  max_value: maxValidationYup,
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(true)
});

const colValuesNotBeNullSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const colValuesToBeInSetSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const colRoundValuesNotBeZeroSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const colValuesToMatchRegexSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  mostly: yup
    .number()
    .typeError(mustBeNumberMessage)
    .min(0, 'must be greater than or equal to 0')
    .max(1, 'must be less than or equal to 1')
    .default(0),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false)
});

const customValidationGroupedResultsSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  name: yup.string().min(1, notEmptyMessage).required(),
  description: yup.string().min(1, notEmptyMessage).required(),
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false),
  query: yup.string().min(1, notEmptyMessage)
});

const customValidationSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  name: yup.string().min(1, notEmptyMessage).required(),
  min_value: minValidationYup,
  max_value: maxValidationYup,
  slack_enabled: yup.boolean().default(false),
  opsgenie_enabled: yup.boolean().default(false),
  query: yup.string().min(1, notEmptyMessage)
});

const tableRowCountSchemaYup = yup.object().shape({
  expectation_name: yup.string(),
  min_value: minValidationYup,
  max_value: maxValidationYup,
  slack_enabled: yup.boolean(),
  opsgenie_enabled: yup.boolean()
});

export const columnValidationSchema = yup.array().of(
  // @ts-ignore
  yup.lazy(item => {
    const { expectation_name } = item;

    const schemaByExpectationName = {
      // TODO: Type this string
      [TableExpectationNames.TABLE_ROW_COUNT]: tableRowCountSchemaYup,
      [TableExpectationNames.CUSTOM_VALIDATION]: customValidationSchemaYup,
      [TableExpectationNames.CUSTOM_VALIDATION_GROUPED_RESULTS]: customValidationGroupedResultsSchemaYup,
      [TableExpectationNames.COMP_COLS_TO_BE_UNIQUE]: compColsToBeUniqueSchemaYup,
      [ColumnExpectationNames.COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN]: colCountDistinctValuesToBeBetweenSchemaYup,
      [ColumnExpectationNames.COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B]: colPairRoundValuesAGreaterThanBSchemaYup,
      [ColumnExpectationNames.COL_ROUND_VALUES_NOT_BE_ZERO]: colRoundValuesNotBeZeroSchemaYup,
      [ColumnExpectationNames.COL_VALUES_NOT_BE_NULL]: colValuesNotBeNullSchemaYup,
      [ColumnExpectationNames.COL_VALUES_TO_BE_BETWEEN]: colValuesToBeBetweenSchemaYup,
      [ColumnExpectationNames.COL_VALUES_TO_BE_IN_SET]: colValuesToBeInSetSchemaYup,
      [ColumnExpectationNames.COL_VALUES_TO_BE_UNIQUE]: colValuesToBeUniqueSchemaYup,
      [ColumnExpectationNames.COL_VALUES_TO_MATCH_REGEX]: colValuesToMatchRegexSchemaYup
    };

    return (
      schemaByExpectationName[expectation_name] ??
      yup.object().shape({
        expectation_name: yup.string()
      })
    );
  })
);
