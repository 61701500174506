import { dataWorkflowApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';


export const useWorkflows = (
  applicationID
) => {
  const dataWorkflowApi = useApi(dataWorkflowApiRef);
  const { value, loading, error } = useAsync(
    () => dataWorkflowApi.getWorkflows(applicationID),
    [dataWorkflowApi, applicationID]
  );
  return { workflows: value, loading, error };
};
