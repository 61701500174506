/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {

  ENTITY_DEFAULT_NAMESPACE,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API
} from '@backstage/catalog-model';
import { ScmIntegrationIcon, scmIntegrationsApiRef } from '@backstage/integration-react';
import { getEntityRelations, getEntitySourceLocation, useEntity } from '@backstage/plugin-catalog-react';
import { Card, CardContent, CardHeader, Divider, IconButton, makeStyles } from '@material-ui/core';
import DocsIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ExtensionIcon from '@material-ui/icons/Extension';
import React from 'react';
import { AboutContent } from './AboutContent';

import { HeaderIconLinkRow, } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { getEditEntityPath } from '../../utils';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px'
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  gridItemCardContent: {
    flex: 1
  },
  fullHeightCardContent: {
    flex: 1
  }
});







export function AboutCard({ variant }) {
  const classes = useStyles();
  const { entity } = useEntity();
  const navigate = useNavigate();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const entitySourceLocation = getEntitySourceLocation(entity, scmIntegrationsApi);
  const entityMetadataEditUrl = getEditEntityPath(entity);
  const providesApiRelations = getEntityRelations(entity, RELATION_PROVIDES_API);
  const consumesApiRelations = getEntityRelations(entity, RELATION_CONSUMES_API);
  const hasApis = providesApiRelations.length > 0 || consumesApiRelations.length > 0;

  const viewInSource = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: React.createElement(ScmIntegrationIcon, { type: entitySourceLocation?.integrationType,} ),
    onClick: () => window.open(entitySourceLocation?.locationTargetUrl, '_blank')
  };
  const viewInTechDocs = {
    label: 'View TechDocs',
    disabled: !entity.metadata.annotations?.['backstage.io/techdocs-ref'],
    icon: React.createElement(DocsIcon, null ),
    href: `/docs/${entity.metadata.namespace || ENTITY_DEFAULT_NAMESPACE}/${entity.kind}/${
      entity.metadata.name
    }`.toLowerCase()
  };
  const viewApi = {
    title: hasApis ? '' : 'No APIs available',
    label: 'View API',
    disabled: !hasApis,
    icon: React.createElement(ExtensionIcon, null ),
    href: 'api'
  };

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  return (
    React.createElement(Card, { className: cardClass,}
      , React.createElement(CardHeader, {
        title: "About",
        action: 
          React.createElement(IconButton, {
            'aria-label': "Edit",
            disabled: !entityMetadataEditUrl,
            title: "Edit Metadata" ,
            onClick: () => entityMetadataEditUrl && navigate(entityMetadataEditUrl, { state: entity }),}
          
            , React.createElement(EditIcon, null )
          )
        ,
        subheader: React.createElement(HeaderIconLinkRow, { links: [viewInSource, viewInTechDocs, viewApi],} ),}
      )
      , React.createElement(Divider, null )
      , React.createElement(CardContent, { className: cardContentClass,}
        , React.createElement(AboutContent, { entity: entity,} )
      )
    )
  );
}
