
import {
  COLUMN_VALIDATIONS_KEY,



  PARTITION_COLUMNS_FORM_KEY,
  SlaConfigOptions,





  QueryResolverIntervalTimeOptions
} from './types';

import { isNull, isUndefined } from 'lodash';

const parseNotificationSettings = (setting) => {
  const { notification_default_resolvers } = setting;

  const opsgenieConfig = notification_default_resolvers.find(el => el.name === 'opsgenie');

  const opsgenieTeamResponder = opsgenieConfig?.params?.responders?.filter(el => el.type === 'team');

  const slackChannels = notification_default_resolvers
    .filter(el => el.name === 'slack')
    .map(el => el.params.channel || '')
    .filter(el => el !== '');

  return {
    slack_channels: slackChannels || [],
    opsgenie_groups: opsgenieTeamResponder?.map(e => e.name) || [],
    opsgenie_priority: (opsgenieConfig?.params.priority || 'P5') 
  };
};

const parsePartitionColumns = (setting) => {
  const column_resolver = setting.dq_settings.resolver.timestamp_column_resolver;
  if (isUndefined(column_resolver) || isNull(column_resolver)) {
    return [];
  }
  const columns = setting.dq_settings.resolver.timestamp_column_resolver?.columns;
  if (isUndefined(columns) || isNull(columns)) {
    return [];
  }
  return columns.map(col => ({
    column_name: col.name,
    column_data_format: col?.format
  }));
};

const parseSlaFromSetting = (setting) => {
  return {
    sla_settings: {
      interval_time:
        setting.datetime_granularity === SlaConfigOptions.daily
          ? QueryResolverIntervalTimeOptions.days
          : QueryResolverIntervalTimeOptions.hours,
      interval_value:
        setting.eta_settings.resolver.data_start_interval?.days !== 0
          ? setting.eta_settings.resolver.data_start_interval?.days ?? -1
          : setting.eta_settings.resolver.data_start_interval.hours
    },
    resolver: (setting.eta_settings.resolver.name || 'query_resolver') ,
    eta: { minutes: setting.eta_settings.sla.minutes, hours: setting.eta_settings.sla.hours },
    slack_enabled: setting.eta_settings.enabled_notification_resolvers.includes('slack'),
    opsgenie_enabled: setting.eta_settings.enabled_notification_resolvers.includes('opsgenie'),
    airflow_url: setting.eta_settings.resolver?.airflow_url?.slice(8) || '',
    dag_id: setting.eta_settings.resolver?.dag_id || '',
    task_id: setting.eta_settings.resolver?.task_id || '',
    execution_delta: {
      hours: setting.eta_settings.resolver?.execution_delta?.hours || 0,
      minutes: setting.eta_settings.resolver?.execution_delta?.minutes || 0
    },
    data_filters_names: setting.eta_settings.resolver.data_filters_names || [],
    schedule_expression: setting.eta_settings?.schedule_expression || ''
  };
};

const parseDQResolverInterval = (setting) => {
  if (
    setting.dq_settings.resolver.data_start_interval.days === 0 &&
    setting.dq_settings.resolver.data_start_interval.hours === 0
  ) {
    return {
      resolver_interval_time: QueryResolverIntervalTimeOptions.days,
      resolver_interval_value: setting.dq_settings.resolver.data_start_interval.days
    };
  } else if (setting.dq_settings.resolver.data_start_interval.days !== 0) {
    return {
      resolver_interval_time: QueryResolverIntervalTimeOptions.days,
      resolver_interval_value: setting.dq_settings.resolver.data_start_interval.days
    };
  } else if (setting.dq_settings.resolver.data_start_interval.hours !== 0) {
    return {
      resolver_interval_time: QueryResolverIntervalTimeOptions.hours,
      resolver_interval_value: setting.dq_settings.resolver.data_start_interval.hours
    };
  }
  // TODO Add parder to get interval and render it in the UI
  return {
    resolver_interval_time: QueryResolverIntervalTimeOptions.days,
    resolver_interval_value: 7,
    data_filters_names: setting.dq_settings.resolver.data_filters_names || []
  };
};

const parseDataQualityValidationsFromSetting = (setting) => {
  return setting.dq_settings.expectations.map(el => {
    return {
      expectation_name: el.name,
      slack_enabled: el.enabled_notification_resolvers.includes('slack'),
      opsgenie_enabled: el.enabled_notification_resolvers.includes('opsgenie'),
      ...el.params
    };
  });
};

const parseOwnershipFromSetting = (setting) => {
  return {
    name: setting.name,
    business_unit: setting.business_unit,
    org_squad: setting.org_squad
  };
};

export const getExistingSettingOrDefault = (
  setting,
  validationSchema,
  tableId
) => {
  const initialFormData = {
    ...validationSchema.cast({}),
    databaseName: tableId.split('.')[0],
    tableName: tableId.split('.')[1],
    enabled: true
  };

  if (!setting) {
    // @ts-ignore
    return { ...initialFormData };
  }
  const formData = {
    databaseName: setting.database_name,
    tableName: setting.table_name,
    dataFilters: setting?.dq_settings?.resolver?.data_filters_names ?? [],
    ownership: parseOwnershipFromSetting(setting),
    notifications: parseNotificationSettings(setting),
    sla: parseSlaFromSetting(setting),
    data_quality_settings: parseDQResolverInterval(setting),
    [PARTITION_COLUMNS_FORM_KEY]: parsePartitionColumns(setting),
    [COLUMN_VALIDATIONS_KEY]: parseDataQualityValidationsFromSetting(setting),
    enabled: setting.enabled
  };
  return formData;
};

export function mergeSchemas(...schemas) {
  const [first, ...rest] = schemas;
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
  return merged;
}

export function getColumnFormatSuggestion(columnName) {
  const COLUMN_SUGGESTION = {
    year: 'yyyy',
    month: 'MM',
    day: 'dd',
    hour: 'HH',
    date: 'yyyy-MM-dd'
  };

  return (COLUMN_SUGGESTION )[columnName] || '';
}

export function getHumanizeBoolean(value) {
  return value ? 'On' : 'Off';
}
