import { dataCatalogApiRef } from '../../../dataCatalogApi';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

export function useTableColumns(tableName)



 {
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const { value } = useAsync(() => dataCatalogApi.getTableByName(tableName), [dataCatalogApi, tableName]);
  const partitionColumns = value?.partition_columns || [];
  const allTableColumns = value?.schema || [];
  const DATE_COLUMNS = ['year', 'month', 'day', 'date', 'created_at', 'updated_at'];
  const partitionColumnsFormData = [];
  const standardColumns = [];

  for (const col of allTableColumns) {
    if (DATE_COLUMNS.includes(col.name) || col.name.includes('_date') || partitionColumns.includes(col.name)) {
      partitionColumnsFormData.push(col.name);
    } else {
      standardColumns.push(col.name);
    }
  }
  const schema = value?.schema;
  const tableColumns = [];
  for (const col of schema || []) {
    tableColumns.push(col.name);
  }
  return { partitionColumnsFormData, tableColumns, standardColumns };
}
