import React from 'react';
import { Link, } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { TableStatus } from '../../types';
import { Chip, Tooltip, Button, Grid } from '@material-ui/core';
import { ChartIcon } from '../Common/ChartIcon';
import { useMixpanel } from '@ring/plugin-common';
import TableChartIcon from '@material-ui/icons/TableChart';

const getColor = (status) => {
  const colors = {
    [TableStatus.NOT_CONFIGURED]: '#F2B9B6',
    [TableStatus.DISABLED]: '#FAD3AE',
    [TableStatus.ENABLED]: '#DBF2DA'
  };
  return colors[status];
};

export function createDatabaseNameColumn() {
  return {
    title: 'Database name',
    field: 'database_name'
  };
}

export function createNameColumn() {
  return {
    title: 'Table name',
    field: 'table_name',
    customFilterAndSearch: () => true
  };
}

export function createOwnerColumn() {
  return {
    title: 'Owner',
    field: 'owner',
    render: ({ owner }) => {
      const squadName = owner && owner.replace(/^group\:/, '');
      return squadName ? React.createElement(Link, { to: `/workforce/squad/${squadName}`,}, squadName) : '';
    }
  };
}

export function createMonitoringColumn() {
  return {
    title: 'Monitoring',
    field: 'setting_status',
    render: ({ setting_status }) => (
      React.createElement(Chip, {
        label: setting_status === 'NOT_CONFIGURED' ? 'NOT CONFIGURED' : setting_status,
        style: { margin: 0, backgroundColor: getColor(setting_status) },}
      )
    )
  };
}







const TrackedLinkSetting = ({ row, onAdd, onOpenSettings }) => {
  return (
    React.createElement(Tooltip, { title: "Data Monitoring Settings"  ,}
      , React.createElement(Button, {
        color: "primary",
        onClick: () => {
          onAdd();
          onOpenSettings(row.database_name, row.table_name);
        },
        startIcon: React.createElement(ChartIcon, null ),}
      )
    )
  );
};





const TrackedLinkMetadata = ({ row }) => {
  const { track } = useMixpanel();
  const navigate = useNavigate();
  return (
    React.createElement(Tooltip, { title: "Table Metadata" ,}
      , React.createElement(Button, {
        color: "primary",
        onClick: () => {
          track('View table metadata', { table: `${row.database_name}.${row.table_name}` });
          navigate(`/observability/tables/${row.database_name}.${row.table_name}/metadata/view`);
        },
        startIcon: React.createElement(TableChartIcon, null ),}
      )
    )
  );
};






export function createActionsColumn({
  onAdd,
  onOpenSettings
}) {
  return {
    title: 'Actions',
    width: '200px',
    cellStyle: { minWidth: '200px' },
    render: row => (
      React.createElement(Grid, null
        , React.createElement(TrackedLinkSetting, { row: row, onAdd: onAdd, onOpenSettings: onOpenSettings,} )
        , React.createElement(TrackedLinkMetadata, { row: row,} )
      )
    )
  };
}
