import React, { } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,

  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: '3rem 2rem'
  },
  field: {
    margin: 'auto auto',
    maxWidth: '40ch',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  paperScrollPaper: {
    // this is for overwite the max-height of the dialog
    maxHeight: 'calc(100% - 34px)'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    '& > *': {
      marginRight: '1rem'
    }
  }
}));














export const AlertDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  disableConfirm = false,
  confirmButtonLeyend = 'Ok',
  cancelButtonLeyend = 'Cancel',
  children,
  size = 'sm'
}) => {
  const classes = useStyles();

  return (
    React.createElement(Dialog, {
      classes: { paperScrollPaper: classes.paperScrollPaper },
      className: classes.container,
      open: open,
      maxWidth: size,}
    
      , title && (
        React.createElement(DialogTitle, null
          , React.createElement(Typography, { variant: "h5",}, title)
        )
      )
      , React.createElement(DialogContent, null
        , React.createElement(React.Fragment, null
          , message && (
            React.createElement(Typography, { variant: "body2", className: classes.field,}
              , message
            )
          )
          , children && children
        )
      )
      , React.createElement(DialogActions, null
        , React.createElement('div', { className: classes.actionsContainer,}
          , React.createElement(Button, { variant: "outlined", onClick: onClose, color: "secondary",}
            , cancelButtonLeyend
          )
          , React.createElement(Button, { variant: "contained", onClick: onConfirm, color: "primary", disabled: disableConfirm,}
            , confirmButtonLeyend
          )
        )
      )
    )
  );
};
