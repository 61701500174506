
















import { Box, Button, Drawer, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState, } from 'react';

export const FilterContainer = ({ children }) => {
  const isMidSizeScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const theme = useTheme();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return isMidSizeScreen ? (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        style: { marginTop: theme.spacing(1), marginLeft: theme.spacing(1) },
        onClick: () => setFilterDrawerOpen(true),
        startIcon: React.createElement(FilterListIcon, null ),}
      , "Filters"

      )
      , React.createElement(Drawer, {
        open: filterDrawerOpen,
        onClose: () => setFilterDrawerOpen(false),
        anchor: "left",
        disableAutoFocus: true,
        keepMounted: true,
        variant: "temporary",}
      
        , React.createElement(Box, { m: 2,}
          , React.createElement(Typography, { variant: "h6", component: "h2", style: { marginBottom: theme.spacing(1) },}, "Filters"

          )
          , children
        )
      )
    )
  ) : (
    React.createElement(Grid, { item: true, lg: 2,}
      , children
    )
  );
};
