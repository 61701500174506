
















import { defaultDefinitionWidgets } from './components/ApiDefinitionCard';
import { apiDocsConfigRef } from './config';
import { createComponentRouteRef, rootRoute } from './routes';
import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension
} from '@backstage/core-plugin-api';


export const apiDocsPlugin = createPlugin({
  id: 'api-docs',
  routes: {
    root: rootRoute
  },
  apis: [
    createApiFactory({
      api: apiDocsConfigRef,
      deps: {},
      factory: () => {
        const definitionWidgets = defaultDefinitionWidgets();
        return {
          getApiDefinitionWidget: (apiEntity) => {
            return definitionWidgets.find(d => d.type === apiEntity.spec.type);
          },
          getApiDefinitionWidgetV1: (api) => {
            return definitionWidgets.find(d => d.type === api.type);
          }
        };
      }
    })
  ],
  externalRoutes: {
    createComponent: createComponentRouteRef
  }
});

export const ApiExplorerPage = apiDocsPlugin.provide(
  createRoutableExtension({
    component: () => import('./components/ApiExplorerPage').then(m => m.ApiExplorerPage),
    mountPoint: rootRoute
  })
);

export const EntityApiDefinitionCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ApiDefinitionCard').then(m => m.ApiDefinitionCard)
    }
  })
);

export const EntityConsumedApisCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ApisCards').then(m => m.ConsumedApisCard)
    }
  })
);

export const EntityConsumingComponentsCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ComponentsCards').then(m => m.ConsumingComponentsCard)
    }
  })
);

export const EntityProvidedApisCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ApisCards').then(m => m.ProvidedApisCard)
    }
  })
);

export const EntityProvidingComponentsCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ComponentsCards').then(m => m.ProvidingComponentsCard)
    }
  })
);

export const EntityHasApisCard = apiDocsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/ApisCards').then(m => m.HasApisCard)
    }
  })
);
