import React, { useState } from 'react';
import {
  Grid,
  Backdrop,
  CircularProgress,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';


import { machineLearningDeploymentApiRef } from '../../api';
import { InfoCard } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import useSWR, { mutate } from 'swr';
import { CreateMachineLearningDeployForm } from '../MachineLearningDeploymentFormPage/CreateMachineLearningDeployForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);





const EditDeploymentConfirmationDialog = ({
  open,
  onClose,
  onClick,
  classes,
  openBackdrop
}





) => {
  return (
    React.createElement(Dialog, {
      open: open,
      onClose: onClose,
      'aria-labelledby': "alert-dialog-title",
      'aria-describedby': "alert-dialog-description",}
    
      , React.createElement(DialogTitle, { id: "alert-dialog-title",}, "These changes will take a few minutes to take effect. Are you sure you want to update this deployment?"

      )
      , React.createElement(DialogActions, null
        , React.createElement(Button, { onClick: onClose, color: "primary",}, "Cancel"

        )
        , React.createElement(Button, { onClick: onClick, color: "primary",}, "Confirm"

        )
        , React.createElement(Backdrop, { className: classes.backdrop, open: openBackdrop,}
          , React.createElement(CircularProgress, null )
        )
      )
    )
  );
};

export const EditMachineLearningDeploymentPage = ({ entity }) => {
  const { deploymentEnvironment, runID } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [deploymentValues, setDeploymentValues] = useState({} );
  const machineLearningDeploymentApi = useApi(machineLearningDeploymentApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const deploymentKey = `v1/deployment/${runID}`;

  const { data, error } = useSWR(deploymentKey, async () => {
    return initializeForm(
      await machineLearningDeploymentApi.getDeploymentMlflowByID(
        entity.metadata.name,
        runID ,
        deploymentEnvironment 
      )
    );
  });

  if (error) {
    errorApi.post(new Error(`Failed to load application deployments, ${error}`));
  }

  const isLoading = !data && !error;

  function initializeForm(deploymentInfo) {
    const deploymentFormValue = {
      ...deploymentInfo,
      editable: false
    };

    return deploymentFormValue;
  }

  const onSubmit = async () => {
    try {
      setOpenBackdrop(true);
      await machineLearningDeploymentApi.updateDeploymentMlflow(entity.metadata.name, deploymentValues);
      mutate(deploymentKey, data);
      navigate(-1);
    } catch (e) {
      setOpenBackdrop(false);
      errorApi.post(e);
    }
  };

  const handleClickOpen = (values) => {
    setDeploymentValues(values);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "Edit Deployment" ,}
        , isLoading && React.createElement(LinearProgress, { 'data-testid': "loading-progress",} )
        , data && React.createElement(CreateMachineLearningDeployForm, { initialValues: data, onSubmit: handleClickOpen,} )
      )
      , React.createElement(EditDeploymentConfirmationDialog, {
        open: open,
        onClose: handleClose,
        onClick: onSubmit,
        classes: classes,
        openBackdrop: openBackdrop,}
      )
    )
  );
};
