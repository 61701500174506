import React, { useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useApplication } from '@ring/plugin-catalog';
import { useAsyncFn } from 'react-use';
import { databaseApiRef } from '../../api';
import { DatabaseTable } from '../DatabaseTable';
import { ResponseErrorPanel } from '@backstage/core-components';

export const DatabaseListPage = () => {
  const { application } = useApplication();
  const databaseApi = useApi(databaseApiRef);

  const [{ value: databases = [], loading, error }, refresh] = useAsyncFn(async () => {
    const response = await databaseApi.getDatabases(application?.id);
    return response;
  });

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (error) {
    React.createElement(ResponseErrorPanel, { error: error,} );
  }

  return React.createElement(DatabaseTable, { loading: loading, databases: databases, onRefresh: refresh,} );
};
