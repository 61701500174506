import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

export const SignInPage = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const loginWithRedirect = () => oktaAuth.signInWithRedirect({ originalUri: `${window.location.origin}/callback` });

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", style: { height: '100vh' },}
      , React.createElement(Grid, { container: true,}
        , React.createElement(Grid, {
          item: true,
          xs: 5,
          style: {
            backgroundImage: 'linear-gradient(to left bottom, #008cff, #0077ff, #005fff, #0041ff, #1600ff)',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },}
        
          , React.createElement(Typography, {
            variant: "h3",
            style: {
              fontWeight: 700,
              color: 'white'
            },}
          
            , 'Welcome\n'
            , React.createElement('span', {
              style: {
                fontWeight: 200
              },}
            , "to Ring"

            )
          )
        )
        , React.createElement(Grid, {
          item: true,
          xs: 7,
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },}
        
          , React.createElement(Box, { display: "flex", flexDirection: "column", style: { borderLeft: '2px solid #008cff', padding: '0 2rem' },}
            , React.createElement(Typography, { variant: "h4",}, "Get Access Via SSO"   )
            , React.createElement(Typography, { variant: "subtitle1", color: "textSecondary",}, "With Okta credentials"

            )
            , React.createElement(Box, { p: "1rem 0" ,}
              , React.createElement(Button, { onClick: loginWithRedirect, color: "primary", variant: "outlined",}, "Login"

              )
            )
            , React.createElement(Typography, { variant: "body1",}, authState?.error?.message)
          )
        )
      )
    )
  );
};
