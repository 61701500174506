import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

import { commonApiRef } from '../api';

export const useMe = () => {
  const commonApi = useApi(commonApiRef);
  const { value, loading, error } = useAsync(() => commonApi.getMe(), [commonApi]);

  return { me: value, loading, error };
};
