import { DismissableBanner, Link, Table, Content } from '@backstage/core-components';

import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { provisioningApiRef } from '../../api';
import { useCoreApplication } from '@ring/plugin-common';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { DashboardCard } from './DashboardCard';










const getNamespace = (serviceName) => {
  const parts = serviceName.split('-');
  parts.splice(-2, 2);
  return parts.join('-');
};

export const DeploymentScopeTable = ({ service }) => {
  const backendAPI = useApi(provisioningApiRef);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { value: coreApplication, loading } = useCoreApplication(service.spec.applicationId);

  const dashboards = coreApplication?.meta?.application_information?.dashboards || [];

  useEffect(() => {
    const deployData = async () => {
      const deployments = await backendAPI.getCoreDeployments(service.spec.applicationId);

      let tData = deployments.map(
        deployment =>
          ({
            name: deployment.name,
            environment: deployment.meta?.environment,
            namespace: getNamespace(deployment.name),
            image: `797740695898.dkr.ecr.us-east-1.amazonaws.com/${getNamespace(deployment.name)}:${
              deployment?.meta?.desired_version
            }`,
            status: deployment.status,
            id: deployment.id
          } )
      );
      tData = tData.flat();
      setData(tData);
      setIsLoading(false);
    };
    deployData();
  }, [backendAPI, service]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (row) => (
        React.createElement(Link, { to: `/infra/${service.spec.applicationId}/deployment/${row.id}/details`,}, row.name)
      )
    },
    {
      title: 'Environment',
      field: 'environment'
    },
    {
      title: 'Namespace',
      field: 'namespace'
    },
    {
      title: 'Image',
      field: 'image'
    },
    {
      title: 'Status',
      field: 'status'
    }
  ];

  const getDashboards = () => {
    if (dashboards?.length > 0) {
      return dashboards.map(dashboard => React.createElement(DashboardCard, { key: dashboard.id, dashboard: dashboard,} ));
    }
    return React.createElement('p', null, "There is no dashboard attached to this app"       );
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Content, null
        , React.createElement(Table, {
          isLoading: isLoading,
          title: "Deployment Scopes" ,
          columns: columns,
          data: data,
          options: { paging: false, search: false },}
        )

        , isLoading === false && data.length === 0 && (
          React.createElement(DismissableBanner, {
            message: "The application was created and the resources still being created in the cluster."            ,
            id: "nodata",
            variant: "info",}
          )
        )
      )
      , React.createElement(Content, null
        , React.createElement(Typography, { variant: "h5", style: { marginBottom: '1rem' },}, "Observability Dashboards"

        )
        , React.createElement(Box, { display: "flex", flexDirection: "row", style: { gap: '1rem' }, flexWrap: "wrap",}
          , loading ? (
            React.createElement(Box, null
              , React.createElement(CircularProgress, null )
            )
          ) : (
            getDashboards()
          )
        )
      )
    )
  );
};
