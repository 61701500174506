import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: (props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    order: -1,
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 101,
    minHeight: theme.spacing(8),
    padding: props?.padding ?? '1rem',
    boxShadow: props.noDivider ? 'none' : theme.shadows[2]
  }),
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: (props) => ({
    whiteSpace: 'nowrap',
    maxWidth: props?.titleMaxWidth ?? '290px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  backBtn: {
    marginRight: '1rem',
    cursor: 'pointer'
  },
  loader: {
    marginLeft: '1rem'
  }
}));











export const ActionsHeader = ({
  children,
  title,
  padding,
  loading = false,
  triggeringFn,
  titleMaxWidth,
  noDivider
}) => {
  const classes = useStyles({ padding, titleMaxWidth, noDivider });
  const navigate = useNavigate();

  const handleBack = () => {
    if (typeof triggeringFn === 'function') {
      triggeringFn();
    } else {
      navigate(-1);
    }
  };

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement('div', { className: classes.titleSection,}
        , React.createElement(ArrowBack, { className: classes.backBtn, onClick: handleBack, color: "primary",} )
        , typeof title === 'string' ? (
          React.createElement(Typography, { className: classes.title, variant: "h5",}
            , title
          )
        ) : (
          title
        )
        , loading && React.createElement(CircularProgress, { className: classes.loader, size: 16,} )
      )
      , !loading && children
    )
  );
};
