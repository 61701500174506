import React from 'react';
import { Container } from '@material-ui/core';
import { CronExpressionConfig } from './CronExpressionConfig';
import { EtaConfig } from './EtaConfig';

export const DataMonitoringStep2 = () => {
  return (
    React.createElement(Container, { maxWidth: "md",}
      , React.createElement(CronExpressionConfig, null )
      , React.createElement(EtaConfig, null )
    )
  );
};
