export var SlaConfigOptions; (function (SlaConfigOptions) {
  const hourly = 'HOUR'; SlaConfigOptions["hourly"] = hourly;
  const daily = 'DAY'; SlaConfigOptions["daily"] = daily;
})(SlaConfigOptions || (SlaConfigOptions = {}));

export var SlaResolverOptions; (function (SlaResolverOptions) {
  const query_resolver = 'Querying the Data'; SlaResolverOptions['query_resolver'] = query_resolver;
  const airflow_task_sensor = 'Airflow Task Sensor'; SlaResolverOptions['airflow_task_sensor'] = airflow_task_sensor;
})(SlaResolverOptions || (SlaResolverOptions = {}));

export var OpsgeniePriorityOptions; (function (OpsgeniePriorityOptions) {
  const P1 = 'P1 - Critical'; OpsgeniePriorityOptions['P1'] = P1;
  const P2 = 'P2 - High'; OpsgeniePriorityOptions['P2'] = P2;
  const P3 = 'P3 - Moderate'; OpsgeniePriorityOptions['P3'] = P3;
  const P4 = 'P4 - Low'; OpsgeniePriorityOptions['P4'] = P4;
  const P5 = 'P5 - Informational'; OpsgeniePriorityOptions['P5'] = P5;
})(OpsgeniePriorityOptions || (OpsgeniePriorityOptions = {}));

export var ignoreIfOptions; (function (ignoreIfOptions) {
  const NEVER = 'never'; ignoreIfOptions["NEVER"] = NEVER;
})(ignoreIfOptions || (ignoreIfOptions = {}));

export var QueryResolverIntervalTimeOptions; (function (QueryResolverIntervalTimeOptions) {
  const hours = 'Hours'; QueryResolverIntervalTimeOptions["hours"] = hours;
  const days = 'Days'; QueryResolverIntervalTimeOptions["days"] = days;
})(QueryResolverIntervalTimeOptions || (QueryResolverIntervalTimeOptions = {}));

export const COLUMN_VALIDATIONS_KEY = 'validations';
export const PARTITION_COLUMNS_FORM_KEY = 'columns';















































































export class ListDataMonitoringSettingsVersions {
  versions;
  constructor(versions) {
    this.versions = versions;
  }
}
