import { createApiRef, } from '@backstage/core-plugin-api';


export const machineLearningDeploymentApiRef = createApiRef({
  id: 'plugin.machine-learning-deployment.service',
  description: 'Used to make requests towards the data workflow backend'
});











export class MachineLearningDeploymentApiClient  {
    discoveryApi;
    identityApi;

   urlEnvironmentsMap = new Map([
    ['production', 'prod'],
    ['development', 'stag'],
    ['staging', 'stag']
  ]);

  constructor(options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getDeploymentsMlflow(applicationID) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-prod/api/v1/deployment/list/${applicationID}`;

    const response = await fetch(url, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    const deployments = [];
    for (const deployment of body.deployments) {
      deployments.push({
        environment: deployment.env.toUpperCase(),
        mlflow_run_url: deployment.url,
        ...deployment
      });
    }

    return deployments;
  }

  async deployMlflow(applicationID, deploymentInfo) {
    if (!this.urlEnvironmentsMap.has(deploymentInfo.environment)) {
      throw new Error(`Environment ${deploymentInfo.environment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(deploymentInfo.environment);

    const token = await this.identityApi.getIdToken();
    const urlProjectInfo = `ring/${applicationID}/mla/${deploymentInfo.run_id}`;

    // Build image for deployment
    const buildImageUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/buildimage/${urlProjectInfo}`;

    const buildImageRequestBody = {
      model_owner: applicationID
    };

    const responseBuild = await fetch(buildImageUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(buildImageRequestBody)
    });

    if (responseBuild.status !== 200) {
      const status = `${responseBuild.status} ${responseBuild.statusText}`;
      const body = await responseBuild.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    // Deploying Model
    const deployNameSpace = 'mle-serving';
    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/deployment/${urlProjectInfo}`;

    const deployRequestBody = {
      image: {
        repository: deployNameSpace,
        tag: urlProjectInfo.replace(/\//g, '-')
      },
      owner: applicationID,
      namespace: deployNameSpace,
      replicas: Number(deploymentInfo.replicas),
      resources: {
        limits: {
          cpu: Number(deploymentInfo.number_of_cores),
          memory: `${deploymentInfo.memory}Mi`
        },
        requests: {
          cpu: Number(deploymentInfo.number_of_cores),
          memory: `${deploymentInfo.memory}Mi`
        }
      }
    };

    const responseDeploy = await fetch(deployUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(deployRequestBody)
    });

    if (responseDeploy.status !== 200) {
      const status = `${responseDeploy.status} ${responseDeploy.statusText}`;
      const body = await responseDeploy.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async updateDeploymentMlflow(applicationID, deploymentInfo) {
    if (!this.urlEnvironmentsMap.has(deploymentInfo.environment)) {
      throw new Error(`Environment ${deploymentInfo.environment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(deploymentInfo.environment);

    const token = await this.identityApi.getIdToken();
    const urlProjectInfo = `ring/${applicationID}/mla/${deploymentInfo.run_id}`;

    // Deploying Model
    const deployNameSpace = 'mle-serving';
    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/deployment/${urlProjectInfo}`;

    const deployRequestBody = {
      image: {
        repository: deployNameSpace,
        tag: urlProjectInfo.replace(/\//g, '-')
      },
      owner: applicationID,
      namespace: deployNameSpace,
      replicas: Number(deploymentInfo.replicas),
      resources: {
        limits: {
          cpu: Number(deploymentInfo.number_of_cores),
          memory: `${deploymentInfo.memory}Mi`
        },
        requests: {
          cpu: Number(deploymentInfo.number_of_cores),
          memory: `${deploymentInfo.memory}Mi`
        }
      }
    };

    const responseDeploy = await fetch(deployUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(deployRequestBody)
    });

    if (responseDeploy.status !== 200) {
      const status = `${responseDeploy.status} ${responseDeploy.statusText}`;
      const body = await responseDeploy.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async removeDeploymentMlflow(applicationID, deploymentInfo) {
    const normalizedEnvironment = deploymentInfo.environment.toLowerCase();
    if (!this.urlEnvironmentsMap.has(normalizedEnvironment)) {
      throw new Error(`Environment ${normalizedEnvironment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(normalizedEnvironment);

    const token = await this.identityApi.getIdToken();
    const urlProjectInfo = `${applicationID}/ring/${applicationID}/mla/${deploymentInfo.run_id}`;

    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/delete/${urlProjectInfo}`;

    const responseDeploy = await fetch(deployUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (responseDeploy.status !== 200) {
      const status = `${responseDeploy.status} ${responseDeploy.statusText}`;
      const body = await responseDeploy.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async getDeploymentMlflowByID(
    applicationID,
    runID,
    environment
  ) {
    if (!this.urlEnvironmentsMap.has(environment)) {
      throw new Error(`Environment ${environment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(environment);

    const token = await this.identityApi.getIdToken();
    const urlProjectInfo = `${applicationID}/ring/${applicationID}/mla/${runID}`;

    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/deployment/${urlProjectInfo}`;

    const response = await fetch(deployUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();
    return body.deployment;
  }

  async getDeploymentAlerts(applicationID, runID, environment) {
    if (!this.urlEnvironmentsMap.has(environment)) {
      throw new Error(`Environment ${environment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(environment);

    const token = await this.identityApi.getIdToken();
    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/alerts/${applicationID}/${environment}/${runID}`;

    const response = await fetch(deployUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();
    return body;
  }

  async manageDeploymentAlerts(applicationID, environment, alertsInfo) {
    if (!this.urlEnvironmentsMap.has(environment)) {
      throw new Error(`Environment ${environment} does not exist.`);
    }
    const deploymentEnvironment = this.urlEnvironmentsMap.get(environment);

    const token = await this.identityApi.getIdToken();
    const deployUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-deploy-${deploymentEnvironment}/api/v1/alerts/${applicationID}/`;

    const response = await fetch(deployUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(alertsInfo)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }
}
