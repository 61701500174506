








































































export var BundleUpdateTypes; (function (BundleUpdateTypes) {
  const MANUAL = 'manual'; BundleUpdateTypes["MANUAL"] = MANUAL;
  const AUTOMATIC = 'automatic'; BundleUpdateTypes["AUTOMATIC"] = AUTOMATIC;
})(BundleUpdateTypes || (BundleUpdateTypes = {}));

export var BundleActionTypes; (function (BundleActionTypes) {
  const COMMIT = 'commit'; BundleActionTypes["COMMIT"] = COMMIT;
  const TAG = 'tag'; BundleActionTypes["TAG"] = TAG;
})(BundleActionTypes || (BundleActionTypes = {}));

export var BundleStatus; (function (BundleStatus) {
  const LIVE = 'live'; BundleStatus["LIVE"] = LIVE;
  const DEPLOYING = 'deploying'; BundleStatus["DEPLOYING"] = DEPLOYING;
})(BundleStatus || (BundleStatus = {}));

export var Environment; (function (Environment) {
  const PRODUCTION = 'production'; Environment["PRODUCTION"] = PRODUCTION;
  const STAGING = 'stage'; Environment["STAGING"] = STAGING;
})(Environment || (Environment = {}));

export var EnvironmentVariableStatus; (function (EnvironmentVariableStatus) {
  const LIVE = 'live'; EnvironmentVariableStatus["LIVE"] = LIVE;
  const WAITING = 'waiting'; EnvironmentVariableStatus["WAITING"] = WAITING;
  const DELETED = 'deleted'; EnvironmentVariableStatus["DELETED"] = DELETED;
})(EnvironmentVariableStatus || (EnvironmentVariableStatus = {}));

























export var MonitorStatus; (function (MonitorStatus) {
  const NO_DATA = 'No Data'; MonitorStatus["NO_DATA"] = NO_DATA;
  const OK = 'OK'; MonitorStatus["OK"] = OK;
  const ERROR = 'Error'; MonitorStatus["ERROR"] = ERROR;
  const WARNING = 'Warning'; MonitorStatus["WARNING"] = WARNING;
})(MonitorStatus || (MonitorStatus = {}));

















