import React from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { PARTITION_COLUMNS_FORM_KEY } from '../types';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import emptyImage from '../../../assets/empty-col.svg';
import { useFormContext } from 'react-hook-form';








const useStyles = makeStyles(() => ({
  tableCell: {
    fontWeight: 'bolder',
    color: '#333',
    width: '40%'
  },
  actionCell: {
    fontWeight: 'bolder',
    color: '#333',
    width: '10%'
  }
}));

export const DisplayTableColumns = ({ fields: rows = [], onDelete }) => {
  const classes = useStyles();
  const { register, formState } = useFormContext();
  const { errors } = formState;

  const handleClick = (index) => () => {
    onDelete(index);
  };

  return (
    React.createElement(Paper, { style: { minHeight: '200px' },}
      , rows.length === 0 ? (
        React.createElement(Box, { display: "flex", flexDirection: "column", mt: "10px", alignItems: "center", style: { gap: '1.5rem' },}
          , React.createElement(Typography, { variant: "h6",}, "Columns you add will appear here"     )
          , React.createElement('img', { style: { display: 'block', width: '20%' }, src: emptyImage, alt: "empty",} )
        )
      ) : (
        React.createElement(Table, { size: "small", style: { height: '100%' },}
          , React.createElement(TableHead, null
            , React.createElement(TableRow, null
              , React.createElement(TableCell, { className: classes.tableCell,}, "NAME")
              , React.createElement(TableCell, { className: classes.tableCell,}, "DATE TIME FORMAT"  )
              , React.createElement(TableCell, { className: classes.tableCell, align: "center",}, "ACTIONS"

              )
            )
          )
          , React.createElement(TableBody, null
            , rows.map((row, index) => (
              React.createElement(TableRow, { key: row.id,}
                , React.createElement(TableCell, { component: "th", scope: "row",}
                  , row.column_name
                )
                , React.createElement(TableCell, null
                  , React.createElement(TextField, {
                    error: errors?.[PARTITION_COLUMNS_FORM_KEY]?.[index],
                    helperText: errors?.[PARTITION_COLUMNS_FORM_KEY]?.[index]?.column_data_format?.message,
                    ...register(`${PARTITION_COLUMNS_FORM_KEY}.${index}.column_data_format`),}
                  )
                )
                , React.createElement(TableCell, { align: "center",}
                  , React.createElement(IconButton, { 'aria-label': "remove", onClick: handleClick(index),}
                    , React.createElement(RemoveCircleOutlineIcon, { color: "secondary",} )
                  )
                )
              )
            ))
          )
        )
      )
    )
  );
};
