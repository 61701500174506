import React from 'react';
import { SvgIcon, } from '@material-ui/core';

export const ChartIcon = (props) => {
  return (
    React.createElement(SvgIcon, { ...props,}
      , React.createElement('path', {
        d: "M19.5 19H5.5V5H14.5V3H5.5C4.4 3 3.5 3.9 3.5 5V19C3.5 20.1 4.4 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V10H19.5V19Z"                ,
        fill: "#008CFF",}
      )
      , React.createElement('path', { d: "M17.5 13H15.5V17H17.5V13Z" , fill: "#008CFF",} )
      , React.createElement('path', { d: "M9.5 10H7.5V17H9.5V10Z" , fill: "#008CFF",} )
      , React.createElement('path', { d: "M13.5 7H11.5V17H13.5V7Z" , fill: "#008CFF",} )
      , React.createElement('path', { d: "M19.5 5V3H17.5V5H15.5V7H17.5V9H19.5V7H21.5V5H19.5Z" , fill: "#008CFF",} )
    )
  );
};
