import { createApiRef, } from '@backstage/core-plugin-api';


export const catalogApiRef = createApiRef({
  id: 'plugin.ring-catalog.service',
  description: 'Used to make requests towards the ring-catalog'
});

export function isOk(res) {
  return res.status >= 200 && res.status < 300;
}

export class CatalogApi {
    identityApi;
    discoveryApi;

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getServicesByApplication(applicationId, type) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const filter = type ? `?service_type=${type}` : '';
    const url = `${baseUrl}/core-api/applications/${applicationId}/services${filter}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async getService(serviceId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services/${serviceId}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }
}
