/* eslint-disable react-hooks/rules-of-hooks */
import { dataWorkflowApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';


export const useApplicationBundles = (
  applicationID
) => {
  const dataWorkflowApi = useApi(dataWorkflowApiRef);
  const { value, loading, error } = useAsync(
    () => dataWorkflowApi.getBundles(applicationID),
    [dataWorkflowApi, applicationID]
  );

  return { bundles: value, loading, error };
};

export function bundle_exist(_bundles



) {
  return _bundles.bundles !== undefined && _bundles.bundles.length > 0;
}
