import { createContext, useContext } from 'react';








export const ObservabilityContext = createContext({
  onRefresh: () => {},
  onEdit: () => {},
  data: {} 
});

export const useObservabilityContext = () => {
  const context = useContext(ObservabilityContext);
  if (context === undefined) {
    throw new Error('useObservabilityContext must be used within a ObservabilityContextProvider');
  }
  return context;
};
