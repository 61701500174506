import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Tooltip,
  Select,
  IconButton,
  useTheme,
  FormLabel
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext, Controller, useWatch } from 'react-hook-form';
import {
  COLUMN_VALIDATIONS_KEY,

  QueryResolverIntervalTimeOptions,

  PARTITION_COLUMNS_FORM_KEY
} from '../types';
import { ColumnExpectationNames, TableExpectationNames } from '../../../types';
import { get } from 'lodash';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FieldsByExpectName } from './FieldsByExpectName';











export const DataMonitoringStep3 = ({ partitionCols, standardCols }) => {
  const {
    control,
    getValues,
    register,
    formState: { errors, isValid }
  } = useFormContext();
  const theme = useTheme();

  const { fields, prepend, remove, update, insert } = useFieldArray({
    control,
    name: COLUMN_VALIDATIONS_KEY
  });

  const currentDraft = useMemo(() => fields.find((f) => f.isDraft), [fields]);

  const handleAddNewDraft = (expectationName) => {
    const prevFieldDraftId = currentDraft?.id;

    if (prevFieldDraftId) remove(prevFieldDraftId);

    prepend({ expectation_name: expectationName, isDraft: true });
  };

  const handleAddValidation = (filedItemIndex) => {
    const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${filedItemIndex}`;
    const values = getValues(fieldPath);

    update(filedItemIndex, { ...values, isDraft: false });
  };

  const partition_columns = useWatch({ control, name: PARTITION_COLUMNS_FORM_KEY });
  const hasPartitionColumns = partition_columns.length > 0;

  return (
    React.createElement(Grid, { container: true, style: { height: '70vh' },}
      , React.createElement(Grid, { item: true, xs: 6,}
        , React.createElement(Grid, { container: true, direction: "column",}
          , hasPartitionColumns && (
            React.createElement(Tooltip, { title: "You are seeing this section because you selected to use Datetime columns to filter your data. Please, select the period of data to perform Data Quality Validations"                          ,}
              , React.createElement(Grid, { item: true, container: true, xs: 12, alignItems: "stretch",}
                , React.createElement(Grid, { item: true, xs: 12, sm: 6,}
                  , React.createElement(Controller, {
                    control: control,
                    name: "data_quality_settings.resolver_interval_time",
                    render: props => (
                      React.createElement(FormControl, { error: !!errors?.data_quality_settings?.resolver_interval_time, fullWidth: true,}
                        , React.createElement(FormLabel, null, "DQ Data interval"  )
                        , React.createElement(Select, {
                          defaultValue: props.field.value ?? '',
                          margin: "dense",
                          id: "dq_resolver_interval_granularity",
                          ...props.field,}
                        
                          , Object.values(QueryResolverIntervalTimeOptions).map((o, index) => (
                            React.createElement(MenuItem, { key: o + index, value: o,}
                              , o
                            )
                          ))
                        )
                        , React.createElement(FormHelperText, null
                          , errors?.data_quality_settings?.resolver_interval_time?.message
                        )
                      )
                    ),}
                  )
                )
                , React.createElement(Grid, { item: true, xs: 12, sm: 6,}
                  , React.createElement(FormLabel, null, "Period")
                  , React.createElement(TextField, {
                    type: "number",
                    id: "dq_resolver_interval_time",
                    fullWidth: true,
                    ...register(`data_quality_settings.resolver_interval_value` ),
                    helperText: errors?.data_quality_settings?.resolver_interval_value?.message,
                    error: !!errors?.data_quality_settings?.resolver_interval_value?.message,
                    margin: "dense",}
                  )
                )
              )
            )
          )
          , React.createElement(Grid, { item: true, container: true, xs: 12,}
            , React.createElement(Grid, { item: true, sm: 12,}
              , React.createElement(TextField, {
                select: true,
                onChange: e => handleAddNewDraft(e.target.value),
                defaultValue: currentDraft?.expectation_name ?? '',
                value: currentDraft?.expectation_name ?? '',
                label: "Validations",
                fullWidth: true,}
              
                , React.createElement(MenuItem, { disabled: true,}, "Column Validation" )
                , Object.values(ColumnExpectationNames).map(value => {
                  return (
                    React.createElement(MenuItem, { key: value, value: value,}
                      , get(FieldsByExpectName, value)?.title ?? '-'
                    )
                  );
                })
                , React.createElement(MenuItem, { disabled: true,}, "Table Validation" )
                , Object.values(TableExpectationNames).map(value => {
                  return (
                    React.createElement(MenuItem, { key: value, value: value,}
                      , get(FieldsByExpectName, value)?.title ?? '-'
                    )
                  );
                })
              )
            )
          )
          , React.createElement(Grid, { item: true, xs: 12,}
            , fields.map((field, index) => {
              if (!field.isDraft) return null;

              return (
                React.createElement(Paper, {
                  key: field.id,
                  elevation: 1,
                  style: { width: '100%', padding: '1rem 2rem', marginBottom: '1rem' },}
                
                  , React.createElement(Box, { display: "flex", p: "1rem 0" , justifyContent: "space-between",}
                    , React.createElement(Typography, { variant: "subtitle2",}, get(FieldsByExpectName, field.expectation_name).title)
                  )
                  , React.createElement(FieldComponent, {
                    expectationName: currentDraft?.expectation_name,
                    fieldIndex: index,
                    key: field.id,
                    tableColumns: standardCols.concat(partitionCols),}
                  )

                  , React.createElement(Box, { display: "flex", justifyContent: "flex-end", p: "1rem",}
                    , React.createElement(Button, {
                      variant: "outlined",
                      style: { borderColor: theme.palette.error.main, color: theme.palette.error.main },
                      type: "button",
                      onClick: () => remove(index),}
                    , "Delete"

                    )
                    , React.createElement(Button, {
                      disabled: !isValid,
                      variant: "outlined",
                      style: { marginLeft: '1rem' },
                      color: "primary",
                      type: "button",
                      onClick: () => handleAddValidation(index),}
                    , "Add validation"

                    )
                  )
                )
              );
            })
          )
        )
      )
      , React.createElement(Grid, { item: true, xs: 6,}
        , React.createElement(Paper, { elevation: 1, style: { height: '70vh', padding: '1rem 2rem', marginBottom: '1rem', overflowY: 'auto' },}
          , fields.map((field, index) => {
            if (field.isDraft) return null;

            return (
              React.createElement(Box, {
                key: field.id,
                display: "flex",
                m: "1.5rem 0" ,
                p: "0 1rem" ,
                flexDirection: "column",
                style: { borderLeft: '3px solid #DDDDDD' },}
              
                , React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center",}
                  , React.createElement(Typography, { variant: "subtitle2", style: { fontWeight: 600 },}
                    , get(FieldsByExpectName, field.expectation_name).title
                  )
                  , React.createElement(Box, { display: "flex", justifyContent: "space-between",}
                    , React.createElement(IconButton, { onClick: () => remove(index), style: { fontSize: '0.6rem' },}
                      , React.createElement(DeleteOutlineOutlinedIcon, { color: "error",} )
                    )
                    , React.createElement(IconButton, {
                      onClick: () => insert(index + 1, { ...getValues(`${COLUMN_VALIDATIONS_KEY}.${index}`) }),
                      style: { fontSize: '0.6rem' },}
                    
                      , React.createElement(ControlPointDuplicate, { color: "primary",} )
                    )
                    , React.createElement(IconButton, {
                      onClick: () =>
                        update(index, { ...getValues(`${COLUMN_VALIDATIONS_KEY}.${index}`), isDraft: true })
                      ,
                      style: { fontSize: '0.6rem' },}
                    
                      , React.createElement(EditOutlinedIcon, { color: "primary",} )
                    )
                  )
                )
                , React.createElement(FieldComponent, {
                  expectationName: field.expectation_name,
                  isSummaryVersionActive: true,
                  fieldIndex: index,
                  key: field.id,
                  tableColumns: standardCols.concat(partitionCols),}
                )
              )
            );
          })
        )
      )
    )
  );
};

function FieldComponent({ expectationName, ...rest }) {
  return get(FieldsByExpectName, expectationName).component(rest);
}
