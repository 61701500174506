import { createApiRef, } from '@backstage/core-plugin-api';









import { ListDataMonitoringSettingsVersions } from './components/DataMonitoringPageV2/types';

export const dataObservabilityApiRef = createApiRef({
  id: 'plugin.data-observability.service',
  description: 'Used to make requests towards the data observabiity backend'
});





































export class DataObservabilityClient  {
    discoveryApi;
    identityApi;

  constructor(options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async searchAllTablesV2(filters) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/search`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        page_size: filters?.size || 20,
        page_number: filters?.page || 1,
        query: {
          resource: 'tables',
          match: {
            id: filters?.query || '',
            owner: filters?.query || ''
          }
        }
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async searchAllSettingsV2(filters



) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/search`;

    const match =
      filters?.query === ''
        ? {}
        : {
            name: filters?.query,
            database_name: filters?.query,
            table_name: filters?.query,
            org_squad: filters?.query
          };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        page_size: filters?.size || 20,
        page_number: filters?.page || 1,
        query: {
          resource: 'settings',
          match: match
        }
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async getAirflowHostsV2() {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/connections`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body.airflow || [];
  }

  async listDataMonitoringSettingsByTableV2(databaseName, tableName) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings?database_name=${databaseName}&table_name=${tableName}&page_size=200&page_number=0&only_enabled=False`;
    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status === 404) {
      return [];
    }
    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body.settings;
  }

  async getDataMonitoringSettingByIdV2(settingId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status === 404) {
      return undefined;
    }
    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async getDataMonitoringSettingByVersionV2(settingId, version) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}/versions/${version}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status === 404) {
      return null;
    }
    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async listDataMonitoringSettingsVersionsV2(settingId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}/versions?page_size=10&page_number=0`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status === 404) {
      return null;
    }
    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    const resp = new ListDataMonitoringSettingsVersions(body.versions);
    return resp;
  }

  async createDataMonitoringSettingV2(args) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(args)
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async updateDataMonitoringSettingV2(settingId, args) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(args)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async deleteDataMonitoringSettingV2(settingId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async enableDataMonitoringSettingV2(settingId, enabled) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}/enableDisable`;

    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({ enabled })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async getDataMonitoringSettingResultsDetailsV2(settingId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    // TODO - Fix V1 to V2
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings/${settingId}/details`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async listAllDataMonitoringSettingsV2(filters


) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/targets/datalake/settings?page_size=${filters?.size}&page_number=${filters?.page}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body.settings;
  }

  async listDashboardsByOwnerV2(user_id) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards?user_id=${user_id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body.dashboards || [];
  }

  async createDashboardV2(name, owner) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        name,
        owner,
        owner_type: 'SQUAD'
      })
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async getDashboardV2(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async getDashboardV2WithReports(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/withReports`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async getDashboardStatusV2(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/status`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body.tables_status || [];
  }

  async getDashboardDeliveriesV2(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/eta/history`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async getDashboardValidationsV2(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/dq/history`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body;
  }

  async addSettingsToDashboardV2(dashboardId, tables) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/settings`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        tables
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body.dashboards_tables || [];
  }

  async deleteSettingFromDashboardV2(dashboardId, settingId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}/settings/${settingId}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 204) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    return;
  }

  async deleteDashboardV2(dashboardId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards/${dashboardId}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 204) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    return;
  }

  async getDataFilters(databaseName, tableName) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/data_filters?database_name=${databaseName}&table_name=${tableName}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body.data_filters;
  }

  async createDataFilter(dataFilter) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/data_filters`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(dataFilter)
    });

    if (response.status !== 201) {
      const body = await response.json();
      throw new Error(`Backend request failed, ${body.exception.message}`);
    }
    const body = await response.json();
    return body;
  }

  async getMacros() {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-observability-api/v2/targets/datalake/modules/dq/macros`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const data = await response.json();
    return data;
  }
  async getNotificationGroups() {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/notification/groups`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    return body.groups;
  }

  async getNotificationGroup(groupId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/notification/groups/${groupId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    return response.json();
  }

  async deleteNotificationGroup(groupId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/notification/groups/${groupId}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    return response.json();
  }

  async createNotificationGroup(notificationGroup) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/notification/groups`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(notificationGroup)
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async editNotificationGroup(notificationGroup) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/notification/groups/${notificationGroup.id}`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(notificationGroup)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async createDashboardReport(dashboardReport) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards_reports`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(dashboardReport)
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }

  async editDashboardReport(dashboardReport) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/data-observability-api/v2/dashboards_reports/${dashboardReport.id}`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(dashboardReport)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body;
  }
}
