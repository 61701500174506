






























export var Metrics; (function (Metrics) {
  const SUCCESS_REQUESTS_RATIO = 'model_metrics.flask_http_request_total'; Metrics["SUCCESS_REQUESTS_RATIO"] = SUCCESS_REQUESTS_RATIO;
  const RESPONSE_TIME = 'model_metrics.flask_http_request_duration_seconds'; Metrics["RESPONSE_TIME"] = RESPONSE_TIME;
})(Metrics || (Metrics = {}));
