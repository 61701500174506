import { createApiRef, } from '@backstage/core-plugin-api';


export const commonApiRef = createApiRef({
  id: 'plugin.common.service',
  description: 'Used to make requests for common endpoits cross application'
});

export const coreApiRef = createApiRef({
  id: 'plugin.ringcore.service',
  description: 'Used to make requests for ring core endpoits cross application'
});

export function isOk(res) {
  return res.status >= 200 && res.status < 300;
}

export class CommonApi {
    identityApi;
    discoveryApi;

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  async getAuthorizationBaseUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('user-authorization');
    return `${baseUrl}`;
  }

  async getUsersBaseUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('users');
    return baseUrl;
  }

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getMe() {
    const token = await this.identityApi.getIdToken();
    const email = this.getUserEmail();

    const headers = {
      ...(email && { 'X-User-Email': email }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...this.commonHeaders
    };

    const res = await fetch(`${await this.getUsersBaseUrl()}/me`, {
      method: 'GET',
      headers
    });

    const resBody = await res.json();

    if (!isOk(res)) {
      throw new Error(`Error: ${resBody?.error?.message || 'Unknown'}`);
    }

    return resBody;
  }

  async getMyUserRoles() {
    const token = await this.identityApi.getIdToken();
    const email = this.getUserEmail();
    const headers = {
      ...(email && { 'X-User-Email': email }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...this.commonHeaders
    };
    const res = await fetch(`${await this.getAuthorizationBaseUrl()}/users/${email?.split('@')[0]}/roles`, {
      headers: headers
    });
    const text = await res.text();
    const body = JSON.parse(text);

    if (!isOk(res)) {
      throw new Error(`failed to get user (${res.status}): ${body.message}`);
    }

    return body;
  }

  getUserEmail() {
    return this.identityApi.getProfile().email;
  }
}

export class RingCoreApi {
    identityApi;
    discoveryApi;

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async fetchCoreAPI(endpoint, method, body, json = true) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/core-api/${endpoint}`;

    const response = await fetch(url, {
      method: method,
      body: body,
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        }),
        'content-type': 'application/json'
      }
    });

    if (!response.ok) {
      const status = `${response.status} ${response.statusText}`;
      const responseBody = await response.text();
      throw new Error(`Backend request failed, ${status} ${responseBody.trim()}`);
    }

    return json ? await response.json() : response.text();
  }

  async getApplication(applicationId) {
    return await this.fetchCoreAPI(`applications/${applicationId}`, 'GET');
  }
}
