
















import { EntityTable } from '@backstage/plugin-catalog-react';
import React from 'react';
import { ApiTypeTitle } from '../ApiDefinitionCard';


export function createSpecApiTypeColumn() {
  return {
    title: 'Type',
    field: 'spec.type',
    render: entity => React.createElement(ApiTypeTitle, { apiEntity: entity,} )
  };
}

// TODO: This could be moved to plugin-catalog-react if we wouldn't have a
// special createSpecApiTypeColumn. But this is required to use ApiTypeTitle to
// resolve the display name of an entity. Is the display name really worth it?

export const apiEntityColumns = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'API' }),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  createSpecApiTypeColumn(),
  EntityTable.columns.createMetadataDescriptionColumn()
];
