

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeSquadFilter } from '@ring/clients';

export class SquadApiClient extends BaseClient {
  constructor(discovery, identityApi) {
    super(getPluginBaseUrl('squads', discovery), serializeSquadFilter, getToken(identityApi));
  }
}
