import React from 'react';
import { Grid } from '@material-ui/core';
import { ApplicationAboutCard } from '@ring/plugin-catalog';

export const OverviewContent = () => (
  React.createElement(Grid, { container: true, spacing: 3, alignItems: "stretch",}
    , React.createElement(Grid, { item: true, md: 8, xs: 12,}
      , React.createElement(ApplicationAboutCard, { variant: "gridItem",} )
    )
  )
);
