import { ColumnExpectationNames, TableExpectationNames } from '../types';

export const titles = {
  [ColumnExpectationNames.COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN]: 'Expect count distinct values to be between',
  [ColumnExpectationNames.COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B]: 'Expect round values column A to be greater than B',
  [ColumnExpectationNames.COL_ROUND_VALUES_NOT_BE_ZERO]: 'Expect round values to not be zero',
  [ColumnExpectationNames.COL_VALUES_NOT_BE_NULL]: 'Expect values to not be null',
  [ColumnExpectationNames.COL_VALUES_TO_BE_BETWEEN]: 'Expect values to be between',
  [ColumnExpectationNames.COL_VALUES_TO_BE_IN_SET]: 'Expect values to be in set',
  [ColumnExpectationNames.COL_VALUES_TO_BE_UNIQUE]: 'Expect values to be unique',
  [ColumnExpectationNames.COL_VALUES_TO_MATCH_REGEX]: 'Expect column values to match regex',
  [TableExpectationNames.COMP_COLS_TO_BE_UNIQUE]: 'Expect compound columns to be unique',
  [TableExpectationNames.TABLE_ROW_COUNT]: 'Expect row count to be between',
  [TableExpectationNames.CUSTOM_VALIDATION]: 'Custom validation',
  [TableExpectationNames.CUSTOM_VALIDATION_GROUPED_RESULTS]: 'Custom validation grouped results'
};

export const getRuleName = (type) => {
  return titles[type] || type;
};
