import React, { useEffect, useRef, useState } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsyncFn, useCopyToClipboard } from 'react-use';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { AlertDialog, useMe, useMixpanel } from '@ring/plugin-common';

import { Link, Table, } from '@backstage/core-components';
import { Box, Button, } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { MTableActions } from 'material-table';

const defaultColumns = [
  {
    field: 'id',
    hidden: true
  },
  {
    title: 'Name',
    field: 'name',
    render: ({ name, id }) => React.createElement(Link, { to: `/observability/dashboard/${id}`,}, name)
  },
  {
    title: 'Owner',
    field: 'owner',
    editable: 'never',
    render: ({ owner }) => React.createElement(Link, { to: `/workforce/squad/${owner}`,}, owner)
  }
];

export const DashboardListPage = () => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const materialTableRef = useRef(null);
  const dataObservabilityApi = useApi(dataObservabilityApiRef);
  const alertApi = useApi(alertApiRef);
  const { track } = useMixpanel();

  const [open, setOpen] = useState(false);
  const [dashboardToDelete, setDashboardToDelete] = useState();

  const [columns, setColumns] = useState(defaultColumns);

  const { me } = useMe();
  const [{ value: dashboards, loading }, refresh] = useAsyncFn(async () => {
    if (me?.squad) {
      return await dataObservabilityApi.listDashboardsByOwnerV2(me.squad);
    }
    return [];
  }, [me?.squad]);

  useEffect(() => {
    if (me?.squad) {
      setColumns(cols =>
        cols.map(column => {
          if (column.field === 'owner') {
            return {
              ...column,
              initialEditValue: me.squad
            };
          }
          return column;
        })
      );
    }
  }, [me?.squad]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const actions = [
    (rowData) => ({
      icon: () => React.createElement(FileCopyOutlinedIcon, { color: "primary",} ),
      tooltip: 'Share dashboard url',
      onClick: () => {
        copyToClipboard(`http://ring.wildlifestudios.com/observability/dashboard/${rowData.id}`);
        track('Dashboard shared', { dashboardId: rowData.id, dashboardName: rowData.name });
        alertApi.post({ message: 'Dashboard url was copy to clipboard!' });
      }
    }),
    (rowData) => ({
      icon: () => React.createElement(DeleteIcon, { color: "secondary",} ),
      tooltip: 'Delete dashboard',
      onClick: () => {
        setDashboardToDelete(rowData);
        setOpen(true);
      }
    })
  ];

  const deleteDashboard = async (rowData) => {
    try {
      await dataObservabilityApi.deleteDashboardV2(rowData.id);
      setOpen(false);
      alertApi.post({
        severity: 'success',
        message: `Dashboard ${rowData.name} was deleted successfully`
      });
      refresh();
    } catch (error) {
      alertApi.post({
        severity: 'error',
        message: `Failed to delete dashboard ${rowData.name}`
      });
      setOpen(false);
    }
  };

  const onAdd = () => {
    const materialTable = materialTableRef.current;
    materialTable.setState({
      ...materialTable.dataManager.getRenderState(),
      showAddRow: true
    });
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Table, {
        tableRef: materialTableRef,
        isLoading: loading,
        columns: columns,
        options: {
          loadingType: 'overlay',
          actionsColumnIndex: -1,
          actionsCellStyle: {
            minWidth: '100px'
          },
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          showSelectAllCheckbox: false,
          debounceInterval: 300
        },
        title: 
          React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
            , React.createElement(Box, { whiteSpace: "nowrap",}, "My Dashboards" )
            , React.createElement(Box, { ml: 3,}
              , React.createElement(Button, { color: "primary", onClick: onAdd, startIcon: React.createElement(AddIcon, null ),}, "New Dashboard"

              )
            )
          )
        ,
        components: {
          Actions: (props) => {
            // filter actions with position: "toolbar"
            const newActions =
              props.actions &&
              props.actions.filter(action => typeof action === 'function' || action.position !== 'toolbar');
            const newProps = { ...props, actions: newActions };
            return React.createElement(MTableActions, { ...newProps,} );
          }
        },
        editable: {
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              try {
                const newDash = await dataObservabilityApi.createDashboardV2(newData.name, newData.owner);
                refresh();
                alertApi.post({
                  severity: 'success',
                  message: `The dashboard ${newData.name} was successfully created`
                });
                track('Dashboard created', { dashboardId: newData.id, dashboardName: newData.name });
                resolve(newDash);
              } catch (error) {
                alertApi.post({
                  severity: 'error',
                  message: `Failed to create dashboard ${newData.name}`
                });
                reject(error);
              }
            })
        },
        actions: actions,
        data: dashboards || [],}
      )
      , React.createElement(AlertDialog, {
        open: open,
        title: "Delete Dashboard" ,
        message: "You are going to delete this dashboard. Are you sure?"         ,
        confirmButtonLeyend: "Keep",
        cancelButtonLeyend: "Delete",
        onClose: () => {
          deleteDashboard(dashboardToDelete);
        },
        onConfirm: () => setOpen(false),}
      )
    )
  );
};
