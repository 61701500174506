import React from 'react';
import { ApplicationLayout } from '@ring/plugin-catalog';
import { EntityDataCatalogContent } from '@internal/plugin-data-catalog';
import { EntityDeploymentContent } from '@internal/plugin-data-product-deployment';
import { OverviewContent } from '../OverviewContent';

export const DataProductPage = () => (
  React.createElement(ApplicationLayout, null
    , React.createElement(ApplicationLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(OverviewContent, null )
    )
    , React.createElement(ApplicationLayout.Route, { path: "/data-catalog", title: "Data Catalog" ,}
      , React.createElement(EntityDataCatalogContent, null )
    )
    , React.createElement(ApplicationLayout.Route, { path: "/data-product-deploy", title: "Data Product Deploy"  ,}
      , React.createElement(EntityDeploymentContent, null )
    )
  )
);
