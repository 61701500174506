import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  title: 'catalog'
});

export const catalogEditApplicationRouteRef = createRouteRef({
  id: 'catalog.edit-application',
  params: ['name']
});
