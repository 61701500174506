import React, { Fragment } from 'react';

import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { DeploymentsTable } from './DeploymentsTable';
import { InfoCard } from '@backstage/core-components';
import { useNavigate } from 'react-router';
import { dataWorkflowApiRef } from '../../api';

import { useWorkflows, useAdvancedDeploy } from '../hooks';
import { get_deploy_data } from '../hooks/useAdvancedDeploy';

export const DeploymentPage = ({ entity }) => {
  const dataWorkflowApi = useApi(dataWorkflowApiRef);
  const errorApi = useApi(errorApiRef);
  const alertApi = useApi(alertApiRef);
  const nav = useNavigate();
  const { workflows, loading, error } = useWorkflows(entity.metadata.name);
  const onTriggerRun = async (_1, _2) => {
    try {
      const response = await dataWorkflowApi.trigger(_2.application_id, _2.workflow_id);
      alertApi.post({
        message: `Succesfully triggered run: ${response.dag_run_id}`,
        severity: 'success'
      });
    } catch (e) {
      errorApi.post(e);
    }
  };

  if (error) {
    errorApi.post(new Error(`Failed to load application deployments, ${error}`));
  }

  const advancedDeploy = useAdvancedDeploy(entity.metadata.name);
  if (!get_deploy_data(advancedDeploy)) {
    return (
      React.createElement('div', null, "Getting app deploy mode"

        , React.createElement(LinearProgress, { 'data-testid': "loading-progress",} )
      )
    );
  }

  if (advancedDeploy.advanced_deploy?.advanced_deploy) {
    return (
      React.createElement(Grid, { container: true, spacing: 3,}
        , React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3,}
          , React.createElement(Grid, { item: true, xs: 12,}
            , React.createElement(InfoCard, {
              title: "Advanced mode enabled for this application.  Please proceed with the deployment via CI of your repository."
                     ,}
            )
          )
        )
      )
    );
  }

  return (
    React.createElement(Grid, { container: true, spacing: 3,}
      , React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3,}
        , React.createElement(Grid, { item: true,}
          , React.createElement('span', null
            , React.createElement(Button, {
              color: "primary",
              variant: "contained",
              onClick: () => {
                return nav('new-deployment');
              },}
            , "New Workflow"

            )
          )
          , React.createElement('div', null
            , React.createElement('br', null ), "Warning: Only one workflow is allowed for each DAG and each data environment (production or development)."

          )
        )
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(InfoCard, { title: "Workflows",}
            , loading && React.createElement(LinearProgress, { 'data-testid': "loading-progress",} )
            , workflows && (
              React.createElement(Fragment, null
                , React.createElement(DeploymentsTable, {
                  appType: entity.spec?.type ,
                  workflows: workflows || [],
                  onTriggerRun: onTriggerRun,
                  emptyContent: React.createElement('div', null, "No workflows yet. Click on \"New Workflow\" to start your journey."          ),}
                )
              )
            )
          )
        )
      )
    )
  );
};
