import { createApiRef } from '@backstage/core-plugin-api';












export const serviceApiRef = createApiRef({
  id: 'plugin.service.service',
  description: 'Used to make requests towards the service backend'
});

export const userApiRef = createApiRef({
  id: 'plugin.user.service',
  description: 'Used to make requests towards the user backend'
});

export const verticalApiRef = createApiRef({
  id: 'plugin.vertical.service',
  description: 'Used to make requests towards the vertical backend'
});

export const squadApiRef = createApiRef({
  id: 'plugin.squad.service',
  description: 'Used to make requests towards the squad backend'
});

export const applicationApiRef = createApiRef({
  id: 'plugin.application.service',
  description: 'Used to make requests towards the application backend'
});

export const systemApiRef = createApiRef({
  id: 'plugin.system.service',
  description: 'Used to make requests towards the system backend'
});

export const mixpanelApiRef = createApiRef({
  id: 'plugin.mixpanel.service',
  description: 'Used to make requests to mixpanel'
});

export const businessUnitApiRef = createApiRef({
  id: 'plugin.business-units.service',
  description: 'Used to make requests to businessUnit'
});

export const identityApiRingRef = createApiRef({
  id: 'plugin.identity.service',
  description: 'Used to make requests to businessUnit'
});
