import { createApiFactory, createPlugin, discoveryApiRef, createRoutableExtension } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { InfrastructureClient, provisioningApiRef, stagingProvisioiningApiRef } from './api';
import { rootRouteRef } from './routes';

export const infrastructurePlugin = createPlugin({
  id: 'infrastructure',
  apis: [
    createApiFactory({
      api: provisioningApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) =>
        new InfrastructureClient({ discoveryApi, identityApi: identityApiRing }, 'production')
    })
  ]
});

export const infrastructurePluginStaging = createPlugin({
  id: 'infrastructure-staging',
  apis: [
    createApiFactory({
      api: stagingProvisioiningApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) =>
        new InfrastructureClient({ discoveryApi, identityApi: identityApiRing }, 'staging')
    })
  ]
});

export const InfraRouter = infrastructurePlugin.provide(
  createRoutableExtension({
    component: () => import('./Root').then(m => m.Root),
    mountPoint: rootRouteRef
  })
);
