import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { useStateSearchParams } from '@ring/plugin-common';
import { TablesTable } from './TablesTable';
import { SettingsDialog } from './SettingsDialog';

export const DataInventory = () => {
  const dataObservabilityApi = useApi(dataObservabilityApiRef);

  const { queryParams, setQueryParams } = useStateSearchParams();
  const [open, setOpen] = useState(false);
  const [databaseName, setDatabaseName] = useState('');
  const [tableName, setTableName] = useState('');

  const tables = useAsync(async () => {
    return await dataObservabilityApi.searchAllTablesV2(queryParams);
  }, [queryParams]);

  const onAdd = () => {
    setOpen(true);
  };

  const onOpenSettings = (dbName, tbName) => {
    setDatabaseName(dbName);
    setTableName(tbName);
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TablesTable, {
        tables: tables,
        setQueryParams: setQueryParams ,
        queryParams: queryParams,
        onAdd: onAdd,
        onOpenSettings: onOpenSettings,}
      )
      , React.createElement(SettingsDialog, { databaseName: databaseName, tableName: tableName, open: open, onClose: () => setOpen(false),} )
    )
  );
};
