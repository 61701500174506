import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { DataQuality } from '../DataQualityPage/DataQuality';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { useAsync, useAsyncFn } from 'react-use';
import { useParams } from 'react-router-dom';
import { ActionsHeader, useMixpanel } from '@ring/plugin-common';
import { Content } from '@backstage/core-components';

export const DashboardOverview = () => {
  const { dashboardId } = useParams();
  const dataObservabilityClient = useApi(dataObservabilityApiRef);

  const { track } = useMixpanel();

  const { value: currentDashboard, loading } = useAsync(async () => {
    if (dashboardId) {
      const dashboard = await dataObservabilityClient.getDashboardV2WithReports(dashboardId);
      track('Dashboard accessed', { dashboardId: dashboard.id, dashboardName: dashboard.name });
      return dashboard;
    }
    return undefined;
  }, [dashboardId]);

  const overviewFn = useAsyncFn(async () => {
    const response = await dataObservabilityClient.getDashboardStatusV2(dashboardId);
    return response;
  });

  const deliveriesFn = useAsyncFn(async () => {
    const response = await dataObservabilityClient.getDashboardDeliveriesV2(dashboardId);
    return response;
  });

  const validationsFn = useAsyncFn(async () => {
    const response = await dataObservabilityClient.getDashboardValidationsV2(dashboardId);
    return response;
  });

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ActionsHeader, { title: "Observability dashboard" , loading: loading,} )
      , React.createElement(Content, null
        , React.createElement(DataQuality, {
          title: currentDashboard && currentDashboard?.name,
          type: "dashboardTables",
          dashboard: currentDashboard,
          overviewFn: overviewFn,
          deliveriesFn: deliveriesFn,
          validationsFn: validationsFn,}
        )
      )
    )
  );
};
