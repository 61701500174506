import React from 'react';
import { makeStyles, Switch, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white
    },
    '&:focus $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    marginTop: 1,
    width: 22,
    height: 22,
    backgroundColor: theme.palette.common.white
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: '1!important',
    transition: theme.transitions.create(['background-color', 'border'])
  }
}));

//  TODO: support Size
export const CustomSwitch = (props) => {
  const classes = useStyles();

  return React.createElement(Switch, { classes: classes, ...props,} );
};
