/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ENTITY_DEFAULT_NAMESPACE, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { Content, Header, HeaderLabel, Page, Progress, RoutedTabs } from '@backstage/core-components';
import { attachComponentData, useElementFilter } from '@backstage/core-plugin-api';
import {
  EntityContext,
  EntityRefLinks,
  getEntityRelations,
  useEntityCompoundName
} from '@backstage/plugin-catalog-react';
import { Box, } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { FavouriteEntity } from '../FavouriteEntity/FavouriteEntity';









const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route = () => null;
attachComponentData(Route, dataKey, true);

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);

const EntityLayoutTitle = ({ entity, title }) => {
  return (
    React.createElement(Box, { display: "inline-flex", alignItems: "center", height: "1em", maxWidth: "100%",}
      , React.createElement(Box, { component: "span", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden",}
        , title
      )
      , entity && React.createElement(FavouriteEntity, { entity: entity,} )
    )
  );
};

const headerProps = (
  paramKind,
  paramNamespace,
  paramName,
  entity
) => {
  const kind = paramKind ?? entity?.kind ?? '';
  const namespace = paramNamespace ?? entity?.metadata.namespace ?? '';
  const name = paramName ?? entity?.metadata.name ?? '';
  return {
    headerTitle: `${name}${namespace && namespace !== ENTITY_DEFAULT_NAMESPACE ? ` in ${namespace}` : ''}`,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec ).type.toLocaleLowerCase('en-US');
      }
      return t;
    })()
  };
};

const EntityLabels = ({ entity }) => {
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  return (
    React.createElement(React.Fragment, null
      , ownedByRelations.length > 0 && (
        React.createElement(HeaderLabel, {
          label: "Owner",
          value: React.createElement(EntityRefLinks, { entityRefs: ownedByRelations, defaultKind: "Group", color: "inherit",} ),}
        )
      )
      , entity.spec?.lifecycle && React.createElement(HeaderLabel, { label: "Lifecycle", value: entity.spec.lifecycle,} )
    )
  );
};

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below

















/**
 * EntityLayout is a compound component, which allows you to define a layout for
 * entities using a sub-navigation mechanism.
 *
 * Consists of two parts: EntityLayout and EntityLayout.Route
 *
 * @example
 * ```jsx
 * <EntityLayout>
 *   <EntityLayout.Route path="/example" title="Example tab">
 *     <div>This is rendered under /example/anything-here route</div>
 *   </EntityLayout.Route>
 * </EntityLayout>
 * ```
 */
export const EntityLayout = ({ children }) => {
  const { kind, namespace, name } = useEntityCompoundName();
  const { entity, loading, error } = useContext(EntityContext);

  const routes = useElementFilter(children, elements =>
    elements
      .selectByComponentData({
        key: dataKey,
        withStrictError: 'Child of EntityLayout must be an EntityLayout.Route'
      })
      .getElements() // all nodes, element data, maintain structure or not?
      .flatMap(({ props }) => {
        if (props.if && entity && !props.if(entity)) {
          return [];
        }

        return [
          {
            path: props.path,
            title: props.title,
            children: props.children,
            tabProps: props.tabProps
          }
        ];
      })
  );

  const { headerTitle, headerType } = headerProps(kind, namespace, name, entity);

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, {
        title: React.createElement(EntityLayoutTitle, { title: headerTitle, entity: entity,} ),
        pageTitleOverride: headerTitle,
        type: headerType,}
      
        , entity && (
          React.createElement(React.Fragment, null
            , React.createElement(EntityLabels, { entity: entity,} )
          )
        )
      )

      , loading && React.createElement(Progress, null )

      , entity && React.createElement(RoutedTabs, { routes: routes,} )

      , error && (
        React.createElement(Content, null
          , React.createElement(Alert, { severity: "error",}, error.toString())
        )
      )
    )
  );
};

EntityLayout.Route = Route;
