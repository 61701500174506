/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { RELATION_OWNED_BY, RELATION_PART_OF } from '@backstage/catalog-model';
import { EntityRefLinks, getEntityRelations } from '@backstage/plugin-catalog-react';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AboutField } from './AboutField';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word'
  }
});





export const AboutContent = ({ entity }) => {
  const classes = useStyles();
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isDomain = entity.kind.toLocaleLowerCase('en-US') === 'domain';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system'
  });
  const partOfComponentRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'component'
  });

  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return (
    React.createElement(Grid, { container: true,}
      , React.createElement(AboutField, { label: "Description", gridSizes: { xs: 12 },}
        , React.createElement(Typography, { variant: "body2", paragraph: true, className: classes.description,}
          , entity?.metadata?.description || 'No description'
        )
      )
      , React.createElement(AboutField, { label: "Owner", gridSizes: { xs: 12, sm: 6, lg: 4 },}
        , React.createElement(EntityRefLinks, { entityRefs: ownedByRelations, defaultKind: "group",} )
      )
      , !isSystem && !isDomain && (
        React.createElement(AboutField, { label: "System", value: "No System" , gridSizes: { xs: 12, sm: 6, lg: 4 },}
          , React.createElement(EntityRefLinks, { entityRefs: partOfSystemRelations, defaultKind: "system",} )
        )
      )
      , isComponent && partOfComponentRelations.length > 0 && (
        React.createElement(AboutField, { label: "Parent Component" , value: "No Parent Component"  , gridSizes: { xs: 12, sm: 6, lg: 4 },}
          , React.createElement(EntityRefLinks, { entityRefs: partOfComponentRelations, defaultKind: "component",} )
        )
      )
      , !isSystem && !isDomain && (
        React.createElement(AboutField, { label: "Type", value: entity?.spec?.type , gridSizes: { xs: 12, sm: 6, lg: 4 },} )
      )
      , isComponent && (
        React.createElement(AboutField, {
          label: "Criticality",
          value: entity?.spec?.criticality ,
          gridSizes: { xs: 12, sm: 6, lg: 4 },}
        )
      )
      , !isSystem && !isDomain && !isResource && (
        React.createElement(AboutField, { label: "Lifecycle", value: entity?.spec?.lifecycle , gridSizes: { xs: 12, sm: 6, lg: 4 },} )
      )
      , React.createElement(AboutField, { label: "Tags", value: "No Tags" , gridSizes: { xs: 12, sm: 6, lg: 4 },}
        , (entity?.metadata?.tags || []).map(t => (
          React.createElement(Chip, { key: t, size: "small", label: t,} )
        ))
      )
    )
  );
};
