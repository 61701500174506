import React, { useEffect, useState } from 'react';
import { OverviewCards } from './OverviewCards';
import { TableStatus } from './TableStatus';
import { Content, InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';


import { EtaStatus } from '../../types';
import { AddTableDialog } from './AddTableDialog';
import { AlertDialog } from '@ring/plugin-common';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';








const initialOverview = {
  completed: 0,
  etaSuccessRage: 0,
  issues: 0,
  running: 0,
  etaBreached: 0
};

export const TablesOverview = ({ data, type = 'applicationTables', dashboard, reload }) => {
  const { value, error, loading } = data;
  const [overview, setOverview] = useState(initialOverview);
  const [openNewTableDialog, setOpenNewTableDialog] = useState(false);
  const alertApi = useApi(alertApiRef);
  const dataObservabilityClient = useApi(dataObservabilityApiRef);

  const [tableToDelete, setTableToDelete] = useState();
  const deleteTable = async (table) => {
    if (dashboard?.id) {
      try {
        await dataObservabilityClient.deleteSettingFromDashboardV2(dashboard.id, table.setting_id);
        alertApi.post({
          severity: 'success',
          message: `Setting ${table.table_name} was removed from dashboard`
        });
        setTableToDelete(undefined);
        reload();
      } catch (e) {
        alertApi.post({
          severity: 'error',
          message: `Could not remove table ${table.table_name} from dashboard`
        });
        setTableToDelete(undefined);
      }
    }
    return;
  };

  useEffect(() => {
    if (value?.length) {
      const newOverview = value.reduce((acc, { dq_failed_validations, eta_updated_at, eta_status, eta }) => {
        const newAcc = { ...acc };
        newAcc.completed += eta_status === EtaStatus.success || eta_status === EtaStatus.failure ? 1 : 0;
        newAcc.etaSuccessRage += eta_updated_at && DateTime.fromISO(eta_updated_at) <= DateTime.fromISO(eta) ? 1 : 0;
        newAcc.issues += dq_failed_validations || 0;
        newAcc.running += eta_status === EtaStatus.pending ? 1 : 0;
        newAcc.etaBreached += eta_updated_at && DateTime.fromISO(eta_updated_at) > DateTime.fromISO(eta) ? 1 : 0;
        return newAcc;
      }, initialOverview);
      setOverview({ ...newOverview, etaSuccessRage: (newOverview.etaSuccessRage / value.length) * 100 });
    }
  }, [value]);

  if (error) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, error.toString())
      )
    );
  }

  if (loading) {
    return (
      React.createElement(InfoCard, { title: "Loading tables data"  ,}
        , React.createElement(Progress, null )
      )
    );
  }
  return (
    React.createElement(React.Fragment, null
      , React.createElement(OverviewCards, { ...overview,} )
      , React.createElement(TableStatus, {
        data: value ?? [],
        type: type,
        onDeleteTable: setTableToDelete,
        onAddTable: () => setOpenNewTableDialog(true),}
      )
      , React.createElement(AlertDialog, {
        open: !!tableToDelete,
        title: "Remove setting" ,
        message: "You are going to remove this data monitoring setting from the current board. Are you sure?"               ,
        confirmButtonLeyend: "Keep",
        cancelButtonLeyend: "Remove",
        onConfirm: () => setTableToDelete(undefined),
        onClose: () => deleteTable(tableToDelete),}
      )
      , openNewTableDialog && (
        React.createElement(AddTableDialog, { open: openNewTableDialog, setOpen: setOpenNewTableDialog, reload: reload,} )
      )
    )
  );
};
