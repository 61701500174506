import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MachineLearningDeploymentPage } from '../MachineLearningDeploymentPage';
import { MachineLearningDeploymentFormPage } from '../MachineLearningDeploymentFormPage';
import { EditMachineLearningDeploymentPage } from '../EditMachineLearningDeploymentPage';
import { MachineLearningAlertsPage } from '../MachineLearningAlertsPage';
import { EntityContext } from '@backstage/plugin-catalog-react';
import { Content, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';

export const EntityMachineLearningDeploymentContent = () => {
  const { entity, loading, error } = useContext(EntityContext);
  if (!entity) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error missed entity\""  )
      )
    );
  }

  if (loading) {
    return React.createElement(Progress, null );
  }

  if (error) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, error.toString())
      )
    );
  }

  return (
    React.createElement(Routes, null
      , React.createElement(Route, { path: "/", element: React.createElement(MachineLearningDeploymentPage, { entity: entity,} ),} )
      , React.createElement(Route, { path: "/create", element: React.createElement(MachineLearningDeploymentFormPage, { entity: entity,} ),} )
      , React.createElement(Route, {
        path: "/edit/:deploymentEnvironment/:runID",
        element: React.createElement(EditMachineLearningDeploymentPage, { entity: entity,} ),}
      )
      , React.createElement(Route, { path: "/alerts/:deploymentEnvironment/:runID", element: React.createElement(MachineLearningAlertsPage, { entity: entity,} ),} )
    )
  );
};
