

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeApplicationFilter } from '@ring/clients';

export class ApplicationApiClient extends BaseClient {
  constructor(discovery, identityApi) {
    super(getPluginBaseUrl('applications', discovery), serializeApplicationFilter, getToken(identityApi));
  }
}
