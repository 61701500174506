import { createApiFactory, createPlugin, discoveryApiRef, createRoutableExtension } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { ScaffolderApi, scaffolderApiRef } from './api';

import { rootRouteRef } from './routes';

export const scaffoldervtwoPlugin = createPlugin({
  id: 'scaffoldervtwo',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: scaffolderApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) => new ScaffolderApi({ identityApi: identityApiRing, discoveryApi })
    })
  ]
});

export const ScaffoldervtwoPage = scaffoldervtwoPlugin.provide(
  createRoutableExtension({
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef
  })
);
