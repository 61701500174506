// src/Squads-context.js

import { useApi } from '@backstage/core-plugin-api';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { applicationApiRef } from '@ring/plugin-common';
import { useParams } from 'react-router-dom';

import { useServiceApiDocs } from '../hooks/useServiceApiDocs';
















const ApplicationContext = createContext(undefined);

const ApplicationProvider = ({ children, mockedApp }) => {
  const applicationApi = useApi(applicationApiRef);
  const { name } = useParams();

  const [{ value: application = mockedApp, loading, error }, getApplication] = useAsyncFn(
    (appName) => {
      return applicationApi.findByName(appName);
    },
    [applicationApi]
  );

  const serviceApiDocs = useServiceApiDocs(application);

  const transformToEntity = useCallback((app) => {
    if (!app) return undefined;
    return {
      apiVersion: 'backstage.io/v1alpha1',
      kind: app.type,
      metadata: {
        name: app.name
      },
      spec: {}
    };
  }, []);

  useEffect(() => {
    if (name) getApplication(name);
  }, [name, getApplication]);

  return (
    React.createElement(ApplicationContext.Provider, {
      value: { application, loading, error, getApplication, transformToEntity, serviceApiDocs },}
    
      , children
    )
  );
};

const useApplication = () => {
  const context = useContext(ApplicationContext);

  if (context === undefined) {
    throw new Error('useApplication must be used within a <ApplicationProvider/>');
  }

  return context;
};

export { ApplicationProvider, useApplication };
