import React from 'react';
import { capitalize, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { Select } from '@backstage/core-components';
import { useApplicationListProvider } from '../../hooks/useApplicationListProvider';





const getItemsOptions = (items) => {
  return items.map((type) => ({
    value: type,
    label: capitalize(type)
  }));
};

export const ApplicationTypePicker = ({ types }) => {
  const { updateFilters, queryParams } = useApplicationListProvider();

  const defaultTypes = [
    { value: 'data_product', label: 'Data Product' },
    { value: 'backend', label: 'Backend' },
    { value: 'metagame_connector', label: 'Metagame Connector' },
    { value: 'game_client', label: 'Game Client' },
    { value: 'frontend', label: 'Frontend' }
  ];

  const itemsOptions = types ? getItemsOptions(types) : defaultTypes;
  const items = [{ value: 'all', label: 'All' }, ...itemsOptions];

  const onChange = (value) => {
    updateFilters(prev => {
      if (value === 'all') {
        return omit(prev, 'types');
      }
      return { ...prev, types: [value] };
    });
  };

  return (
    React.createElement(Box, { pb: 1, pt: 1,}
      , React.createElement(Select, {
        label: "Type",
        items: items,
        selected: queryParams.types ? queryParams.types[0] : 'all',
        onChange: onChange,}
      )
    )
  );
};
