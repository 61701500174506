import React, { Fragment } from 'react';
import {
  Tooltip,
  Switch,
  Grid,
  TextField,
  Button as MButton,
  FormControlLabel,
  Link,
  Paper,
  Typography,
  Box,
  Checkbox,

} from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '60%',
    margin: '1rem 0',
    display: 'grid',
    gridTemplateColumns: '3rem 1fr',
    alignItems: 'center',
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.warning.main}`
  },
  icon: {
    fill: theme.palette.success.main,
    fontSize: '2rem'
  },
  actionsContainer: {
    marginTop: '0.3rem'
  },
  actionLink: {
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: '1rem'
  },
  exampleItem: {
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: '1rem 0'
  },
  exampleItemField: {
    margin: '0 1rem'
  }
}));

const columnTypeOptions = [
  'BOOLEAN',
  'BYTE',
  'TINYINT',
  'INT',
  'INTEGER',
  'LONG',
  'BIGINT',
  'FLOAT',
  'REAL',
  'DOUBLE',
  'DATE',
  'TIMESTAMP',
  'STRING',
  'BINARY',
  'DECIMAL',
  'DEC',
  'NUMERIC',
  'INTERVAL',
  'ARRAY',
  'STRUCT',
  'MAP'
];

const ColumnTypeRegex =
  /(BOOLEAN|BYTE|TINYINT|INT|INTEGER|LONG|BIGINT|FLOAT|REAL|DOUBLE|DATE|TIMESTAMP|STRING|BINARY|DECIMAL|DEC|NUMERIC|INTERVAL|(ARRAY<.*>$)|STRUCT<.*>$|MAP<.*>$)/;

const validationSchema = Yup.object().shape({
  name: Yup.string().min(4, 'Table name should contain at least 4 characters').required('Table name is required'),
  description: Yup.string().required('Table description is required'),
  schema: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Column name is required'),
      type: Yup.string().required('Column type is required').matches(ColumnTypeRegex, 'Invalid column type'),
      description: Yup.string().required('Column description is required')
    })
  ),
  partition_columns: Yup.string().optional()
});






export const CreateOutputTableFormik = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  let initialData = initialValues;

  if (!initialData) {
    initialData = {
      name: '',
      description: '',
      partition_columns: '',
      editable: true,
      intermediate_table: false,
      schema: [
        {
          name: '',
          type: '',
          description: '',
          tags: [],
          is_pii_data: false,
          editable: true
        }
      ]
    };
  }
  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(Formik, { initialValues: initialData, validationSchema: validationSchema, onSubmit: onSubmit, enableReinitialize: true,}
        , ({ values, touched, errors, handleChange, setFieldValue }) => (
          React.createElement(Form, { noValidate: true, autoComplete: "off",}
            , React.createElement(Grid, { item: true, container: true, xs: 12,}
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "name",
                  label: "Table Name (Unique)"  ,
                  name: "name",
                  fullWidth: true,
                  required: true,
                  value: values.name,
                  onChange: handleChange,
                  error: touched.name && Boolean(errors.name),
                  helperText: touched.name && errors.name,
                  disabled: !values.editable,}
                )
              )
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "description",
                  label: "Table Description" ,
                  name: "description",
                  fullWidth: true,
                  required: true,
                  value: values.description,
                  onChange: handleChange,
                  error: touched.description && Boolean(errors.description),
                  helperText: touched.description && errors.description,}
                )
              )
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(Paper, { 'elevation-': 0, className: classes.container,}
                  , React.createElement(SecurityIcon, { className: classes.icon,} )
                  , React.createElement(Box, null
                    , React.createElement(Typography, { variant: "body1",}, "We need to know if you are using "
                              , React.createElement('strong', null, "PII data" ), ", please, check the box if you are using it!"

                    )
                    , React.createElement(Typography, { style: { opacity: 0.7 }, variant: "body1",}
                      , React.createElement('strong', null, "PII data" ), " is information that can identify the players, either by itself or when combined with others"

                      , React.createElement('br', null )
                      , React.createElement('strong', null, "Examples:"), " IP address"
                    )
                    , React.createElement(Box, { className: classes.actionsContainer,}
                      , React.createElement(Link, {
                        href: "https://wiki.wildlifestudios.com/pages/viewpage.action?pageId=95365202",
                        target: "_blank",}
                      
                        , React.createElement('strong', null, "Visit the Wiki page for more info!"      )
                      )
                    )
                  )
                )
              )
              , React.createElement(FieldArray, { name: "schema",}
                , ({ push, remove }) => {
                  return (
                    React.createElement(Fragment, null
                      , values.schema.map((col, index) => {
                        const columnName = `schema[${index}].name`;
                        const touchedColumnName = getIn(touched, columnName);
                        const errorColumnName = getIn(errors, columnName);

                        const columnType = `schema[${index}].type`;
                        const touchedColumnType = getIn(touched, columnType);
                        const errorColumnType = getIn(errors, columnType);

                        const columnDescription = `schema[${index}].description`;
                        const touchedColumnDescription = getIn(touched, columnDescription);
                        const errorColumnDescription = getIn(errors, columnDescription);

                        const columnIsPIIData = `schema[${index}].is_pii_data`;

                        const onAutoCompleteChageType = (_, value) => {
                          setFieldValue(columnType, value || '');
                        };

                        return (
                          React.createElement(Fragment, { key: index,}
                            , React.createElement(Grid, { xs: 12, sm: 2, item: true,}
                              , React.createElement(TextField, {
                                id: `column_name_${index}`,
                                label: "Column Name" ,
                                name: columnName,
                                fullWidth: true,
                                required: true,
                                value: col.name,
                                onChange: handleChange,
                                helperText: touchedColumnName && errorColumnName ? errorColumnName : '',
                                error: Boolean(touchedColumnName && errorColumnName),
                                disabled: !col.editable,}
                              )
                            )
                            , React.createElement(Grid, { xs: 12, sm: 2, item: true,}
                              , React.createElement(Autocomplete, {
                                id: `column_type_${index}`,
                                freeSolo: true,
                                options: columnTypeOptions,
                                onChange: onAutoCompleteChageType,
                                defaultValue: col.type,
                                disabled: !col.editable,
                                renderInput: params => (
                                  React.createElement(TextField, {
                                    ...params,
                                    label: "Column Type" ,
                                    fullWidth: true,
                                    name: columnType,
                                    value: col.type,
                                    disabled: !col.editable,
                                    onChange: handleChange,
                                    helperText: touchedColumnType && errorColumnType ? errorColumnType : '',
                                    error: Boolean(touchedColumnType && errorColumnType),}
                                  )
                                ),}
                              )
                            )
                            , React.createElement(Grid, { xs: 12, sm: 4, item: true,}
                              , React.createElement(TextField, {
                                id: `column_description_${index}`,
                                label: "Column Description" ,
                                name: columnDescription,
                                fullWidth: true,
                                required: true,
                                value: col.description,
                                onChange: handleChange,
                                helperText: 
                                  touchedColumnDescription && errorColumnDescription ? errorColumnDescription : ''
                                ,
                                error: Boolean(touchedColumnDescription && errorColumnDescription),}
                              )
                            )
                            , React.createElement(Grid, { xs: 12, sm: 2, item: true,}
                              , React.createElement(FormControlLabel, {
                                label: "PII data" ,
                                control: 
                                  React.createElement(Checkbox, {
                                    id: `column_is_pii_data_${index}`,
                                    name: columnIsPIIData,
                                    checked: col.is_pii_data,
                                    onChange: handleChange,
                                    value: col.is_pii_data,}
                                  )
                                ,}
                              )
                            )
                            , React.createElement(Grid, {
                              xs: 12,
                              sm: 2,
                              item: true,
                              container: true,
                              direction: "row",
                              alignItems: "center",
                              justify: "center",
                              alignContent: "center",}
                            
                              , React.createElement(MButton, {
                                variant: "contained",
                                color: "secondary",
                                onClick: () => remove(index),
                                startIcon: React.createElement(DeleteIcon, null ),
                                disabled: !col.editable,}
                              , "Delete Column"

                              )
                            )
                          )
                        );
                      })
                      , React.createElement(Grid, { item: true, xs: 12,}
                        , React.createElement(MButton, {
                          variant: "contained",
                          color: "primary",
                          onClick: () =>
                            push({ name: '', type: '', description: '', classification: '', editable: true })
                          ,}
                        , "Add Column"

                        )
                      )
                    )
                  );
                }
              )
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "partition_columns",
                  label: "Partition Columns" ,
                  name: "partition_columns",
                  fullWidth: true,
                  value: values.partition_columns,
                  onChange: handleChange,
                  disabled: !values.editable,}
                )
              )
              , React.createElement(Grid, { container: true,}
                , React.createElement(Grid, { item: true, xs: 3,}
                  , React.createElement(Tooltip, { title: "A table that will be only available for internal use by this application. Cannot be attached by other applications"                  ,}
                    , React.createElement(FormControlLabel, {
                      label: "Intermediate Table" ,
                      control: 
                        React.createElement(Switch, {
                          id: "intermediate_table",
                          name: "intermediate_table",
                          checked: values.intermediate_table,
                          onChange: handleChange,
                          value: values.intermediate_table,}
                        )
                      ,}
                    )
                  )
                )
                , React.createElement(Grid, { item: true, hidden: values.editable,}
                  , React.createElement(Tooltip, { title: "Deprecating a table won't let other applications attach to it anymore"          ,}
                    , React.createElement(FormControlLabel, {
                      label: "Deprecated",
                      control: 
                        React.createElement(Switch, {
                          id: "deprecated",
                          name: "deprecated",
                          color: "secondary",
                          checked: values.deprecated,
                          onChange: handleChange,
                          value: values.deprecated,}
                        )
                      ,}
                    )
                  )
                )
              )
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(MButton, {
                  onClick: () => {
                    navigate(-1);
                  },}
                , "Back"

                )
                , React.createElement(MButton, { variant: "contained", color: "primary", type: "submit",}, "Save Table"

                )
              )
            )
          )
        )
      )
    )
  );
};
