


export class SystemFilter  {
    _names;
    _description;
    _owner;
    _ownerIds;
    _type;

  constructor(builder) {
    this._names = builder.names;
    this._description = builder.description;
    this._owner = builder.owners;
    this._ownerIds = builder.ownerIds;
    this._type = builder.type;
  }

  isEmpty() {
    return (
      !this._names && !this._description && !this._owner && (!this._ownerIds || !this._ownerIds.length) && !this._type
    );
  }

  get names() {
    return this._names;
  }

  get description() {
    return this._description;
  }

  get owner() {
    return this._owner;
  }

  get ownerIds() {
    return this._ownerIds;
  }

  get type() {
    return this._type;
  }
}
