import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import TimeIcon from '@material-ui/icons/QueryBuilder';
import FlagIcon from '@material-ui/icons/EmojiFlags';

import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  overviewCards: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(4, 1fr)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '2rem 0',
    '& > *:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

const formatTwoDigits = (value) => {
  return value.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
};

const OverviewCard = ({ Icon, title, value }) => {
  return (
    React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", style: { gap: '0.5rem' },}
      , Icon
      , React.createElement(Typography, null, title, ":")
      , React.createElement(Typography, { style: { fontWeight: 'bolder' }, variant: "h5",}
        , value ?? '-'
      )
    )
  );
};

export const OverviewCards = ({ completed, etaSuccessRage, issues, etaBreached }) => {
  const classes = useStyles();
  return (
    React.createElement('div', { className: classes.overviewCards,}
      , React.createElement(OverviewCard, {
        Icon: React.createElement(CheckIcon, { fontSize: "large", color: "primary",} ),
        title: "ETA Completed" ,
        value: formatTwoDigits(completed),}
      )
      , React.createElement(OverviewCard, {
        Icon: React.createElement(FlagIcon, { fontSize: "large", style: { color: green[500] },} ),
        title: "ETA success rate"  ,
        value: `${Math.round(etaSuccessRage)}%`,}
      )
      , React.createElement(OverviewCard, {
        Icon: React.createElement(TimeIcon, { fontSize: "large", color: "error",} ),
        title: "ETA Breached" ,
        value: formatTwoDigits(etaBreached),}
      )
      , React.createElement(OverviewCard, {
        Icon: React.createElement(ErrorIcon, { fontSize: "large", color: "error",} ),
        title: "Data Quality Issues"  ,
        value: formatTwoDigits(issues),}
      )
    )
  );
};
