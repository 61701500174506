

import { useEffect } from 'react';


import { businessUnitApiRef } from '../apis';
import { useFindAllPages } from './useFindAllPages';

export const useCoreBusinessUnits = () => {
  const [businessUnitsState, refresh] = useFindAllPages(
    businessUnitApiRef
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  return businessUnitsState;
};
