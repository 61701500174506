import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { InfoCard } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid, CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { MachineLearningAlertsFormik } from './MachineLearningAlertsFormik';
import { machineLearningDeploymentApiRef } from '../../api';


const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

export const MachineLearningAlertsPage = ({ entity }) => {
  const { deploymentEnvironment, runID } = useParams();
  const classes = useStyles();
  const errorApi = useApi(errorApiRef);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const alertsCacheKey = `v1/alerts/${entity.metadata.name}/${deploymentEnvironment}/${runID}`;

  const machineLearningDeploymentApi = useApi(machineLearningDeploymentApiRef);
  const { data, error } = useSWR(alertsCacheKey, async () => {
    return await machineLearningDeploymentApi.getDeploymentAlerts(
      entity.metadata.name,
      runID ,
      deploymentEnvironment 
    );
  });

  if (error) {
    errorApi.post(new Error(`Failed to load application deployments, ${error}`));
  }

  const onSubmit = async (alertFormValues) => {
    try {
      setOpen(true);

      if (alertFormValues.alerts.length === 0) {
        throw new Error('You must add at least one alert.');
      }

      alertFormValues.alerts.map((eachAlert) => {
        if (
          String(eachAlert.metric) === '' ||
          String(eachAlert.threshold) === '' ||
          String(eachAlert.time) === '' ||
          String(eachAlert.level) === ''
        ) {
          throw new Error('You have missing fields.');
        }
      });

      await machineLearningDeploymentApi.manageDeploymentAlerts(
        entity.metadata.name,
        deploymentEnvironment ,
        alertFormValues
      );

      setOpen(false);
      navigate(-1);
    } catch (e) {
      setOpen(false);
      errorApi.post(e);
    }
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "Manage Alerts" ,}
        , data && React.createElement(MachineLearningAlertsFormik, { onSubmit: onSubmit, initialValues: data,} )
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: open,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
