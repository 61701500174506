import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { dataObservabilityApiRef } from '../../../dataObservabilityApi';


export const useDataMonitoringSetting = (settingId) => {
  const dataObservabilityClient = useApi(dataObservabilityApiRef);
  const { value } = useAsync(async () => {
    if (settingId === 'new') {
      return undefined;
    }
    return await dataObservabilityClient.getDataMonitoringSettingByIdV2(settingId);
  }, [dataObservabilityClient, settingId]);
  return value;
};
