import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,

  Tooltip,
  Typography
} from '@material-ui/core';

import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { PARTITION_COLUMNS_FORM_KEY } from '../types';
import ExamplesDialog from './ExamplesDialog';
import { getColumnFormatSuggestion } from '../helpers';
import { DisplayTableColumns } from './DisplayTableColumns';

const useStyles = makeStyles(() => ({
  infoLink: {
    userSelect: 'none',
    cursor: 'pointer'
  }
}));







export const DateTimeSettings = ({ partitionCols, standardCols }) => {
  const classes = useStyles();

  const [isExamplesDialogOpen, setIsExamplesDialogOpen] = useState(false);

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: PARTITION_COLUMNS_FORM_KEY,
    control
  });

  const alreadyUsed = (useWatch({ control, name: PARTITION_COLUMNS_FORM_KEY }) ) ?? [];
  const alreadyUsedCols = alreadyUsed.map(col => col.column_name);

  const filteredPartitionCols = partitionCols.filter(col => !alreadyUsedCols.includes(col));
  const filteredStandardCols = standardCols.filter(col => !alreadyUsedCols.includes(col));

  const handleChange = (e) => {
    append({ column_data_format: getColumnFormatSuggestion(`${e.target.value}`), column_name: e.target.value });
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Box, { py: "2rem", display: "flex", flexDirection: "column", style: { gap: '8px' },}
        , React.createElement(Typography, { style: { fontWeight: 'bolder' },}, "Select the Datetime columns to filter your data."       )
        , React.createElement(Tooltip, { title: "Those date columns are gonna be used to build a WHERE clause that filters this table based on further ETA and DQ data interval and schedule expression."                          ,}
          , React.createElement(Box, { display: "flex", flexDirection: "column",}
            , React.createElement(FormControl, { fullWidth: true,}
              , React.createElement(InputLabel, { htmlFor: "grouped-columns",}, "Date time columns"  )
              , React.createElement(Select, { defaultValue: "", id: "grouped-columns", onChange: handleChange,}
                , filteredPartitionCols.length > 0 && React.createElement(ListSubheader, null, "Partition columns" )
                , filteredPartitionCols.map(col => (
                  React.createElement(MenuItem, { value: col, key: col,}
                    , col
                  )
                ))

                , filteredStandardCols.length > 0 && React.createElement(ListSubheader, null, "Other columns" )
                , filteredStandardCols.map(col => (
                  React.createElement(MenuItem, { value: col, key: col,}
                    , col
                  )
                ))
              )
              /*       <FormHelperText>
                Please select the columns you want to use as a date time reference and inform the date's format If you
                need you can check our{' '}
                <Link className={classes.infoLink} onClick={() => setIsExamplesDialogOpen(true)}>
                  check the examples
                </Link>
              </FormHelperText> */
            )
            , React.createElement(Box, null
              , React.createElement(Typography, null, "Those columns are used to automatically generate the Data Interval to check. It's applied when selecting"
                               , ' '
                , React.createElement('span', { style: { fontWeight: 'bolder' },}, "Querying the Data"  ), " and to all"   , ' '
                , React.createElement('span', { style: { fontWeight: 'bolder' },}, "no Custom Data Quality Validations"    ), "."
              )
              , React.createElement(Typography, null, "Tip: If possible, use "
                    , React.createElement('span', { style: { fontWeight: 'bolder' },}, "partitions columns" ), ". See some"  , ' '
                , React.createElement(Link, { className: classes.infoLink, onClick: () => setIsExamplesDialogOpen(true),}, "date format examples"

                )
              )
            )
          )
        )
        , React.createElement(DisplayTableColumns, { fields: fields , onDelete: remove,} )
      )

      , React.createElement(ExamplesDialog, { open: isExamplesDialogOpen, onClose: () => setIsExamplesDialogOpen(false),} )
    )
  );
};
