













export const contextualLinks = {
  catalog: {
    pattern: /^\/catalog\/?$/gi,
    links: [
      {
        title: 'What is RING? Features & Overview',
        url: '/docs/default/component/ring/explorering/#exploring-ring'
      },
      { title: 'RING docs', url: '/docs/default/component/ring' }
    ]
  },
  createComponent: {
    pattern: /^\/(create|scaffolder)\/?$/gi,
    links: [
      {
        title: 'Creating First RING Application from Template',
        url: '/docs/default/component/ring/Batch_Data_Application_Create/#batch-data-applications'
      },
      {
        title: 'Getting Started! Setup Your Local Environment',
        url: '/docs/default/component/ring/Batch_Data_Application_Edit/#edit-batch-data-application'
      },
      {
        title: 'Test your Application',
        url: '/docs/default/component/ring/Batch_Data_Application_Edit/#git-repository'
      }
    ]
  },
  componentDetail: {
    pattern: /^\/catalog\/[\w|\-|_]+\/component\/[\w|\-|_]+\/?$/gi,
    links: [
      {
        title: 'YAML File - DAG Definitions',
        url: '/docs/default/component/ring/YAML/#dag-general-properties'
      },
      {
        title: 'SDKs & Examples',
        url: '/docs/default/component/ring/SDKs/#sdks'
      },
      {
        title: 'Creating Your Tables in RING',
        url: '/docs/default/component/ring/Bath_Data_Application_DataCatalog/#how-to-attach-input-tables'
      },
      {
        title: 'Registering & Deploying your Application',
        url: '/docs/default/component/ring/Batch_Data_Application_Deployment/#deploy-data-application'
      }
    ]
  },
  dataMonitoringEdit: {
    pattern: /^\/catalog\/[\w|\-|_]+\/component\/[\w|\-|_]+\/data-catalog\/\output\/[\w|\-|_|.]+\/data-monitoring/gi,
    links: [
      {
        title: 'Data Observability Manual',
        url: 'https://docs.google.com/document/d/1eysUxX4D8xEbO5YQ4qqyArhHwBmPj5A2G4gHnJBmnPM'
      }
    ]
  },
  dataMonitoringObservability: {
    pattern: /^\/catalog\/[\w|\-|_]+\/component\/[\w|\-|_]+\/observability/gi,
    links: [
      {
        title: 'Data Observability Manual',
        url: 'https://docs.google.com/document/d/1eysUxX4D8xEbO5YQ4qqyArhHwBmPj5A2G4gHnJBmnPM'
      }
    ]
  },
  dataMonitoringTableDetails: {
    pattern: /^\/observability\/[\w|\-|_|.]+\/overview/gi,
    links: [
      {
        title: 'Data Observability Manual',
        url: 'https://docs.google.com/document/d/1eysUxX4D8xEbO5YQ4qqyArhHwBmPj5A2G4gHnJBmnPM'
      }
    ]
  },
  dataPlatform: {
    pattern: /^\/catalog\/[\w+|\-|_]+\/data-platform\/?$/gi,
    links: [
      {
        title: 'Data Platform',
        url: 'Overview/diagram'
      }
    ]
  }
};

export const genericSupportLinks = [
  {
    title: 'What is RING? Features & Overview',
    url: '/docs/default/component/ring/explorering/#exploring-ring'
  },
  {
    title: 'Creating First RING Application from Template',
    url: '/docs/default/component/ring/Batch_Data_Application_Create/#batch-data-applications'
  }
];
