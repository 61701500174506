import React, { useEffect, useState } from 'react';
import { apiDocsConfigRef } from '@ring/plugin-api-docs';
import { useLocation, useParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';

import { Alert } from '@material-ui/lab';
import { InfoCard, Progress } from '@backstage/core-components';
import { catalogApiRef } from '../../../api';
import { useAsyncFn } from 'react-use';





export const ServiceApiPage = () => {
  const location = useLocation();
  const { serviceId, name } = useParams();

  const state = location.state ;
  const dataUrl = state ? state.data : undefined;
  const [loading, setLoading] = useState(false);
  const catalogApi = useApi(catalogApiRef);
  const [{ value: service, error }, getService] = useAsyncFn(
    async serviceid => {
      const serviceData = await catalogApi.getService(serviceid);
      setLoading(false);
      return serviceData;
    },
    [catalogApi]
  );
  const data = dataUrl || service?.meta?.service_information?.apis.find((api) => api['api-name'] === name);

  const config = useApi(apiDocsConfigRef);
  const { getApiDefinitionWidgetV1 } = config;

  useEffect(() => {
    if (!dataUrl && serviceId) {
      getService(serviceId);
      setLoading(true);
    }
  }, [dataUrl, getService, serviceId]);

  if (loading) {
    return (
      React.createElement(InfoCard, { variant: "flex",}
        , React.createElement(Progress, null )
      )
    );
  }

  const definitionWidget = data && getApiDefinitionWidgetV1(data);

  if (!definitionWidget || error) {
    return React.createElement(Alert, { severity: "error",}, "Could not fetch the API"    );
  }

  // if no data needs to make the call to the backend
  return definitionWidget.component(data.value);
};
