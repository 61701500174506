import React from 'react';
import { Typography, Box } from '@material-ui/core';
import emptyImage from '../../assets/empty-table.png';






export const EmptyBanner = ({ loading, message = 'Select a Database to add the tables' }) => {
  return (
    React.createElement(Box, { display: "flex", flexDirection: "column", px: "2rem", py: "4rem", justifyContent: "center", alignItems: "center",}
      , loading ? (
        React.createElement(React.Fragment, null)
      ) : (
        React.createElement(React.Fragment, null
          , React.createElement(Typography, { variant: "h5", style: { lineHeight: 1.2, marginBottom: '2rem' },}
            , message
          )
          , React.createElement('img', { style: { display: 'block', width: '45%' }, src: emptyImage, alt: "feedback",} )
        )
      )
    )
  );
};
