import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { IssuesBanner } from './IssuesBanner';
import { OverviewHeader } from './OverviewHeader';
import { EtaBarChart } from './EtaBarChart';
import { TableValidations } from './TableValidations';
import { IssuesBarChart } from './IssuesBarChart';
import { useObservabilityContext } from './ObersvabilityContext';

export const TableDataOverview = () => {
  const { data, onRefresh, onEdit } = useObservabilityContext();

  return (
    React.createElement(Box, { p: "2rem",}
      , React.createElement(Grid, { container: true, spacing: 4,}
        , React.createElement(Grid, { item: true, xs: 12,}
          , React.createElement(OverviewHeader, {
            owner: data?.owner,
            database_name: data?.database_name,
            table_name: data?.table_name,
            setting_name: data?.setting_name,
            onRefresh: onRefresh,}
          )
        )
        , React.createElement(Grid, { item: true, xs: 8,}
          , React.createElement(Grid, { container: true, spacing: 2,}
            , React.createElement(Grid, { item: true, xs: 12,}
              , React.createElement(Grid, { container: true, spacing: 2,}
                , React.createElement(Grid, { item: true, xs: 6,}
                  , React.createElement(Card, null
                    , React.createElement(CardContent, null
                      , React.createElement(Typography, { variant: "h6",}, "Table Deliveries" )
                      , React.createElement(Typography, null, "How long it took to deliver vs ETA"       )
                      , React.createElement(EtaBarChart, { data: data?.eta?.runtimes, etaThreshold: data?.eta?.sla_in_minutes,} )
                    )
                  )
                )
                , React.createElement(Grid, { item: true, xs: 6,}
                  , React.createElement(Card, null
                    , React.createElement(CardContent, null
                      , React.createElement(Typography, { variant: "h6",}, "Data Validation" )
                      , React.createElement(Typography, null, "Nº of succesful checks vs Nº of issues"       )
                      , React.createElement(IssuesBarChart, { data: data?.data_quality?.latest_runs_statistics,} )
                    )
                  )
                )
              )
            )
            , React.createElement(Grid, { item: true, xs: 12,}
              , React.createElement(TableValidations, { data: data?.data_quality?.configured_validations, onEdit: onEdit,} )
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 4,}
          , React.createElement(IssuesBanner, { data: data?.data_quality?.latest_issues,} )
        )
      )
    )
  );
};
