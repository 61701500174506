import { createApiRef, } from '@backstage/core-plugin-api';


export const machineLearningExperimentsApiRef = createApiRef({
  id: 'plugin.machine-learning-experiments.service',
  description: 'Used to make requests towards the experiments backend'
});





export class MachineLearningExperimentsApiClient  {
    discoveryApi;
    identityApi;

  constructor(options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getDeployableExperimentsMlflow(applicationID, urlSourceCode) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/machine-learning-experiments/api/v1/experiments/${applicationID}`;

    const response = await fetch(url, {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    const experiments = [];
    for (const experiment of body.experiments) {
      experiment.url_git = `${urlSourceCode}/${experiment.url_git}`;
      experiments.push({
        ...experiment
      });
    }

    return experiments;
  }
}
