import React, { memo, useState } from 'react';
import { Box, Card, CardContent, IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartJsTooltip,
  Legend,
  TimeScale,



} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Content, InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { DateTime } from 'luxon';
import { pickColor } from '../../utils/pickColor';

ChartJS.register(CategoryScale, TimeScale, PointElement, LineElement, Title, ChartJsTooltip, Legend, zoomPlugin);











const TablesValidationsChart = memo(({ data: validationsData }) => {
  const theme = useTheme();
  const [isZoomEnabled, setZoomEnabled] = useState(false);
  const { value: data = [], error, loading } = validationsData;
  const chartData = {
    datasets: data.map(({ database_name, table_name, setting_name, data: tableData }, i) => ({
      label: `${database_name}.${table_name} - ${setting_name}`,
      data: tableData.map(td => ({
        database_name: database_name,
        table_name: table_name,
        setting_name: setting_name,
        ...td
      })),
      borderColor: pickColor(i),
      backgroundColor: pickColor(i)
    }))
  };

  const onZoom = () => {
    setZoomEnabled(zoom => !zoom);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: 'datetime',
      yAxisKey: 'failure'
    },
    layout: {
      padding: { top: 40 }
    },
    scales: {
      y: {
        grid: {
          color: '#dddddd',
          borderDash: [5, 5]
        },
        ticks: {
          stepSize: 1,
          color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black
        }
      },
      x: {
        type: 'time',
        display: true,
        grid: {
          color: '#dddddd'
        },
        ticks: {
          color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
          autoSkip: false,
          maxRotation: 0,
          major: {
            enabled: true
          },
          font: context => {
            if (context.tick && context.tick.major) {
              return {
                weight: 'bold'
              } ;
            }
            return undefined;
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          pointStyle: 'circle',
          color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
          usePointStyle: true
        }
      },
      tooltip: {
        displayColors: true,
        callbacks: {
          label: context => {
            const raw = context.raw ;
            return [
              ` Table: ${raw.database_name}.${raw.table_name}`,
              ` Setting: ${raw.setting_name}`,
              ` Date: ${DateTime.fromISO(raw.datetime, { zone: 'utc' }).toFormat(' dd/LLL/yy')}`,
              ` Total validations: ${raw.failure + raw.success}`,
              ` Issues: ${raw.failure}`
            ];
          },
          afterLabel: () => ' ',
          beforeLabel: () => ' ',
          title: () => ''
        }
      },
      zoom: {
        pan: {
          enabled: true
        },
        zoom: {
          wheel: {
            enabled: isZoomEnabled
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      }
    }
  };

  if (error) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, error.toString())
      )
    );
  }

  if (loading) {
    return (
      React.createElement(InfoCard, { title: "Loading validations chart data"   ,}
        , React.createElement(Progress, null )
      )
    );
  }

  return (
    React.createElement(Card, null
      , React.createElement(CardContent, null
        , React.createElement(Box, { display: "flex", justifyContent: "space-between",}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "h6",}, "Data Quality history"  )
            , React.createElement(Typography, null, "N° of data quality issues that happened each run"        )
          )
          , React.createElement(IconButton, { color: isZoomEnabled ? 'secondary' : 'primary', onClick: onZoom,}
            , React.createElement(Tooltip, { title: isZoomEnabled ? 'Click to disable zoom' : 'Click to enable zoom', placement: "bottom-start",}
              , React.createElement(ZoomInIcon, null )
            )
          )
        )
        , React.createElement(Box, { height: "400px", overflow: "hidden",}
          , React.createElement(Line, { data: chartData, options: options,} )
        )
      )
    )
  );
});

export { TablesValidationsChart };
