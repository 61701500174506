// src/Squads-context.js

import { useApi } from '@backstage/core-plugin-api';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { Pageable, } from '@ring/model';
import { squadApiRef } from '../apis';
















const SquadsContext = createContext({
  squadsByName: {},
  squads: [],
  loading: false
});

const SquadsProvider = ({ children }) => {
  const squadApi = useApi(squadApiRef);
  const [squadsByName, setSquadsByName] = useState({});

  const { value, loading, error } = useAsync(async () => {
    try {
      const handshakeResponse = await squadApi.findAll({ pageable: new Pageable(0, 100) });

      const response = await Promise.all(
        Array.from({ length: handshakeResponse.totalPages - 1 }, (_, i) =>
          squadApi.findAll({ pageable: new Pageable(i + 1, 100) })
        )
      );

      const initialData = handshakeResponse.data ?? [];

      const data = response?.reduce((arr, row) => {
        return arr.concat(row.data);
      }, initialData);

      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }, [squadApi]);

  useEffect(() => {
    if (value?.length) {
      const newSquadsByName = value.reduce(
        (accum, current) => ({
          ...accum,
          [String(current.name)]: current
        }),
        {}
      );
      setSquadsByName(newSquadsByName);
    }
  }, [value]);

  return (
    React.createElement(SquadsContext.Provider, { value: { squads: value ?? [], squadsByName, loading, error },}
      , children
    )
  );
};

const useSquads = () => {
  const context = useContext(SquadsContext);

  if (context === undefined) {
    throw new Error('useSquads must be used within a SquadsProvider');
  }

  return context;
};

export { SquadsProvider, useSquads };
