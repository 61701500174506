import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DeploymentPage } from '../DeploymentPage';
import { Content, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { NewDeployment } from '../NewDeployment';
import { useApplication } from '@ring/plugin-catalog';

export const EntityDeploymentContent = () => {
  const { application, loading, error, transformToEntity } = useApplication();
  const entity = transformToEntity(application);

  if (!entity) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error missed entity\""  )
      )
    );
  }
  return (
    React.createElement(Routes, null
      , loading && React.createElement(Progress, null )
      , error && (
        React.createElement(Content, null
          , React.createElement(Alert, { severity: "error",}, error.toString())
        )
      )
      , React.createElement(Route, { path: "/", element: React.createElement(DeploymentPage, { entity: entity,} ),} )
      , React.createElement(Route, { path: "/new-deployment", element: React.createElement(NewDeployment, { entity: entity,} ),} )
    )
  );
};
