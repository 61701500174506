import React, { useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,




  TimeScale,

} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import { DateTime } from 'luxon';
import { useTheme } from '@material-ui/core';
import 'chartjs-adapter-luxon';

ChartJS.register(annotationPlugin, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);






const NUMBER_CHART_COLUMNS = 7;
const TRESHOLDCOLOR_LIGHT = '#1DAC15';
const TRESHOLDCOLOR_DARK = '#E72318';

const getTresholdColor = (theme) => {
  return theme.palette.type === 'dark' ? TRESHOLDCOLOR_DARK : TRESHOLDCOLOR_LIGHT;
};

const timeUtcFromMidnight = (minutes) => {
  if (typeof minutes !== 'number') return '';
  return DateTime.utc().startOf('day').plus({ minutes }).toFormat('hh:mm a');
};

export const EtaBarChart = ({ data = [], etaThreshold }) => {
  const theme = useTheme();
  const dataOrdered = data.reverse();

  const labels = dataOrdered.map(({ datetime }) => datetime);

  const dataChart = dataOrdered.map(({ runtime_in_minutes }) => runtime_in_minutes);
  const labelsData = labels.slice(0, NUMBER_CHART_COLUMNS);

  const chartData = {
    labels: labelsData,
    datasets: [
      {
        label: 'Delivery Time',
        data: dataChart.slice(0, NUMBER_CHART_COLUMNS),
        backgroundColor: '#008CFF'
      },
      { label: 'ETA', data: [], hidden: true, backgroundColor: getTresholdColor(theme) }
    ]
  };
  const options = useCallback(
    () => ({
      responsive: true,
      scales: {
        y: {
          grid: {
            color: '#dddddd',
            borderDash: [5, 5]
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#fff' : '#000',
            callback: value => timeUtcFromMidnight(+value)
          }
        },
        x: {
          type: 'time',
          display: true,
          grid: {
            color: '#dddddd'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#fff' : '#000',
            autoSkip: false,
            maxRotation: 0,
            major: {
              enabled: true
            },
            font: context => {
              if (context.tick && context.tick.major) {
                return {
                  weight: 'bold'
                } ;
              }
              return undefined;
            }
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#000',
            generateLabels: ({ data: { datasets } }) =>
              datasets.map(({ label, backgroundColor }, index) => ({
                datasetIndex: index,
                text: label ?? '',
                fillStyle: backgroundColor ,
                strokeStyle: backgroundColor 
              })),
            usePointStyle: true
          }
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: context => {
              return [
                `Date: ${DateTime.fromISO(labelsData[context.dataIndex])
                  .toUTC()
                  .startOf('day')
                  .toFormat('dd/LLL/yy - hh:mm a z')}`,
                `ETA: ${timeUtcFromMidnight(etaThreshold)}`,
                `Delivery  ${timeUtcFromMidnight(+context.parsed.y)}`
              ];
            },
            title: () => ''
          }
        },
        annotation: {
          drawTime: 'afterDraw',
          annotations: {
            meanLine: {
              type: 'line',
              scaleID: 'y',
              value: etaThreshold,
              borderColor: getTresholdColor(theme),
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                enabled: (ctx) => ctx.hovered,
                backgroundColor: getTresholdColor(theme),
                drawTime: 'afterDraw',
                content: ['ETA is', `${timeUtcFromMidnight(etaThreshold)}`],
                position: (ctx) => ctx.hoverPosition
              },
              enter(ctx, event) {
                ctx.hovered = true;
                ctx.hoverPosition = `${(event.x / ctx.chart.chartArea.width) * 100} %`;
                ctx.chart.update();
              },
              leave(ctx) {
                ctx.hovered = false;
                ctx.chart.update();
              }
            }
          }
        }
      }
    }),
    [etaThreshold, labelsData, theme]
  );
  return React.createElement(Bar, { options: options(), data: chartData,} );
};
