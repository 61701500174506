import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { CatalogApi, catalogApiRef } from './api';

import { rootRouteRef } from './routes';

export const catalogPlugin = createPlugin({
  id: 'catalog',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: catalogApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new CatalogApi({ identityApi: identityApiRing, discoveryApi })
    })
  ]
});

export const CatalogRouter = catalogPlugin.provide(
  createRoutableExtension({
    component: () => import('./Root').then(m => m.Root),
    mountPoint: rootRouteRef
  })
);

export const ApplicationDetailPage = catalogPlugin.provide(
  createRoutableExtension({
    component: () => import('./Application/components/ApplicationDetailPage').then(m => m.ApplicationDetailPage),
    mountPoint: rootRouteRef
  })
);
