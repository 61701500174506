import React, { useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';
import { Content, Progress } from '@backstage/core-components';
import { useInterval } from 'react-use';
import { serviceApiRef } from '@ring/plugin-common';

import { Alert } from '@material-ui/lab';
import { ServiceStatus } from '@ring/model';







export const ResourceCreation = ({ response, token }) => {
  const [gotResponse, setGotResponse] = useState(false);
  const [err, setErr] = useState({} );
  const serviceAPI = useApi(serviceApiRef);

  useInterval(() => {
    if (!gotResponse) {
      serviceAPI
        .findByName(response?.name, { overrideToken: token })
        .then(svc => {
          if (svc?.status === ServiceStatus.ACTIVE) {
            setGotResponse(true);
          }
          if (svc?.status === ServiceStatus.ERROR) {
            setGotResponse(true);
            setErr(new Error('Error creating service!'));
          }
        })
        .catch(e => {
          setGotResponse(true);
          setErr(e);
        });
    }
  }, 2000);

  if (!gotResponse) return React.createElement(Progress, null );
  if (err.message) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, err.message)
      )
    );
  }

  return (
    React.createElement(Content, null
      , React.createElement(Alert, { severity: "success",}, " Resource created!"  )
    )
  );
};
