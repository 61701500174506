import { Table, } from '@backstage/core-components';
import React from 'react';





const formatData = (props) => {
  const data = [];
  Object.entries(props.inputs).map(v => {
    data.push({
      col1: v[0],
      col2: JSON.stringify(v[1])
    });
  });
  return data;
};

export const ReviewStep = (props) => {
  const columns = [
    {
      field: 'col1'
    },
    {
      field: 'col2'
    }
  ];
  return (
    React.createElement(Table, {
      title: "Review and create"  ,
      options: { search: false, paging: false },
      columns: columns,
      data: formatData(props),}
    )
  );
};
