import React, { useCallback, useState } from 'react';
import {
  IconButton,
  Popover,
  Divider,
  makeStyles,
  List,
  ListItem,
  Button,
  DialogActions,
  ListItemText,
  ListItemIcon,
  Tooltip
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Link, } from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import { useLocation } from 'react-use';
import { contextualLinks, genericSupportLinks } from './contextualLinks';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: '3rem',
    right: '3rem',
    zIndex: theme.zIndex.appBar,
    transition: 'all 0.3s'
  },
  containerHidden: {
    position: 'fixed',
    bottom: '50%',
    right: '1rem',
    zIndex: theme.zIndex.appBar,
    transition: 'all 0.3s'
  },
  sideBtn: {
    transform: 'rotate(-90deg)'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  popoverList: {
    minWidth: 260,
    maxWidth: 400
  },
  helpBtn: {
    width: '3rem',
    height: '3rem'
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.2rem'
  },
  itemIcon: {
    padding: '0.6rem'
  }
}));

const SupportIcon = ({ icon }) => {
  const app = useApp();
  const Icon = icon ? app.getSystemIcon(icon) ?? Help : Help;
  return React.createElement(Icon, null );
};

const SupportLink = ({ link }) => {
  const { origin } = useLocation();
  return (
    React.createElement(Link, { target: "_blank", rel: "noopener noreferrer" , to: link.url.includes('http') ? link.url : origin + link.url,}
      , link.title
    )
  );
};

const SupportListItem = ({ item }) => {
  const classes = useStyles();

  return (
    React.createElement(ListItem, { className: classes.listItem,}
      , React.createElement(ListItemIcon, { className: classes.itemIcon,}
        , React.createElement(SupportIcon, { icon: item.icon,} )
      )
      , React.createElement(ListItemText, {
        primary: item.title,
        secondary: item.links?.reduce(
          (prev, link, idx) => [
            ...prev,
            React.createElement('span', { key: idx + link.title, className: classes.linkContainer,}
              , React.createElement(ChevronRight, null )
              , React.createElement(SupportLink, { link: link, key: link.url,} )
            )
          ],
          []
        ),}
      )
    )
  );
};

export const SupportButton = () => {
  const { pathname } = useLocation();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRelatedLinks = useCallback(
    () =>
      Object.entries(contextualLinks).reduce((accum, [key, value]) => {
        const links = contextualLinks[key].links;
        if (pathname?.match(value.pattern)?.length) {
          accum.push(...links);
        }
        return accum;
      }, []),
    [pathname]
  );

  





  const relatedLinks = getRelatedLinks();

  const relatedItems = {
    icon: 'gitlab',
    links: relatedLinks.length ? relatedLinks : genericSupportLinks,
    title: `Having issues? \n${relatedLinks.length > 1 ? 'these docs' : 'this doc'} might help:`
  };

  const getInTouchItem = {
    title: 'Get in touch with RING team',
    icon: 'chat',
    links: [
      {
        url: 'https://wildlifestudios.slack.com/archives/CM64R5Z6K',
        title: '#help-cloud-platform'
      }
    ]
  };

  const [isSideActive, setIsSideActive] = useState(false);

  return (
    React.createElement('div', {
      draggable: true,
      onDragStart: () => setIsSideActive(prev => !prev),
      className: isSideActive ? classes.containerHidden : classes.container,}
    
      , React.createElement(Tooltip, { arrow: true, title: `If I bother you, drag me ${isSideActive ? 'down' : 'up'}!`,}
        , React.createElement(IconButton, { size: "medium", 'aria-label': "support", 'aria-haspopup': "true", onClick: handleClick,}
          , React.createElement(Help, { color: "primary", className: classes.helpBtn,} )
        )
      )
      , React.createElement(Popover, {
        open: open,
        anchorEl: anchorEl,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        onClose: handleClose,}
      
        , React.createElement(List, { className: classes.popoverList,}
          , React.createElement(SupportListItem, { item: relatedItems,} )
          , React.createElement(Divider, { variant: "middle",} )
          , React.createElement(SupportListItem, { item: getInTouchItem,} )
        )
        , React.createElement(DialogActions, null
          , React.createElement(Button, { color: "primary", onClick: handleClose,}, "Close"

          )
        )
      )
    )
  );
};
