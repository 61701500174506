import React from 'react';
import { Typography, Container, TextField, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Table } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useAsync } from 'react-use';
import { EmptyBanner } from '../Common/EmptyBanner';












export const TableSelection = ({
  selectedDatabase,
  selectedTables,
  onSelectDatabase,
  onSelectTables
}) => {
  const dataCatalogApi = useApi(dataCatalogApiRef);

  const { value: databases, loading: loadingDatabases } = useAsync(async () => {
    return await dataCatalogApi.listImportDatabases();
  });

  const { value: tables = [], loading: loadingTables } = useAsync(async () => {
    if (selectedDatabase) {
      const response = await dataCatalogApi.listImportTables(selectedDatabase);
      return response.map(r => {
        if (selectedTables.includes(r)) {
          return { name: r, tableData: { checked: true } };
        }
        return { name: r };
      });
    }
    return undefined;
  }, [selectedDatabase]);

  const handleDatabaseChange = (_, value) => {
    onSelectDatabase(value || '');
    onSelectTables([]);
  };
  const onSelectionChange = (data) => {
    onSelectTables(data.map(d => d.name));
  };

  return (
    React.createElement(Container, { maxWidth: "md",}
      , React.createElement(Typography, { variant: "h5",}, "Table Selection" )
      , React.createElement(Box, { pt: "1rem", pb: "2rem",}
        , React.createElement(Autocomplete, {
          options: databases || [],
          loading: loadingDatabases,
          value: selectedDatabase,
          onChange: handleDatabaseChange,
          renderInput: params => React.createElement(TextField, { ...params, label: "Database",} ),}
        )
      )
      , React.createElement(Table, {
        columns: [
          { field: 'name', title: 'SELECT ALL', filtering: false, sorting: false, cellStyle: { lineHeight: '0.5rem' } }
        ],
        options: {
          loadingType: 'overlay',
          paging: false,
          selection: true,
          headerStyle: { padding: 0, position: 'sticky', transform: 'translate3d(0,-1px,0)' },
          maxBodyHeight: '500px'
        },
        isLoading: loadingDatabases || loadingTables,
        localization: {
          body: {
            emptyDataSourceMessage: React.createElement(EmptyBanner, { loading: loadingDatabases || loadingTables,} )
          }
        },
        title: 
          React.createElement(Box, { whiteSpace: "nowrap", width: "300px", textAlign: "left",}, "Available tables"

          )
        ,
        onSelectionChange: onSelectionChange,
        data: tables,}
      )
    )
  );
};
