
import { lightTheme } from '@backstage/theme';

const customTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    background: {
      default: '#ffffff',
      paper: '#fff'
    }
  }
};
export const themes = [
  {
    id: 'ring-theme',
    title: 'Ring Theme',
    variant: 'light',
    theme: customTheme
  } /* ,
  { id: 'ring-theme-dark', title: 'Ring Theme Dark', variant: 'dark', theme: darkTheme } */
];
