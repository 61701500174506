import { createApiRef, } from '@backstage/core-plugin-api';










export const dataCatalogApiRef = createApiRef({
  id: 'plugin.data-catalog.service',
  description: 'Used to make requests towards the data catalog backend'
});
































export class DataCatalogClient  {
    discoveryApi;
    identityApi;

  constructor(options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async attachTables(
    applicationID,
    dataEnvironment,
    tables,
    relationshipType
  ) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const endpoint = `${baseUrl}/data-product-api/v2/applications/${applicationID}/data_environments/${dataEnvironment}/relationships`;
    const tableIds = [];

    for (const table of tables) {
      tableIds.push(table.id);
    }

    const attachTablesResponse = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        relationship_type: relationshipType,
        resource_ids: tableIds,
        resource_type: 'hive_table'
      })
    });

    if (attachTablesResponse.status !== 201) {
      const status = `${attachTablesResponse.status} ${attachTablesResponse.statusText}`;
      const body = await attachTablesResponse.json();
      throw new Error(`Backend request failed, ${status} | ${body.exception.message}`);
    }
  }

  async detachTable(
    applicationID,
    dataEnvironment,
    tableID,
    relationship_type
  ) {
    const token = await this.identityApi.getIdToken();
    const endpoint = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-product-api/v2/applications/${applicationID}/data_environments/${dataEnvironment}/relationships`;

    const responseAssociateTable = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        relationship_type: relationship_type,
        resource_ids: [tableID],
        resource_type: 'hive_table'
      })
    });

    if (responseAssociateTable.status !== 204) {
      const status = `${responseAssociateTable.status} ${responseAssociateTable.statusText}`;
      const body = await responseAssociateTable.json();
      throw new Error(`Backend request failed, ${status} | ${body.exception.message}`);
    }
  }

  async deleteTable(applicationID, dataEnvironment, tableId) {
    const token = await this.identityApi.getIdToken();
    const endpoint = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-product-api/v2/applications/${applicationID}/data_environments/${dataEnvironment}/tables/${tableId}`;

    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.json();
      throw new Error(`Backend request failed, ${status} | ${body.exception.message}`);
    }
  }

  async getApplicationTables(applicationID, dataEnvironment) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');

    const endpoint = `${baseUrl}/data-product-api/v2/applications/${applicationID}/data_environments/${dataEnvironment}/relationships`;

    const response = await fetch(endpoint, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    const result = {
      inputTables: [],
      outputTables: []
    };

    for (const table of body.inputs) {
      result.inputTables.push(table );
    }
    for (const table of body.outputs) {
      result.outputTables.push(table );
    }

    return result;
  }

  async listTables() {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/tables`;
    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const body = await response.json();

    const result = [];

    for (const table of body) {
      const hiveTable = {
        id: table.id,
        name: table.name,
        database: table.database,
        atlas_url: table.atlas_url,
        owner: table.owner,
        description: table.description,
        schema: table.schema,
        tags: table.tags,
        produced_by: table.produced_by
      };
      result.push(hiveTable);
    }

    return result;
  }

  async listTableRelationships(table_id) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/tables/${table_id}/relationships`;
    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const result = (await response.json()) ;
    return result;
  }

  async getTableByName(name) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/tables/${name}`;
    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    return await response.json();
  }

  async createOutputTable(
    applicationID,
    dataEnvironment,
    values
  ) {
    const token = await this.identityApi.getIdToken();
    const createTableURL = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-product-api/v2/applications/${applicationID}/data_environments/${dataEnvironment}/tables`;
    const responseCreateTable = await fetch(createTableURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(values)
    });

    if (responseCreateTable.status !== 201) {
      const status = `${responseCreateTable.status} ${responseCreateTable.statusText}`;
      const body = await responseCreateTable.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async updateOutputTable(
    id,
    applicationId,
    dataEnvironment,
    values
  ) {
    const token = await this.identityApi.getIdToken();
    const createTableURL = `${await this.discoveryApi.getBaseUrl(
      'proxy'
    )}/data-product-api/v2/applications/${applicationId}/data_environments/${dataEnvironment}/tables/${id}`;

    const responseCreateTable = await fetch(createTableURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(values)
    });

    if (responseCreateTable.status !== 200) {
      const status = `${responseCreateTable.status} ${responseCreateTable.statusText}`;
      const body = await responseCreateTable.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
  }

  async listImportDatabases(databaseName = '') {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/metastore/search`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        page_size: 100000,
        page_number: 1,
        query: {
          resource: 'databases',
          match: {
            database_name: databaseName
          }
        }
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const data = await response.json();
    return data.match;
  }

  async listImportTables(databaseName) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/metastore/search`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        page_size: 100000,
        page_number: 1,
        query: {
          resource: 'tables',
          match: {
            database_name: databaseName
          }
        }
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const data = await response.json();
    return data.match;
  }

  async getBulkImportTables(database, tables) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/metastore/tables/bulkGet`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({
        database,
        tables
      })
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const data = await response.json();
    return data.tables;
  }

  async bulkImportTables(tablesData

) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/data-product-api/v2/tables/bulkImport`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(tablesData)
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const data = await response.json();
    return data;
  }
}
