import React from 'react';
import { ApplicationAboutContent } from './ApplicationAboutContent';
import { Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import { HeaderIconLinkRow, } from '@backstage/core-components';
import { useApplication } from '../../context/applicationContext';
import { ScmIntegrationIcon } from './ScmIntegrationIcon';

import DocsIcon from '@material-ui/icons/Description';
import ExtensionIcon from '@material-ui/icons/Extension';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px'
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  gridItemCardContent: {
    flex: 1
  },
  fullHeightCardContent: {
    flex: 1
  }
});





export function ApplicationAboutCard({ variant }) {
  const classes = useStyles();
  const { application, serviceApiDocs } = useApplication();

  const hasApis = serviceApiDocs?.value?.meta?.service_information?.apis.length > 0;

  const viewInSource = {
    label: 'View Source',
    disabled: !application?.repositoryUrl,
    icon: React.createElement(ScmIntegrationIcon, null ),
    onClick: () => window.open(application?.repositoryUrl, '_blank')
  };

  const viewInTechDocs = {
    label: 'View TechDocs',
    // TODO: wich property may i use to check this on Application entity?
    disabled: false,
    icon: React.createElement(DocsIcon, null ),
    href: `/docs/default/component/${application?.name}`.toLowerCase()
  };
  const viewApi = {
    title: hasApis ? '' : 'No APIs available',
    label: 'View API',
    disabled: !hasApis,
    icon: React.createElement(ExtensionIcon, null ),
    href: 'api'
  };

  const viewTips = {
    label: 'Security Tips',
    disabled: false,
    href: 'https://docs.wildlifestudios.com/Secure-coding-best-practices/',
    icon: React.createElement(HttpsOutlinedIcon, null )
  };

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  return (
    React.createElement(Card, { className: cardClass,}
      , React.createElement(CardHeader, {
        title: "About",
        subheader: React.createElement(HeaderIconLinkRow, { links: [viewInSource, viewInTechDocs, viewApi, viewTips],} ),}
      )
      , React.createElement(Divider, null )
      , React.createElement(CardContent, { className: cardContentClass,}
        , React.createElement(ApplicationAboutContent, { application: application,} )
      )
    )
  );
}
