/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { ENTITY_DEFAULT_NAMESPACE, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  EntityContext,
  EntityRefLinks,
  getEntityRelations,
  useEntityCompoundName
} from '@backstage/plugin-catalog-react';
import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { FavouriteEntity } from '../FavouriteEntity/FavouriteEntity';
import { UnregisterEntityDialog } from '../UnregisterEntityDialog/UnregisterEntityDialog';
import { Tabbed } from './Tabbed';

import {
  Content,
  Header,
  HeaderLabel,
  Link,
  Page,
  Progress,
  ResponseErrorPanel,
  WarningPanel
} from '@backstage/core-components';



const EntityPageTitle = ({ entity, title }) => (
  React.createElement(Box, { display: "inline-flex", alignItems: "center", height: "1em",}
    , title
    , entity && React.createElement(FavouriteEntity, { entity: entity,} )
  )
);

const EntityLabels = ({ entity }) => {
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  return (
    React.createElement(React.Fragment, null
      , ownedByRelations.length > 0 && (
        React.createElement(HeaderLabel, {
          label: "Owner",
          value: React.createElement(EntityRefLinks, { entityRefs: ownedByRelations, defaultKind: "Group", color: "inherit",} ),}
        )
      )
      , entity.spec?.lifecycle && React.createElement(HeaderLabel, { label: "Lifecycle", value: entity.spec.lifecycle,} )
    )
  );
};

const headerProps = (
  kind,
  namespace,
  name,
  entity
) => {
  return {
    headerTitle: `${name}${namespace && namespace !== ENTITY_DEFAULT_NAMESPACE ? ` in ${namespace}` : ''}`,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec ).type.toLocaleLowerCase('en-US');
      }
      return t;
    })()
  };
};

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below

















export const EntityPageLayout = ({ children }) => {
  const { kind, namespace, name } = useEntityCompoundName();
  const { entity, loading, error } = useContext(EntityContext);
  const { headerTitle, headerType } = headerProps(kind, namespace, name, entity);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const navigate = useNavigate();
  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    navigate('/');
  };

  return (
    React.createElement(Page, { themeId: entity?.spec?.type?.toString() ?? 'home',}
      , React.createElement(Header, {
        title: React.createElement(EntityPageTitle, { title: headerTitle, entity: entity,} ),
        pageTitleOverride: headerTitle,
        type: headerType,}
      
        /* TODO: Make entity labels configurable for entity kind / type */
        , entity && (
          React.createElement(React.Fragment, null
            , React.createElement(EntityLabels, { entity: entity,} )
          )
        )
      )

      , loading && (
        React.createElement(Content, null
          , React.createElement(Progress, null )
        )
      )

      , entity && React.createElement(Tabbed.Layout, null, children)

      , error && (
        React.createElement(Content, null
          , React.createElement(ResponseErrorPanel, { error: error,} )
        )
      )

      , !loading && !error && !entity && (
        React.createElement(Content, null
          , React.createElement(WarningPanel, { title: "Entity not found"  ,}, "There is no "
               , kind, " with the requested"   , ' '
            , React.createElement(Link, { to: "https://backstage.io/docs/features/software-catalog/references",}, "kind, namespace, and name"   ), "."
          )
        )
      )

      , React.createElement(UnregisterEntityDialog, {
        open: confirmationDialogOpen,
        entity: entity,
        onConfirm: cleanUpAfterRemoval,
        onClose: () => setConfirmationDialogOpen(false),}
      )
    )
  );
};

EntityPageLayout.Content = Tabbed.Content;
