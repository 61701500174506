import { useLocalStorage } from 'react-use';

const KEY_FEATURE_VALUE = 'newFeatureViewed';

export const useFeature = (feature) => {
  const [value, setValue] = useLocalStorage(KEY_FEATURE_VALUE, []);
  const hasSeen = !!value && value.includes(feature);

  const setFeatureView = () => {
    if (!hasSeen) {
      setValue(values => (values ? [...values, feature] : [feature]));
    }
  };

  return { hasSeen, setFeatureView };
};
