import React, { useState } from 'react';
import { Grid, Backdrop, CircularProgress, LinearProgress } from '@material-ui/core';
import { CreateOutputTableFormik } from '../CreateOutputTablePage/CreateOutputTableFormik';

import { dataCatalogApiRef } from '../../dataCatalogApi';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import useSWR, { mutate } from 'swr';

import { useSessionStorage } from 'react-use';
import { getIsPIIDatafromTags, getOutputTableFormWithTags } from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);




export const EditOutputTablePage = ({ application }) => {
  const { tableName } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const updateTableMutateKey = `update_table_${tableName}`;
  const [dataEnvironment] = useSessionStorage('dataEnvironment', 'prod');

  const { data, error } = useSWR([updateTableMutateKey, tableName], async (_, name) => {
    return initializeForm(await dataCatalogApi.getTableByName(name));
  });

  const isLoading = !data && !error;

  function initializeForm(hiveTable) {
    const table = hiveTable ;
    table.editable = false;

    table.owner = hiveTable.owner;
    table.business_unit = hiveTable.business_unit;
    table.schema.forEach(column => {
      column.editable = false;
      column.type = column.type.toUpperCase();
      column.is_pii_data = getIsPIIDatafromTags(column.tags || []);
    });

    table.deprecated = hiveTable.tags?.includes('DEPRECATED') || false;
    table.intermediate_table = hiveTable.tags?.includes('INTERMEDIATE_TABLE') || false;

    if (hiveTable.partition_columns) {
      table.partition_columns = hiveTable.partition_columns.join();
    } else {
      table.partition_columns = '';
    }

    return table;
  }

  const onSubmit = async (values) => {
    try {
      if (!values.id) {
        return;
      }
      setOpen(true);

      const tableValues = getOutputTableFormWithTags(values);

      await dataCatalogApi
        .updateOutputTable(tableValues.id || '', application.name, dataEnvironment, {
          schema: tableValues.schema,
          description: tableValues.description,
          owner: tableValues.owner || (application.owner ) || '',
          business_unit: tableValues.business_unit || application?.businessUnit || '',
          tags: tableValues.tags
        })
        .then(() => {
          mutate([updateTableMutateKey, tableName]);
          navigate(-1);
        });
    } catch (e) {
      setOpen(false);
      errorApi.post(e);
    }
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "Edit Output Table"  ,}
        , isLoading && React.createElement(LinearProgress, { 'data-testid': "loading-progress",} )
        , data && React.createElement(CreateOutputTableFormik, { initialValues: data, onSubmit: onSubmit,} )
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: open,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
