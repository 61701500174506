import React from 'react';
import { Grid } from '@material-ui/core';
import { EntityProvidingComponentsCard } from '@ring/plugin-api-docs';







export const ApplicationApiPage = ({ apis, serviceId }) => {
  return (
    React.createElement(Grid, { container: true, spacing: 3,}
      , React.createElement(Grid, { container: true, item: true, md: 12,}
        , React.createElement(Grid, { item: true, md: 6,}
          , React.createElement(EntityProvidingComponentsCard, { apis: apis, serviceId: serviceId,} )
        )
      )
    )
  );
};
