export function formatString(template, ...args) {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}

export function randomString(length) {
  const result = [];
  const characters = '!"$\'*+,-./0123456789;?@ABCDEFGHIJKLMNOPQRSTUVWXYZ^_`abcdefghijklmnopqrstuvwxyz{|}~';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export function isEmptyOrBlankString(s) {
  return !s || !s.length || !s.trim().length;
}

export function stringHashCode(s) {
  let hash = 0;
  if (s.length === 0) return hash;

  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);

    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}
