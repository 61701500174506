

export function serializeFilter(filter) {
  if (!filter || filter.isEmpty()) return '';

  const stringArr = [];

  if (filter?.names) stringArr.push(`names=${filter.names.join(',')}`);
  if (filter?.types) stringArr.push(`types=${filter.types.join(',')}`);
  if (filter?.ownerIds) stringArr.push(`owner_id=${filter.ownerIds?.join(',')}`);
  if (filter?.owners) stringArr.push(`owners=${filter.owners}`);
  if (filter?.systemId) stringArr.push(`system_id=${filter.systemId}`);
  if (filter?.templateId) stringArr.push(`template_id=${filter.templateId}`);

  if (!stringArr.length) return '';

  return stringArr.join(',');
}
