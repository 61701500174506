import React from 'react';


import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getPrivacyClassificationfromTags } from '.';

function getTableName(rowData) {
  return `${rowData.database}.${rowData.name}`;
}

const privacyClassificationColumnName = (
  React.createElement('div', {
    style: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },}
  
    , React.createElement('p', null, "PRIVACY CLASSIFICATION " )
    , React.createElement(InfoOutlinedIcon, { style: { fontSize: 18 },} )
  )
);

export const dataCatalogTableColumns = [
  {
    title: 'Table Name',
    field: 'name',
    highlight: true,
    render: rowData => getTableName(rowData),
    customFilterAndSearch: (term, rowData) => `${rowData.database}.${rowData.name}`.includes(term)
  },
  {
    title: 'Owner (Squad)',
    field: 'owner',
    customFilterAndSearch: (term, rowData) => `${rowData.owner}`.includes(term)
  },
  {
    title: privacyClassificationColumnName,
    field: 'tags',
    render: rowData => getPrivacyClassificationfromTags(rowData.tags || []),
    tooltip: 'Indicates if the table contains or not PII data, which can identify a player'
  }
];
