export class ScrollablePage {
   static EMPTY_PAGE = new ScrollablePage([], undefined);
    _data;
    _next;

  constructor(data, next) {
    this._data = data;
    this._next = next;
  }

   isEmpty() {
    return !this._next && !this._data.length;
  }

  get next() {
    return this._next;
  }

  get data() {
    return this._data;
  }

  toJSON() {
    return {
      data: this._data,
      next: this._next
    };
  }
}
