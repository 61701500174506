
















import { useEntity } from '@backstage/plugin-catalog-react';

import { attachComponentData, useElementFilter } from '@backstage/core-plugin-api';

const ENTITY_SWITCH_KEY = 'core.backstage.entitySwitch';

const EntitySwitchCase = (_) => null;

attachComponentData(EntitySwitchCase, ENTITY_SWITCH_KEY, true);






export const EntitySwitch = ({ children }) => {
  const { entity } = useEntity();
  const switchCases = useElementFilter(children, collection =>
    collection
      .selectByComponentData({
        key: ENTITY_SWITCH_KEY,
        withStrictError: 'Child of EntitySwitch is not an EntitySwitch.Case'
      })
      .getElements()
      .flatMap((element) => {
        const { if: condition, children: elementsChildren } = element.props;
        return [{ if: condition, children: elementsChildren }];
      })
  );

  const matchingCase = switchCases.find(switchCase => (switchCase.if ? switchCase.if(entity) : true));
  return matchingCase?.children ?? null;
};

EntitySwitch.Case = EntitySwitchCase;
