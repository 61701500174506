import React from 'react';
import { Content } from '@backstage/core-components';
import { makeStyles, Tab, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CustomTabs } from './CustomTabs';







const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px'
  }
}));

const useWrapperStyles = makeStyles(theme => ({
  root: props => ({
    position: 'sticky',
    top: `${props.sticky}px`,
    zIndex: 999,
    background: theme.palette.background.default,
    overflow: 'hidden',
    paddingBottom: '4px',
    '& > div': {
      boxShadow: theme.shadows[2]
    }
  })
}));

const Wrapper = ({ children, sticky }) => {
  const classes = useWrapperStyles({ sticky: sticky || 0 });
  if (sticky !== null) {
    return (
      React.createElement('div', { className: classes.root,}
        , React.createElement('div', null, children)
      )
    );
  }
  return children;
};

export const TabsHeader = ({ actual, tabs, stickTop = undefined }) => {
  const classes = useStyles();
  return (
    React.createElement(Wrapper, { sticky: stickTop ? stickTop : null,}
      , React.createElement(Content, { className: classes.root,}
        , React.createElement(CustomTabs, { value: actual,}
          , tabs.map(({ label, path, disabled }) => (
            React.createElement(Tab, {
              disabled: disabled,
              style: { textTransform: 'capitalize' },
              key: label,
              value: path,
              label: label,
              component: Link,
              to: path,}
            )
          ))
        )
      )
    )
  );
};
