import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,

  Typography
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useAirflowHosts } from '../hooks';
import { SlaResolverOptions } from '../types';

const useStyles = makeStyles(() => ({
  radioOption: {
    textTransform: 'uppercase',
    marginRight: '2rem',
    '& > span': {
      fontSize: '0.8rem'
    }
  },
  helperText: {
    marginLeft: 0
  }
}));

export const UpdateSettings = () => {
  const classes = useStyles();
  const { register, control, formState } = useFormContext();
  const { errors } = formState;
  const airflowHosts = useAirflowHosts();
  const resolvers = Object.keys(SlaResolverOptions);
  const resolver = useWatch({ control, name: 'sla.resolver' }) ;
  return (
    React.createElement(Box, { pt: "2rem",}
      , React.createElement(Typography, { style: { fontWeight: 'bolder', marginBottom: '1rem' },}, "How to check table ETA?"    )
      , React.createElement(Controller, {
        control: control,
        name: "sla.resolver",
        render: ({ field }) => (
          React.createElement(Box, null
            , React.createElement(RadioGroup, {
              row: true,
              'aria-label': "Type of update"  ,
              ...field,
              value: field.value === resolvers[0] ? resolvers[0] : resolvers[1],
              onChange: v => field.onChange(v.target.value),}
            
              , Object.entries(SlaResolverOptions).map(([key, value]) => (
                React.createElement(FormControlLabel, {
                  key: key,
                  className: classes.radioOption,
                  value: key,
                  control: React.createElement(Radio, { color: "primary",} ),
                  label: value,}
                )
              ))
            )
          )
        ),}
      )
      , resolver === 'airflow_task_sensor' && (
        React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '1rem' },}
          , React.createElement(Controller, {
            control: control,
            name: "sla.airflow_url",
            render: props => (
              React.createElement(FormControl, { error: !!errors?.sla?.airflow_url, required: true, fullWidth: true,}
                , React.createElement(InputLabel, { htmlFor: "airflow_url",}, "Host")
                , React.createElement(Select, { defaultValue: props.field.value, id: "airflow_url", ...props.field,}
                  , Object.values(airflowHosts).map((o, index) => (
                    React.createElement(MenuItem, { key: o + index, value: o,}
                      , o
                    )
                  ))
                )
                , React.createElement(FormHelperText, { error: true,}, errors?.sla?.airflow_url?.message)
              )
            ),}
          )

          , React.createElement(TextField, {
            label: "DAG ID" ,
            required: true,
            fullWidth: true,
            ...register('sla.dag_id'),
            helperText: errors?.sla?.dag_id?.message,
            error: !!errors?.sla?.dag_id?.message,}
          )

          , React.createElement(TextField, {
            label: "Task ID" ,
            fullWidth: true,
            ...register('sla.task_id'),
            helperText: errors?.sla?.task_id?.message,
            error: !!errors?.sla?.task_id?.message,}
          )

          , React.createElement(Box, null
            , React.createElement(FormLabel, null, "DAG Schedule Time"  )
            , React.createElement(Controller, {
              control: control,
              name: "sla.execution_delta",
              render: ({ field: { value, onChange, onBlur, ...rest } }) => (
                React.createElement(TimePicker, {
                  ...rest,
                  ampm: false,
                  margin: "dense",
                  fullWidth: true,
                  inputVariant: "outlined",
                  format: "HH:mm",
                  disableToolbar: true,
                  error: errors?.sla?.execution_delta?.message,
                  helperText: errors?.sla?.execution_delta?.message || 'UTC time zone',
                  FormHelperTextProps: {
                    classes: {
                      root: classes.helperText
                    }
                  },
                  onChange: date => {
                    const hours = date?.hour ?? 0;
                    const minutes = date?.minute ?? 0;

                    return onChange({ hours, minutes });
                  },
                  onBlur: onBlur,
                  value: value ? DateTime.fromObject({ hour: value.hours, minute: value.minutes }) : null,}
                )
              ),}
            )
          )
        )
      )
    )
  );
};
