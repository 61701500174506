/* eslint-disable react-hooks/rules-of-hooks */
import { dataWorkflowApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';


export const useEconomicTags = (applicationID) => {
  const dataWorkflowApi = useApi(dataWorkflowApiRef);
  const { value } = useAsync(() => dataWorkflowApi.getEconomicTags(applicationID), [dataWorkflowApi, applicationID]);

  return { economic_tags: value };
};
