import React, { useState } from 'react';
import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import ReplayIcon from '@material-ui/icons/Replay';









const getActualTime = () => {
  return DateTime.utc().toFormat('yyyy-MM-dd HH:mm (ZZZZ)');
};

export const OverviewHeader = ({ owner, database_name, table_name, setting_name, onRefresh }) => {
  const [lastUpdatedDate, setLastUpdatedDate] = useState(getActualTime());
  return (
    React.createElement(Box, { display: "flex", style: { gap: '2rem' },}
      , React.createElement(Grid, { container: true, spacing: 2,}
        , React.createElement(Grid, { item: true, xs: 4,}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "body2",}, "Table")
            , React.createElement(Typography, { variant: "body2", style: { overflowWrap: 'break-word' },}
              , database_name, ".", table_name
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 4,}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "body2",}, "Setting Name" )
            , React.createElement(Typography, { variant: "body2", style: { overflowWrap: 'break-word' },}
              , setting_name
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 2,}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "body2",}, "Owner")
            , React.createElement(Link, { variant: "button", href: `/workforce/squad/${owner}`,}
              , React.createElement(Typography, { variant: "body2", style: { overflowWrap: 'break-word' },}
                , owner
              )
            )
          )
        )
        , React.createElement(Grid, { item: true, xs: 2,}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "body2",}, "Last Checked" )
            , React.createElement(Box, { display: "flex", style: { gap: 8 },}
              , React.createElement(Typography, { variant: "body2", style: { overflowWrap: 'break-word' },}
                , lastUpdatedDate
              )
              , React.createElement(Box, null
                , React.createElement(IconButton, {
                  style: { padding: 0 },
                  'aria-label': "refresh",
                  onClick: () => {
                    onRefresh();
                    setLastUpdatedDate(getActualTime());
                  },
                  color: "primary",}
                
                  , React.createElement(ReplayIcon, null )
                )
              )
            )
          )
        )
      )
    )
  );
};
