import React from 'react';
import { ApplicationEntitySwitch, ApplicationLayout, isType } from '@ring/plugin-catalog';
import { InfrastructureProductPage, DataProductPage, FrontendProductPage } from './pages';
import { OverviewContent } from './OverviewContent';

const defaultEntityPage = (
  React.createElement(ApplicationLayout, null
    , React.createElement(ApplicationLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(OverviewContent, null )
    )
  )
);

export const ApplicationPage = () => {
  return (
    React.createElement(ApplicationEntitySwitch, null
      , React.createElement(ApplicationEntitySwitch.Case, { if: isType('data_product'),}
        , React.createElement(DataProductPage, null )
      )
      , React.createElement(ApplicationEntitySwitch.Case, { if: isType('backend'),}
        , React.createElement(InfrastructureProductPage, null )
      )
      , React.createElement(ApplicationEntitySwitch.Case, { if: isType('metagame_connector'),}
        , React.createElement(InfrastructureProductPage, null )
      )
      , React.createElement(ApplicationEntitySwitch.Case, { if: isType('frontend'),}
        , React.createElement(FrontendProductPage, null )
      )
      , React.createElement(ApplicationEntitySwitch.Case, null, defaultEntityPage)
    )
  );
};
