import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { CreateMachineLearningDeployForm } from './CreateMachineLearningDeployForm';
import { machineLearningDeploymentApiRef } from '../../api';


const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

export const MachineLearningDeploymentFormPage = ({ entity }) => {
  const machineLearningDeploymentApi = useApi(machineLearningDeploymentApiRef);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      setOpen(true);
      await machineLearningDeploymentApi.deployMlflow(entity.metadata.name, {
        run_id: values.run_id,
        environment: values.environment,
        replicas: values.replicas,
        number_of_cores: values.number_of_cores,
        memory: values.memory
      });
      navigate(-1);
    } catch (e) {
      setOpen(false);
      errorApi.post(e);
    }
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "New Deployment" ,}
        , React.createElement(CreateMachineLearningDeployForm, { onSubmit: onSubmit,} )
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: open,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
