import { IllegalError } from './illegalError';

export class InvalidPageableError extends IllegalError {
   constructor(message) {
    super(message);
  }

   static fromInvalidPage(page) {
    return new InvalidPageableError(`the page's value may not be negative (page: ${page})`);
  }

   static fromInvalidSize(size) {
    return new InvalidPageableError(`the size's value may not be negative (size: ${size})`);
  }

   static fromInvalidPageAndSize(page, size) {
    return new InvalidPageableError(`the page and size values may not be negative (page: ${page}, size: ${size})`);
  }

   static fromInvalidPageNumber(page, totalPages) {
    return new InvalidPageableError(`Page does not exist. (page: ${page}, total pages: ${totalPages})`);
  }
}
