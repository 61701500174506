export var DatabaseStatus; (function (DatabaseStatus) {
  const PENDING = 'pending'; DatabaseStatus["PENDING"] = PENDING;
  const CREATING = 'creating'; DatabaseStatus["CREATING"] = CREATING;
  const READY = 'ready'; DatabaseStatus["READY"] = READY;
  const ERROR = 'error'; DatabaseStatus["ERROR"] = ERROR;
  const DELETED = 'deleted'; DatabaseStatus["DELETED"] = DELETED;
  const DELETING = 'deleting'; DatabaseStatus["DELETING"] = DELETING;
})(DatabaseStatus || (DatabaseStatus = {}));





















































