import React, { useRef } from 'react';
import { Table, } from '@backstage/core-components';

import { Box, /* Button, */ Typography } from '@material-ui/core';
import { getRuleName } from '../../utils';
/* import EditIcon from '@material-ui/icons/Edit'; */

const cellStyle = { padding: '0.5rem 16px' };
const SHOWED_VALUES = ['mostly', 'max_value', 'min_value', 'ignore_row_if'];

const columns = [
  {
    title: 'Type',
    field: 'name',
    defaultSort: 'asc',
    cellStyle,
    render: ({ name }) => React.createElement(Typography, { color: "primary",}, getRuleName(name), " " )
  },
  {
    title: 'Columns',
    field: 'columns',
    defaultSort: 'asc',
    cellStyle
  },
  {
    title: 'Values',
    field: 'values',
    cellStyle,
    render: ({ values }) => {
      const valuesFiltered = Object.keys(values).filter(key => SHOWED_VALUES.includes(key));

      if (valuesFiltered.length === 0) {
        return React.createElement(Typography, null, "no issues" );
      }

      return (
        React.createElement(React.Fragment, null
          , valuesFiltered.map(key => (
            React.createElement(React.Fragment, null
              , React.createElement(Typography, { color: "error", display: "inline", component: "span",}, `${key}`)
              , React.createElement(Typography, {
                display: "inline",
                component: "span",
                style: { wordBreak: 'break-all' },}
              , ` ${values[key]} `)
            )
          ))
        )
      );
    }
  }
];

export const TableValidations = ({
  data
}



) => {
  const materialTableRef = useRef(null);

  return (
    React.createElement(Table, {
      tableRef: materialTableRef,
      title: 
        React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
          , React.createElement(Box, { whiteSpace: "nowrap",}, "Validations")
          , React.createElement(Box, { ml: 3,}
            /*         <Button color="primary" onClick={onEdit} startIcon={<EditIcon />}>
              Edit validations
            </Button> */
          )
        )
      ,
      columns: columns,
      data: data ?? [],
      options: {
        paging: false,
        thirdSortClick: false
        /*    actionsCellStyle: {
          width: '110px',
          display: 'block'
        } */
      },}
    )
  );
};
