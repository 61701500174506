import React from 'react';
import { CodeSnippet, InfoCard, Progress, WarningPanel, Link } from '@backstage/core-components';

import { Typography } from '@material-ui/core';
import { TableCard } from '@ring/plugin-common';









const columns = serviceId => [
  {
    title: 'Name',
    field: 'api-name',
    render: apiDoc => (
      React.createElement(Link, { to: `/catalog/service/${serviceId}/api/${apiDoc['api-name']}`, state: { data: apiDoc },}
        , apiDoc['api-name']
      )
    )
  },
  {
    title: 'Type',
    field: 'type',
    render: apiDoc => apiDoc.type
  }
];

export const ProvidingComponentsCard = ({
  variant = 'gridItem',
  loading = false,
  error,
  apis = [],
  serviceId
}) => {
  if (loading) {
    return (
      React.createElement(InfoCard, { variant: variant, title: "Providers",}
        , React.createElement(Progress, null )
      )
    );
  }

  if (error || !apis) {
    return (
      React.createElement(InfoCard, { variant: variant, title: "Providers",}
        , React.createElement(WarningPanel, {
          severity: "error",
          title: "Could not load components"   ,
          message: React.createElement(CodeSnippet, { text: `${error}`, language: "text",} ),}
        )
      )
    );
  }

  return (
    React.createElement(TableCard, {
      title: "Providers",
      variant: variant,
      emptyContent: 
        React.createElement('div', { style: { textAlign: 'center' },}
          , React.createElement(Typography, { variant: "body1",}, "No component provides this API."    )
        )
      ,
      columns: columns(serviceId),
      entities: apis,}
    )
  );
};
