import React, { createContext, useContext, useMemo } from 'react';
import { useAsyncFn, useDebounce } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { ApplicationFilter, ApplicationFilterBuilder, Pageable } from '@ring/model';
import { applicationApiRef, buildFilters, useStateSearchParams } from '@ring/plugin-common';
import { isEmpty } from 'lodash';
import { useStarredApplications } from './useStarredApplications';













export const ApplicationListContext = createContext(undefined);

export const ApplicationListProvider = ({ children }) => {
  const applicationApi = useApi(applicationApiRef);
  const { starredApplications } = useStarredApplications();

  const { queryParams, setQueryParams } = useStateSearchParams();

  const [{ value: applications, loading, error }, refresh] = useAsyncFn(
    async (starredApps = starredApplications) => {
      // const page = queryParams?.page ? Number(queryParams?.page) : 0;

      // if starred is set and there is no starred apps on localstorage, return undefined
      if (queryParams.starred && starredApps.size === 0) {
        return undefined;
      }

      // if starred is set, ignore other filters
      const filterValues = queryParams.starred ? { names: Array.from(starredApps) } : queryParams;
      const filters = buildFilters(
        filterValues,
        ApplicationFilterBuilder
      );

      /* const response = await applicationApi.findAll({
        ...(!isEmpty(filters) && { filter: new ApplicationFilter(filters) }),
        pageable: new Pageable(page, 100)
      });
      return response; */

      try {
        const handshakeResponse = await applicationApi.findAll({
          pageable: new Pageable(0, 100),
          ...(!isEmpty(filters) && { filter: new ApplicationFilter(filters) })
        });

        const response = await Promise.all(
          Array.from({ length: handshakeResponse.totalPages - 1 }, (_, i) =>
            applicationApi.findAll({
              pageable: new Pageable(i + 1, 100),
              ...(!isEmpty(filters) && { filter: new ApplicationFilter(filters) })
            })
          )
        );

        const initialData = handshakeResponse.data ?? [];

        const data = response?.reduce((arr, row) => {
          return arr.concat(row.data);
        }, initialData);

        return Promise.resolve(data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [applicationApi, queryParams]
  );

  useDebounce(() => refresh(starredApplications), 10, [queryParams, starredApplications]);

  const value = useMemo(
    () => ({
      applications,
      queryParams,
      updateFilters: setQueryParams,
      loading,
      error
    }),
    [applications, queryParams, setQueryParams, loading, error]
  );

  return React.createElement(ApplicationListContext.Provider, { value: value,}, children);
};

export function useApplicationListProvider() {
  const context = useContext(ApplicationListContext);
  if (!context) throw new Error('useApplicationListProvider must be used within ApplicationListProvider');
  return context;
}
