import React, { useState } from 'react';
import { Box, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export const ViewDetailsAccordion = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Box, {
        onClick: () => setIsExpanded(prev => !prev),
        display: "flex",
        alignItems: "center",
        style: { gridColumnStart: 'span 2' },}
      
        , React.createElement(Link, { style: { cursor: 'pointer', fontWeight: 600 }, variant: "body2",}
          , isExpanded ? 'Hide Details' : 'View Details'
        )
        , isExpanded ? React.createElement(ExpandLessIcon, null ) : React.createElement(ExpandMoreIcon, null )
      )
      , React.createElement(Box, {
        style: { gridColumnStart: 'span 2' },
        display: isExpanded ? 'grid' : 'none',
        gridGap: "1rem",
        gridTemplateColumns: "1fr 2fr" ,}
      
        , children
      )
    )
  );
};
