import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word'
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));








export const AboutField = ({ label, value, gridSizes, children }) => {
  const classes = useStyles();

  // Content is either children or a string prop `value`
  const content = React.Children.count(children) ? (
    children
  ) : (
    React.createElement(Typography, { variant: "body2", className: classes.value,}
      , value || `unknown`
    )
  );
  return (
    React.createElement(Grid, { item: true, ...gridSizes,}
      , React.createElement(Typography, { variant: "subtitle2", className: classes.label,}
        , label
      )
      , content
    )
  );
};
