

export function serializeFilter(filter) {
  if (!filter || filter.isEmpty()) return '';

  const stringArr = [];

  if (filter?.names) stringArr.push(`names=${filter.names.join(',')}`);
  if (filter?.description) stringArr.push(`description=${filter.description}`);
  if (filter?.owner) stringArr.push(`owner=${filter.owner}`);
  if (filter?.type) stringArr.push(`type=${filter.type}`);

  if (!stringArr.length) return '';

  return stringArr.join(',');
}
