import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

const icon = React.createElement(CheckBoxOutlineBlankIcon, { fontSize: "small",} );
const checkedIcon = React.createElement(CheckBoxIcon, { fontSize: "small",} );








export const OwnerPicker = ({ onChange, selected, options }) => {
  const [value, setValue] = React.useState();

  useEffect(() => {
    if (selected && !isEmpty(options)) {
      const initObjects = options.filter(owner => selected.includes(`${owner.id}`));
      if (initObjects) {
        setValue(initObjects);
      }
    }
  }, [selected, options]);

  if (!options.length) return null;

  return (
    React.createElement(Box, { pb: 1, pt: 1,}
      , React.createElement(Typography, { variant: "button",}, "Owner")
      , React.createElement(Autocomplete, {
        multiple: true,
        value: value ?? [],
        getOptionLabel: o => o.displayName,
        options: options,
        onChange: (_, squad) => {
          setValue(squad);
          onChange(squad.map(s => s.id));
        },
        renderOption: (option, { selected: selectedOpt }) => (
          React.createElement(FormControlLabel, {
            control: React.createElement(Checkbox, { icon: icon, checkedIcon: checkedIcon, checked: selectedOpt,} ),
            label: option.displayName,}
          )
        ),
        size: "small",
        popupIcon: React.createElement(ExpandMoreIcon, { 'data-testid': "owner-picker-expand",} ),
        renderInput: params => React.createElement(TextField, { ...params, variant: "outlined",} ),}
      )
    )
  );
};
