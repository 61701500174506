import React from 'react';
import { OverflowTooltip, } from '@backstage/core-components';

import { EntityRefLink } from '../../../common/components/EntityRefLink';
import { getSquadName } from '../../../common/utils';

export function createNameColumn() {
  return {
    title: 'Name',
    field: 'name',
    highlight: true,
    render: ({ displayName, name }) => React.createElement(EntityRefLink, { entityRef: "application", name: name, displayName: displayName,} )
  };
}

export function createSystemColumn() {
  return {
    title: 'System',
    field: 'system',
    render: ({ system }) => React.createElement(EntityRefLink, { entityRef: "system", name: system,} )
  };
}

export function createOwnerColumn() {
  return {
    title: 'Owner',
    field: 'owner',
    render: ({ owner }) => React.createElement(EntityRefLink, { forceRef: "/workforce/squad", name: getSquadName(owner),} )
  };
}

export function createSpecTypeColumn() {
  return {
    title: 'Type',
    field: 'type',
    hidden: true
  };
}

export function createDescriptionColumn() {
  return {
    title: 'Description',
    field: 'description',
    render: ({ description }) => React.createElement(OverflowTooltip, { text: description, placement: "bottom-start",} ),
    width: 'auto'
  };
}

export function createSpecLifecycleColumn() {
  return {
    title: 'Lifecycle',
    field: 'lifecycle'
  };
}
