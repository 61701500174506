export const DATABRICKS_OPERATOR_DISPLAY_NAME = 'DataBricks Python Ephemeral Cluster';
export const DATABRICKS_POOLS_DISPLAY_NAME = 'Databricks Python Pools';
export const DELTA_PARTITION_SENSOR_DISPLAY_NAME = 'Delta Table Partition Sensor';
export const HIVE_PARTITION_SENSOR_DISPLAY_NAME = 'Hive Table Partition Sensor';
export const EXTERNAL_TASK_SENSOR_DISPLAY_NAME = 'Airflow1 Sensor';
export const AIRFLOW_TASK_SENSOR_DISPLAY_NAME = 'Airflow2 Sensor';

export const DATABRICKS_PYTHON_OPERATOR = 'wildlifestudios_provider.operators.databricks.databricks_python_bundle_v1';
export const DATABRICKS_POOLS_OPERATOR =
  'wildlifestudios_provider.operators.databricks_pools_simple.databricks_pools_simple';

export const DELTA_TABLE_PARTITION_SENSOR_OPERATOR =
  'wildlifestudios_provider.sensors.delta_table_partition_sensor.DeltaTablePartitionSensor';

export const HIVE_TABLE_PARTITION_SENSOR_OPERATOR =
  'wildlifestudios_provider.sensors.hive_table_partition_sensor.HiveMetastorePartitionSensor';

export const EXTERNAL_TASK_SENSOR_OPERATOR =
  'wildlifestudios_provider.sensors.external_airflow_task_sensor.ExternalAirflow1TaskSensor';

export const AIRFLOW_TASK_SENSOR_OPERATOR =
  'wildlifestudios_provider.sensors.external_airflow2_task_sensor.airflow2_task_sensor';
