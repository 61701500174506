

export const getOwnerEntityRef = (entity) => {
  if (!entity) {
    return '';
  }

  const {
    kind,
    metadata: { namespace, name }
  } = entity;

  const namespacePart = !namespace || namespace === 'default' ? '' : `${namespace}/`;
  const kindPart = kind.toLowerCase() === 'group' ? '' : `${kind}:`;

  return `${kindPart}${namespacePart}${name}`;
};
