import React, { useState } from 'react';
import { Box, makeStyles, Typography, Tooltip, Button, CircularProgress, IconButton } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { useMixpanel } from '@ring/plugin-common';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

import EditIcon from '@material-ui/icons/Edit';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeleteIcon from '@material-ui/icons/Delete';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '0.5rem',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0.5rem'
  }
}));






export const SettingsItem = ({ setting: setting, refresh }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const [loading, setLoading] = useState(false);
  const [confirmMode, setConfirmMode] = useState(undefined);

  const alertApi = useApi(alertApiRef);
  const dataObservabilityApi = useApi(dataObservabilityApiRef);

  const handleConfirm = async () => {
    setLoading(true);
    if (confirmMode === 'delete') {
      try {
        await dataObservabilityApi.deleteDataMonitoringSettingV2(setting.id);
        track('Setting deleted', {
          table: `${setting.database_name}.${setting.table_name}`,
          setting_name: `${setting.name}`
        });
        alertApi.post({
          severity: 'success',
          message: `The setting ${setting.name} was successfuly deleted.'}`
        });
      } catch (error) {
        if (error instanceof Error)
          alertApi.post({
            severity: 'error',
            message: `There was an error deleting the setting:\n ${error.message}`
          });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await dataObservabilityApi.enableDataMonitoringSettingV2(setting.id, !setting.enabled);
        track(`Setting ${setting.enabled ? 'paused' : 'resumed'}`, {
          table: `${setting.database_name}.${setting.table_name}`,
          setting_name: `${setting.name}`
        });
        alertApi.post({
          severity: 'success',
          message: `The setting ${setting.name} was successfuly ${setting.enabled ? 'paused.' : 'resumed.'}`
        });
      } catch (error) {
        if (error instanceof Error)
          alertApi.post({
            severity: 'error',
            message: `There was an error updating the setting:\n ${error.message}`
          });
      } finally {
        setLoading(false);
      }
    }
    refresh();
    setConfirmMode(undefined);
  };

  const handleCancel = () => {
    setConfirmMode(undefined);
  };

  const getLabel = (mode) => (mode ? 'pause' : 'resume');

  return (
    React.createElement(Box, { className: `${classes.container}`,}
      , confirmMode ? (
        React.createElement(Box, { display: "flex", justifyContent: "center", style: { gap: '10px' }, alignItems: "center",}
          , React.createElement(Typography, null, `Are you sure to ${
            confirmMode !== 'delete' ? getLabel(setting.enabled) : confirmMode
          } this setting?`)
          , React.createElement(Box, { display: "flex", style: { gap: '10px' }, alignItems: "center",}
            , React.createElement(Button, { disabled: loading, color: "primary", onClick: handleConfirm,}, "yes"

            )
            , React.createElement(Button, { disabled: loading, color: "secondary", onClick: handleCancel,}, "No"

            )
            , loading && React.createElement(CircularProgress, { size: 16,} )
          )
        )
      ) : (
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center",}
          , React.createElement(Box, { overflow: "hidden",}
            , React.createElement('label', { htmlFor: setting.id,}
              , React.createElement(Typography, { style: { overflowWrap: 'break-word' },}, setting?.name)
              , React.createElement(Typography, { variant: "body2",}, "Owner: " , setting?.org_squad)
            )
          )
          , React.createElement(Box, { display: "flex", justifyContent: "flex-end",}
            , React.createElement(Tooltip, { title: "Delete setting" ,}
              , React.createElement(IconButton, { color: "primary", onClick: () => setConfirmMode('delete'),}
                , React.createElement(DeleteIcon, null )
              )
            )
            , React.createElement(Tooltip, { title: `${setting.enabled ? 'Pause' : 'Resume'} setting`,}
              , React.createElement(IconButton, { color: "primary", onClick: () => setConfirmMode('pause'),}
                , setting.enabled ? React.createElement(PauseCircleOutlineIcon, null ) : React.createElement(PlayCircleOutlineIcon, null )
              )
            )
            , React.createElement(Tooltip, { title: "Edit Data Monitoring Settings"   ,}
              , React.createElement(IconButton, {
                color: "primary",
                onClick: () => {
                  track('DQ to config btn clicked', {
                    table: `${setting.database_name}.${setting.table_name}`,
                    setting_name: `${setting.name}`,
                    org_squad: `${setting.org_squad}`
                  });
                  navigate(
                    `/observability/tables/${setting.database_name}.${setting.table_name}/settings/${setting.id}/data-monitoring`
                  );
                },}
              
                , React.createElement(EditIcon, null )
              )
            )

            , React.createElement(Tooltip, { title: "Overview Data Monitoring Results"   ,}
              , React.createElement(IconButton, {
                color: "primary",
                onClick: () => {
                  navigate(`/observability/${setting.id}/overview`);
                },}
              
                , React.createElement(BarChartIcon, null )
              )
            )
          )
        )
      )
    )
  );
};
