import React, { useState } from 'react';
import { AlertDialog } from '@ring/plugin-common';
import { Box } from '@material-ui/core';


import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { Table, } from '@backstage/core-components';
import { MTableToolbar } from 'material-table';
import { useParams } from 'react-router-dom';







const defaultColumns = [
  {
    title: 'Database Name',
    field: 'database_name',
    customFilterAndSearch: () => true
  },
  {
    title: 'Table Name',
    field: 'table_name',
    customFilterAndSearch: () => true
  },
  {
    title: 'Setting Name',
    field: 'setting_name',
    customFilterAndSearch: () => true,
    render: ({ name }) => name
  },
  {
    title: 'Owner',
    field: 'owner',
    render: ({ org_squad }) => {
      const squadName = org_squad && org_squad.replace(/^group\:/, '');
      return squadName;
    }
  }
];

export const AddTableDialog = ({ open, setOpen, reload }) => {
  const dataObservabilityApi = useApi(dataObservabilityApiRef);
  const alertApi = useApi(alertApiRef);
  const { dashboardId } = useParams();

  const [requesting, setRequesting] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);

  const handleConfirm = async () => {
    setRequesting(true);
    try {
      const selectedSettings = [];
      for (const setting of selectedTables) {
        selectedSettings.push({
          setting_id: setting.id || '',
          database_name: setting.database_name,
          table_name: setting.table_name
        });
      }
      await dataObservabilityApi.addSettingsToDashboardV2(dashboardId, selectedSettings);
      alertApi.post({
        severity: 'success',
        message: 'Data monitoring settings added to your dashboard'
      });
      setRequesting(false);
      setOpen(false);
      reload();
    } catch (error) {
      setRequesting(false);
      alertApi.post({
        severity: 'error',
        message: 'Error adding data monitoring settings to the Dashboard'
      });
    }
  };

  const onSelectionChange = (_, clickedRow) => {
    setSelectedTables(tables => {
      if (clickedRow?.tableData?.checked) {
        return [...tables, clickedRow];
      }
      return tables.filter(table => table.id !== clickedRow.id);
    });
  };

  return (
    React.createElement(AlertDialog, {
      open: open,
      onConfirm: handleConfirm,
      onClose: () => setOpen(false),
      confirmButtonLeyend: "attach",
      disableConfirm: selectedTables.length === 0 || requesting,
      size: false,}
    
      , React.createElement(Table, {
        columns: defaultColumns,
        components: {
          Toolbar: props => {
            return React.createElement(MTableToolbar, { ...props, selectedRows: selectedTables,} );
          }
        },
        options: {
          initialPage: 0,
          loadingType: 'overlay',
          actionsColumnIndex: -1,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [],
          paging: true,
          selection: true,
          selectionProps: (row) => ({
            disabled: !row.id
          }),
          showSelectAllCheckbox: false,
          debounceInterval: 300
        },
        title: 
          React.createElement(Box, { whiteSpace: "nowrap", width: "650px", textAlign: "left",}, "Available settings"

          )
        ,
        onSelectionChange: onSelectionChange,
        data: query =>
          new Promise(async (resolve, reject) => {
            const page = query?.page || 0;
            const pageSize = query?.pageSize || 10;
            const search = query?.search || '';
            try {
              const data = await dataObservabilityApi.searchAllSettingsV2({
                size: pageSize,
                page: page + 1,
                query: search
              });
              resolve({
                data: data.match.map(t => ({
                  ...t,
                  tableData: { checked: selectedTables.map(table => table.id).includes(t.id) }
                })),
                page: data.page_number - 1,
                totalCount: data.total_count
              });
            } catch (error) {
              reject(error);
            }
          })
        ,}
      )
    )
  );
};
