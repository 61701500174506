






























/**
 * CatalogClient wrapper that injects identity token for all requests
 */
export class CatalogClientWrapper  {
    identityApi;
    client;
    discoveryApi;

  constructor(options) {
    this.client = options.client;
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  async getBaseUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('catalog');
    return baseUrl;
  }

  async getLocationById(id, options) {
    return await this.client.getLocationById(id, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async getEntities(
    request,
    options
  ) {
    return await this.client.getEntities(request, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async getEntityByName(compoundName, options) {
    return await this.client.getEntityByName(compoundName, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async addLocation(request, options) {
    return await this.client.addLocation(request, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async getOriginLocationByEntity(entity, options) {
    return await this.client.getOriginLocationByEntity(entity, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async getLocationByEntity(entity, options) {
    return await this.client.getLocationByEntity(entity, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async removeLocationById(id, options) {
    return await this.client.removeLocationById(id, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async removeEntityByUid(uid, options) {
    return await this.client.removeEntityByUid(uid, {
      token: options?.token ?? (await this.identityApi.getIdToken())
    });
  }

  async createEntity(body) {
    const token = await this.identityApi.getIdToken();
    const email = this.getUserEmail();
    const headers = {
      ...(email && { 'X-User-Email': email }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...this.commonHeaders
    };

    const res = await fetch(`${await this.getBaseUrl()}/entities`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    return res;
  }

  async updateEntity(body, uid) {
    const token = await this.identityApi.getIdToken();
    const email = this.getUserEmail();
    const headers = {
      ...(email && { 'X-User-Email': email }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...this.commonHeaders
    };

    const res = await fetch(`${await this.getBaseUrl()}/entities/${uid}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body)
    });

    return res;
  }

  getUserEmail() {
    return this.identityApi.getProfile().email;
  }
}
