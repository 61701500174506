import * as yup from 'yup';
import {

  PARTITION_COLUMNS_FORM_KEY,
  SlaResolverOptions,
  QueryResolverIntervalTimeOptions,
  COLUMN_VALIDATIONS_KEY
} from '../../types';
import { mergeSchemas } from '../../helpers';
import cron from 'cron-validate';
import { columnValidationSchema } from './step3ValidationSchema';
import { notEmptyMessage } from './messages';

export const useValidationSchema = (
  step



) => {
  const validationSchemas = [
    // validation for step1
    yup.object().shape({
      dataFilters: yup.array().of(yup.string()).default([]),
      notifications: yup.object().shape({
        slack_channels: yup.array().of(yup.string()).optional().default([]),
        opsgenie_groups: yup.array().of(yup.string()).optional().default([]),
        opsgenie_priority: yup
          .string()
          .default('')
          .when('opsgenie_groups', {
            is: (opsgenie_groups) => {
              return !!opsgenie_groups?.length;
            },
            then: schema => schema.required()
          })
      }),
      ownership: yup.object({
        name: yup.string().required('Name is a required field'),
        org_squad: yup.string().required('Owner is a required field'),
        business_unit: yup.string().required('Business unit is a required field')
      }),
      sla: yup.object().shape({
        // sla_config: yup.mixed().oneOf(Object.values(SlaConfigOptions)).required().default(SlaConfigOptions.daily),
        resolver: yup.mixed().oneOf(Object.keys(SlaResolverOptions)).required().default('query_resolver'),
        // eta: yup.string().required('Expected time of arrival is required. Format HH:mm'),
        // slack_enabled: yup.boolean().required().default(true),
        // opsgenie_enabled: yup.boolean().required().default(false),
        airflow_url: yup.mixed().when('resolver', {
          is: 'airflow_task_sensor',
          then: schema => schema.required('Airflow Host is required'),
          otherwise: schema => schema.optional()
        }),
        dag_id: yup.string().when('resolver', {
          is: 'airflow_task_sensor',
          then: schema => schema.required('DAG ID is required'),
          otherwise: schema => schema.optional()
        }),
        task_id: yup.string().optional(),
        execution_delta: yup
          .object()
          .shape({
            hours: yup.number(),
            minutes: yup.number().max(59)
          })
          .when('resolver', {
            is: 'airflow_task_sensor',
            then: schema => schema.required('Execution time is required. Format HH:mm'),
            otherwise: schema => schema.optional()
          })
          .default({
            hours: 0,
            minutes: 0
          })
      }),
      [PARTITION_COLUMNS_FORM_KEY]: yup
        .array()
        .of(
          yup.object().shape({
            column_name: yup.string(),
            column_data_format: yup.string().trim().min(2, notEmptyMessage).required()
          })
        )
        .default([])
    }),
    // validation for step2
    yup.object().shape({
      sla: yup.object().shape({
        schedule_expression: yup
          .string()
          .test('valid cron', '', (val, ctx) => {
            const cronResult = cron(val, {
              preset: 'default',
              override: {
                useLastDayOfWeek: true,
                useLastDayOfMonth: true
              }
            });
            return cronResult.isValid() ? true : ctx.createError({ message: cronResult.getError().join(' ') });
          })
          .default('*/4 * * * *'),
        sla_settings: yup.object().shape({
          interval_time: yup
            .mixed()
            .oneOf(Object.values(QueryResolverIntervalTimeOptions))
            .when('$sla.resolver', {
              is: 'airflow_task_sensor',
              then: schema => schema.required('Interval Time is required'),
              otherwise: schema => schema.optional()
            })
            .default(QueryResolverIntervalTimeOptions.days),

          interval_value: yup
            .number()
            .typeError('Interval must be a number')
            .max(0, 'must be zero or a negative number')
            .when('$sla.resolver', {
              is: 'airflow_task_sensor',
              then: schema => schema.required('Interval value is required'),
              otherwise: schema => schema.optional()
            })
            .default(-1)
        }),
        eta: yup
          .object()

          .shape({
            hours: yup.number(),
            minutes: yup.number().lessThan(60)
          })
          .required(notEmptyMessage)
          .default({
            hours: 0,
            minutes: 0
          }),
        opsgenie_enabled: yup.boolean().required().default(false),
        slack_enabled: yup.boolean().required().default(true)
      }),
      data_quality_settings: yup.object().shape({
        resolver_interval_time: yup
          .mixed()
          .oneOf(Object.values(QueryResolverIntervalTimeOptions))
          .required()
          .default(QueryResolverIntervalTimeOptions.days),
        resolver_interval_value: yup.number().max(0, 'must be zero or a negative number').default(-1)
      })
    }),
    // validation for step3
    yup.object().shape({
      data_quality_settings: yup.object().shape({
        resolver_interval_time: yup
          .mixed()
          .oneOf(Object.values(QueryResolverIntervalTimeOptions))
          .required()
          .default(QueryResolverIntervalTimeOptions.days),
        resolver_interval_value: yup.number().max(0, 'must be zero or a negative number').default(-1)
      }),
      [COLUMN_VALIDATIONS_KEY]: columnValidationSchema.default([])
    })
  ];

  return {
    currentValidationSchema: validationSchemas[step],
    fullValidationSchema: mergeSchemas(...validationSchemas) 
  };
};
