import React from 'react';
import * as columnFactories from './columns';
import { CodeSnippet, Table, WarningPanel } from '@backstage/core-components';
import { useApplicationListProvider } from '../../hooks/useApplicationListProvider';

import { capitalize } from 'lodash';
import { useMe } from '@ring/plugin-common';
import { useStarredApplications } from '../../hooks/useStarredApplications';
import { favouriteEntityIcon, favouriteEntityTooltip } from '../../../common/components/FavouriteEntity';

const defaultColumns = [
  columnFactories.createNameColumn(),
  columnFactories.createSystemColumn(),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createDescriptionColumn()
];






export const ApplicationsTable = ({ columns, actions }) => {
  const { me } = useMe();
  const { isStarredApplication, toggleStarredApplication } = useStarredApplications();
  const { loading, error, applications, queryParams } = useApplicationListProvider();

  const title = capitalize(
    queryParams?.owners && queryParams.owners.length === 1 && queryParams.owners[0] === me?.squad ? 'owned' : 'all'
  );
  const titlePreamble = queryParams?.starred ? 'Starred' : title;

  if (error) {
    return (
      React.createElement('div', null
        , React.createElement(WarningPanel, { severity: "error", title: "Could not fetch catalog applications."    ,}
          , React.createElement(CodeSnippet, { language: "text", text: error.toString(),} )
        )
      )
    );
  }

  const defaultActions = [
    application => {
      const isStarred = isStarredApplication(application);
      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => favouriteEntityIcon(isStarred),
        tooltip: favouriteEntityTooltip(isStarred),
        onClick: () => toggleStarredApplication(application)
      };
    }
  ];

  return (
    React.createElement(Table, {
      isLoading: loading,
      columns: columns || defaultColumns,
      options: {
        loadingType: 'overlay',
        paging: true,
        pageSize: 20,
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: !loading,
        padding: 'dense',
        pageSizeOptions: [20, 50, 100]
      },
      title: `${titlePreamble} (${applications?.length ?? 0})`,
      data: applications ?? [],
      actions: actions || defaultActions,}
    )
  );
};
