import React, { useState } from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { getTasksSchema } from './DeploymentSchemas';










const DeploymentTaskForm = withTheme(MuiTheme);

export const DeploymentTaskDialog = ({ setTaskById, onClose, taskDeps, editingState, newTask }) => {
  const [formState, setFormState] = useState(editingState);

  const addTask = () => {
    const id = uuidv4(); // creates a temp id
    const newTaskContent = { ...formState, id }; // adding the formState to the task

    // set taskById adding the new task
    setTaskById(prevState => {
      return { ...prevState, [id]: newTaskContent };
    });
    onClose();
  };

  const updateTask = () => {
    const newTaskContent = { ...formState, id: editingState.id };
    setTaskById(prevState => {
      return { ...prevState, [editingState.id]: newTaskContent };
    });
    onClose();
  };

  const saveTaskWithValidations = () => {
    return newTask ? addTask : updateTask;
  };

  function customValidate(formData, errors) {
    const current_task_names = taskDeps; // .slice(0,-1)
    const current_task_name = formData?.conditional.task_name;

    if (current_task_name) {
      if (current_task_names.includes(current_task_name)) {
        errors.conditional.task_name.addError(`Task name '${current_task_name}' already exist`);
      }
    }

    return errors;
  }

  return (
    React.createElement(DialogContent, null
      , React.createElement(DeploymentTaskForm, {
        formData: formState,
        onChange: e => setFormState(e.formData),
        schema: getTasksSchema(taskDeps),
        validate: customValidate,
        onSubmit: saveTaskWithValidations(),}
        // liveValidate
      
        , React.createElement(DialogActions, null
          , React.createElement(Button, { variant: "contained", onClick: () => onClose(),}, "Cancel"

          )
          , React.createElement(Button, { color: "primary", variant: "contained", type: "submit",}, "Save task"

          )
        )
      )
    )
  );
};
