import { upperFirst, invoke, isEmpty } from 'lodash';

export function buildFilters(filters, constructor) {
  return Object.entries(filters).reduce((builder, [key, value]) => {
    const builderMethod = `set${upperFirst(key)}`;

    return (!isEmpty(value) || value !== '') && builderMethod in builder
      ? invoke(builder, builderMethod, value)
      : builder;
  }, new constructor());
}
