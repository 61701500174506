import React from 'react';
import * as columnFactories from './columns';
import { Table, } from '@backstage/core-components';


import { Box } from '@material-ui/core';
import { MTableToolbar } from 'material-table';









export const TablesTable = ({ tables, queryParams, setQueryParams, onAdd, onOpenSettings }) => {
  const defaultColumns = [
    columnFactories.createDatabaseNameColumn(),
    columnFactories.createNameColumn(),
    columnFactories.createOwnerColumn(),
    columnFactories.createMonitoringColumn(),
    columnFactories.createActionsColumn({ onAdd, onOpenSettings })
  ];

  const { loading, value: tablesPage } = tables;

  const onPageChange = (page) => {
    setQueryParams((prev) => ({ ...prev, page: page + 1 }));
  };
  const onRowsPerPageChange = (size) => {
    setQueryParams((prev) => ({ ...prev, size }));
  };
  const onSearchChange = (search) => {
    setQueryParams((prev) => ({ ...prev, query: search, page: 1 }));
  };

  return (
    React.createElement(Table, {
      isLoading: loading,
      columns: defaultColumns,
      page: tablesPage ? tablesPage.page_number - 1 : 0,
      totalCount: tablesPage?.total_count,
      components: {
        Toolbar: props => {
          return React.createElement(MTableToolbar, { ...props, selectedRows: [],} );
        }
      },
      options: {
        loadingType: 'overlay',
        paging: true,
        pageSize: tablesPage?.count || 20,
        showEmptyDataSourceMessage: !loading,
        padding: 'dense',
        pageSizeOptions: [20, 50, 100],
        debounceInterval: 1000,
        searchText: queryParams?.query 
      },
      title: 
        React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
          , React.createElement(Box, { whiteSpace: "nowrap",}, `Table Inventory (${tablesPage?.total_count ?? 0})`)
        )
      ,
      data: tablesPage?.match || [],
      onPageChange: onPageChange,
      onRowsPerPageChange: onRowsPerPageChange,
      onSearchChange: onSearchChange,}
    )
  );
};
