import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import { Content } from '@backstage/core-components';
import { ActionsHeader, TabsHeader, useMixpanel } from '@ring/plugin-common';



const tabs = [
  { label: 'Catalog', path: 'inventory', disabled: false },
  { label: 'Observability Dashboards', path: 'dashboards', disabled: false },
  { label: 'Notification Groups', path: 'notification', disabled: false }
];

export const DataObservabilityGlobal = ({}) => {
  const { '*': actualTab } = useParams();
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const matchUrl = actualTab.replace(/[\/]/g, '') || tabs[0].label;

  const handleImport = () => {
    track('Import table btn clicked');
    navigate('/observability/import-tables');
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ActionsHeader, { title: "Data Catalog" , padding: "2rem",}
        , React.createElement(Box, { width: "100%", display: "flex", justifyContent: "end",}
          , React.createElement(Button, { style: { marginLeft: '1rem' }, onClick: handleImport, color: "primary", variant: "outlined",}, "Import table"

          )
        )
      )
      , React.createElement(TabsHeader, { tabs: tabs, actual: matchUrl, stickTop: 100,} )
      , React.createElement(Content, null
        , React.createElement(Outlet, null )
      )
    )
  );
};
