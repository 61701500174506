import { ApplicationFilter } from '../applicationFilter';


export class ApplicationFilterBuilder  {
   _names;
   _types;
   _ownerIds;
   _owners;
   _systemId;
   _templateId;

  build() {
    return new ApplicationFilter(this);
  }

  get names() {
    return this._names;
  }

  setNames(value) {
    this._names = value;
    return this;
  }

  get types() {
    return this._types;
  }

  setTypes(value) {
    this._types = value;
    return this;
  }

  get ownerIds() {
    return this._ownerIds;
  }

  setOwnerIds(value) {
    this._ownerIds = value;
    return this;
  }

  get owners() {
    return this._owners;
  }

  setOwners(value) {
    this._owners = value;
    return this;
  }

  get systemId() {
    return this._systemId;
  }

  setSystemId(value) {
    this._systemId = value;
    return this;
  }

  get templateId() {
    return this._templateId;
  }

  setTemplateId(value) {
    this._templateId = value;
    return this;
  }
}
