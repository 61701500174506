/* eslint-disable no-console */

import { createApp, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay } from '@backstage/core-components';
import { ApiExplorerPage } from '@ring/plugin-api-docs';
import { catalogPlugin } from '@ring/plugin-application-catalog';

import { ScaffolderPage, scaffolderPlugin } from '@ring/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { TechdocsPage } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import AlarmIcon from '@material-ui/icons/Alarm';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { Navigate, Route } from 'react-router';
import { apis } from './apis';
import { Root } from './components/Root';

import { searchPage } from './components/search/SearchPage';
import * as plugins from './plugins';

import { SdkCommitmentToolPage } from '@internal/plugin-sdk-commitment-tool';
import { SystemCatalogPage } from '@ring/plugin-system-catalog';
import { ObservabilityCatalogPage } from '@internal/plugin-data-catalog';
import { IdentityApiRing, SupportButton } from '@ring/plugin-common';
import { WorkforcePage } from '@ring/plugin-workforce';
import { ApplicationDetailPage, CatalogRouter } from '@ring/plugin-catalog';
import { ApplicationPage } from './components/catalog/ApplicationPage';
import { ServicePage } from './components/catalog/ServicePage';
import { ScaffoldervtwoPage } from '@ring/plugin-scaffoldervtwo';
import { DatabaseRouter } from '@ring/plugin-database';
import { themes } from './themeOverride';
import { InfraRouter } from '@internal/plugin-infrastructure';
import { Security, useOktaAuth } from '@okta/okta-react';
import { SignInPage } from './components/SignInPage';
import { LinearProgress } from '@material-ui/core';

const identityApi = new IdentityApiRing();
const oktaInstance = identityApi.getOktaInstance();

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  icons: {
    alert: AlarmIcon
  },
  themes,

  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root
    });
  }
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const ProtectedApp = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    // callback flow
    if (oktaAuth.isLoginRedirect()) {
      try {
        oktaAuth.handleLoginRedirect();

        return undefined;
      } catch (e) {
        console.log('Okta HandleLoginRedirect Error');
        return undefined;
      }
    }
    return undefined;
  }, [oktaAuth]);

  const [isLoginLoading, setIsLoginLoading] = useState(true);

  useEffect(() => {
    const key = setTimeout(() => {
      setIsLoginLoading(false);
    }, 2000);
    return () => {
      clearInterval(key);
    };
  }, []);

  if (isLoginLoading) return React.createElement(LinearProgress, null );

  return !authState?.isAuthenticated ? (
    React.createElement(SignInPage, null )
  ) : (
    React.createElement(AppProvider, null
      , React.createElement(AlertDisplay, null )
      , React.createElement(SupportButton, null )
      , React.createElement(AppRouter, null
        , React.createElement(Root, null
          , React.createElement(FlatRoutes, null
            , React.createElement(Navigate, { key: "/", to: "/catalog/application",} )

            , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogRouter, null ),} )
            , React.createElement(Route, { path: "/catalog/application/:name", element: React.createElement(ApplicationDetailPage, null ),}
              , React.createElement(ApplicationPage, null )
            )

            , React.createElement(Route, { path: "/catalog/service/:serviceId/api/:name",}
              , React.createElement(ServicePage, null )
            )

            , React.createElement(Route, { path: "/database", element: React.createElement(DatabaseRouter, null ),} )
            , React.createElement(Route, { path: "/infra", element: React.createElement(InfraRouter, null ),} )

            , React.createElement(Route, { path: "/docs", element: React.createElement(TechdocsPage, null ),} )
            , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
            , React.createElement(Route, { path: "/scaffolder", element: React.createElement(ScaffolderPage, null ),} )
            , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
            , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
              , searchPage
            )
            , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
            , React.createElement(Route, { path: "/sdk-tool", element: React.createElement(SdkCommitmentToolPage, null ),} )
            , React.createElement(Route, { path: "/systems", element: React.createElement(SystemCatalogPage, null ),} )
            , React.createElement(Route, { path: "/observability", element: React.createElement(ObservabilityCatalogPage, null ),} )
            , React.createElement(Route, { path: "/workforce", element: React.createElement(WorkforcePage, null ),} )
            , React.createElement(Route, { path: "/scaffoldervtwo", element: React.createElement(ScaffoldervtwoPage, null ),} )
          )
        )
      )
    )
  );
};

const App = () => {
  return (
    React.createElement(Security, { oktaAuth: oktaInstance, restoreOriginalUri: () => window.location.replace('/'),}
      , React.createElement(ProtectedApp, null )
    )
  );
};

export default hot(App);
