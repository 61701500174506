import React from 'react';
import { Tabs, withStyles } from '@material-ui/core';





export const CustomTabs = withStyles({
  scroller: {
    paddingBottom: '12px'
  },
  indicator: {
    height: '6px',
    backgroundColor: '#008CFF'
  }
})((props) => React.createElement(Tabs, { ...props,} ));
