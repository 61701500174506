import React, { memo, useState } from 'react';
import { Box, Card, CardContent, IconButton, Typography, useTheme, Tooltip } from '@material-ui/core';

import { Line } from 'react-chartjs-2';
import { DateTime } from 'luxon';
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartJsTooltip,
  Legend,
  TimeScale,



} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ErrorImg from '../Common/chart-assets/errorImg.png';

import { Content, InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { pickColor } from '../../utils/pickColor';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

ChartJS.register(CategoryScale, TimeScale, PointElement, LineElement, Title, ChartJsTooltip, Legend, zoomPlugin);












const timeUtcFromMidnight = (minutes, basedate) => {
  if (typeof minutes !== 'number') return '';
  return DateTime.fromISO(basedate, { zone: 'utc' }).plus({ minutes }).toFormat('dd/LLL/yy - hh:mm a');
};

const ErrorImage = new Image();
ErrorImage.src = ErrorImg;
ErrorImage.width = 20;
ErrorImage.height = 20;

const TablesDeliveriresChart = memo(({ data: deliveriesData }) => {
  const theme = useTheme();
  const [isZoomEnabled, setZoomEnabled] = useState(false);
  const { value: data = [], error, loading } = deliveriesData;
  const chartData = {
    datasets: data.map(({ database_name, table_name, setting_name, sla_in_minutes, data: tableData }, i) => ({
      label: `${database_name}.${table_name} - ${setting_name}`,
      data: tableData.map(({ datetime, runtime_in_minutes }) => ({
        datetime,
        database_name: database_name,
        table_name: table_name,
        setting_name: setting_name,
        runtime_in_minutes,
        eta: sla_in_minutes
      })),
      borderColor: pickColor(i),
      backgroundColor: pickColor(i)
    }))
  };

  const onZoom = () => {
    setZoomEnabled(zoom => !zoom);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: 'datetime',
      yAxisKey: 'runtime_in_minutes'
    },
    layout: {
      padding: { top: 40 }
    },
    elements: {
      point: {
        pointStyle: ctx => {
          if (!ctx.raw) return undefined;
          const raw = ctx.raw ;
          return raw.runtime_in_minutes < raw.eta ? 'circle' : ErrorImage;
        },
        backgroundColor: ctx => {
          if (!ctx.raw) return undefined;
          const raw = ctx.raw ;
          return raw.runtime_in_minutes < raw.eta ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 0, 0, 1)!important';
        },
        radius: ctx => {
          if (!ctx.raw) return undefined;
          const raw = ctx.raw ;
          return raw.runtime_in_minutes < raw.eta ? 4 : 5;
        }
      }
    },
    scales: {
      y: {
        display: false
      },
      x: {
        type: 'time',
        display: true,
        grid: {
          color: '#dddddd'
        },
        ticks: {
          color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
          autoSkip: false,
          maxRotation: 0,
          major: {
            enabled: true
          },
          font: context => {
            if (context.tick && context.tick.major) {
              return {
                weight: 'bold'
              } ;
            }
            return undefined;
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          pointStyle: 'circle',
          color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
          usePointStyle: true
        }
      },
      tooltip: {
        displayColors: true,
        callbacks: {
          label: context => {
            const raw = context.raw ;
            return [
              ` Table: ${raw.database_name}.${raw.table_name}`,
              ` Setting: ${raw.setting_name}`,
              ` Date: ${DateTime.fromISO(raw.datetime, { zone: 'utc' }).toFormat(' dd/LLL/yy')}`,
              ` ETA: ${timeUtcFromMidnight(raw.eta, raw.datetime)}`,
              ` Completed ${timeUtcFromMidnight(raw.runtime_in_minutes, raw.datetime)}`
            ];
          },
          afterLabel: () => ' ',
          beforeLabel: () => ' ',
          title: () => ''
        }
      },
      zoom: {
        pan: {
          enabled: true
        },
        zoom: {
          wheel: {
            enabled: isZoomEnabled
          },
          pinch: {
            enabled: true
          },
          mode: 'xy'
        }
      }
    }
  };

  if (error) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, error.toString())
      )
    );
  }

  if (loading) {
    return (
      React.createElement(InfoCard, { title: "Loading deliveries data chart"   ,}
        , React.createElement(Progress, null )
      )
    );
  }

  return (
    React.createElement(Card, null
      , React.createElement(CardContent, null
        , React.createElement(Box, { display: "flex", justifyContent: "space-between",}
          , React.createElement(Box, null
            , React.createElement(Typography, { variant: "h6",}, "Performance Overview" )
            , React.createElement(Typography, null, "Check here if the data meets the ETA. All time stamps are in UTC."             )
          )
          , React.createElement(IconButton, { color: isZoomEnabled ? 'secondary' : 'primary', onClick: onZoom,}
            , React.createElement(Tooltip, { title: isZoomEnabled ? 'Click to disable zoom' : 'Click to enable zoom', placement: "bottom-start",}
              , React.createElement(ZoomInIcon, null )
            )
          )
        )
        , React.createElement(Box, { height: "400px", overflow: "hidden",}
          , React.createElement(Line, { data: chartData, options: options,} )
        )
      )
    )
  );
});

export { TablesDeliveriresChart };
