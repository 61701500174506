
















import { useEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { apiDocsConfigRef } from '../../config';
import { PlainApiDefinitionWidget } from '../PlainApiDefinitionWidget';

import { CardTab, TabbedCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';






export const ApiDefinitionCard = (_) => {
  const { entity } = useEntity();
  const config = useApi(apiDocsConfigRef);
  const { getApiDefinitionWidget } = config;

  if (!entity) {
    return React.createElement(Alert, { severity: "error",}, "Could not fetch the API"    );
  }

  const definitionWidget = getApiDefinitionWidget(entity);

  if (definitionWidget) {
    return (
      React.createElement(TabbedCard, { title: entity.metadata.name,}
        , React.createElement(CardTab, { label: definitionWidget.title, key: "widget",}
          , definitionWidget.component(entity.spec.definition)
        )
        , React.createElement(CardTab, { label: "Raw", key: "raw",}
          , React.createElement(PlainApiDefinitionWidget, {
            definition: entity.spec.definition,
            language: definitionWidget.rawLanguage || entity.spec.type,}
          )
        )
      )
    );
  }

  return (
    React.createElement(TabbedCard, {
      title: entity.metadata.name,
      children: [
        // Has to be an array, otherwise typescript doesn't like that this has only a single child
        React.createElement(CardTab, { label: entity.spec.type, key: "raw",}
          , React.createElement(PlainApiDefinitionWidget, { definition: entity.spec.definition, language: entity.spec.type,} )
        )
      ],}
    )
  );
};
