import React, { useState } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { Table, Progress } from '@backstage/core-components';

import { useProvisioningApi } from '../../utils';











export const ModuleOutputsTable = ({ bundleName, application: app }) => {
  const [bundleOutputList, setBundleOutput] = useState([] );
  const [gotResponse, setGotResponse] = useState(false);
  const [madeRequest, setMadeRequest] = useState(false);
  const backendAPI = useProvisioningApi();
  const errorAPI = useApi(errorApiRef);
  const application = app.name;

  if (!madeRequest) {
    backendAPI
      .getApplicationOutputs(application, bundleName)
      .then((r) => {
        setBundleOutput(r);
        setGotResponse(true);
      })
      .catch(errorAPI.post);

    setMadeRequest(true);
  }

  if (!Array.isArray(bundleOutputList)) {
    return (
      React.createElement('h3', null
        , ' ', "No "
         , bundleName.toLowerCase(), " was found for "    , application, ". Try creating a new one."
      )
    );
  }

  if (!gotResponse) return React.createElement(Progress, null );

  const columns = new Array();

  bundleOutputList.forEach(bundleOutput => {
    if (bundleName === 'postgres-database') {
      const defaultOutputs = bundleOutput.outputs.filter(
        (output) => output.name.includes('connection_string') || !output.name.includes('vault_path')
      );
      bundleOutput.outputs = defaultOutputs;
    }

    // TODO: This should be implemented when we want to display the available images
    if (bundleName === 'docker-registry' && bundleOutput.taskKind === 'aws') {
      bundleOutput.outputs = [];
    }

    bundleOutput.outputs.forEach((output) => {
      const title = output.name.split('-')[0].toLowerCase();
      const column = columns.find(col => col.title === title);

      if (!column) {
        columns.push({
          title: title,
          field: output.name.split('-')[0],
          highlight: false
        });
      }
    });
  });

  const rows = new Array();
  bundleOutputList.forEach(bundleOutput => {
    bundleOutput.outputs.forEach((output) => {
      const row = {};

      const split = output.name.split('-');
      const nameSuffix = split.at(-1) || '';
      const rowData = bundleOutput.outputs.filter((out) => out.name.endsWith(nameSuffix));

      rowData.forEach((item) => {
        row.id = nameSuffix;
        const namePreffix = item.name.split('-')[0];
        row[namePreffix] = item.value;
      });

      const found = rows.find(r => {
        return r.id === nameSuffix;
      });

      if (!found) {
        rows.push(row);
      }
    });
  });

  return (
    React.createElement(Table, {
      isLoading: !gotResponse,
      columns: columns,
      data: rows,
      options: {
        paging: false,
        pageSize: rows.length,
        loadingType: 'linear',
        padding: 'dense',
        pageSizeOptions: [20, 50, 100]
      },
      title: `Outputs (${rows.length || 0})`,}
    )
  );
};
