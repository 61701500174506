import React, { useState } from 'react';
import { Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { CreateOutputTableFormik } from './CreateOutputTableFormik';

import { dataCatalogApiRef } from '../../dataCatalogApi';
import { InfoCard } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, } from '@material-ui/core/styles';

import { useSessionStorage } from 'react-use';
import { getOutputTableFormWithTags } from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

export const CreateOutputTablePage = ({ application }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const [dataEnvironment, _] = useSessionStorage('dataEnvironment', 'prod');

  const onSubmit = async (values) => {
    try {
      setOpen(true);

      const tableValues = getOutputTableFormWithTags(values);

      const partition_columns = !tableValues.partition_columns ? [] : tableValues.partition_columns.split(',');

      if (partition_columns) {
        partition_columns.forEach(function (partition, index, arr) {
          arr[index] = partition.trim();
        });
      }

      await dataCatalogApi.createOutputTable(application.name, dataEnvironment, {
        name: tableValues.name,
        database: application.name,
        owner: (application.owner ) ?? '',
        business_unit: application?.businessUnit ?? '',
        schema: tableValues.schema,
        partition_columns: partition_columns,
        description: tableValues.description,
        tags: tableValues.tags
      });
      navigate(-1);
    } catch (e) {
      setOpen(false);
      errorApi.post(e);
    }
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "New Output Table"  ,}
        , React.createElement(CreateOutputTableFormik, { onSubmit: onSubmit, initialValues: undefined,} )
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: open,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
