/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, } from 'react';
import { Badge, Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MemoryIcon from '@material-ui/icons/Memory';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ExtensionAssetIcon from '@material-ui/icons/Extension';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarContext,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  SidebarScrollWrapper
} from '@backstage/core-components';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { CopyTokenBtn } from './CopyTokenBtn';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useFeature } from '@ring/plugin-common';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24
  }
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, { component: NavLink, to: "/", underline: "none", className: classes.link,}
        , isOpen ? React.createElement(LogoFull, null ) : React.createElement(LogoIcon, null )
      )
    )
  );
};

export const Root = ({ children }) => {
  const { hasSeen, setFeatureView } = useFeature('global-data-plugin');
  return (
    React.createElement(SidebarPage, null
      , React.createElement(Sidebar, null
        , React.createElement(SidebarLogo, null )
        , React.createElement(SidebarDivider, null )
        /* Global nav, not org-specific */
        , React.createElement(SidebarItem, { icon: HomeIcon, to: "/catalog/application", text: "Home",} )
        , React.createElement(SidebarItem, { icon: MemoryIcon, to: "/catalog/system", text: "Systems",} )
        , React.createElement(SidebarItem, {
          icon: () => {
            return hasSeen ? (
              React.createElement(BarChartIcon, null )
            ) : (
              React.createElement(Badge, { color: "secondary", variant: "dot",}
                , React.createElement(BarChartIcon, { onClick: setFeatureView,} )
              )
            );
          },
          to: "/observability/global",
          text: "Data catalog" ,}
        )
        , React.createElement(SidebarItem, { icon: PermIdentityIcon, to: "/workforce", text: "Workforce",} )
        /* End global nav */
        , React.createElement(SidebarDivider, null )
        , React.createElement(SidebarScrollWrapper, null
          , React.createElement(SidebarItem, { icon: ExtensionAssetIcon, to: "sdk-tool", text: "SDK DevX Tool"  ,} )
        )
        , React.createElement(SidebarSpace, null )
        , React.createElement(SidebarDivider, null )
        , React.createElement(CopyTokenBtn, null )
        , React.createElement(SidebarSettings, null )
      )
      , children
    )
  );
};
