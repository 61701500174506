import { ENTITY_DEFAULT_NAMESPACE } from '@backstage/catalog-model';

export const getEditEntityPath = (entity) => {
  const pathByEntityKind = {
    Component: `/catalog/${entity.metadata.namespace || ENTITY_DEFAULT_NAMESPACE}/${entity.kind}/${
      entity.metadata.name
    }/edit`,
    System: `/systems/${entity?.metadata.name}/edit`
  };

  return pathByEntityKind[entity.kind];
};
