import React, { useEffect, useMemo, useState } from 'react';
import { StatusLabel } from './StatusLabel';
import { Link, Table, } from '@backstage/core-components';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Chip } from '@material-ui/core';
import { DatabaseStatus } from '../../types';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApplication } from '@ring/plugin-catalog';
import { EmptyBanner } from './EmptyBanner';
import { AlertDialog, useMixpanel } from '@ring/plugin-common';
import { databaseApiRef } from '../../api';







const cellStyle = { padding: '0.5rem 16px' };

export const DatabaseTable = ({ databases, loading, onRefresh }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [databaseToDelete, setDatabaseToDelete] = useState();
  const { application, loading: loadingApp } = useApplication();
  const { track } = useMixpanel();

  const databaseApi = useApi(databaseApiRef);
  const alertApi = useApi(alertApiRef);

  useEffect(() => {
    if (!loadingApp && !application) navigate('/catalog/application');
  }, [application, loadingApp, navigate]);

  const createNewUrl = `/database/${application?.id}/new`;

  const columns = [
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      render: (row) => {
        const shouldRender = ![DatabaseStatus.READY, DatabaseStatus.CREATING].includes(row?.status);
        return shouldRender ? (
          row.name
        ) : (
          React.createElement(Link, { to: `/database/${row.id}`, onClick: () => track('Database Details View', { database: row }),}
            , row.name
          )
        );
      },
      cellStyle
    },
    {
      title: 'Environment',
      field: 'environment',
      render: row => React.createElement(Chip, { label: row.meta.environment, style: { margin: 0 },} ),
      cellStyle
    },
    {
      title: 'Engine',
      render: () => React.createElement(Chip, { label: "Postgres", style: { margin: 0, backgroundColor: '#DBF2DA' },} ),
      cellStyle
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle,
      render: (rowData) => React.createElement(StatusLabel, { status: rowData.status,} )
    }
  ];

  const actions = useMemo(
    () => [
      row => {
        return {
          icon: () => React.createElement(EditIcon, { fontSize: "small",} ),
          tooltip: 'Edit',
          disabled: DatabaseStatus.READY === row?.status,
          onClick: () => {
            navigate(`/database/edit/${row.id}`);
          }
        };
      },
      row => {
        return {
          icon: () => React.createElement(DeleteIcon, { fontSize: "small",} ),
          tooltip: 'Delete',
          disabled: DatabaseStatus.READY === row?.status,
          onClick: _ => {
            setDatabaseToDelete(row.id);
            setOpen(true);
          }
        };
      }
    ],
    [navigate]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await databaseApi.deleteDatabase(databaseToDelete);
      setOpen(false);
      onRefresh();
    } catch (error) {
      alertApi.post({
        severity: 'error',
        message: 'There was an error during the database deletion process'
      });
    }
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Table, {
        isLoading: loading,
        title: 
          React.createElement(Box, { flexDirection: "row", display: "flex", alignItems: "center",}
            , React.createElement(Box, { whiteSpace: "nowrap",}, "Databases")
            , React.createElement(Box, { ml: 3,}
              , React.createElement(Button, { component: RouterLink, color: "primary", to: createNewUrl, startIcon: React.createElement(AddIcon, null ),}, "Create Database"

              )
            )
          )
        ,
        columns: columns,
        data: databases,
        actions: actions,
        localization: {
          body: {
            emptyDataSourceMessage: React.createElement(EmptyBanner, { loading: loading, url: createNewUrl,} )
          }
        },
        options: {
          paging: false,
          thirdSortClick: false,
          actionsColumnIndex: -1,
          actionsCellStyle: {
            minWidth: '110px',
            display: 'block'
          }
        },}
      )
      , React.createElement(AlertDialog, {
        open: open,
        title: "You're about to delete your database"     ,
        message: "This action will create a ticket to our team and we'll delete the database entirely.\nDoing this might heavily impact your application. Do you want to proceed?"
           ,
        onClose: handleDelete,
        onConfirm: handleClose,
        cancelButtonLeyend: "delete",
        confirmButtonLeyend: "Keep Database" ,}
      )
    )
  );
};
