import { Progress } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-api';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useAsync } from 'react-use';

import { ResourceCreation } from '../ResourceCreation/ResourceCreation';
import { serviceApiRef } from '@ring/plugin-common';

import { useProvisioningApi } from '../../utils';

export const ServiceCreation = ({ service, project }) => {
  const backendAPI = useProvisioningApi();
  const serviceAPI = useApi(serviceApiRef);
  const errorAPI = useApi(errorApiRef);
  const [token, setToken] = useState('');

  const {
    value: createServiceResponse,
    loading,
    error
  } = useAsync(async () => {
    setToken((await backendAPI.getToken()) || '');
    return serviceAPI.create(service, { overrideToken: token || '' });
  }, [serviceAPI]);

  if (error) {
    errorAPI.post(error);
    return React.createElement(Alert, { severity: "error",}, "\"Error creating RING service\""   );
  }

  if (loading) {
    return React.createElement(Progress, null );
  }

  return React.createElement(ResourceCreation, { project: project, response: createServiceResponse, token: token,} );
};
