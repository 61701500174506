

import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CLIENT_ID, OKTA_ISSUER } from './config';

export class IdentityApiRing  {
    oktaLocalStorageKey = 'okta-token-storage';
   static oktaInstance;
   oktaLocalStorageString;
   userInfo;

  constructor() {
    this.oktaLocalStorageString = localStorage.getItem(this.oktaLocalStorageKey);
    this.userInfo = this.oktaLocalStorageString ? JSON.parse(this.oktaLocalStorageString) : null;
  }

   getUserId() {
    if (!this.userInfo) throw new Error(`couldn't found ${this.oktaLocalStorageKey} in local storage`);

    return this.userInfo.idToken.claims.email.split('@')[0];
  }

  /**
   *
   * The profile of the signed in user.
   */
   getProfile() {
    if (!this.userInfo) throw new Error(`couldn't found ${this.oktaLocalStorageKey} in local storage`);

    const { email, name } = this.userInfo.idToken.claims;

    return {
      email: email,
      displayName: name,
      picture: '' // TODO: grab user photo from somewhere in the future ask @federico.pezzola@wildlifestudios.com
    };
  }

  /**
   * An OpenID Connect ID Token which proves the identity of the signed in user.
   *
   * The ID token will be undefined if the signed in user does not have a verified
   * identity, such as a demo user or mocked user for e2e tests.
   */
   async getIdToken() {
    return Promise.resolve(this.getOktaInstance().getAccessToken());
  }
  /**
   * Sign out the current user
   */
   async signOut() {
    return Promise.resolve(this.getOktaInstance().signOut());
  }

   getOktaInstance() {
    if (!IdentityApiRing.oktaInstance) {
      IdentityApiRing.oktaInstance = new OktaAuth({
        issuer: OKTA_ISSUER,
        clientId: OKTA_CLIENT_ID,
        redirectUri: window.location.origin,
        services: {
          autoRenew: true
        }
      });
    }

    return IdentityApiRing.oktaInstance;
  }
}
