import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  TextField,
  Typography,
  Link,
  makeStyles,
  Button,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useDataFilters } from '../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';

import { alertApiRef, useApi } from '@backstage/core-plugin-api';



const useStyles = makeStyles(() => ({
  infoLink: {
    userSelect: 'none',
    cursor: 'pointer'
  }
}));

export const DataFilters = ({}) => {
  const classes = useStyles();
  const newFilterRef = useRef(null);
  const alertApi = useApi(alertApiRef);
  const [addNewCondition, setAddNewCondition] = useState(false);
  const [options, setOptions] = useState([]);

  const { getValues, control, setValue } = useFormContext();
  const { filterListStatus, addFilterFn } = useDataFilters(getValues('databaseName'), getValues('tableName'));

  const { value: filters, loading } = filterListStatus;
  const [{ value: newAddedFilter, loading: addingNewFilter, error }, addNew] = addFilterFn;

  const handleAdd = async () => {
    const newValue = newFilterRef?.current?.value;
    if (!newValue || newValue === '') return;
    await addNew({
      name: newValue,
      data_filter_statement: newValue
    } );
  };

  useEffect(() => {
    if (error) {
      alertApi.post({
        severity: 'error',
        message: error.message || 'Invalid expression'
      });
    }
  }, [alertApi, error]);

  useEffect(() => {
    if (newAddedFilter) {
      const previusValues = getValues('dataFilters');
      setValue('dataFilters', [...previusValues, newAddedFilter.name]);
      setOptions(opts => [...opts, newAddedFilter.name]);
      alertApi.post({
        severity: 'success',
        message: 'Your filter was saved and added'
      });
      if (newFilterRef.current?.value) newFilterRef.current.value = '';
      setAddNewCondition(false);
    }
  }, [alertApi, getValues, newAddedFilter, setValue]);

  useEffect(() => {
    if (filters) setOptions(filters.map(f => f?.name));
  }, [filters]);

  return (
    React.createElement(Box, { py: "2rem", display: "flex", flexDirection: "column", style: { gap: '8px' },}
      , React.createElement(Typography, { style: { fontWeight: 'bolder', marginBottom: '1rem' },}, "Data filters" )
      , React.createElement(Box, { display: "flex", alignItems: "center", style: { gap: '1rem', maxWidth: '72ch' },}
        , React.createElement(InfoOutlinedIcon, { color: "primary", fontSize: "medium",} )
        , React.createElement(Typography, { variant: "body2",}, "Data filters allow you to use any ”where” statement to filter the data that should be checked in your ETA and Data Quality validations."


        )
      )
      , React.createElement(Controller, {
        control: control,
        name: "dataFilters",
        render: ({ field: { onChange, value } }) => (
          React.createElement(Autocomplete, {
            loading: loading,
            multiple: true,
            placeholder: "Saved filters" ,
            options: options,
            value: value,
            onChange: (_, v) => {
              onChange(v);
            },
            renderTags: (v, getTagProps) =>
              v.map((option, index) => (
                React.createElement(Chip, {
                  size: "small",
                  variant: "outlined",
                  style: { marginBottom: '5px' },
                  label: option,
                  ...getTagProps({ index }),}
                )
              ))
            ,
            size: "small",
            renderInput: params => (
              React.createElement(TextField, {
                ...params,
                placeholder: "Saved filters" ,
                helperText: 
                  React.createElement(React.Fragment, null, "Can't find what you need? Create a"
                          , ' '
                    , React.createElement(Link, {
                      className: classes.infoLink,
                      onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAddNewCondition(true);
                      },}
                    , "new condition"

                    )
                  )
                ,}
              )
            ),}
          )
        ),}
      )
      , addNewCondition && (
        React.createElement(Box, { display: "flex", flexDirection: "column",}
          , React.createElement('p', { style: { fontWeight: 'bolder', marginBottom: 0 },}, "New condition" )
          , React.createElement(TextField, {
            InputProps: {
              endAdornment: (
                React.createElement(InputAdornment, { position: "end",}, addingNewFilter && React.createElement(CircularProgress, { size: 16,} ))
              )
            },
            disabled: addingNewFilter,
            inputRef: newFilterRef,
            margin: "dense",
            variant: "outlined",
            fullWidth: true,}
          )
          , React.createElement(Button, {
            disabled: addingNewFilter,
            style: { alignSelf: 'flex-end' },
            onClick: handleAdd,
            color: "primary",
            startIcon: React.createElement(AddIcon, null ),}
          , "Use"

          )
        )
      )
    )
  );
};
