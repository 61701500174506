/* eslint-disable react-hooks/rules-of-hooks */
import { dataWorkflowApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';


export const useAdvancedDeploy = (applicationID) => {
  const dataWorkflowApi = useApi(dataWorkflowApiRef);
  const { value } = useAsync(() => dataWorkflowApi.getDeployMode(applicationID), [dataWorkflowApi, applicationID]);

  return { advanced_deploy: value };
};

export function get_deploy_data(_advanced_deploy) {
  return _advanced_deploy.advanced_deploy !== undefined;
}
