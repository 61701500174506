import { formatString } from '@ring/utils';

export class RequestErrorCodes {
   static UNEXPECTED_EXCEPTION = new RequestErrorCodes(
    0,
    'the request could not be completed',
    false
  );

   static MISSING_PARAMETER = new RequestErrorCodes(1, 'the parameter {0} is missing', true);

   static EMPTY_FILTER = new RequestErrorCodes(2, 'the filter may not be empty', false);

   static INVALID_PAGEABLE = new RequestErrorCodes(
    3,
    "both 'page' and 'size' query parameters are required",
    false
  );

   static GENERIC = new RequestErrorCodes(4, '{0}', true);

   static NOT_FOUND = new RequestErrorCodes(5, 'the {0} ({1}: {2}) was not found', true);

   static PARAMETER_MISMATCH = new RequestErrorCodes(
    6,
    `the body {0}} does not match the {0} provided in the uri`,
    true
  );

    _code;

    _message;

    _hasParameters;

   constructor(code, message, hasParameters) {
    this._code = code;
    this._message = message;
    this._hasParameters = hasParameters;
  }

   format(...val) {
    let formattedMessage;

    if (!this._hasParameters) {
      if (val && val.length !== 0) throw new Error('the message does not have any parameters');

      formattedMessage = this._message;
    } else if (this._hasParameters) {
      if (val.length === 0) throw new Error('the message require parameters');

      formattedMessage = formatString(this._message, ...val);
    }

    return {
      code: this._code,
      message: formattedMessage
    };
  }
}
