import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';






const useStyles = makeStyles({
  input: {
    minWidth: '100px',
    maxWidth: '200px',
    wordSpacing: '5px'
  },
  label: {
    textTransform: 'uppercase'
  }
});

export const CronExpressionInput = ({ cronExpInput, setCronExpInput }) => {
  const classes = useStyles();
  const [cronInput, setCronInput] = useState(cronExpInput);
  useEffect(() => {
    setCronInput(cronExpInput);
  }, [cronExpInput]);
  const [_] = useDebounce(
    () => {
      setCronExpInput(cronInput);
    },
    500,
    [cronInput]
  );
  return (
    React.createElement(TextField, {
      variant: "outlined",
      value: cronInput,
      onChange: event => {
        setCronInput(event.target.value);
      },
      label: "Cron Expression" ,
      margin: "dense",
      InputProps: {
        classes: {
          input: classes.input
        }
      },}
    )
  );
};
