

















import { ResponseError } from '@backstage/errors';


import ObservableImpl from 'zen-observable';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { createApiRef, } from '@backstage/core-plugin-api';

export const scaffolderApiRef = createApiRef({
  id: 'plugin.scaffolder.service',
  description: 'Used to make requests towards the scaffolder backend'
});

















































export class ScaffolderClient  {
    discoveryApi;
    identityApi;
    scmIntegrationsApi;

  constructor(options



) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
    this.scmIntegrationsApi = options.scmIntegrationsApi;
  }

  async getIntegrationsList(options

) {
    return [
      ...this.scmIntegrationsApi.azure.list(),
      ...this.scmIntegrationsApi.bitbucket.list(),
      ...this.scmIntegrationsApi.github.list(),
      ...this.scmIntegrationsApi.gitlab.list()
    ]
      .map(c => ({ type: c.type, title: c.title, host: c.config.host }))
      .filter(c => options.allowedHosts.includes(c.host));
  }

  async getTemplateParameterSchema(templateName) {
    const { namespace, kind, name } = templateName;

    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const templatePath = [namespace, kind, name].map(s => encodeURIComponent(s)).join('/');
    const url = `${baseUrl}/v2/templates/${templatePath}/parameter-schema`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    const schema = await response.json();
    return schema;
  }

  /**
   * Executes the scaffolding of a component, given a template and its
   * parameter values.
   *
   * @param templateName Template name for the scaffolder to use. New project is going to be created out of this template.
   * @param values Parameters for the template, e.g. name, description
   */
  async scaffold(templateName, values) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('scaffolder')}/v2/tasks`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify({ templateName, values: { ...values } })
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const { id } = (await response.json()) ;
    return id;
  }

  async getTask(taskId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const url = `${baseUrl}/v2/tasks/${encodeURIComponent(taskId)}`;
    const response = await fetch(url, {
      headers: token ? { Authorization: `Bearer ${token}` } : {}
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async streamLogs({ taskId }) {
    const token = await this.identityApi.getIdToken();

    return new ObservableImpl(subscriber => {
      this.discoveryApi.getBaseUrl('scaffolder').then(
        baseUrl => {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(taskId)}/eventstream`;

          const eventSource = new EventSourcePolyfill(url, {
            withCredentials: true,
            headers: token ? { Authorization: `Bearer ${token}` } : {}
          });

          eventSource.addEventListener('log', (event) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
          });

          eventSource.addEventListener('completion', (event) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
            eventSource.close();
            subscriber.complete();
          });
          eventSource.addEventListener('error', (event) => {
            subscriber.error(event);
          });
        },
        error => {
          subscriber.error(error);
        }
      );
    });
  }

  /**
   * @returns ListActionsResponse containing all registered actions.
   */
  async listActions() {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const token = await this.identityApi.getIdToken();
    const response = await fetch(`${baseUrl}/v2/actions`, {
      headers: token ? { Authorization: `Bearer ${token}` } : {}
    });

    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
