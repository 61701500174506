import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, FormLabel, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { PARTITION_COLUMNS_FORM_KEY, QueryResolverIntervalTimeOptions, } from '../types';
import { CustomSwitch } from '@ring/plugin-common';
import { PatternFormat } from 'react-number-format';

export const EtaConfig = () => {
  const {
    formState: { errors },
    control
  } = useFormContext();

  /*  eta field was execution_delta before */

  const resolver = useWatch({ control, name: 'sla.resolver' }) ;
  const partition_columns = useWatch({ control, name: PARTITION_COLUMNS_FORM_KEY });

  const isAirflowTask = resolver === 'airflow_task_sensor';
  const hasPartitionColumns = partition_columns.length > 0;

  return (
    React.createElement(Box, { pt: "2rem", display: "flex", flexDirection: "column", style: { gap: '1rem' },}
      , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '1rem' },}
        , React.createElement(FormLabel, null, "How long does it take for the table to finish running?"          )
        , React.createElement(Controller, {
          control: control,
          name: "sla.eta",
          render: ({ field: { value, onChange, ...rest } }) => (
            React.createElement(PatternFormat, {
              valueIsNumericString: true,
              value: `${String(value?.hours).length > 1 ? value?.hours : `0${value?.hours}`}${
                String(value?.minutes).length > 1 ? value?.minutes : `0${value?.minutes}`
              }`,
              defaultValue: "0000",
              onValueChange: values => {
                if (!values?.formattedValue) return 0;
                if (values?.formattedValue?.includes('_')) return 0;
                const [hours = 0, minutes = 0] = values.formattedValue.split(':');
                return onChange({ hours: Number(hours), minutes: Number(minutes) });
              },
              error: !!errors?.sla?.eta,
              helperText: 
                (errors?.sla?.eta?.minutes?.message || errors?.sla?.eta?.hours?.message) ?? 'Use ( HH:mm ) format'
              ,
              format: "##:##",
              variant: "outlined",
              size: "small",
              mask: "_",
              customInput: TextField,
              ...rest,}
            )
          ),}
        )
      )
      , !isAirflowTask && hasPartitionColumns && (
        React.createElement(Tooltip, { title: "You are seeing this section because you selected to use Datetime columns to filter your data. Please, select the period of data to perform the ETA checks"                          ,}
          , React.createElement(Box, { display: "flex", flexDirection: "row", style: { gap: '1rem' },}
            , React.createElement(Box, { width: "80%",}
              , React.createElement(FormLabel, null, "ETA data interval"  )
              , React.createElement(Controller, {
                control: control,
                name: "sla.sla_settings.interval_time",
                render: ({ field }) => (
                  React.createElement(TextField, { fullWidth: true, select: true, ...field,}
                    , Object.values(QueryResolverIntervalTimeOptions).map((o, index) => (
                      React.createElement(MenuItem, { key: o + index, value: o,}
                        , o
                      )
                    ))
                  )
                ),}
              )
            )
            , React.createElement(Box, null
              , React.createElement(FormLabel, null, "Period")
              , React.createElement(Controller, {
                control: control,
                name: "sla.sla_settings.interval_value",
                render: ({ field }) => (
                  React.createElement(TextField, {
                    ...field,
                    fullWidth: true,
                    inputProps: { max: -1 },
                    type: "number",
                    error: !!errors?.sla?.sla_settings?.interval_value,
                    helperText: errors?.sla?.sla_settings?.interval_value?.message,}
                  )
                ),}
              )
            )
          )
        )
      )
      , React.createElement(Box, { pt: "1rem", display: "flex", flexDirection: "column", style: { gap: '1rem' },}
        , React.createElement(Typography, { style: { fontWeight: 'bolder' },}, "ETA notifications" )
        , React.createElement(Box, { display: "flex", style: { gap: '1rem' }, alignItems: "center",}
          , React.createElement(Box, { display: "flex", style: { gap: '1rem' }, alignItems: "center",}
            , React.createElement(Typography, null, "Slack notification" )
            , React.createElement(Controller, {
              name: "sla.slack_enabled",
              control: control,
              render: ({ field }) => (
                React.createElement(CustomSwitch, {
                  onChange: () => {
                    field.onChange(!field.value);
                  },
                  checked: field.value,}
                )
              ),}
            )
          )
          , React.createElement(Box, { display: "flex", style: { gap: '1rem' }, alignItems: "center",}
            , React.createElement(Typography, null, "OpsGenie Notification" )
            , React.createElement(Controller, {
              name: "sla.opsgenie_enabled",
              control: control,
              render: ({ field }) => (
                React.createElement(CustomSwitch, {
                  onChange: () => {
                    field.onChange(!field.value);
                  },
                  checked: field.value,}
                )
              ),}
            )
          )
        )
      )
    )
  );
};
