import {
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createRoutableExtension,
  createRouteRef
} from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { dataCatalogApiRef, DataCatalogClient } from './dataCatalogApi';
import { dataObservabilityApiRef, DataObservabilityClient } from './dataObservabilityApi';

import { rootRouteRef } from './routes';

export const dataCatalogPlugin = createPlugin({
  id: 'data-catalog',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: dataCatalogApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) =>
        new DataCatalogClient({ discoveryApi, identityApi: identityApiRing })
    }),
    createApiFactory({
      api: dataObservabilityApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) =>
        new DataObservabilityClient({ discoveryApi, identityApi: identityApiRing })
    })
  ]
});

export const dataCatalogRouteRef = createRouteRef({
  path: 'observability',
  title: 'obsrvability Catalog'
});

export const ObservabilityCatalogPage = dataCatalogPlugin.provide(
  createRoutableExtension({
    component: () => import('./Router').then(m => m.Router),
    mountPoint: dataCatalogRouteRef
  })
);
