import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useCoreBusinessUnits, useSquads } from '@ring/plugin-common';
import { Autocomplete } from '@material-ui/lab';

export const OwnershipSettings = () => {
  const { value: businessUnits = [], loading: loadingBusinessUnits } = useCoreBusinessUnits();
  const { squads, loading: loadingSquads } = useSquads();
  const { control, formState } = useFormContext();

  const { errors } = formState;

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: 8 },}
      , React.createElement(Controller, {
        control: control,
        name: "ownership.name",
        defaultValue: "",
        render: ({ field }) => (
          React.createElement(TextField, {
            label: "Setting Name" ,
            ...field,
            FormHelperTextProps: {
              error: errors?.ownership?.name?.message
            },
            required: true,
            helperText: 
              errors?.ownership?.name?.message
                ? errors?.ownership?.name?.message
                : 'This name will identify your configuration later on.'
            ,
            fullWidth: true,}
          )
        ),}
      )
      , React.createElement(Controller, {
        control: control,
        name: "ownership.org_squad",
        render: props => (
          React.createElement(Autocomplete, {
            options: squads.map(s => s.name),
            loading: loadingSquads,
            value: props.field.value ?? '',
            onChange: (_, value) => {
              props.field.onChange(value);
            },
            renderInput: params => (
              React.createElement(TextField, {
                error: errors?.ownership?.org_squad?.message,
                helperText: errors?.ownership?.org_squad?.message,
                required: true,
                ...params,
                label: "Owner",}
              )
            ),}
          )
        ),}
      )
      , React.createElement(Controller, {
        control: control,
        name: "ownership.business_unit",
        render: props => (
          React.createElement(Autocomplete, {
            loading: loadingBusinessUnits,
            options: businessUnits.map(b => b.name),
            value: props.field.value ?? '',
            onChange: (_, value) => {
              props.field.onChange(value);
            },
            renderInput: params => (
              React.createElement(TextField, {
                required: true,
                ...params,
                error: errors?.ownership?.business_unit?.message,
                helperText: errors?.ownership?.business_unit?.message,
                label: "Business Unit" ,}
              )
            ),}
          )
        ),}
      )
    )
  );
};
