

export function serializeFilter(filter) {
  if (!filter || filter.isEmpty()) return '';

  const stringArr = [];

  if (filter?.name) stringArr.push(`name=${filter.name}`);

  if (!stringArr.length) return '';

  return stringArr.join(',');
}
