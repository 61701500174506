import { Box, Chip, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { getHumanizeBoolean } from '../../helpers';
import { COLUMN_VALIDATIONS_KEY, } from '../../types';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';

export const ColCountDistinctValuesToBeBetween = ({
  fieldIndex,
  tableColumns,
  isSummaryVersionActive
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = useCallback(
    (field) => {
      return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
    },
    [errors, fieldIndex]
  );

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Grid, { container: true, spacing: 4,}
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(Controller, {
            name: `${fieldPath}.columns` ,
            render: ({ field: { onChange, value, ...fieldProps } }) => (
              React.createElement(Autocomplete, {
                ...fieldProps,
                multiple: true,
                freeSolo: true,
                options: tableColumns,
                value: value ?? [],
                disableClearable: true,
                onChange: (_, data) => onChange(data),
                renderTags: (v, getTagProps) =>
                  v.map((option, index) => (
                    React.createElement(Chip, {
                      key: option + index,
                      size: "small",
                      variant: "outlined",
                      label: option,
                      ...getTagProps({ index }),}
                    )
                  ))
                ,
                renderInput: params => (
                  React.createElement(TextField, {
                    ...params,
                    InputLabelProps: { shrink: true },
                    placeholder: "Columns",
                    helperText: getErrorMessageByField('columns') ?? 'You can select multiple columns',
                    error: !!getErrorMessageByField('columns'),}
                  )
                ),}
              )
            ),
            control: control,}
          )
        )
        , React.createElement(Grid, { item: true, sm: 3,}
          , React.createElement(TextField, {
            label: "Minimum",
            size: "small",
            variant: "outlined",
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.min_value` ),
            helperText: getErrorMessageByField('min_value'),
            error: !!getErrorMessageByField('min_value'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 3,}
          , React.createElement(TextField, {
            label: "Maximum",
            size: "small",
            variant: "outlined",
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.max_value` ),
            helperText: getErrorMessageByField('max_value'),
            error: !!getErrorMessageByField('max_value'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
        )
      )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();

  const { columns, min_value, max_value, slack_enabled, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Typography, { style: { gridColumnStart: 'span 2' }, variant: "body2",}
        , React.createElement('strong', null, "Columns:"), " " , columns?.join(', ')
      )
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Min Value:" ), " " , min_value
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Max Value:" ), " " , max_value
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
