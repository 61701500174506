

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeServiceFilter } from '@ring/clients';

export class ServiceApiClient extends BaseClient {
  constructor(discovery, identityApi) {
    super(getPluginBaseUrl('services', discovery), serializeServiceFilter, getToken(identityApi));
  }
}
