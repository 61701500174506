
import { attachComponentData, useElementFilter } from '@backstage/core-plugin-api';
import { useApplication } from '../../context/applicationContext';


const SWITCH_KEY = 'core.ring.applicationEntitySwitch';

const ApplicationEntitySwitchCase = (_) => null;

attachComponentData(ApplicationEntitySwitchCase, SWITCH_KEY, true);






export const ApplicationEntitySwitch = ({ children }) => {
  const { application } = useApplication();

  const switchCases = useElementFilter(
    children,
    collection =>
      collection
        .selectByComponentData({
          key: SWITCH_KEY,
          withStrictError: 'Child of EntitySwitch is not an EntitySwitch.Case'
        })
        .getElements()
        .flatMap((element) => {
          const { if: condition, children: elementsChildren } = element.props;
          return [{ if: condition, children: elementsChildren }];
        }),
    [application]
  );

  // Tipar esto esto bien
  const matchingCase =
    application !== undefined && switchCases.find(switchCase => (switchCase.if ? switchCase.if(application) : true)); //  TODO esto no debe ir acá incializar el hook

  return matchingCase ? matchingCase.children : null;
};

ApplicationEntitySwitch.Case = ApplicationEntitySwitchCase;
