import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DataCatalogOverviewPage } from '../OverviewPage';
import { CreateOutputTablePage } from '../CreateOutputTablePage';
import { AttachInputTablePage } from '../AttachInputTablePage';
import { AttachOutputTablePage } from '../AttachOutputTablePage';
import { EditOutputTablePage } from '../EditOutputTablePage';
import { Content, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { useApplication } from '@ring/plugin-catalog';

export const EntityDataCatalogContent = () => {
  const { application, loading, error } = useApplication();

  if (!application) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error missed entity\""  )
      )
    );
  }
  return (
    React.createElement(Routes, null
      , loading && React.createElement(Progress, null )
      , error && (
        React.createElement(Content, null
          , React.createElement(Alert, { severity: "error",}, error.toString())
        )
      )
      , React.createElement(Route, { path: "/", element: React.createElement(DataCatalogOverviewPage, { application: application,} ),} )
      , React.createElement(Route, { path: "/output/create", element: React.createElement(CreateOutputTablePage, { application: application,} ),} )
      , React.createElement(Route, { path: "/attach-input", element: React.createElement(AttachInputTablePage, { application: application,} ),} )
      , React.createElement(Route, { path: "/attach-output", element: React.createElement(AttachOutputTablePage, { application: application,} ),} )
      , React.createElement(Route, { path: "/output/:tableName/edit", element: React.createElement(EditOutputTablePage, { application: application,} ),} )
    )
  );
};
