import { Box, Chip, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { getHumanizeBoolean } from '../../helpers';
import { COLUMN_VALIDATIONS_KEY, } from '../../types';
import { NotificationEnabledInput } from '../NotificationEnabledInput';
import { ViewDetailsAccordion } from '../ViewDetailsAccordion';

export const ColValuesToBeUnique = ({
  fieldIndex,
  tableColumns,
  isSummaryVersionActive
}) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const fieldPath = `${COLUMN_VALIDATIONS_KEY}.${fieldIndex}`;

  const getErrorMessageByField = (field) => {
    return errors?.[COLUMN_VALIDATIONS_KEY]?.[fieldIndex]?.[field]?.message;
  };

  if (isSummaryVersionActive) return React.createElement(SummaryVersion, { fieldPath: fieldPath,} );

  return (
    React.createElement(Box, { display: "flex", flexDirection: "column",}
      , React.createElement(Grid, { container: true, spacing: 4,}
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(Controller, {
            name: `${fieldPath}.columns` ,
            render: ({ field: { onChange, value, ...fieldProps } }) => (
              React.createElement(Autocomplete, {
                ...fieldProps,
                multiple: true,
                freeSolo: true,
                options: tableColumns,
                disableClearable: true,
                value: value ?? [],
                onChange: (_, data) => onChange(data),
                renderTags: (v, getTagProps) =>
                  v.map((option, index) => (
                    React.createElement(Chip, {
                      key: option + index,
                      size: "small",
                      variant: "outlined",
                      label: option,
                      ...getTagProps({ index }),}
                    )
                  ))
                ,
                renderInput: params => (
                  React.createElement(TextField, {
                    ...params,
                    InputLabelProps: { shrink: true },
                    placeholder: "Columns",
                    helperText: getErrorMessageByField('columns') ?? 'You can select multiple columns',
                    error: !!getErrorMessageByField('columns'),}
                  )
                ),
                ...fieldProps,}
              )
            ),
            control: control,}
          )
        )
        , React.createElement(Grid, { item: true, sm: 4,}
          , React.createElement(TextField, {
            variant: "outlined",
            size: "small",
            label: "Expected success rate"  ,
            required: true,
            type: "number",
            fullWidth: true,
            ...register(`${fieldPath}.mostly` ),
            helperText: getErrorMessageByField('mostly'),
            error: !!getErrorMessageByField('mostly'),}
          )
        )
        , React.createElement(Grid, { item: true, sm: 12,}
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.slack_enabled`, label: "Slack notification" ,} )
          , React.createElement(NotificationEnabledInput, { fieldPath: `${fieldPath}.opsgenie_enabled`, label: "OpsGenie Notification" ,} )
        )
      )
    )
  );
};

function SummaryVersion({ fieldPath }) {
  const { getValues } = useFormContext();

  const { columns, mostly, slack_enabled, opsgenie_enabled } = getValues(fieldPath);

  return (
    React.createElement(Box, { display: "grid", gridGap: "1rem", gridTemplateColumns: "1fr 2fr" ,}
      , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: 'span 2' },}
        , React.createElement('strong', null, "Column:"), " " , columns?.join(', ')
      )
      , React.createElement(ViewDetailsAccordion, null
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Expected success rate:"  ), " " , mostly
        )
        , React.createElement(Typography, { variant: "body2", style: { gridColumnStart: '1' },}
          , React.createElement('strong', null, "Slack:"), " " , getHumanizeBoolean(slack_enabled)
        )
        , React.createElement(Typography, { variant: "body2",}
          , React.createElement('strong', null, "Ops Genie:" ), " " , getHumanizeBoolean(opsgenie_enabled)
        )
      )
    )
  );
}
