


export class ApplicationFilter  {
    _names;
    _types;
    _ownerIds;
    _owners;
    _systemId;
    _templateId;

   constructor(builder) {
    this._names = builder.names;
    this._types = builder.types;
    this._ownerIds = builder.ownerIds;
    this._owners = builder.owners;
    this._systemId = builder.systemId;
    this._templateId = builder.templateId;
  }

  isEmpty() {
    return (
      (!this._names || !this._names?.length) &&
      (!this._types || !this._types.length) &&
      (!this._ownerIds || !this._ownerIds.length) &&
      (!this._owners || !this._owners?.length) &&
      !this._systemId &&
      !this._templateId
    );
  }

   get names() {
    return this._names;
  }

   get types() {
    return this._types;
  }

  get ownerIds() {
    return this._ownerIds;
  }

  get owners() {
    return this._owners;
  }

  get systemId() {
    return this._systemId;
  }

  get templateId() {
    return this._templateId;
  }
}
