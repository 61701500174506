import { createApiFactory, createPlugin, discoveryApiRef } from '@backstage/core-plugin-api';
import { CommonApi, commonApiRef, coreApiRef, RingCoreApi } from './api';
import {
  userApiRef,
  serviceApiRef,
  verticalApiRef,
  squadApiRef,
  systemApiRef,
  applicationApiRef,
  mixpanelApiRef,
  businessUnitApiRef,
  identityApiRingRef
} from './apis';
import { rootRouteRef } from './routes';
import {
  UserApiClient,
  ServiceApiClient,
  VerticalApiClient,
  SquadApiClient,
  ApplicationApiClient,
  SystemApiClient,
  MixpanelApiClient,
  BusinessUnitApiClient
} from './lib/clients';
import { IdentityApiRing } from './lib';

export const commonPlugin = createPlugin({
  id: 'common',
  routes: {
    root: rootRouteRef
  },
  register({ featureFlags }) {
    featureFlags.register('enable-session-btn');
  },
  apis: [
    createApiFactory({
      api: identityApiRingRef,
      deps: {},
      factory: () => new IdentityApiRing()
    }),
    createApiFactory({
      api: commonApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new CommonApi({ identityApi: identityApiRing, discoveryApi })
    }),
    createApiFactory({
      api: coreApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new RingCoreApi({ identityApi: identityApiRing, discoveryApi })
    }),
    createApiFactory({
      api: userApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new UserApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: serviceApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new ServiceApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: verticalApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new VerticalApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: squadApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new SquadApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: systemApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new SystemApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: applicationApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new ApplicationApiClient(discoveryApi, identityApiRing)
    }),
    createApiFactory({
      api: mixpanelApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new MixpanelApiClient(identityApiRing, discoveryApi)
    }),
    createApiFactory({
      api: businessUnitApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ identityApiRing, discoveryApi }) => new BusinessUnitApiClient(identityApiRing, discoveryApi)
    })
  ]
});
