import { isNull } from 'lodash';








import {
  COLUMN_VALIDATIONS_KEY,

  QueryResolverIntervalTimeOptions,
  SlaConfigOptions
} from './types';

function removeEmptyParams(props) {
  const PARAMS_TO_CLEAN = ['min_value', 'max_value', 'round_ndigits'];
  for (const key in props) {
    if ((props[key] === '' && PARAMS_TO_CLEAN.includes(key)) || key === 'isDraft') {
      delete props[key];
    }
  }

  return props;
}

export function getExpectations(
  formData
) {
  const expectations = [];
  for (const validation of formData[COLUMN_VALIDATIONS_KEY] || []) {
    const { expectation_name, slack_enabled, opsgenie_enabled, ...params } = validation;
    const notificationResolvers = getNotifiersList(slack_enabled, opsgenie_enabled);
    expectations.push({
      name: expectation_name,
      params: removeEmptyParams(params),
      enabled: notificationResolvers.length > 0,
      enabled_notification_resolvers: notificationResolvers
    });
  }

  return expectations;
}

function getTimestampColumns(formData) {
  if (!formData.columns) {
    return null;
  }
  return formData.columns.map(col => ({ name: col.column_name, format: col.column_data_format }));
}

function getNotifiersList(slack_enabled, opsgenie_enabled) {
  const notificationResolvers = [];
  if (slack_enabled) {
    notificationResolvers.push('slack');
  }
  if (opsgenie_enabled) {
    notificationResolvers.push('opsgenie');
  }
  return notificationResolvers;
}

export function getTimestampColumnResolver(formData)


 {
  const columns = getTimestampColumns(formData);

  if (isNull(columns) || columns.length < 1) {
    return null;
  }

  return {
    timezone: 'UTC',
    columns: columns
  };
}

export function getNotificationSettings(formData) {
  const formSettings = formData.notifications;

  const notificationSettings = [];
  formSettings.slack_channels.forEach(el => {
    notificationSettings.push({ name: 'slack', params: { channel: el } });
  });

  const opsgeniesResponders = [];

  formSettings.opsgenie_groups.forEach(el => {
    opsgeniesResponders.push({
      name: el,
      type: 'team'
    });
  });

  if (opsgeniesResponders.length > 0) {
    notificationSettings.push({
      name: 'opsgenie',
      params: {
        responders: opsgeniesResponders,
        priority: formSettings.opsgenie_priority
      }
    });
  }

  return notificationSettings;
}

export function getDQSettingsResolverInterval(formData)

 {
  const interval = { months: 0, days: 0, hours: 0, minutes: 0 };
  const time = formData.data_quality_settings.resolver_interval_time;
  const value = formData.data_quality_settings.resolver_interval_value;

  switch (time) {
    case QueryResolverIntervalTimeOptions.days: {
      interval.days = value;
      break;
    }
    case QueryResolverIntervalTimeOptions.hours: {
      interval.hours = value;
      break;
    }
    default: {
      interval.days = 1;
      break;
    }
  }

  return { DQstartInterval: interval };
}

function getInterval(interval) {
  const type = interval.interval_time;
  const value = interval.interval_value;
  return {
    startInterval: {
      months: 0,
      days: type === QueryResolverIntervalTimeOptions.days ? value : 0,
      hours: type === QueryResolverIntervalTimeOptions.hours ? value : 0,
      minutes: 0
    },
    endInterval: { months: 0, days: 0, hours: 0, minutes: 0 }
  };
}

export function getEtaSettings(formData)


 {
  const {
    eta,
    airflow_url,
    sla_settings,
    execution_delta,
    dag_id,
    task_id,
    slack_enabled,
    opsgenie_enabled,
    schedule_expression
  } = formData.sla;

  const notificationResolvers = getNotifiersList(slack_enabled, opsgenie_enabled);

  const airflowResolver = {
    airflow_url: `https://${airflow_url}`,
    dag_id: dag_id,
    task_id: task_id,
    execution_delta: {
      months: 0,
      days: 0,
      hours: execution_delta.hours,
      minutes: execution_delta.minutes
    }
  };

  const { startInterval, endInterval } = getInterval(sla_settings);
  const timestamp_column_resolver = getTimestampColumnResolver(formData);
  const queryResolver = {
    data_start_interval: startInterval,
    data_end_interval: endInterval,
    timestamp_column_resolver: timestamp_column_resolver,
    data_filters_names: formData.dataFilters || []
  };

  const etaSettings = {
    schedule_expression,
    sla: { months: 0, days: 0, hours: eta.hours, minutes: eta.minutes },
    enabled_notification_resolvers: notificationResolvers,
    resolver: {
      name: formData.sla.resolver,
      ...((formData.sla.resolver ) === 'airflow_task_sensor' && airflowResolver),
      ...((formData.sla.resolver ) === 'query_resolver' && queryResolver)
    }
  };

  return { endInterval: endInterval, etaSettings: etaSettings };
}

function getBusinessUnit(formData) {
  const businessUnit = formData.ownership.business_unit;
  return businessUnit;
}

function getOrgSquad(formData) {
  const owner = formData.ownership.org_squad;
  return owner;
}

function getSettingName(formData) {
  const name = formData.ownership.name;
  return name;
}

export function getPayload(formData) {
  const name = getSettingName(formData);
  const businessUnit = getBusinessUnit(formData);
  const orgSquad = getOrgSquad(formData);
  const expectations = getExpectations(formData);
  const notificationSettings = getNotificationSettings(formData);
  const { endInterval, etaSettings } = getEtaSettings(formData);
  const { DQstartInterval } = getDQSettingsResolverInterval(formData);
  const timestamp_column_resolver = getTimestampColumnResolver(formData);

  const payload = {
    name,
    database_name: formData.databaseName,
    table_name: formData.tableName,
    datetime_granularity:
      formData.sla.sla_settings.interval_time === QueryResolverIntervalTimeOptions.days
        ? SlaConfigOptions.daily
        : SlaConfigOptions.hourly,
    eta_settings: etaSettings,
    notification_default_resolvers: notificationSettings,
    org_squad: orgSquad,
    business_unit: businessUnit,
    dq_settings: {
      resolver: {
        name: 'query_resolver',
        data_start_interval: DQstartInterval,
        data_end_interval: endInterval,
        timestamp_column_resolver: timestamp_column_resolver,
        data_filters_names: formData.dataFilters || []
      },
      scheduling_resolver: { name: 'after_successful_eta_check' },
      expectations: expectations
    },
    enabled: true
  };

  return payload;
}
