
















import { makeStyles } from '@material-ui/core/styles';
import 'graphiql/graphiql.css';
import { buildSchema } from 'graphql';
import React, { Suspense } from 'react';
import { Progress } from '@backstage/core-components';

const GraphiQL = React.lazy(() => import('graphiql'));

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  graphiQlWrapper: {
    flex: 1,
    '@global': {
      '.graphiql-container': {
        boxSizing: 'initial',
        height: '100%',
        minHeight: '600px',
        flex: '1 1 auto'
      }
    }
  }
}));





export const GraphQlDefinitionWidget = ({ definition }) => {
  const classes = useStyles();
  const schema = buildSchema(definition);

  return (
    React.createElement(Suspense, { fallback: React.createElement(Progress, null ),}
      , React.createElement('div', { className: classes.root,}
        , React.createElement('div', { className: classes.graphiQlWrapper,}
          , React.createElement(GraphiQL, {
            fetcher: () => Promise.resolve(null) ,
            schema: schema,
            docExplorerOpen: true,
            defaultSecondaryEditorOpen: false,}
          )
        )
      )
    )
  );
};
