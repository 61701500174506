import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box } from '@material-ui/core';
import { DatabaseStatus } from '../../types';





const colorsByStatus = {
  [DatabaseStatus.READY]: '#E17A19',
  [DatabaseStatus.CREATING]: '#E17A19',
  [DatabaseStatus.PENDING]: '#E17A19',
  [DatabaseStatus.ERROR]: '#9E1FFF',
  [DatabaseStatus.DELETED]: '#ff2e1f',
  [DatabaseStatus.DELETING]: '#ff2e1f'
};

const textByStatus = {
  [DatabaseStatus.READY]: 'Live',
  [DatabaseStatus.CREATING]: 'Creating',
  [DatabaseStatus.PENDING]: 'Creating',
  [DatabaseStatus.ERROR]: 'Error',
  [DatabaseStatus.DELETED]: 'Deleted',
  [DatabaseStatus.DELETING]: 'Deleting'
};

export const StatusLabel = ({ status }) => {
  return (
    React.createElement(Box, { flexDirection: "row", display: "flex",}
      , React.createElement(FiberManualRecordIcon, { fontSize: "small", style: { color: colorsByStatus[status] },} )
      , React.createElement(Box, { lineHeight: "20px", ml: 1, fontWeight: "bold",}
        , textByStatus[status]
      )
    )
  );
};
