import { CustomError } from 'ts-custom-error';

export class RequestError extends CustomError {
    _code;

    _codeMessage;

    _message;

  constructor(code, codeMessage, message) {
    super(`${code} (${codeMessage}): ${message}`);

    this._code = code;
    this._codeMessage = codeMessage;
    this._message = message;
  }

  get code() {
    return this._code;
  }

  get codeMessage() {
    return this._codeMessage;
  }

  get message() {
    return this._message;
  }
}
