import React, { useMemo } from 'react';
import { isArray } from 'lodash';
import { OwnerPicker } from '../../../common/components/OwnerPicker';
import { useApplicationListProvider } from '../../hooks/useApplicationListProvider';

const forceArrayValue = (value) => (value ? [value] : []);

export const ApplicationOwnerPicker = () => {
  const { applications, updateFilters, queryParams } = useApplicationListProvider();

  const allSquadsArray = useMemo(() => {
    if (!applications || applications.length === 0) return [];
    const allSquads = Object.assign({}, ...applications.map(x => ({ [x.owner]: x.owner_id })));
    return Object.keys(allSquads).map(key => ({
      displayName: key,
      id: allSquads[key]
    }));
  }, [applications]);

  const ownersSelected =
    queryParams?.ownerIds && isArray(queryParams.ownerIds)
      ? queryParams.ownerIds
      : forceArrayValue(queryParams?.ownerIds);

  const handleOnChange = (ownerIds) => {
    updateFilters(actualFilters => ({ ...actualFilters, ownerIds }));
  };

  return React.createElement(OwnerPicker, { onChange: handleOnChange, selected: ownersSelected, options: allSquadsArray,} );
};
