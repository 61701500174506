
















import { CustomFieldValidator, FieldExtensionOptions } from './types';
import { attachComponentData } from '@backstage/core-plugin-api';

export const FIELD_EXTENSION_WRAPPER_KEY = 'scaffolder.extensions.wrapper.v1';
export const FIELD_EXTENSION_KEY = 'scaffolder.extensions.field.v1';

export function createScaffolderFieldExtension(options) {
  return {
    expose() {
      const FieldExtensionDataHolder = () => null;

      attachComponentData(FieldExtensionDataHolder, FIELD_EXTENSION_KEY, options);

      return FieldExtensionDataHolder;
    }
  };
}

export const ScaffolderFieldExtensions = () => null;
attachComponentData(ScaffolderFieldExtensions, FIELD_EXTENSION_WRAPPER_KEY, true);

;

export { DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS } from './default';
