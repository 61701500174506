export var NotificationType; (function (NotificationType) {
  const BAMBOO_INCONSISTENCY = 'BAMBOO_INCONSISTENCY'; NotificationType["BAMBOO_INCONSISTENCY"] = BAMBOO_INCONSISTENCY;
})(NotificationType || (NotificationType = {}));

export var NotificationChannelType; (function (NotificationChannelType) {
  const SLACK = 'slack'; NotificationChannelType["SLACK"] = SLACK;
})(NotificationChannelType || (NotificationChannelType = {}));






