/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { RELATION_PROVIDES_API } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import { EntityTable, useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import React from 'react';
import { apiEntityColumns } from './presets';
import { CodeSnippet, InfoCard, Link, Progress, WarningPanel } from '@backstage/core-components';







export const ProvidedApisCard = ({ variant = 'gridItem' }) => {
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_PROVIDES_API
  });

  if (loading) {
    return (
      React.createElement(InfoCard, { variant: variant, title: "Provided APIs" ,}
        , React.createElement(Progress, null )
      )
    );
  }

  if (error || !entities) {
    return (
      React.createElement(InfoCard, { variant: variant, title: "Provided APIs" ,}
        , React.createElement(WarningPanel, {
          severity: "error",
          title: "Could not load APIs"   ,
          message: React.createElement(CodeSnippet, { text: `${error}`, language: "text",} ),}
        )
      )
    );
  }

  return (
    React.createElement(EntityTable, {
      title: "Provided APIs" ,
      variant: variant,
      emptyContent: 
        React.createElement('div', { style: { textAlign: 'center' },}
          , React.createElement(Typography, { variant: "body1",}, "This "
             , entity.kind.toLocaleLowerCase('en-US'), " does not provide any APIs."
          )
          , React.createElement(Typography, { variant: "body2",}
            , React.createElement(Link, { to: "https://backstage.io/docs/features/software-catalog/descriptor-format#specprovidesapis-optional",}, "Learn how to change this."

            )
          )
        )
      ,
      columns: apiEntityColumns,
      entities: entities ,}
    )
  );
};
