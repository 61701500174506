import { createApiRef, } from '@backstage/core-plugin-api';



export const workforceApiRef = createApiRef({
  id: 'plugin.workforce.service',
  description: 'Used to make requests towards the workforce backend'
});

export function isOk(res) {
  return res.status >= 200 && res.status < 300;
}
export class WorkforceApi {
    identityApi;
    discoveryApi;

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  async getBaseUrl() {
    const baseUrl = await this.discoveryApi.getBaseUrl('workforce');
    return baseUrl;
  }

  async getCatalogBaseUri() {
    const baseUrl = await this.discoveryApi.getBaseUrl('catalog');
    return baseUrl;
  }

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getApplicationsBySquad(squadName) {
    const token = await this.identityApi.getIdToken();
    const email = this.getUserEmail();
    const headers = {
      ...(email && { 'X-User-Email': email }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...this.commonHeaders
    };

    const res = await fetch(
      `${await this.getCatalogBaseUri()}/api/catalog/entities?filter=kind=Component,spec.owner=${squadName}`,
      {
        method: 'GET',
        headers
      }
    );

    const resBody = await res.json();

    if (!isOk(res)) {
      throw new Error(`Error: ${resBody?.error?.message || 'Unknown'}`);
    }

    return resBody;
  }

  getUserEmail() {
    return this.identityApi.getProfile().email;
  }
}
