import { Progress } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';


import Form from '@rjsf/material-ui';
import Ajv from 'ajv';

import jsonpath from 'jsonpath';
import React, { useState } from 'react';
import { useAsync } from 'react-use';

import { useProvisioningApi } from '../../utils';










const fillDefaultValues = (
  schema,
  defaultValues,
  ringInfo
) => {
  const newSchema = schema ;
  const properties = schema.properties;

  let ringDefaults = {};

  defaultValues.forEach(value => {
    if (schema.properties[value.key]) {
      const info = jsonpath.value(ringInfo, `$.${value.value}`);
      ringDefaults = { ...ringDefaults, [value.key]: info };
      delete properties[value.key];
    }
  });
  newSchema.properties = properties;

  return {
    schema: newSchema,
    ringDefaults: ringDefaults
  };
};

/**
 * Display Bundle infos and keep updated interpolating strings
 * @param event
 * @param bundleFormInfo
 * @param formFields
 * @returns
 */
const updateFormInfos = (event, bundleFormInfo, formFields) => {
  const infos = [];
  for (const info of bundleFormInfo.infos) {
    const message = info.info;
    let interpolatedInfo = message;
    const regex = /{{([^}]*)}}/g;

    const infoFields = message.match(regex).map((item) => item.replace(/{|}|\t|\s/g, ''));

    for (const field of formFields) {
      const i = infoFields.indexOf(field);
      if (i !== -1) {
        interpolatedInfo = interpolatedInfo.replaceAll(message.match(regex)[i], event.formData[field]);
      }
    }
    infos.push(interpolatedInfo);
  }

  return infos;
};

export const BundleCreation = ({
  bundleType,
  bundleData,
  setBundleData,
  setIsBundleValid,
  project,
  application
}) => {
  const [formInfoReplaced, setFormInfoReplaced] = useState([]);
  const ajv = new Ajv();
  const errorAPI = useApi(errorApiRef);
  const backendAPI = useProvisioningApi();
  const {
    value: bundleInputs,
    loading: loadingBundleInputs,
    error: errorBundleInputs
  } = useAsync(async () => {
    return backendAPI.getBundleInputs(bundleType);
  }, [backendAPI]);
  const {
    value: bundleExternalInfo,
    loading: loadingBundleExternalInfo,
    error: errorBundleExternalInfo
  } = useAsync(async () => {
    const response = await backendAPI.getBundleExternalValues(bundleType);

    if (response.form) setFormInfoReplaced(response.form.infos.map((info) => info.info));

    return response;
  }, [backendAPI]);
  const {
    value: userEmail,
    loading: loadingUserEmail,
    error: errorUserEmail
  } = useAsync(async () => {
    return backendAPI.getUserEmail();
  }, [backendAPI]);

  if (errorUserEmail) {
    errorAPI.post(new Error(`error reading user email: ${errorUserEmail}`));
  }

  if (errorBundleInputs || errorBundleExternalInfo) {
    const error = errorBundleInputs || errorBundleExternalInfo;
    errorAPI.post(error );
  }

  if (loadingBundleInputs || loadingBundleExternalInfo || loadingUserEmail) {
    return React.createElement(Progress, null );
  }

  // Getting owner of application but removing "group:" from its string
  if (!application.owner) {
    errorAPI.post(new Error(`error reading owner: undefined`));
  }
  const owner = application.owner?.toString().split(':')[1];

  const bundleInfo = {
    info: {
      project: project,
      email: userEmail,
      entity: application,
      provider: {
        type: 'aws',
        account: 'wildlife-general-prod',
        region: 'us-east-1'
      },
      application_repository: application.repositoryUrl,
      dns: {
        dns_mode: 'CNAME'
      },
      owner: owner
    }
  };

  const { schema, ringDefaults } = fillDefaultValues(
    bundleInputs,
    bundleExternalInfo && 'ring' in bundleExternalInfo ? bundleExternalInfo.ring : bundleExternalInfo,
    bundleInfo
  );

  const validate = ajv.compile(schema);

  return (
    React.createElement(Form, {
      schema: schema,
      formData: bundleData,
      onChange: e => {
        setIsBundleValid(validate(e.formData));
        setBundleData({ ...ringDefaults, ...e.formData });
        if (bundleExternalInfo.form)
          setFormInfoReplaced(updateFormInfos(e, bundleExternalInfo.form, Object.keys(e.formData)));
      },}
    
      , React.createElement('div', null )
      , React.createElement('div', null, formInfoReplaced.length > 0 && React.createElement('h1', null, "Informations " ))

      , React.createElement('ul', null
        , formInfoReplaced.map(info => (
          React.createElement('li', { key: info,}, info)
        ))
      )
    )
  );
};
