import React, { useState } from 'react';
import { Content, SimpleStepperStep, SimpleStepper } from '@backstage/core-components';
import { Button } from '@material-ui/core';
import { ReviewStep } from '../ReviewStep';
import { BundleCreation } from '../BundleCreation';
import { ServiceCreation } from '../ServiceCreation/ServiceCreation';



export const CreateResources = ({
  project,
  application,
  service,
  onCancel
}




) => {
  const [submitting, setSubmitting] = useState(false);
  const [bundleData, setBundleData] = useState({});
  const [isBundleValid, setIsBundleValid] = useState(false);
  const [serviceData, setService] = useState(service);

  return (
    React.createElement(Content, null
      , !submitting ? (
        React.createElement(SimpleStepper, null
          , React.createElement(SimpleStepperStep, {
            title: "Resource Inputs" ,
            actions: {
              canNext: () => isBundleValid,
              onNext: () =>
                setService({
                  ...serviceData,
                  spec: {
                    system: application.system,
                    bundle_name: service.spec.bundle_name,
                    service_name: service.spec.service_name,
                    ...bundleData
                  }
                })
            },}
          
            , React.createElement(BundleCreation, {
              application: application,
              bundleType: serviceData.spec?.bundle_name || '',
              bundleData: bundleData,
              setBundleData: setBundleData,
              setIsBundleValid: setIsBundleValid,
              project: project,}
            )
          )
          , React.createElement(SimpleStepperStep, {
            title: "Review",
            actions: {
              showRestart: true,
              onRestart: () => {
                setBundleData({});
              },
              nextText: 'create',
              onNext: () => {
                setSubmitting(true);
              }
            },}
          
            , React.createElement(ReviewStep, { inputs: bundleData,} )
          )
        )
      ) : (
        React.createElement(ServiceCreation, { service: serviceData, project: project,} )
      )

      , React.createElement(Button, { variant: "contained", color: "default", onClick: onCancel,}, "Cancel"

      )
    )
  );
};
