import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { ActionsHeader, TabsHeader } from '@ring/plugin-common';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { TableStatusLabel } from '../Common';
import { ObservabilityContext } from './ObersvabilityContext';
import { useAsyncFn } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { Content, InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';



const tabs = [
  { label: 'overview', path: 'overview', disabled: false }
  // disabled tabs until we develop the screens ands fetures
  // { label: 'issues', path: 'issues', disabled: true },
  // { label: 'details', path: 'details', disabled: true },
  // { label: 'dependencies', path: 'dependencies', disabled: true }
];

export const ObservabilityPage = ({}) => {
  const dataObservabilityClient = useApi(dataObservabilityApiRef);
  const navigate = useNavigate();

  const { '*': actualTab, settingId: settingId } = useParams();
  const [{ value: data, error, loading }, refresh] = useAsyncFn(async () => {
    const response = await dataObservabilityClient.getDataMonitoringSettingResultsDetailsV2(settingId);
    return response;
  });

  useEffect(() => {
    if (settingId) {
      refresh();
    }
  }, [refresh, settingId]);

  const handleEdit = () => {
    // todo: implement on observability route
    navigate(`/catalog/default/component/app-name/data-catalog/output/${settingId}/data-monitoring`);
  };

  const handleRefresh = () => {
    refresh();
  };

  if (error) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, error.toString())
      )
    );
  }

  return (
    React.createElement('div', null
      , React.createElement(ActionsHeader, { title: data ? data.setting_name : settingId, padding: "2rem",}
        , !loading && (
          React.createElement(Box, { width: "100%", display: "flex", justifyContent: "space-between",}
            , React.createElement(TableStatusLabel, { status: data?.eta?.status, variant: "paper",} )
            /*  <Button style={{ marginLeft: '1rem' }} onClick={handleEdit} color="primary" variant="contained">
              Edit
            </Button> */
          )
        )
      )
      , React.createElement(TabsHeader, { tabs: tabs, actual: actualTab || tabs[0].label,} )
      , React.createElement(ObservabilityContext.Provider, { value: { onRefresh: handleRefresh, data, onEdit: handleEdit },}
        /* this context can be removed when we can update the react-router-dom to stable version, the <Outlet/> have an implemented context */
        , loading ? (
          React.createElement(Box, { p: "2rem",}
            , React.createElement(InfoCard, { title: "Loading table data"  ,}
              , React.createElement(Progress, null )
            )
          )
        ) : (
          React.createElement(Outlet, null )
        )
      )
    )
  );
};
