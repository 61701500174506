

export const useEnvironments = () => {
  // TODO get environment from data catalog API when ready
  return {
    environments: [
      {
        const: JSON.stringify({ slug: 'dev', is_production: false }),
        title: 'development'
      },
      {
        const: JSON.stringify({ slug: 'prod', is_production: true }),
        title: 'production'
      }
    ]
  };
};
