import React from 'react';
import { Content, Header, Page, Progress, RoutedTabs } from '@backstage/core-components';
import { attachComponentData, useElementFilter } from '@backstage/core-plugin-api';


import { useApplication } from '../../context/applicationContext';
import { ApplicationApiPage } from '../ApplicationApiPage';
import { ApplicationPageTitle } from './ApplicationPageTitle';









const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route = () => null;
attachComponentData(Route, dataKey, true);

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);





export const ApplicationLayout = ({ children }) => {
  const { application, serviceApiDocs } = useApplication();
  const { value: service, loading } = serviceApiDocs;

  const apis = service?.meta?.service_information?.apis;

  let routes = useElementFilter(children, elements =>
    elements
      .selectByComponentData({
        key: dataKey,
        withStrictError: 'Child of EntityLayout must be an EntityLayout.Route'
      })
      .getElements() // all nodes, element data, maintain structure or not?
      .flatMap(({ props }) => {
        if (props.if && application && !props.if(application)) {
          return [];
        }

        return [
          {
            path: props.path,
            title: props.title,
            children: props.children,
            tabProps: props.tabProps
          }
        ];
      })
  );

  // add apis tab if the application has apis
  if (apis && apis.length > 0)
    routes = [
      ...routes,
      {
        path: '/api',
        title: 'API',
        children: React.createElement(ApplicationApiPage, { apis: apis, serviceId: service?.id,} ),
        tabProps: undefined
      }
    ];

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, {
        title: React.createElement(ApplicationPageTitle, { title: application.name, application: application,} ),
        pageTitleOverride: application.name,
        type: application.type,}
      )

      , loading ? (
        React.createElement(Content, null
          , React.createElement(Progress, null )
        )
      ) : (
        React.createElement(RoutedTabs, { routes: routes,} )
      )
    )
  );
};

ApplicationLayout.Route = Route;
