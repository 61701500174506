import { FormControlLabel } from '@material-ui/core';
import { CustomSwitch } from '@ring/plugin-common';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';






export const NotificationEnabledInput = ({ fieldPath, label }) => {
  const { control, trigger } = useFormContext();

  return (
    React.createElement(Controller, {
      control: control,
      name: fieldPath,
      defaultValue: false,
      render: ({ field: { value, onChange } }) => (
        React.createElement(FormControlLabel, {
          control: 
            React.createElement(CustomSwitch, {
              checked: value,
              onChange: (_, checked) => {
                onChange(checked);
                trigger();
              },}
            )
          ,
          labelPlacement: "start",
          label: label,}
        )
      ),}
    )
  );
};
