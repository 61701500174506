import { useEffect } from 'react';

import { useAsyncFn } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '../../api';


export const useServiceApiDocs = (application) => {
  const catalogApi = useApi(catalogApiRef);

  const [service, getService] = useAsyncFn(
    async (app) => {
      try {
        let serviceData;
        let ApplicationServices;
        if (app && app?.id) {
          ApplicationServices = await catalogApi.getServicesByApplication(app.id, 'documentation');
        } else {
          return Promise.reject('No application service found');
        }

        if (ApplicationServices && ApplicationServices[0] && ApplicationServices[0]?.id) {
          serviceData = await catalogApi.getService(ApplicationServices[0].id);
        } else {
          return Promise.reject('No service found');
        }

        return Promise.resolve(serviceData);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [catalogApi]
  );

  useEffect(() => {
    if (application) getService(application);
  }, [application, getService]);

  return service;
};
