


export class UserFilter  {
    _ids;
    _names;
    _firstName;
    _lastName;
    _displayName;
    _email;
    _position;
    _squad;
    _manager;
    _bambooId;
    _external;

   constructor(builder) {
    this._ids = builder.ids;
    this._names = builder.names;
    this._firstName = builder.firstName;
    this._lastName = builder.lastName;
    this._displayName = builder.displayName;
    this._email = builder.email;
    this._position = builder.position;
    this._squad = builder.squad;
    this._manager = builder.manager;
    this._bambooId = builder.bambooId;
    this._external = builder.external;
  }

  isEmpty() {
    return (
      !this._ids &&
      !this._names &&
      !this._firstName &&
      !this._lastName &&
      !this._displayName &&
      !this._email &&
      !this._position &&
      !this._squad &&
      !this._manager &&
      !this._bambooId &&
      !this._external
    );
  }

  get ids() {
    return this._ids;
  }

  get names() {
    return this._names;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get displayName() {
    return this._displayName;
  }

  get email() {
    return this._email;
  }

  get position() {
    return this._position;
  }

  get squad() {
    return this._squad;
  }

  get manager() {
    return this._manager;
  }

  get bambooId() {
    return this._bambooId;
  }

  get external() {
    return this._external;
  }
}
