
















import { EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useState } from 'react';
import { useUnregisterEntityDialogState } from './useUnregisterEntityDialogState';

import { alertApiRef, configApiRef, useApi } from '@backstage/core-plugin-api';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';

const useStyles = makeStyles({
  advancedButton: {
    fontSize: '0.7em'
  }
});








const Contents = ({ entity, onConfirm }) => {
  const alertApi = useApi(alertApiRef);
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const state = useUnregisterEntityDialogState(entity);
  const [showDelete, setShowDelete] = useState(false);
  const [busy, setBusy] = useState(false);
  const appTitle = configApi.getOptionalString('app.title') ?? 'Backstage';

  const onUnregister = useCallback(
    async function onUnregisterFn() {
      if ('unregisterLocation' in state) {
        setBusy(true);
        try {
          await state.unregisterLocation();
          onConfirm();
        } catch (err) {
          alertApi.post({ message: err.message });
        } finally {
          setBusy(false);
        }
      }
    },
    [alertApi, onConfirm, state]
  );

  const onDelete = useCallback(
    async function onDeleteFn() {
      if ('deleteEntity' in state) {
        setBusy(true);
        try {
          await state.deleteEntity();
          onConfirm();
        } catch (err) {
          alertApi.post({ message: err.message });
        } finally {
          setBusy(false);
        }
      }
    },
    [alertApi, onConfirm, state]
  );

  if (state.type === 'loading') {
    return React.createElement(Progress, null );
  }

  if (state.type === 'error') {
    return React.createElement(ResponseErrorPanel, { error: state.error,} );
  }

  if (state.type === 'bootstrap') {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Alert, { severity: "info",}, "You cannot unregister this entity, since it originates from a protected Backstage configuration (location \""

          , state.location, "\"). If you believe this is in error, please contact the "           , appTitle, " integrator."
        )

        , React.createElement(Box, { marginTop: 2,}
          , !showDelete && (
            React.createElement(Button, {
              variant: "text",
              size: "small",
              color: "primary",
              className: classes.advancedButton,
              onClick: () => setShowDelete(true),}
            , "Advanced Options"

            )
          )

          , showDelete && (
            React.createElement(React.Fragment, null
              , React.createElement(DialogContentText, null, "You have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or moved from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog."



              )
              , React.createElement(Button, { variant: "contained", color: "secondary", disabled: busy, onClick: onDelete,}, "Delete Entity"

              )
            )
          )
        )
      )
    );
  }

  if (state.type === 'only-delete') {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(DialogContentText, null, "This entity does not seem to originate from a location. You therefore only have the option to delete it outright from the catalog."


        )
        , React.createElement(Button, { variant: "contained", color: "secondary", disabled: busy, onClick: onDelete,}, "Delete Entity"

        )
      )
    );
  }

  if (state.type === 'unregister') {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(DialogContentText, null, "This action will unregister the following entities:"      )
        , React.createElement(DialogContentText, { component: "ul",}
          , state.colocatedEntities.map(e => (
            React.createElement('li', { key: `${e.kind}:${e.namespace}/${e.name}`,}
              , React.createElement(EntityRefLink, { entityRef: e,} )
            )
          ))
        )
        , React.createElement(DialogContentText, null, "Located at the following location:"    )
        , React.createElement(DialogContentText, { component: "ul",}
          , React.createElement('li', null, state.location)
        )
        , React.createElement(DialogContentText, null, "To undo, just re-register the entity in "       , appTitle, ".")
        , React.createElement(Box, { marginTop: 2,}
          , React.createElement(Button, { variant: "contained", color: "secondary", disabled: busy, onClick: onUnregister,}, "Unregister Location"

          )
          , !showDelete && (
            React.createElement(Box, { component: "span", marginLeft: 2,}
              , React.createElement(Button, {
                variant: "text",
                size: "small",
                color: "primary",
                className: classes.advancedButton,
                onClick: () => setShowDelete(true),}
              , "Advanced Options"

              )
            )
          )
        )

        , showDelete && (
          React.createElement(React.Fragment, null
            , React.createElement(Box, { paddingTop: 4, paddingBottom: 4,}
              , React.createElement(Divider, null )
            )
            , React.createElement(DialogContentText, null, "You also have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or moved from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog."



            )
            , React.createElement(Button, { variant: "contained", color: "secondary", disabled: busy, onClick: onDelete,}, "Delete Entity"

            )
          )
        )
      )
    );
  }

  return React.createElement(Alert, { severity: "error",}, "Internal error: Unknown state"   );
};

export const UnregisterEntityDialog = ({ open, onConfirm, onClose, entity }) => (
  React.createElement(Dialog, { open: open, onClose: onClose,}
    , React.createElement(DialogTitle, { id: "responsive-dialog-title",}, "Are you sure you want to unregister this entity?"        )
    , React.createElement(DialogContent, null
      , React.createElement(Contents, { entity: entity, onConfirm: onConfirm,} )
    )
    , React.createElement(DialogActions, null
      , React.createElement(Button, { onClick: onClose, color: "primary",}, "Cancel"

      )
    )
  )
);
