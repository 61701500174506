import { createApiRef, } from '@backstage/core-plugin-api';


import {




  BundleActionTypes,
  BundleUpdateTypes,




} from './types/bundle';


export const provisioningApiRef = createApiRef({
  id: 'plugin.infrastructure.service',
  description: 'Used to make requests towards the provisioning backend API'
});

export const stagingProvisioiningApiRef = createApiRef({
  id: 'plugin.infrastructure-staging.service',
  description: 'Used to make requests towards the staging provisioining backend API'
});

const getEnvVars = (envars, environment, bundleId) => {
  if (!envars) return [];
  const envVariables = [];
  Object.entries(envars).forEach(([key, value]) => {
    envVariables.push({ name: key, value, environment, bundleId: bundleId });
  });
  return envVariables;
};

export class InfrastructureClient {
    discoveryApi;
    identityApi;

    environment_endpoint;

  constructor(
    options,
    environment
  ) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;

    this.environment_endpoint = environment === 'staging' ? 'infrastructure-staging' : 'infrastructure';
  }

  async getUserEmail() {
    return this.identityApi.getProfile().email;
  }

  getResources() {
    return ['Postgres Database'];
  }

  async fetchAPI(endpoint, method, body, json = true) {
    const token = await this.identityApi.getIdToken();

    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/${this.environment_endpoint}/${endpoint}`;

    const response = await fetch(url, {
      method: method,
      body: body,
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        }),
        ...(json && {
          'Content-Type': 'application/json'
        })
      }
    });

    if (!response.ok) {
      const status = `${response.status} ${response.statusText}`;
      const responseBody = await response.text();
      throw new Error(`Backend request failed, ${status} ${responseBody.trim()}`);
    }

    return json ? response.json() : response.text();
  }

  async fetchCoreAPI(endpoint, method, body, json = true) {
    const token = await this.identityApi.getIdToken();
    const url = `${await this.discoveryApi.getBaseUrl('proxy')}/core-api/${endpoint}`;

    const response = await fetch(url, {
      method: method,
      body: body,
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`
        }),
        'content-type': 'application/json'
      }
    });

    if (!response.ok) {
      const status = `${response.status} ${response.statusText}`;
      const responseBody = await response.text();
      throw new Error(`Backend request failed, ${status} ${responseBody.trim()}`);
    }

    return json ? await response.json() : response.text();
  }

  getToken() {
    return this.identityApi.getIdToken();
  }

  getProfile() {
    return this.identityApi.getProfile();
  }

  async getBundleInputs(bundleType) {
    const response = await this.fetchAPI(`v1/bundles/${bundleType}/inputs`, 'GET');
    return response;
  }

  async getBundleFormInfo(bundleType) {
    const response = await this.fetchAPI(`v1/bundles/${bundleType}/forminfo`, 'GET');
    return response;
  }

  async getBundleExternalValues(bundleType) {
    const response = await this.fetchAPI(`v1/bundles/${bundleType}/external`, 'GET');
    return response;
  }

  async getInfrastructureProjects() {
    const response = await this.fetchAPI(`v1/projects?group_id=1037`, 'GET');
    return response ;
  }

  async getPipelineInfo(projectID, commitID) {
    return await this.fetchAPI(`api/v1/resources/pipeline?project_id=${projectID}&commit_id=${commitID}`, 'GET');
  }

  async getApplicationOutputs(application, bundleType) {
    const bundles = await this.fetchAPI(`v1/bundles/${application.replace(/[_ ]/g, '-')}`, 'GET');

    const bundleIds = bundles.filter(bundle => bundle.type === bundleType).map(bundle => bundle.id);

    let bundeOutputList = [];

    for (const bundleId of bundleIds) {
      const bundleOutputs = (await this.fetchAPI(`v2/bundles/${bundleId}/outputs`, 'GET')) ;
      bundeOutputList = bundeOutputList.concat(bundleOutputs);
    }

    return bundeOutputList;
  }

  async getApplicationEnvVars(application, bundleType, taskKind) {
    const bundles = await this.fetchAPI(`v1/bundles/${application.replace(/[_ ]/g, '-')}`, 'GET');
    const envBundles = bundles
      .filter(bundle => bundle.type === bundleType)
      .map(bundle => ({ id: bundle.id, environment: bundle?.params?.environment }));

    const envVariables = [];
    for (const bundle of envBundles) {
      const bundleOutputs = await this.fetchAPI(`v2/bundles/${bundle.id}/outputs`, 'GET');
      const deployBundles = bundleOutputs.filter(bundleOutput => bundleOutput.taskKind === taskKind);

      for (const deployBundle of deployBundles) {
        const bundleEnvVars = deployBundle?.outputs?.environment_variables;
        if (bundleEnvVars) {
          Object.entries(bundleEnvVars).forEach(([key, value]) => {
            envVariables.push({ name: key, value, environment: bundle.environment, bundleId: bundle.id });
          });
        }
      }
    }

    return envVariables;
  }

  async getJobTrace(projectID, jobID) {
    const response = this.fetchAPI(
      `/v1/pipelines/jobs/output?project_id=${projectID}&job_id=${jobID}`,
      'GET',
      undefined,
      false
    );
    return response ;
  }

  async listPipelineJobs(projectID, pipelineID) {
    const response = this.fetchAPI(`/v1/pipelines/jobs/info?project_id=${projectID}&pipeline_id=${pipelineID}`, 'GET');
    return (await response) ;
  }

  async getJobStatus(projectID, jobID) {
    const response = this.fetchAPI(`/v1/pipelines/jobs/info?project_id=${projectID}&job_id=${jobID}`, 'GET');
    return (await response) ;
  }

  async listBundles() {
    return await this.fetchAPI(`/v1/bundles`, 'GET');
  }

  async listResources(projectName) {
    const response = this.fetchAPI(`/api/v1/resources?application=${projectName.replace(/[_ ]/g, '-')}`, 'GET');
    return (await response) ;
  }

  async getInfraRepository(applicationName, repository_id) {
    const response = this.fetchAPI(
      `/v1/project?repository_id=${repository_id}/${applicationName.replace(/[_ ]/g, '-')}`,
      'GET'
    );

    return response ;
  }

  async getDNSList(appId) {
    const dnses = await this.fetchCoreAPI(`/applications/${appId}/services?service_type=dns`, 'GET');

    const dnsesData = await Promise.all(
      dnses.map(dns => {
        return this.fetchCoreAPI(`services/${dns.id}`, 'GET');
      })
    );

    return dnsesData;
  }

  /**
   * Get all Deployments of an application
   *
   * @param application
   * @param bundleType
   * @param taskKind for filter
   * @returns
   */
  async getDeployments(application, bundleType) {
    // Get all bundles for the desired application
    const bundles = await this.fetchAPI(`v1/bundles/${application}`, 'GET');
    const envBundles = bundles.filter(bundle => bundle.type === bundleType);

    const deployments = [];
    for (const bundle of envBundles) {
      // Get the kubernetes outputs only
      const bundleOutputs = await this.fetchAPI(`v2/bundles/${bundle.id}/outputs`, 'GET');
      let deployBundles = bundleOutputs.filter(bundleOutput => bundleOutput.taskKind === 'kubernetes');

      if (deployBundles.length > 0) {
        // The bundle has always only one task kind kubernetes
        const deployment = deployBundles[0].outputs[0] ;
        deployment.environment = bundle.params?.environment;
        deployment.clusterName = bundle.params?.cluster_name;
        deployment.bundleId = bundle.id;

        deployBundles = bundleOutputs.filter(bundleOutput => bundleOutput.taskKind === 'helm-v2');
        const helmTask = deployBundles[0].outputs;
        deployment.replicas = helmTask.desiredReplicas;

        deployments.push(deployment);
      }
    }

    return deployments ;
  }

  async getCoreDeployments(appId) {
    return await this.fetchCoreAPI(`/applications/${appId}/services?service_type=kubernetes_deployment`, 'GET');
  }

  async getCoreImageRepository(appId) {
    return await this.fetchCoreAPI(`/applications/${appId}/services?service_type=image_repository`, 'GET');
  }

  async getCoreService(serviceId) {
    const response = await this.fetchCoreAPI(`/services/${serviceId}`, 'GET');
    return response;
  }

  async updateBundle(bundleId, updateValues) {
    return await this.fetchAPI(`v1/bundles/${bundleId}/helm-v2`, 'PUT', JSON.stringify(updateValues));
  }

  async updateCoreDeployment(serviceId, updateValues) {
    return await this.fetchCoreAPI(`services/${serviceId}`, 'PATCH', JSON.stringify({ meta: updateValues }));
  }

  /**
   * Get all info of a service/bundle
   *
   * @param serviceId
   * @returns
   */

  async getCoreBundleInfo(serviceId, appId) {
    const service = await this.getCoreService(serviceId);
    const appImageRepository = await this.getCoreImageRepository(appId);
    const imageService = await this.getCoreService(appImageRepository[0].id);
    const serviceType = service.meta?.deployment_type;

    const envars =
      serviceType === 'parametrized'
        ? service.meta?.deployment_spec?.environment_variables
        : service.meta?.service_information?.environment_variables;
    const annotations =
      serviceType === 'parametrized'
        ? service.meta?.deployment_spec?.deployment?.annotations
        : service.meta?.service_information?.deployment_annotations;
    const containerName =
      serviceType === 'parametrized'
        ? service.meta?.deployment_spec?.deployment?.container?.name
        : service.meta?.service_information?.deployment_container?.name;
    const desiredReplicas =
      serviceType === 'parametrized'
        ? service.meta?.deployment_spec?.replica_count
        : service.meta?.service_information?.replica_count;
    return {
      id: service.id,
      application: `${appId}`,
      namespace: service.meta?.service_information?.namespace,
      cluster: service.meta?.service_information?.cluster_name,
      replicas: {
        readyReplicas: service.meta?.service_information?.ready_replicas,
        desiredReplicas
      },
      image: service.meta?.service_information?.running_image?.split(':')[1],
      imageFull: service.meta?.service_information?.running_image,
      availableImages: imageService.meta?.service_information?.images
        .filter((img) => !!img.ImageTag && img.ImageTag !== 'latest')
        .map((img) => img.ImageTag),
      updates: annotations?.[`fluxcd.io/tag.${containerName}`]?.startsWith('glob')
        ? BundleUpdateTypes.MANUAL
        : BundleUpdateTypes.AUTOMATIC,
      type: annotations?.[`fluxcd.io/tag.${containerName}`] ? BundleActionTypes.TAG : BundleActionTypes.COMMIT,
      environment: service?.meta?.environment,
      envars: getEnvVars(envars, service?.meta?.environment, 'bundleid'),
      deploymentName: service.name,
      logs: service?.meta?.log_filters?.[0]?.Items,
      monitors: service.meta?.service_information?.monitors?.map((m) => ({
        active: true,
        name: m.name,
        status: m.overall_state,
        id: m.id
      })),
      containerName,
      status: service?.status,
      error: service.meta?.service_information?.error
    };
  }
}
