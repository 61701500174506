import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useApplicationListProvider } from '../../hooks/useApplicationListProvider';
import { UserListPicker } from '../../../common/components/UserListPicker';
import { isArray, omit, isEmpty } from 'lodash';
import { useMe } from '@ring/plugin-common';



const inferInitialState = (queryParams) => {
  if (queryParams.starred) {
    return 'starred';
  }
  return 'all';
};

export const ApplicationUserListPicker = () => {
  const { updateFilters, queryParams } = useApplicationListProvider();
  const { me } = useMe();
  const defaultFilterSelection = useRef(true);
  const [selected, setSelected] = useState(inferInitialState(queryParams));

  useEffect(() => {
    const queryOwned = isArray(queryParams.ownerIds) ? queryParams.ownerIds : [queryParams.ownerIds];
    if (me?.squad_id && queryOwned.length === 1 && queryOwned.includes(`${me.squad_id}`) && !queryParams?.starred) {
      setSelected('owned');
    } else if (!queryParams?.starred) {
      setSelected('all');
    }
  }, [me, queryParams.ownerIds, queryParams.starred, selected]);

  const setOwnSquad = useCallback(() => {
    if (me?.squad_id) {
      updateFilters(prev => {
        const newFilers = omit(prev, 'starred');
        return { ...newFilers, ownerIds: [me.squad_id] };
      });
    }
  }, [me, updateFilters]);

  useEffect(() => {
    // automatic set owner filter for first time access and empty filters
    if (isEmpty(queryParams) && me?.squad_id && defaultFilterSelection.current) {
      defaultFilterSelection.current = false;
      setOwnSquad();
    }
  }, [queryParams, setOwnSquad, me?.squad_id]);

  const handleOnChange = (selectedOption) => {
    if (selectedOption === 'all') {
      updateFilters(prev => omit(prev, ['starred', 'ownerIds']));
    }
    if (selectedOption === 'starred') {
      updateFilters(prev => ({ ...prev, starred: true }));
    }

    if (selectedOption === 'owned') {
      setOwnSquad();
    }
    setSelected(selectedOption);
  };

  return React.createElement(UserListPicker, { onChange: handleOnChange, selected: selected,} );
};
