import React from 'react';
import { ApplicationLayout } from '@ring/plugin-catalog';
import { EntityInfraResourcesContent } from '@internal/plugin-infrastructure';

import { OverviewContent } from '../OverviewContent';

export const FrontendProductPage = () => (
  React.createElement(ApplicationLayout, null
    , React.createElement(ApplicationLayout.Route, { path: "/", title: "Overview",}
      , React.createElement(OverviewContent, null )
    )
    , React.createElement(ApplicationLayout.Route, { path: "/docker-registry", title: "Docker Registries" ,}
      , React.createElement(EntityInfraResourcesContent, {
        service: 
          {
            status: 'creating',
            spec: { service_name: 'docker_registry_service', bundle_name: 'docker-registry' }
          } 
        ,}
      )
    )
    , React.createElement(ApplicationLayout.Route, { path: "/deployments", title: "Deployments",}
      , React.createElement(EntityInfraResourcesContent, {
        service: 
          {
            status: 'creating',
            spec: { service_name: 'flux_integration_service', bundle_name: 'flux' }
          } 
        ,}
      )
    )
  )
);
