import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowFordward from '@material-ui/icons/ArrowForward';







export const ArrowButton = ({ onClick, type, disabled = false }) => {
  return (
    React.createElement(Button, { disabled: disabled, color: disabled ? 'default' : 'primary', onClick: onClick,}
      , React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", style: { gap: '1rem' },}
        , type === 'back' ? React.createElement(ArrowBack, null ) : React.createElement(ArrowFordward, null )
        , React.createElement(Typography, { variant: "body2", gutterBottom: true,}
          , type === 'back' ? 'Previous Table' : 'Next Table'
        )
      )
    )
  );
};
