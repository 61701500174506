

import { getPluginBaseUrl, getToken } from '../../..';
import { BaseClient, serializeBusinessUnitFilter } from '@ring/clients';

export class BusinessUnitApiClient extends BaseClient {
  constructor(identityApi, discovery) {
    super(getPluginBaseUrl('business-units', discovery), serializeBusinessUnitFilter, getToken(identityApi));
  }
}
