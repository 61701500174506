import { Metrics } from '../../types';

export const metricsThreshold = new Map([
  [
    Metrics.SUCCESS_REQUESTS_RATIO,
    [
      { id: '< 0.99', value: '< 99%' },
      { id: '< 0.98', value: '< 98%' },
      { id: '< 0.97', value: '< 97%' },
      { id: '< 0.96', value: '< 96%' },
      { id: '< 0.95', value: '< 95%' },
      { id: '< 0.90', value: '< 90%' },
      { id: '< 0.80', value: '< 80%' },
      { id: '< 0.70', value: '< 70%' },
      { id: '< 0.60', value: '< 60%' },
      { id: '< 0.50', value: '< 50%' }
    ]
  ],
  [
    Metrics.RESPONSE_TIME,
    [
      { id: '> 0.05', value: '> 50ms' },
      { id: '> 0.1', value: '> 100ms' },
      { id: '> 0.2', value: '> 200ms' },
      { id: '> 0.3', value: '> 300ms' },
      { id: '> 0.4', value: '> 400ms' },
      { id: '> 0.5', value: '> 500ms' },
      { id: '> 0.6', value: '> 600ms' },
      { id: '> 0.7', value: '> 700ms' },
      { id: '> 0.8', value: '> 800ms' },
      { id: '> 0.9', value: '> 900ms' },
      { id: '> 1', value: '> 1s' },
      { id: '> 2', value: '> 2s' },
      { id: '> 3', value: '> 3s' }
    ]
  ]
]);

export const alertTimeOptions = [
  { value: 'last_1m', label: 'In the last 1 minute' },
  { value: 'last_5m', label: 'In the last 5 minutes' },
  { value: 'last_10m', label: 'In the last 10 minutes' },
  { value: 'last_15m', label: 'In the last 15 minutes' },
  { value: 'last_30m', label: 'In the last 30 minutes' },
  { value: 'last_1h', label: 'In the last 1 hour' },
  { value: 'last_2h', label: 'In the last 2 hours' },
  { value: 'last_4h', label: 'In the last 4 hours' },
  { value: 'last_1d', label: 'In the last 1 day' },
  { value: 'last_2d', label: 'In the last 2 days' },
  { value: 'last_1w', label: 'In the last 1 week' },
  { value: 'last_1mo', label: 'In the last 1 month' }
];
