import { useApi } from '@backstage/core-plugin-api';
import { Pageable } from '@ring/model';
import { useAsyncFn } from 'react-use';


export function useFindAllPages(
  entityApiRef
) {
  const entityApi = useApi(entityApiRef);
  const fetchAllPages = async (filter) => {
    try {
      const handshakeResponse = await entityApi.findAll({
        pageable: new Pageable(0, 100),
        ...(filter && { filter })
      });

      const response = await Promise.all(
        Array.from({ length: handshakeResponse.totalPages - 1 }, (_, i) =>
          entityApi.findAll({ pageable: new Pageable(i + 1, 100), ...(filter && { filter }) })
        )
      );

      const initialData = handshakeResponse.data ?? [];

      const data = response?.reduce((arr, row) => {
        return arr.concat(row.data);
      }, initialData);

      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return useAsyncFn((filter) => fetchAllPages(filter));
}
