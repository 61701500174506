import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Grid,
  Button as MButton,
  Backdrop,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useApi, errorApiRef, alertApiRef } from '@backstage/core-plugin-api';
import { Table, ErrorBoundary } from '@backstage/core-components';
import useSWR, { mutate } from 'swr';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles, createStyles, } from '@material-ui/core/styles';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router';
import { useSessionStorage } from 'react-use';

import { dataCatalogTableColumns } from '../../utils';





const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
);

const columns = dataCatalogTableColumns;

export const DataCatalogOverviewPage = ({ application }) => {
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const errorApi = useApi(errorApiRef);
  const alertApi = useApi(alertApiRef);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const classes = useStyles();
  const [dataEnvironment, setDataEnvironment] = useSessionStorage('dataEnvironment', 'prod');
  const nav = useNavigate();
  const applicationTablesCacheKey = `v2/applications/${application.name}/tables`;

  const [openDetachInputTableDialog, setOpenDetachInputTableDialog] = useState(false);
  const [selectedTableToDetachInput, setSelectedTableToDetachInput] = useState();

  const [openDetachOutputTableDialog, setOpenDetachOutputTableDialog] = useState(false);
  const [selectedTableToDetachOutput, setSelectedTableToDetachOutput] = useState();

  const [openCloneTableDialog, setOpenCloneTableDialog] = useState(false);
  const [selectedTableToClone, setSelectedTableToClone] = useState();

  const [openDeleteTableDialog, setOpenDeleteTableDialog] = useState(false);
  const [selectedTableToDelete, setSelectedTableToDelete] = useState();
  const [deleteTableDialogMessage, setDeleteTableDialogMessage] = useState();

  const { data: applicationTables, error } = useSWR(
    [applicationTablesCacheKey, dataEnvironment],
    async (_, dataEnv) => {
      return await dataCatalogApi.getApplicationTables(application.name, dataEnv);
    }
  );

  const handleChangeDataEnvironment = (event) => {
    setDataEnvironment(event.target.value);
  };

  if (error) {
    errorApi.post(error);
  }

  const isLoading = !applicationTables && !error;

  const handleDetachInputTable = async () => {
    setOpenBackdrop(true);
    try {
      if (selectedTableToDetachInput) {
        await dataCatalogApi.detachTable(application.name, dataEnvironment, selectedTableToDetachInput.id, 'input');
        mutate([applicationTablesCacheKey, dataEnvironment]);
      }
      alertApi.post({
        severity: 'success',
        message: `Detached input table with success`
      });
    } catch (e) {
      errorApi.post(e);
    }
    setOpenDetachInputTableDialog(false);
    setSelectedTableToDetachInput(undefined);
    setOpenBackdrop(false);
  };

  const handleDetachOutputTable = async () => {
    setOpenBackdrop(true);
    try {
      if (selectedTableToDetachOutput) {
        await dataCatalogApi.detachTable(application.name, dataEnvironment, selectedTableToDetachOutput.id, 'output');
        mutate([applicationTablesCacheKey, dataEnvironment]);
      }
      alertApi.post({
        severity: 'success',
        message: `Detached output table with success`
      });
    } catch (e) {
      errorApi.post(e);
    }
    setOpenDetachOutputTableDialog(false);
    setSelectedTableToDetachOutput(undefined);
    setOpenBackdrop(false);
  };

  const handleOpenDetachTableDialog = (table, relationship_type) => {
    if (relationship_type === 'input') {
      setSelectedTableToDetachInput(table);
      setOpenDetachInputTableDialog(true);
    }
    if (relationship_type === 'output') {
      setSelectedTableToDetachOutput(table);
      setOpenDetachOutputTableDialog(true);
    }
  };

  const handleCloseDetachInputTableDialog = () => {
    setOpenDetachInputTableDialog(false);
  };
  const handleCloseDetachOutputTableDialog = () => {
    setOpenDetachOutputTableDialog(false);
  };

  const handleCloseCloneTableDialog = () => {
    setOpenCloneTableDialog(false);
  };

  const handleOpenCloneTableDialog = (_, rowData) => {
    setSelectedTableToClone(rowData );
    setOpenCloneTableDialog(true);
  };

  const handleCloseDeleteTableDialog = () => {
    setOpenDeleteTableDialog(false);
  };

  const handleOpenDeleteTableDialog = async (_, rowData) => {
    setOpenBackdrop(true);
    const table = rowData ;
    const relationships = await dataCatalogApi.listTableRelationships(table.id);
    if (relationships.relationships.includes(application.name) && relationships.relationships.length > 1) {
      const i = relationships.relationships.indexOf(application.name);
      relationships.relationships.splice(i, 1);
      const msg = `This table is consumed by the following applications: ${relationships.relationships.toString()}`;
      setDeleteTableDialogMessage(msg);
    } else {
      setDeleteTableDialogMessage('This table has no consumer application');
    }
    setSelectedTableToDelete(table);
    setOpenBackdrop(false);
    setOpenDeleteTableDialog(true);
  };

  const handleDeleteTableConfirmation = async () => {
    setOpenBackdrop(true);
    if (selectedTableToDelete) {
      try {
        await dataCatalogApi.deleteTable(application.name, dataEnvironment, selectedTableToDelete.id);
        mutate([applicationTablesCacheKey, dataEnvironment]);
        alertApi.post({
          severity: 'success',
          message: `Deleted table with success`
        });
      } catch (exc) {
        errorApi.post(exc);
      }
    }
    setOpenBackdrop(false);
    setOpenDeleteTableDialog(false);
    setSelectedTableToDelete(undefined);
  };

  // TODO - Migrate to API V2, create settings page
  // const handleDataMonitoringClick = (table: HiveTable) => {
  //   nav(`/observability/tables/${table.id}/data-monitoring`);
  // };

  const handleEditClick = (table) => {
    nav(`output/${table.id}/edit`);
  };

  const handleCloneTable = async () => {
    if (selectedTableToClone) {
      const table = selectedTableToClone;
      const envToCopy = dataEnvironment === 'prod' ? 'dev' : 'prod';
      const envTableId = `${application.name}_${envToCopy}.${table.name}`; // @FIXME - This is a workaround to get the table ID from the other environment
      setOpenBackdrop(true);
      try {
        await dataCatalogApi.updateOutputTable(envTableId, application.name, envToCopy, {
          description: table.description,
          schema: table.schema,
          owner: table.owner,
          tags: table.tags
        });
        setOpenBackdrop(false);
        alertApi.post({
          severity: 'success',
          message: `Cloned table with success`
        });
      } catch (e) {
        if (!(e ).message.includes('ResourceNotFoundException')) {
          setOpenBackdrop(false);
          errorApi.post(e);
        }
        try {
          await dataCatalogApi.createOutputTable(application.name, envToCopy, {
            name: table.name,
            database: application.name,
            owner: (application.owner ) ?? '',
            schema: table.schema,
            partition_columns: !table.partition_columns ? [] : table.partition_columns,
            description: table.description,
            tags: table.tags ? [] : table.tags
          });
          alertApi.post({
            severity: 'success',
            message: `Cloned table with success`
          });
          setOpenBackdrop(false);
        } catch (exc) {
          setOpenBackdrop(false);
          errorApi.post(exc);
        }
      }
      setOpenCloneTableDialog(false);
      setSelectedTableToClone(undefined);
    }
  };

  const outputTablesActions = [
    {
      icon: () => React.createElement(EditIcon, null ),
      tooltip: 'Edit Output Table',
      onClick: (_, rowData) => handleEditClick(rowData )
    },
    {
      icon: () => React.createElement(RemoveCircleOutlineIcon, null ),
      tooltip: 'Detach Output Table',
      onClick: (_, rowData) => handleOpenDetachTableDialog(rowData , 'output')
    }
  ];

  if (dataEnvironment === 'dev') {
    outputTablesActions.push({
      icon: () => React.createElement(ContentCopyIcon, null ),
      tooltip:
        'Clone table to Production. It will clone the table metadata (no data will be copied) to Production. If the table already exists it will try to evolute the schema (the data will be keept).',
      onClick: handleOpenCloneTableDialog
    });
    outputTablesActions.push({
      icon: () => React.createElement(DeleteIcon, null ),
      tooltip: 'Permanently delete table. WARNING! This will cause Data loss',
      onClick: handleOpenDeleteTableDialog
    });
  } else if (dataEnvironment === 'prod') {
    outputTablesActions.push({
      icon: () => React.createElement(ContentCopyIcon, null ),
      tooltip:
        'Clone table to Development. It will clone the table metadata (no data will be copied) to Development. If the table already exists it will try to evolute the schema (the data will be keept).',
      onClick: handleOpenCloneTableDialog
    });
  }
  // TODO - Migrate to API V2, create settings page
  // actions.push({
  //   icon: () => <AddAlertIcon />,
  //   tooltip: 'Edit Data Monitoring',
  //   onClick: (_: any, rowData: any) => handleDataMonitoringClick(rowData as HiveTable)
  // });

  return (
    React.createElement(Grid, { container: true, spacing: 3,}
      , React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3,}
        , React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3,}
          , React.createElement(Grid, { item: true,}
            , React.createElement(MButton, { color: "primary", variant: "contained", onClick: () => nav('attach-input'),}, "Attach Input tables"

            )
          )
          , React.createElement(Grid, { item: true,}
            , React.createElement(MButton, { color: "primary", variant: "contained", onClick: () => nav('attach-output'),}, "Attach Output tables"

            )
          )
          , React.createElement(Grid, { item: true,}
            , React.createElement(MButton, { color: "primary", variant: "contained", onClick: () => nav('output/create'),}, "Create Output table"

            )
          )
        )
        , React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3,}
          , React.createElement(Grid, { item: true,}
            , React.createElement(FormControl, null
              , React.createElement(InputLabel, { id: "data-env-selector-label",}, "Data Environment" )
              , React.createElement(Select, {
                labelId: "data-env-selector-label",
                id: "data-env-selector",
                value: dataEnvironment,
                onChange: handleChangeDataEnvironment,
                defaultValue: dataEnvironment,}
              
                , React.createElement(MenuItem, { value: "prod",}, "production")
                , React.createElement(MenuItem, { value: "dev",}, "development")
              )
            )
          )
        )
      )
      , React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1,}
        , React.createElement(Grid, { item: true, md: 12,}
          , React.createElement(ErrorBoundary, { slackChannel: "backstage",}
            , React.createElement(Table, {
              title: "Input Tables" ,
              options: {
                actionsColumnIndex: -1
              },
              isLoading: isLoading,
              data: applicationTables?.inputTables ?? [],
              columns: columns,
              actions: [
                {
                  icon: () => React.createElement(RemoveCircleOutlineIcon, null ),
                  tooltip: 'Detach input table',
                  onClick: (_, rowData) => handleOpenDetachTableDialog(rowData , 'input')
                }
              ],}
            )
          )
        )
        , React.createElement(Grid, { item: true, md: 12,}
          , React.createElement(ErrorBoundary, { slackChannel: "backstage",}
            , React.createElement(Table, {
              options: {
                actionsColumnIndex: -1
              },
              title: "Output Tables" ,
              isLoading: isLoading,
              data: applicationTables?.outputTables ?? [],
              columns: columns,
              actions: outputTablesActions,}
            )
            /* TODO - Use an AlertDialog instead of duplicating dialogs */
            , React.createElement(Dialog, {
              open: openCloneTableDialog,
              onClose: handleCloseCloneTableDialog,
              'aria-labelledby': "alert-dialog-title",
              'aria-describedby': "alert-dialog-description",}
            
              , React.createElement(DialogTitle, { id: "alert-dialog-title",}, "Do you want to clone this table metadata?"       )
              , React.createElement(DialogContent, null, "Cloning from "
                  , React.createElement('b', null, dataEnvironment), " to "  , React.createElement('b', null, dataEnvironment === 'prod' ? 'dev' : 'prod')
                , React.createElement('br', null )
                , React.createElement('b', null, "Database:"), " " , selectedTableToClone?.database, " " , React.createElement('br', null )
                , React.createElement('b', null, "Table:"), " " , selectedTableToClone?.name, " " , React.createElement('br', null )
                , React.createElement('b', null, "Note:"), " No data will be cloned, only metadata"
              )
              , React.createElement(DialogActions, null
                , React.createElement(MButton, { onClick: handleCloseCloneTableDialog, color: "primary",}, "Cancel"

                )
                , React.createElement(MButton, { onClick: handleCloneTable, color: "primary",}, "Clone"

                )
              )
            )
            , React.createElement(Dialog, {
              open: openDeleteTableDialog,
              'aria-labelledby': "alert-dialog-title",
              'aria-describedby': "alert-dialog-description",}
            
              , React.createElement(DialogTitle, { id: "delete-table-dialog",}, "Do you want to delete this table?"      )
              , React.createElement(DialogContent, null
                , React.createElement('b', null, "Database:"), " " , selectedTableToDelete?.database, " " , React.createElement('br', null )
                , React.createElement('b', null, "Table:"), " " , selectedTableToDelete?.name, " " , React.createElement('br', null )
                , React.createElement('br', null )
                , deleteTableDialogMessage, " " , React.createElement('br', null )
                , React.createElement('b', null, "WARNING! IT WILL CAUSE COMPLETE DATA LOSS"      )
              )
              , React.createElement(DialogActions, null
                , React.createElement(MButton, { onClick: handleDeleteTableConfirmation, color: "secondary",}, "Delete"

                )
                , React.createElement(MButton, { onClick: handleCloseDeleteTableDialog, color: "primary",}, "Keep"

                )
              )
            )
          )
        )
      )
      , React.createElement(Dialog, {
        open: openDetachInputTableDialog,
        onClose: handleCloseDetachInputTableDialog,
        'aria-labelledby': "alert-dialog-title",
        'aria-describedby': "alert-dialog-description",}
      
        , React.createElement(DialogTitle, { id: "alert-dialog-title",}, "Do you want to detach this input table?"       )
        , React.createElement(DialogContent, null
          , React.createElement(DialogContentText, { id: "alert-dialog-description",}
            , React.createElement('b', null, "Database: " )
            , selectedTableToDetachInput?.database
            , React.createElement('br', null )
            , React.createElement('b', null, "Table: " )
            , selectedTableToDetachInput?.name
            , React.createElement('br', null ), "Detaching the table won't cause any data loss."

          )
        )
        , React.createElement(DialogActions, null
          , React.createElement(MButton, { onClick: handleCloseDetachInputTableDialog, color: "primary",}, "Cancel"

          )
          , React.createElement(MButton, { onClick: handleDetachInputTable, color: "primary",}, "Detach"

          )
        )
      )
      , React.createElement(Dialog, {
        open: openDetachOutputTableDialog,
        onClose: handleCloseDetachOutputTableDialog,
        'aria-labelledby': "detach-output-title",
        'aria-describedby': "detach-output-content",}
      
        , React.createElement(DialogTitle, { id: "detach-output-title",}, "Do you want to detach this output table?"       )
        , React.createElement(DialogContent, null
          , React.createElement(DialogContentText, { id: "detach-output-content",}
            , React.createElement('b', null, "Database: " )
            , selectedTableToDetachOutput?.database
            , React.createElement('br', null )
            , React.createElement('b', null, "Table: " )
            , selectedTableToDetachOutput?.name
            , React.createElement('br', null ), "Detaching the table won't cause any data loss."

            , React.createElement('br', null )
            , React.createElement('b', null, "Note: " ), "The owner will remain accountable for the table"
          )
        )
        , React.createElement(DialogActions, null
          , React.createElement(MButton, { onClick: handleCloseDetachOutputTableDialog, color: "primary",}, "Cancel"

          )
          , React.createElement(MButton, { onClick: handleDetachOutputTable, color: "primary",}, "Detach"

          )
        )
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: openBackdrop,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
