import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button as MButton, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';


// Refer to mlflow validation https://github.com/mlflow/mlflow/blob/master/mlflow/utils/validation.py
const runIdValidationRegex = /^[a-zA-Z0-9][\w\-]{0,255}$/i;

const validationSchema = Yup.object().shape({
  run_id: Yup.string()
    .matches(runIdValidationRegex, 'MLFlow Run ID should be a valid ID type')
    .required('Run ID from MLFlow is required'),
  environment: Yup.string().required().oneOf(['production', 'staging'], 'Environment should be production or staging'),
  replicas: Yup.number()
    .integer()
    .required()
    .when('environment', (environment, schema) =>
      environment === 'production'
        ? schema.min(3, 'In production, replicas must be greater than or equal to 3')
        : schema.min(1)
    ),
  number_of_cores: Yup.number().required().min(0, 'Number of cores must be positive'),
  memory: Yup.number().required().min(0, 'Memory must be positive')
});






export const CreateMachineLearningDeployForm = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate();
  let initialData = initialValues;
  if (!initialData) {
    initialData = {
      run_id: 'Run ID',
      environment: 'staging',
      replicas: 1,
      number_of_cores: 1,
      memory: 256,
      editable: true
    };
  }

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(Formik, { initialValues: initialData, validationSchema: validationSchema, onSubmit: onSubmit,}
        , ({ values, touched, errors, handleChange }) => (
          React.createElement(Form, { noValidate: true, autoComplete: "off",}
            , React.createElement(Grid, { item: true, container: true, xs: 12,}
              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "run_id",
                  label: "Run ID" ,
                  name: "run_id",
                  fullWidth: true,
                  required: true,
                  value: values.run_id,
                  onChange: handleChange,
                  error: touched.run_id && Boolean(errors.run_id),
                  helperText: touched.run_id && errors.run_id,
                  disabled: !values.editable,}
                )
              )

              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(FormControl, { fullWidth: true,}
                  , React.createElement(InputLabel, { id: "environment",}, "Environment")
                  , React.createElement(Select, {
                    id: "environment",
                    label: "Environment",
                    name: "environment",
                    fullWidth: true,
                    required: true,
                    value: values.environment,
                    onChange: handleChange,
                    error: touched.environment && Boolean(errors.environment),
                    disabled: !values.editable,}
                  
                    , React.createElement(MenuItem, { value: "staging",}, "Staging")
                    , React.createElement(MenuItem, { value: "production",}, "Production")
                  )
                )
              )

              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "replicas",
                  label: "Replicas",
                  name: "replicas",
                  fullWidth: true,
                  required: true,
                  value: values.replicas,
                  onChange: handleChange,
                  error: touched.replicas && Boolean(errors.replicas),
                  helperText: touched.replicas && errors.replicas,}
                )
              )

              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "number_of_cores",
                  label: "Number of cores (Kubernetes Cluster)"    ,
                  name: "number_of_cores",
                  fullWidth: true,
                  required: true,
                  value: values.number_of_cores,
                  onChange: handleChange,
                  error: touched.number_of_cores && Boolean(errors.number_of_cores),
                  helperText: touched.number_of_cores && errors.number_of_cores,}
                )
              )

              , React.createElement(Grid, { item: true, xs: 12,}
                , React.createElement(TextField, {
                  id: "memory",
                  label: "Memory (Mb)" ,
                  name: "memory",
                  fullWidth: true,
                  required: true,
                  value: values.memory,
                  onChange: handleChange,
                  error: touched.memory && Boolean(errors.memory),
                  helperText: touched.memory && errors.memory,}
                )
              )

              , React.createElement(Grid, { item: true, container: true, xs: 12,}
                , React.createElement(MButton, {
                  onClick: () => {
                    navigate(-1);
                  },}
                , "Back"

                )
                , React.createElement(MButton, { variant: "contained", color: "primary", type: "submit",}, "Deploy"

                )
              )
            )
          )
        )
      )
    )
  );
};
