import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  title: 'workforce'
});

export const squadDetailRouteRef = createRouteRef({
  id: 'edit-application',
  params: ['namespace', 'id'],
  path: 'workforce/:namespace/squad/:id'
});
