import { InvalidPageableError } from '@ring/errors';

export class Pageable {
   static DEFAULT = new Pageable(0, 20);
   static UNPAGED = new Pageable(-1, -1);
   static SINGLE_RESULT = new Pageable(0, 1);

   static DEFAULT_PAGE_SIZE = 20;

    _page;

    _size;

  constructor(page, size) {
    this._page = page;
    this._size = size;
  }

  get page() {
    return this._page;
  }

  get size() {
    return this._size;
  }

  isValid() {
    return this._page >= 0 && this._size >= 0;
  }

  validate() {
    if (this._size < 0) {
      if (this._page < 0 && !this.privateIsUnpaged())
        throw InvalidPageableError.fromInvalidPageAndSize(this._page, this._size);

      throw InvalidPageableError.fromInvalidPage(this._page);
    }

    if (this.page < 0) throw InvalidPageableError.fromInvalidSize(this._size);
  }

  isUnpaged() {
    return this.privateIsUnpaged();
  }

  privateIsUnpaged() {
    return this._size === Pageable.UNPAGED._size && this._page === Pageable.UNPAGED._page;
  }
}
