import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import { InfoCard, Table } from '@backstage/core-components';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';

import { useNavigate } from 'react-router-dom';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import useSWR, { mutate } from 'swr';
import { makeStyles, createStyles, } from '@material-ui/core/styles';

import { useSessionStorage } from 'react-use';
import { dataCatalogTableColumns } from '../../utils';

const columns = dataCatalogTableColumns;

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
);

const OutputTableSelectionTable = ({
  tables,
  onTableSelectionChange,
  isLoading
}



) => {
  return (
    React.createElement(Table, {
      options: {
        selection: true,
        showSelectAllCheckbox: false
      },
      title: "Available Tables" ,
      data: tables,
      columns: columns,
      onSelectionChange: onTableSelectionChange,
      localization: {
        toolbar: {
          nRowsSelected: '{0} table(s) selected'
        }
      },
      isLoading: isLoading,}
    )
  );
};

const OutputTableAttachmentConfirmationDialog = ({
  tables,
  open,
  onClose,
  onClick
}




) => {
  return (
    React.createElement(Dialog, {
      open: open,
      onClose: onClose,
      'aria-labelledby': "alert-dialog-title",
      'aria-describedby': "alert-dialog-description",}
    
      , React.createElement(DialogTitle, { id: "alert-dialog-title",}, "Attach the following tables to your applications?"      )
      , React.createElement(DialogContent, null
        , React.createElement(Table, {
          options: {
            paging: false,
            search: false,
            showTitle: false,
            toolbar: false,
            padding: 'dense'
          },
          data: tables,
          columns: columns,}
        )
      )
      , React.createElement(DialogActions, null
        , React.createElement(Button, { onClick: onClose, color: "primary",}, "Cancel"

        )
        , React.createElement(Button, { onClick: onClick, color: "primary",}, "Confirm"

        )
      )
    )
  );
};

export const AttachOutputTablePage = ({ application }) => {
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const [selectedTables, setSelectedTables] = useState([] );
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const classes = useStyles();
  const [dataEnvironment] = useSessionStorage('dataEnvironment', 'prod');

  const applicationTablesKey = `v1/applications/${application.name}/tables`;
  const tablesKey = 'v1/tables';

  const { data: applicationTables, error: applicationTablesError } = useSWR(
    [applicationTablesKey, dataEnvironment],
    async (_, scope) => {
      return await dataCatalogApi.getApplicationTables(application.name, scope);
    }
  );

  const { data: tables, error } = useSWR(tablesKey, async () => {
    return await dataCatalogApi.listTables();
  });

  const isLoading = (!tables && !error) || (!applicationTables && !applicationTablesError);

  const outputTableIDs = applicationTables?.outputTables.map(table => table?.id);

  const availableTables = isLoading
    ? []
    : tables?.filter(table => {
        return !(
          table.tags?.includes('DEPRECATED') ||
          table.tags?.includes('INTERMEDIATE_TABLE') ||
          table.produced_by?.length ||
          outputTableIDs?.includes(table.id)
        );
      });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmAttachment = async () => {
    try {
      setOpenBackdrop(true);
      await dataCatalogApi.attachTables(application.name, dataEnvironment, selectedTables, 'output');
      mutate(applicationTablesKey, {
        inputTables: applicationTables?.inputTables,
        outputTables: [...(applicationTables?.outputTables ?? []), ...selectedTables]
      });
      navigate(-1);
    } catch (e) {
      setOpenBackdrop(false);
      errorApi.post(e);
    }
  };

  const onTableSelectionChange = (rows, _) => {
    setSelectedTables(rows.map(item => ({ ...item })));
  };

  return (
    React.createElement(Grid, { item: true,}
      , React.createElement(InfoCard, { title: "Attach Output Table"  ,}
        , React.createElement(Grid, { container: true,}
          , React.createElement(Grid, { item: true, xs: 12,}
            , React.createElement(OutputTableSelectionTable, {
              tables: availableTables ?? [],
              onTableSelectionChange: onTableSelectionChange,
              isLoading: isLoading,}
            )
          )
          , React.createElement(Grid, { item: true,}
            , React.createElement(Button, { onClick: () => navigate(-1),}, "Back")
            , React.createElement(Tooltip, {
              title: "Select at least one table"    ,
              disableHoverListener: selectedTables.length > 0,
              disableFocusListener: selectedTables.length > 0,
              disableTouchListener: selectedTables.length > 0,}
            
              , React.createElement('span', null
                , React.createElement(Button, {
                  variant: "contained",
                  color: "primary",
                  disabled: selectedTables.length === 0,
                  onClick: handleClickOpen,}
                , "Attach Tables"

                )
              )
            )
          )
        )
      )
      , React.createElement(OutputTableAttachmentConfirmationDialog, {
        tables: selectedTables,
        open: open,
        onClose: handleClose,
        onClick: handleConfirmAttachment,}
      )
      , React.createElement(Backdrop, { className: classes.backdrop, open: openBackdrop,}
        , React.createElement(CircularProgress, null )
      )
    )
  );
};
