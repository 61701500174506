

















export const repoPickerValidation = (value, validation) => {
  try {
    const { host, searchParams } = new URL(`https://${value}`);
    if (!host || !searchParams.get('owner') || !searchParams.get('repo')) {
      validation.addError('Incomplete repository location provided');
    }
  } catch {
    validation.addError('Unable to parse the Repository URL');
  }
};
