import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef } from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { WorkforceApi, workforceApiRef } from './api';

import { rootRouteRef } from './routes';

export const workforcePlugin = createPlugin({
  id: 'workforce',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: workforceApiRef,
      deps: {
        identityApiRing: identityApiRingRef,
        discoveryApi: discoveryApiRef
      },
      factory: ({ discoveryApi, identityApiRing }) => new WorkforceApi({ identityApi: identityApiRing, discoveryApi })
    })
  ]
});

export const WorkforcePage = workforcePlugin.provide(
  createRoutableExtension({
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef
  })
);
