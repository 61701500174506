
import {
  DATABRICKS_OPERATOR_DISPLAY_NAME,
  DATABRICKS_POOLS_DISPLAY_NAME,
  DELTA_PARTITION_SENSOR_DISPLAY_NAME,
  HIVE_PARTITION_SENSOR_DISPLAY_NAME,
  EXTERNAL_TASK_SENSOR_DISPLAY_NAME,
  AIRFLOW_TASK_SENSOR_DISPLAY_NAME
} from './DeploymentCommon';

import { useCallback } from 'react';

import { useApplicationBundles } from '../hooks/useApplicationBundles';
import { useEnvironments } from '../hooks/useEnvironments';

export const getSeverityOptions = [
  {
    const: 'wildlifestudios_provider.callbacks.opsgenie.notify_informational_priority_alert',
    title: 'INFORMATIONAL'
  },
  {
    const: 'wildlifestudios_provider.callbacks.opsgenie.notify_low_priority_alert',
    title: 'LOW'
  },
  {
    const: 'wildlifestudios_provider.callbacks.opsgenie.notify_moderated_priority_alert',
    title: 'MODERATE'
  },
  {
    const: 'wildlifestudios_provider.callbacks.opsgenie.notify_high_priority_alert',
    title: 'HIGH'
  },
  {
    const: 'wildlifestudios_provider.callbacks.opsgenie.notify_critical_priority_alert',
    title: 'CRITICAL'
  }
];

export const useDeploymentSchema = (
  entity,
  isEditAction,
  dag_id
) => {
  const { bundles } = useApplicationBundles(entity.metadata.name);

  




  const getBundleOptions = useCallback(bundlesArray => {
    const noneOption = { const: '', title: 'none' };
    if (!bundlesArray) return [noneOption];

    return bundlesArray?.map((bundle) => {
      const version_and_type = `${bundle.category}|${bundle.name}`;
      const display_version_and_type = `[${bundle.category}] ${bundle.name_pretty}`;
      return { const: version_and_type, title: display_version_and_type };
    });
  }, []);
  const { environments } = useEnvironments();

  const NEW_DEPLOYMENT_SCHEMA = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    required: ['startDate', 'environment'],
    title: 'Job details',
    properties: {
      dag_id: {
        type: 'string',
        title: 'ID',
        description: 'Dag ID',
        default: dag_id,
        readOnly: true
      },
      dag_name: {
        type: 'string',
        title: 'Dag Name',
        description:
          'The name of your DAG, if it comes from a repository with multiple DAGs. Use underscore "_" to separate words',
        pattern: '^[_A-Za-z0-9]+$',
        readOnly: isEditAction
      },
      version: {
        type: 'string',
        title: 'Bundle Version',
        oneOf: getBundleOptions(bundles),
        description:
          'This field is required if you have a Databricks Operator task. Otherwise, Databricks does not have access to your code.'
      },
      environment: {
        type: 'string',
        title: 'Data Environment',
        oneOf: environments,
        description: 'Data Environment to be deployed on',
        readOnly: isEditAction
      },
      schedule: {
        type: 'string',
        title: 'Schedule',
        description:
          'Define the application schedule either using a preset (@hourly, @daily,..) or a valid cron expression'
      },
      startDate: {
        type: 'string',
        title: 'Start Date',
        description: 'Define the application start date',
        default: '2021-03-01',
        readOnly: isEditAction
      },
      endDate: {
        type: 'string',
        title: 'End Date',
        description: 'Define the application end date'
      },
      params: {
        type: 'object',
        title: 'Additional Parameters',
        description:
          'Advanced use case. Define additional key-value parameters for the DAG, which will be used by the task via airflow macros. \n' +
          'Example: "games": "all". This parameter can be accessed by the task through "args": {{ params[games] }}, for instance. \n' +
          'These parameters can be used to build a query. \n' +
          'Feature useful in backfills and one-off experiments. Can be overriden at task level. ',
        additionalProperties: {
          type: 'string'
        }
      },
      concurrency: {
        type: 'number',
        title: 'Concurrency',
        description: 'the number of task instances allowed to run concurrently'
      },
      max_active_runs: {
        type: 'number',
        title: 'Maximal Active Runs',
        description:
          "maximum number of active DAG runs, beyond this number of DAG runs in a running state, the scheduler won't create new active DAG runs"
      },
      opsgenie_team_name: {
        type: 'string',
        title: 'Opsgenie Team Name',
        description: 'The team that should be assigned in Opsgenie if the user wants to set alerts'
      },
      catchup: {
        type: 'boolean',
        title: 'Catchup',
        description: 'Perform scheduler catchup (or only run latest)? Defaults to True',
        default: true
      }
    },
    dependencies: {
      opsgenie_team_name: {
        properties: {
          on_failure_callback: {
            type: 'string',
            title: 'Opsgenie Failure Alert Severity',
            oneOf: getSeverityOptions,
            description: 'The severity of the created Opsgenie ticket for failure'
          },
          on_success_callback: {
            type: 'string',
            title: 'Opsgenie Success Alert Severity',
            oneOf: getSeverityOptions,
            description: 'The severity of the created Opsgenie ticket for success'
          }
        }
      }
    }
  };

  return { deploymentSchema: NEW_DEPLOYMENT_SCHEMA };
};

export function getTasksSchema(deps) {
  const enumOperators = [
    DATABRICKS_OPERATOR_DISPLAY_NAME,
    DATABRICKS_POOLS_DISPLAY_NAME,
    DELTA_PARTITION_SENSOR_DISPLAY_NAME,
    HIVE_PARTITION_SENSOR_DISPLAY_NAME,
    EXTERNAL_TASK_SENSOR_DISPLAY_NAME,
    AIRFLOW_TASK_SENSOR_DISPLAY_NAME
  ];

  const schema = {
    // title: "DAG Creation",
    // description: "Create your DAG nodes here",
    type: 'object',
    properties: {
      conditional: {
        title: 'Task Selector',
        $ref: '#/definitions/operator_type'
      }
    },

    definitions: {
      operator_type: {
        title: 'Operator Type',
        type: 'object',
        properties: {
          operator: {
            type: 'string',
            enum: enumOperators,
            default: DATABRICKS_OPERATOR_DISPLAY_NAME
          }
        },
        required: ['operator'],
        dependencies: {
          operator: {
            oneOf: [
              // DATABRICKS PYTHON SCRIPT OPERATOR
              {
                properties: {
                  operator: {
                    enum: [DATABRICKS_OPERATOR_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  script_name: {
                    type: 'string',
                    title: 'Script Name',
                    description: 'string with the exact name of your script in you app/ folder',
                    default: './my_spark_job.py'
                  },
                  driver_node_type: {
                    type: 'string',
                    title: 'Driver Node Type'
                  },
                  worker_node_type: {
                    type: 'string',
                    title: 'Worker Node Type',
                    default: 'i3.xlarge'
                  },
                  num_workers: {
                    type: 'number',
                    title: 'Number Of Workers',
                    description: 'Please add an integer number',
                    minimum: 1,
                    default: 1
                  },
                  args: {
                    type: 'array',
                    title: 'Job Arguments',
                    description: 'List of strings for job arguments. Example: ["--execution-date", "21/05/2021"]',
                    items: {
                      type: 'string'
                    }
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  timeout_seconds: {
                    type: 'integer',
                    title: 'Timeout Seconds',
                    description: 'Seconds after which the task will be declared as timed out (integer)'
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  spark_env_vars: {
                    type: 'object',
                    title: 'Spark Environment Variables',
                    description:
                      'Dictionary with spark env vars. Example: "MY_ENV_VAR": "foo/bar". Deploy will fail if the env var is already defined.',
                    additionalProperties: {
                      type: 'string'
                    }
                  },
                  spark_conf: {
                    type: 'object',
                    title: 'Spark Configuration',
                    description: 'Dictionary with spark configuration. Example: "spark.executor.memory": "1g"',
                    additionalProperties: {
                      type: 'string'
                    }
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name']
              },

              // DATABRICKS POOL OPERATOR
              {
                properties: {
                  operator: {
                    enum: [DATABRICKS_POOLS_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  script_name: {
                    type: 'string',
                    title: 'Script Name',
                    description: 'string with the exact name of your script in you app/ folder',
                    default: './my_spark_job.py'
                  },
                  pool_id: {
                    type: 'string',
                    title: 'Pool ID',
                    description:
                      'The ID of the databricks pool. To get this id, read documentation: https://ring.wildlifestudios.com/docs/default/component/ring/Batch_Data_Application_Deployment/#task-parameters'
                  },
                  num_workers: {
                    type: 'number',
                    title: 'Number Of Workers',
                    description: 'Please add an integer number',
                    minimum: 1,
                    default: 1
                  },
                  args: {
                    type: 'array',
                    title: 'Job Arguments',
                    description: 'List of strings for job arguments. Example: ["--execution-date", "21/05/2021"]',
                    items: {
                      type: 'string'
                    }
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  timeout_seconds: {
                    type: 'integer',
                    title: 'Timeout Seconds',
                    description: 'Seconds after which the task will be declared as timed out (integer)'
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  spark_env_vars: {
                    type: 'object',
                    title: 'Spark Environment Variables',
                    description:
                      'Dictionary with spark env vars. Example: "MY_ENV_VAR": "foo/bar". Deploy will fail if the env var is already defined.',
                    additionalProperties: {
                      type: 'string'
                    }
                  },
                  spark_conf: {
                    type: 'object',
                    title: 'Spark Configuration',
                    description: 'Dictionary with spark configuration. Example: "spark.executor.memory": "1g"',
                    additionalProperties: {
                      type: 'string'
                    }
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name', 'pool_id']
              },

              // DELTA TABLE SENSOR
              {
                properties: {
                  operator: {
                    enum: [DELTA_PARTITION_SENSOR_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  database_name: {
                    type: 'string',
                    title: 'Database',
                    description: 'Table containing the partition that the sensor is going to check'
                  },
                  table_name: {
                    type: 'string',
                    title: 'Table',
                    description: 'Database containing the table that the sensor is going to check'
                  },
                  partition: {
                    type: 'array',
                    title: 'Partition Expression',
                    description:
                      'List of expressions of the partition that the sensor is going to check. Example: field_a = "2021-03-02"',
                    items: {
                      type: 'string'
                    }
                  },
                  poke_interval: {
                    type: 'number',
                    title: 'Poke Interval',
                    description: "Poke Interval in seconds. Defaults to 3'"
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  timeout: {
                    type: 'number',
                    title: 'Timeout',
                    description:
                      'max time allowed for the execution of this task instance, if it goes beyond it will raise and fail.'
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name', 'database_name', 'table_name', 'partition']
              },

              // HIVE TABLE SENSOR
              {
                properties: {
                  operator: {
                    enum: [HIVE_PARTITION_SENSOR_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  database: {
                    type: 'string',
                    title: 'Database',
                    description: 'Table containing the partition that the sensor is going to check'
                  },
                  table: {
                    type: 'string',
                    title: 'Table',
                    description: 'Database containing the table that the sensor is going to check'
                  },
                  partition_name: {
                    type: 'string',
                    title: 'Partition Expression',
                    description:
                      "Expression of the partition that the sensor is going to check. Example: year=2021/month=11/day=03 or year={{ macros.ds_format(ds, '%Y-%m-%d', '%Y') }}/month=11/day=29/account=an_account"
                  },
                  fail_on_empty: {
                    type: 'boolean',
                    title: 'Fail on empty',
                    description: 'Fail when no partitions are found.',
                    default: false
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  timeout: {
                    type: 'number',
                    title: 'Timeout',
                    description:
                      'max time allowed for the execution of this task instance, if it goes beyond it will raise and fail.'
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name', 'database', 'table', 'partition_name']
              },

              // AIRFLOW1 TASK SENSOR
              {
                properties: {
                  operator: {
                    enum: [EXTERNAL_TASK_SENSOR_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  external_dag_id: {
                    type: 'string',
                    title: 'DAG ID',
                    description: 'ID (name) of the DAG that the sensor is going to check'
                  },
                  external_task_id: {
                    type: 'string',
                    title: 'Task ID',
                    description: 'ID of the Task that the sensor is going to check'
                  },
                  execution_delta_days: {
                    type: 'integer',
                    title: 'Execution Delta Days',
                    description: 'Days to consider DAG/task failure before the execution date',
                    default: false
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  timeout: {
                    type: 'number',
                    title: 'Timeout',
                    description:
                      'If The upstream run has a failed state, this task will be on running state until the timeout, after which it will be with a failed state too.'
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name', 'timeout', 'external_dag_id']
              },

              // AIRFLOW2 TASK SENSOR
              {
                properties: {
                  operator: {
                    enum: [AIRFLOW_TASK_SENSOR_DISPLAY_NAME]
                  },
                  task_name: {
                    type: 'string',
                    title: 'Task Name',
                    description: 'must be unique'
                  },
                  external_dag_id: {
                    type: 'string',
                    title: 'DAG ID',
                    description: 'ID (name) of the DAG that the sensor is going to check'
                  },
                  external_task_id: {
                    type: 'string',
                    title: 'Task ID',
                    description: 'ID of the Task that the sensor is going to check'
                  },
                  execution_delta: {
                    type: 'string',
                    title: 'Execution Delta',
                    description:
                      'Time difference with the previous execution to look at. Example: `4 15:23:43` will pass 4 days 15h 23m 43s. More details in RING documentation.',
                    default: false
                  },
                  dependencies: {
                    type: 'array',
                    title: 'Dependencies',
                    description: 'Tasks that must be executed before this one',
                    items: {
                      type: 'string',
                      enum: getAvailableDependencies(deps)
                    },
                    uniqueItems: true
                  },
                  retries: {
                    type: 'number',
                    title: 'Retries',
                    description: 'the number of retries that should be performed before failing the task'
                  },
                  task_concurrency: {
                    type: 'number',
                    title: 'Task Concurrency',
                    description: 'When set, a task will be able to limit the concurrent runs across execution_dates'
                  },
                  timeout: {
                    type: 'number',
                    title: 'Timeout',
                    description:
                      'If The upstream run has a failed state, this task will be on running state until the timeout, after which it will be with a failed state too.'
                  },
                  on_failure_callback: {
                    type: 'string',
                    title: 'Opsgenie Failure Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for failure. Make sure opsgenie_team_name is defined'
                  },
                  on_success_callback: {
                    type: 'string',
                    title: 'Opsgenie Success Alert Severity',
                    oneOf: getSeverityOptions,
                    description:
                      'The severity of the created Opsgenie ticket for success. Make sure opsgenie_team_name is defined'
                  }
                },
                required: ['operator', 'task_name', 'timeout', 'external_dag_id']
              }
            ]
          }
        }
      }
    }
  };
  return schema;
}

function getAvailableDependencies(deps) {
  return deps.concat('');
}
