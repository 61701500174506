import React, { useCallback, useMemo, useState } from 'react';
import { ActionsHeader, AlertDialog, SquadsProvider, useMixpanel } from '@ring/plugin-common';
import { Box, Button, Container, makeStyles, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Content } from '@backstage/core-components';
import * as yup from 'yup';
import { TableSelection } from './TableSelection';
import { TableSetup } from './TableSetup';
import { TableTags } from '../../types';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { dataCatalogApiRef } from '../../dataCatalogApi';
import { useNavigate } from 'react-router';







function noOnePiiTag(value) {
  return value.every((item) => {
    return item.tags === null || item.tags?.length === 0;
  });
}

function validatePiiData(value, testContext) {
  const { parent } = testContext;
  const noOnePiiTagItem = noOnePiiTag(parent.schema_metadata);

  if (value) {
    return noOnePiiTagItem;
  }
  return !noOnePiiTagItem;
}

function getTableTags(prevTags, noPiiColumn) {
  const setTags = new Set(prevTags);
  if (noPiiColumn) {
    setTags.delete(TableTags.PII_DATA);
  } else {
    setTags.add(TableTags.PII_DATA);
  }
  return Array.from(setTags);
}

const schema = yup
  .object()
  .shape({
    tables: yup
      .array(
        yup.object().shape({
          database_name: yup.string().required(),
          table_name: yup.string().required(),
          metadata: yup
            .object()
            .shape({
              description: yup.string(),
              owner: yup.string().required(),
              business_unit: yup.string().required(),
              checkbox: yup
                .boolean() // @ts-ignore - override correct yup type
                .test('PII DATA', 'at least one table column should be checked as PII DATA', validatePiiData),
              tags: yup.array().nullable(true),
              schema_metadata: yup.array(
                yup.object().shape({
                  name: yup.string().required(),
                  description: yup.string(),
                  tags: yup.array().nullable(true)
                })
              )
            })
            .required()
        })
      )
      .required()
  })
  .required();













const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '1rem'
    },
    paddingBottom: '1rem'
  },
  stepper: {
    background: 'none',
    border: 'none',
    padding: '2rem 30vw'
  }
}));

export const ImportTables = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const dataCatalogApi = useApi(dataCatalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);

  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [selectedTables, setSelectedTables] = useState([]);

  const methods = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({})
  });

  const { handleSubmit, formState } = methods;
  const isSubmitting = formState.isSubmitting;

  const onSubmit = useCallback(
    async (data) => {
      const purgedTables = data.tables.map((table) => {
        const noPiiColumn = noOnePiiTag(table.metadata.schema_metadata);
        const prevTags = table.metadata.tags || [];
        const newMetadata = { ...table.metadata, tags: getTableTags(prevTags, noPiiColumn) };
        const { checkbox, ...filteredMetadata } = newMetadata;
        return { ...table, metadata: filteredMetadata };
      });
      try {
        const { success, failure } = await dataCatalogApi.bulkImportTables({ tables: purgedTables });
        const numberOfErrors = failure.length;
        const numberOfSuccesses = success.length;

        track('Import tables form completed', data);

        if (numberOfErrors === 0 && numberOfSuccesses !== 0) {
          alertApi.post({
            severity: 'success',
            message: 'We successfully imported your tables'
          });
        } else if (numberOfErrors !== 0 && numberOfSuccesses !== 0) {
          alertApi.post({
            severity: 'warning',
            message: `We successfully imported ${numberOfSuccesses} your tables. ${numberOfErrors} tables failed to be imported.`
          });
        }

        navigate('/observability/global/');
      } catch (error) {
        errorApi.post(error );
      }
    },
    [dataCatalogApi, track, navigate, alertApi, errorApi]
  );

  const steps = useMemo(
    () => ({
      0: {
        label: 'Table Selection',
        component: (
          React.createElement(TableSelection, {
            onSelectTables: setSelectedTables,
            onSelectDatabase: setSelectedDatabase,
            selectedDatabase: selectedDatabase,
            selectedTables: selectedTables,}
          )
        ),
        nextAction: { label: 'Next', onClick: () => setActiveStep(prev => prev + 1) },
        disabledCondition: selectedTables.length === 0
      },
      1: {
        label: 'Table Setup',
        component: (
          React.createElement(TableSetup, {
            database: selectedDatabase,
            tables: selectedTables,
            onFail: () => {
              setActiveStep(0);
            },}
          )
        ),
        nextAction: {
          label: 'import',
          onClick: () => {
            handleSubmit(onSubmit)();
          },
          tooltip: 'Fill all the required fields to import tables'
        },
        prevAction: { label: 'Back', onClick: () => setOpenDialog(true) },
        disabledCondition: !formState.isValid
      }
    }),
    [formState.isValid, handleSubmit, onSubmit, selectedDatabase, selectedTables]
  );

  const {
    prevAction,
    nextAction,
    component: activeStepComponent,
    disabledCondition
  } = useMemo(() => steps[activeStep], [steps, activeStep]);

  return (
    React.createElement(SquadsProvider, null
      , React.createElement(ActionsHeader, { title: "Import table" ,} )
      , React.createElement(Box, { display: "flex", flexDirection: "column",}
        , React.createElement(FormProvider, { ...methods,}
          , React.createElement('form', { style: { flex: 1 },}
            , React.createElement(Stepper, {
              classes: {
                root: classes.stepper
              },
              activeStep: activeStep,}
            
              , Object.values(steps).map(({ label }) => {
                return (
                  React.createElement(Step, { key: label,}
                    , React.createElement(StepLabel, null, label)
                  )
                );
              })
            )
            , React.createElement(Content, null, activeStepComponent)
            , React.createElement(Container, { maxWidth: "md",}
              , React.createElement('div', { className: classes.actions,}
                , prevAction && (
                  React.createElement(Button, { variant: "outlined", disabled: isSubmitting, color: "primary", onClick: prevAction?.onClick,}
                    , prevAction?.label
                  )
                )
                , React.createElement(Tooltip, { title: (disabledCondition && nextAction?.tooltip) || '', arrow: true,}
                  , React.createElement('span', null
                    , React.createElement(Button, {
                      variant: "contained",
                      color: "primary",
                      disabled: disabledCondition || isSubmitting,
                      onClick: nextAction?.onClick,}
                    
                      , nextAction?.label
                    )
                  )
                )
              )
            )
          )
        )
      )
      , React.createElement(AlertDialog, {
        open: openDialog,
        title: "Unsaved Changes" ,
        message: "Going back to the previous page will clear the information added here. Are you sure you want to proceed?"                  ,
        confirmButtonLeyend: "Proceed",
        cancelButtonLeyend: "Cancel",
        onClose: () => {
          setOpenDialog(false);
        },
        onConfirm: () => {
          setActiveStep(prev => prev - 1);
          setOpenDialog(false);
        },}
      )
    )
  );
};
