import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,

  TimeScale,


} from 'chart.js';

import { useTheme } from '@material-ui/core';
import { DateTime } from 'luxon';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, TimeScale, Legend);





const NUMBER_CHART_COLUMNS = 7;

export const IssuesBarChart = ({ data = [] }) => {
  const theme = useTheme();
  const dataOrdered = data.reverse();
  const labels = dataOrdered.map(({ datetime }) => datetime);
  const dataSuccessChart = dataOrdered.map(({ success }) => success);
  const dataFailureChart = dataOrdered.map(({ failure }) => failure);

  const labelsData = labels.slice(0, NUMBER_CHART_COLUMNS);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        stacked: true,
        grid: {
          color: '#dddddd'
        },
        ticks: {
          color: theme.palette.type === 'dark' ? '#fff' : '#000',
          maxRotation: 0,
          major: {
            enabled: true
          },
          font: context => {
            if (context.tick && context.tick.major) {
              return {
                weight: 'bold'
              } ;
            }
            return undefined;
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: theme.palette.type === 'dark' ? '#fff' : '#000',
          stepSize: 1
        },
        grid: {
          color: '#dddddd',
          borderDash: [5, 5]
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          color: theme.palette.type === 'dark' ? '#fff' : '#000'
        }
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: context => {
            return [
              `Date: ${DateTime.fromISO(labelsData[context.dataIndex])
                .toUTC()
                .startOf('day')
                .toFormat('dd/LLL/yy - hh:mm a z')}`,
              `${context.parsed.y} ${context.dataset.label}`
            ];
          },
          title: () => ''
        }
      }
    }
  };

  const chartData = {
    labels: labelsData,
    datasets: [
      {
        label: 'Issues',
        data: dataFailureChart.slice(0, NUMBER_CHART_COLUMNS),
        backgroundColor: '#E72318'
      },
      {
        label: 'Successful checks',
        data: dataSuccessChart.slice(0, NUMBER_CHART_COLUMNS),
        backgroundColor: '#7ABEF5'
      }
    ]
  };

  return React.createElement(Bar, { options: options, data: chartData,} );
};
