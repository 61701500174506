import { storageApiRef, useApi } from '@backstage/core-plugin-api';

import { useCallback, useEffect, useState } from 'react';
import { useObservable } from 'react-use';

const buildEntityKey = (application) => `${application.name}`;

export const useStarredApplications = (



) => {
  const storageApi = useApi(storageApiRef);
  const settingsStore = storageApi.forBucket('settings');
  const rawStarredApplicationKeys = settingsStore.get('starredApplications') ?? [];

  const [starredApplications, setStarredApplications] = useState(new Set(rawStarredApplicationKeys));

  const observedItems = useObservable(settingsStore.observe$('starredApplications'));

  useEffect(() => {
    if (observedItems?.newValue) {
      const currentValue = observedItems?.newValue ?? [];
      setStarredApplications(new Set(currentValue));
    }
  }, [observedItems?.newValue]);

  const toggleStarredApplication = useCallback(
    (application) => {
      const entityKey = buildEntityKey(application);
      if (starredApplications.has(entityKey)) {
        starredApplications.delete(entityKey);
      } else {
        starredApplications.add(entityKey);
      }

      settingsStore.set('starredApplications', Array.from(starredApplications));
    },
    [starredApplications, settingsStore]
  );

  const isStarredApplication = useCallback(
    (application) => {
      const entityKey = buildEntityKey(application);
      return starredApplications.has(entityKey);
    },
    [starredApplications]
  );

  return {
    starredApplications,
    toggleStarredApplication,
    isStarredApplication
  };
};
