






















































































































































































export var ETAConfigurationCron; (function (ETAConfigurationCron) {
  ETAConfigurationCron['0 * * * *'] = '0 * * * *';
  ETAConfigurationCron['0 0 * * *'] = '0 0 * * *';
})(ETAConfigurationCron || (ETAConfigurationCron = {}));

export var EtaStatus; (function (EtaStatus) {
  const success = 'SUCCESS'; EtaStatus["success"] = success;
  const delayed_success = 'DELAYED_SUCCESS'; EtaStatus["delayed_success"] = delayed_success;
  const failure = 'FAILURE'; EtaStatus["failure"] = failure;
  const pending = 'PENDING'; EtaStatus["pending"] = pending;
  const not_configured = 'NOT_CONFIGURED'; EtaStatus["not_configured"] = not_configured;
})(EtaStatus || (EtaStatus = {}));

export var ColumnExpectationNames; (function (ColumnExpectationNames) {
  const COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN = 'expect_column_count_distinct_values_to_be_between'; ColumnExpectationNames["COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN"] = COL_COUNT_DISTINCT_VALUES_TO_BE_BETWEEN;
  const COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B = 'expect_column_pair_round_values_A_to_be_greater_than_B'; ColumnExpectationNames["COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B"] = COL_PAIR_ROUND_VALUES_A_GREATER_THAN_B;
  const COL_ROUND_VALUES_NOT_BE_ZERO = 'expect_column_round_values_to_not_be_zero'; ColumnExpectationNames["COL_ROUND_VALUES_NOT_BE_ZERO"] = COL_ROUND_VALUES_NOT_BE_ZERO;
  const COL_VALUES_NOT_BE_NULL = 'expect_column_values_to_not_be_null'; ColumnExpectationNames["COL_VALUES_NOT_BE_NULL"] = COL_VALUES_NOT_BE_NULL;
  const COL_VALUES_TO_BE_BETWEEN = 'expect_column_values_to_be_between'; ColumnExpectationNames["COL_VALUES_TO_BE_BETWEEN"] = COL_VALUES_TO_BE_BETWEEN;
  const COL_VALUES_TO_BE_IN_SET = 'expect_column_values_to_be_in_set'; ColumnExpectationNames["COL_VALUES_TO_BE_IN_SET"] = COL_VALUES_TO_BE_IN_SET;
  const COL_VALUES_TO_BE_UNIQUE = 'expect_column_values_to_be_unique'; ColumnExpectationNames["COL_VALUES_TO_BE_UNIQUE"] = COL_VALUES_TO_BE_UNIQUE;
  const COL_VALUES_TO_MATCH_REGEX = 'expect_column_values_to_match_regex'; ColumnExpectationNames["COL_VALUES_TO_MATCH_REGEX"] = COL_VALUES_TO_MATCH_REGEX;
})(ColumnExpectationNames || (ColumnExpectationNames = {}));
export var TableExpectationNames; (function (TableExpectationNames) {
  const TABLE_ROW_COUNT = 'expect_table_row_count_to_be_between'; TableExpectationNames["TABLE_ROW_COUNT"] = TABLE_ROW_COUNT;
  const COMP_COLS_TO_BE_UNIQUE = 'expect_compound_columns_to_be_unique'; TableExpectationNames["COMP_COLS_TO_BE_UNIQUE"] = COMP_COLS_TO_BE_UNIQUE;
  const CUSTOM_VALIDATION = 'custom_validation'; TableExpectationNames["CUSTOM_VALIDATION"] = CUSTOM_VALIDATION;
  const CUSTOM_VALIDATION_GROUPED_RESULTS = 'custom_validation_grouped_results'; TableExpectationNames["CUSTOM_VALIDATION_GROUPED_RESULTS"] = CUSTOM_VALIDATION_GROUPED_RESULTS;
})(TableExpectationNames || (TableExpectationNames = {}));

// END
// Scope: Data Observability

export var TableStatus; (function (TableStatus) {
  const NOT_CONFIGURED = 'NOT_CONFIGURED'; TableStatus["NOT_CONFIGURED"] = NOT_CONFIGURED;
  const ENABLED = 'ENABLED'; TableStatus["ENABLED"] = ENABLED;
  const DISABLED = 'DISABLED'; TableStatus["DISABLED"] = DISABLED;
})(TableStatus || (TableStatus = {}));


















































export var TableTags; (function (TableTags) {
  const PII_DATA = 'PII_DATA'; TableTags["PII_DATA"] = PII_DATA;
})(TableTags || (TableTags = {}));












