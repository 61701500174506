import { makeStyles } from '@material-ui/core';
import React, { } from 'react';
import { Table, } from '@backstage/core-components';









const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

export function TableCard({
  entities,
  title,
  emptyContent,
  variant = 'gridItem',
  columns
}) {
  const classes = useStyles();
  const tableStyle = {
    minWidth: '0',
    width: '100%'
  };

  if (variant === 'gridItem') {
    tableStyle.height = 'calc(100% - 10px)';
  }

  return (
    React.createElement(Table, {
      columns: columns,
      title: title,
      style: tableStyle,
      emptyContent: emptyContent && React.createElement('div', { className: classes.empty,}, emptyContent),
      options: {
        search: false,
        paging: false,
        actionsColumnIndex: -1,
        padding: 'dense'
      },
      data: entities,}
    )
  );
}
