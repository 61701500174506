export const pickColor = (index) => {
  const lightColors = [
    '#2458B2',
    '#E72318',
    '#E0B30B',
    '#1F946B',
    '#F07B54',
    '#52365E',
    '#9B17FF',
    '#11BFA0',
    '#80C5FF',
    '#B34612',
    '#00CF93',
    '#440773',
    '#16F2CB',
    '#008CFF',
    '#640000',
    '#FF3814',
    '#774E8A',
    '#F005C7',
    '#0A7360',
    '#7617BF',
    '#99852E',
    '#956FA6',
    '#9B46DB',
    '#C29E00',
    '#0D8C75',
    '#CC2D10',
    '#27BA6E',
    '#146146',
    '#161616',
    '#002E54'
  ];
  return lightColors[index % lightColors.length];
};
