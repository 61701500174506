

















import { createApiRef } from '@backstage/core-plugin-api';


export const apiDocsConfigRef = createApiRef({
  id: 'plugin.api-docs.config',
  description: 'Used to configure api-docs widgets'
});





