import React from 'react';
import { Content, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { InfraPage } from '../InfraPage';
import { useAsync } from 'react-use';
import { ServiceManager } from '@ring/model';
import { useProvisioningApi } from '../../utils';
import { useApplication } from '@ring/plugin-catalog';

export const EntityInfraResourcesContent = ({ service }) => {
  const { application } = useApplication();
  const backendAPI = useProvisioningApi();
  const repositoryID = application?.template?.toString().endsWith('staging')
    ? 'infrastructure-staging'
    : 'infrastructure';
  const {
    value: repo,
    loading,
    error: repositoryError
  } = useAsync(
    () => backendAPI.getInfraRepository(application?.system || application?.name || '', repositoryID),
    [backendAPI]
  );

  if (!application) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error missed entity\""  )
      )
    );
  }

  if (loading) {
    return (
      React.createElement(Content, null
        , React.createElement(Progress, null )
      )
    );
  }
  if (repositoryError) {
    return (
      React.createElement(Content, null
        , React.createElement(Alert, { severity: "error",}, "\"Error fetching infrastructure data\" "    , repositoryError.message)
      )
    );
  }

  service.spec.application = application.name;
  service.spec.applicationId = application.id;
  service.spec.system = application.system;

  return (
    React.createElement(InfraPage, {
      project: repo,
      application: application,
      service: {
        ...service,
        owner: `application:${application.name}`,
        type: service.spec.service_name,
        managedBy: ServiceManager.RING
      },}
    )
  );
};
