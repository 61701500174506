
import { IllegalArgumentError, InvalidPageableError } from '@ring/errors';

export class Page {
   static EMPTY_PAGE = new Page(0, 0, 0, []);

    _page;
    _size;
    _totalPages;
    _data;

  constructor(page, size, totalPages, data) {
    this._page = page;
    this._size = size;
    this._totalPages = totalPages;
    this._data = data;

    if (page > totalPages && !this.isEmpty()) throw InvalidPageableError.fromInvalidPageNumber(page, totalPages);
  }

  static unpaged(data) {
    return new Page(0, data.length, 1, data);
  }

  static empty() {
    return Page.EMPTY_PAGE;
  }

   isEmpty() {
    return !this._page && !this._totalPages && !this._data.length;
  }

   static parse(page) {
    if (
      page.hasOwnProperty('page') &&
      page.hasOwnProperty('size') &&
      page.hasOwnProperty('totalPages') &&
      page.hasOwnProperty('data')
    )
      return new Page(page.page, page.size, page.totalPages, page.data);

    throw new IllegalArgumentError('the object is not a page');
  }

  get page() {
    return this._page;
  }

  get size() {
    return this._size;
  }

  get totalPages() {
    return this._totalPages;
  }

  get data() {
    return this._data;
  }

  toJSON() {
    return {
      page: this._page,
      size: this._size,
      totalPages: this._totalPages,
      data: this._data
    };
  }
}
