import React from 'react';

import { Box, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import dashboardIcon from '../../assets/dashboard-icon.svg';
import LaunchIcon from '@material-ui/icons/Launch';





const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: '0 0 450px',
    overflow: 'hidden',
    gap: '1rem',
    padding: '1.5rem 1rem'
  },
  title: {
    fontWeight: 700,
    fontSize: '1rem'
  },
  image: {
    width: '100%',
    maxWidth: '75px'
  }
}));

export const DashboardCard = ({ dashboard }) => {
  const classes = useStyles();
  const { name, id } = dashboard;
  const url = `https://app.datadoghq.com/dashboard/${id}`;
  return (
    React.createElement(Paper, { elevation: 1, className: classes.root,}
      , React.createElement('img', { className: classes.image, src: dashboardIcon, alt: "dashboard",} )
      , React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: '1rem' },}
        , React.createElement(Typography, { className: classes.title, variant: "h6",}
          , name
        )
        , React.createElement(Link, { href: url, target: "_blank", underline: "none", color: "primary",}
          , React.createElement(Box, { display: "flex", alignItems: "center",}
            , React.createElement('span', { style: { fontWeight: 'bold', textTransform: 'uppercase', margin: '0 4px' },}, "Open Dashboard" )
            , React.createElement(LaunchIcon, { color: "primary", fontSize: "small",} )
          )
        )
      )
    )
  );
};
