import { createApiRef, } from '@backstage/core-plugin-api';












import axios from 'axios';
import { isEmpty } from 'lodash';
import qs from 'qs';

export const scaffolderApiRef = createApiRef({
  id: 'plugin.scaffoldervtwo.service',
  description: 'Used to make requests towards the scaffoldervtwo backend'
});

export function isOk(res) {
  return res.status >= 200 && res.status < 300;
}

export class ScaffolderApi {
    identityApi;
    discoveryApi;

  commonHeaders = {
    'Content-Type': 'application/json'
  };

  constructor(options) {
    this.identityApi = options.identityApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getApplicationTemplates() {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/templates?page=0&size=1000`; //  FIXME: use backend pagination shape when possible

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();

    if (Array.isArray(body.data) && body.data.length > 0) {
      // Show only the Data Product template (ID 8)
      const filteredTemplates = (
        body.data 
      ).filter(template => template.id === 8);

      body.data = filteredTemplates;
    }

    return body || [];
  }

  async getApplication(applicationId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/applications/${applicationId}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async postApplication(application) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/applications`;

    const response = await axios({
      method: 'post',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: application
    });

    return response.data;
  }

  async getSystems(params) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const queryString = qs.stringify(isEmpty(params) ? {} : params, {
      addQueryPrefix: true
    });
    const url = `${baseUrl}/core-api/systems${queryString}`; //  FIXME: use backend pagination shape when possible

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async postSystem(newSystem) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/systems`;

    const response = await axios({
      method: 'post',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: newSystem
    });

    return response.data;
  }

  async getMe() {
    const token = await this.identityApi.getIdToken();
    const username = (await this.identityApi.getProfile().email?.split('@')[0]) ?? '';
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/users?username=${username}`;

    const response = await axios({
      method: 'get',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.data.data.length) {
      return response.data.data[0];
    }

    return response.data;
  }

  async getServicesByApplication(applicationId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/applications/${applicationId}/services`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async getService(serviceId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/services/${serviceId}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  async getSquad(squadId) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${baseUrl}/core-api/squads/${squadId}`;

    const response = await fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      }
    });

    if (response.status !== 200) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }
    const body = await response.json();
    return body || [];
  }

  getUserEmail() {
    return this.identityApi.getProfile().email;
  }
}
