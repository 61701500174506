import * as yup from 'yup';
import { ValidationError } from '..';
import { Notification, NotificationType } from './types';

;
export { NotificationType } from './types';

const validationSchema = yup.object().shape({
  sender: yup.string().required(),
  type: yup.mixed().required(),
  content: yup.string().required()
});

export function notificationCast(data) {
  try {
    return validationSchema.validateSync(data, { abortEarly: false });
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      const fieldErrors = error.inner.map(innerError => ({
        fieldName: innerError.path || '',
        description: innerError.message
      }));
      throw new ValidationError(fieldErrors, error.message);
    } else {
      throw error;
    }
  }
}
