
import { serializeUserFilter, BaseClient } from '@ring/clients';

import { processResponseError } from '@ring/utils';
import qs from 'qs';
import { getPluginBaseUrl, getToken } from '../../..';
import fetch from 'cross-fetch';

export class UserApiClient extends BaseClient {
    identityApi;
    discoveryApi;

  constructor(discoveryApi, identityApi) {
    super(getPluginBaseUrl('users', discoveryApi), serializeUserFilter, getToken(identityApi));
    this.identityApi = identityApi;
    this.discoveryApi = discoveryApi;
  }

   async updateAll(users) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('users');

    const response = await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(users)
    });

    if (!response.ok) return processResponseError(response);

    return (await response.json()) ;
  }

   async findAllDependants(name, options) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('users');

    const queryString = qs.stringify(options?.level ? { steps: options.level } : {}, {
      addQueryPrefix: true
    });

    const url = `${baseUrl}/${name}/dependants${queryString}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) return processResponseError(response);

    return (await response.json()) ;
  }

   async deleteByName(name) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/${name}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) return processResponseError(response);

    return (await response.json()) ;
  }
}
