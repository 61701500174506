import React, { useEffect, useState } from 'react';
import { dataObservabilityApiRef } from '../../dataObservabilityApi';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, Button, CircularProgress, FormHelperText, MenuItem, TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useAsync } from 'react-use';
import { TimePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import luxonUtils from '@date-io/luxon';
import * as yup from 'yup';
import cron from 'cron-validate';
import cronstrue from 'cronstrue/i18n';

import { useMixpanel } from '@ring/plugin-common';












var PeriodOptions; (function (PeriodOptions) {
  const DAILY = 'daily'; PeriodOptions["DAILY"] = DAILY;
  const CRON = 'cron'; PeriodOptions["CRON"] = CRON;
})(PeriodOptions || (PeriodOptions = {}));

const schema = yup.object().shape({
  name: yup.string().required(),
  expression: yup
    .string()
    .required()
    .test('valid cron', '', (val, ctx) => {
      const cronResult = cron(val, {
        preset: 'default',
        override: {
          useLastDayOfWeek: true,
          useLastDayOfMonth: true
        }
      });
      return cronResult.isValid() ? true : ctx.createError({ message: cronResult.getError().join(' ') });
    })
});

export const DashboardReport = ({ dashboard, edit = false }) => {
  const dataObservabilityApi = useApi(dataObservabilityApiRef);
  const alertApi = useApi(alertApiRef);
  const { track } = useMixpanel();
  const [period, setPeriod] = useState(PeriodOptions.CRON);
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(false);
  const [newCreatedReport, setNewCreatedReport] = useState();

  const { control, handleSubmit, formState, getValues, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: dashboard?.notification_group_name ?? '',
      expression: dashboard?.report_schedule_expression ?? '0 0 * * *'
    }
  });

  useEffect(() => {
    if (dashboard?.notification_group_name)
      reset({
        name: dashboard.notification_group_name,
        expression: dashboard.report_schedule_expression
      });
  }, [dashboard, reset]);

  const errors = formState.errors;

  const { value: NotificationGroups = [], loading: loadingNotificationGroups } = useAsync(
    async () => await dataObservabilityApi.getNotificationGroups()
  );
  const onSubmit = async (values) => {
    if (!dashboard) return;

    const payload = {
      name: dashboard.name,
      notification_group_id: NotificationGroups.find(ng => ng.name === values.name)?.id ?? ' ',
      dashboard_id: dashboard?.id,
      schedule_expression: values.expression,
      report_only_failed: false,
      enabled: true
    };

    try {
      setLoading(true);
      if (edit || newCreatedReport) {
        await dataObservabilityApi.editDashboardReport({
          ...payload,
          id: newCreatedReport ? newCreatedReport.id : dashboard.dashboard_report_id
        });
      } else {
        const newCreated = await dataObservabilityApi.createDashboardReport(payload);
        setNewCreatedReport(newCreated);
      }
      alertApi.post({
        severity: 'success',
        message: `Dashboard report was successfuly ${edit ? 'edited' : 'atached'}.`
      });

      track('Dashboard Report form submitted');
    } catch (e) {
      alertApi.post({
        severity: 'error',
        message: `There was an error:\n ${e}`
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue('expression', `${date?.minute} ${date?.hour} * * *`);
  };

  if (!dashboard || loadingNotificationGroups)
    return (
      React.createElement(Box, { minHeight: "80px", display: "flex", justifyContent: "center", alignItems: "center",}
        , React.createElement(CircularProgress, { size: 20,} )
      )
    );

  return (
    React.createElement(MuiPickersUtilsProvider, { utils: luxonUtils,}
      , React.createElement('form', null
        , React.createElement(Box, { display: "flex", py: "1rem", style: { gap: '1rem' },}
          , React.createElement(Controller, {
            name: "name",
            render: ({ field }) => (
              React.createElement(TextField, {
                style: { width: 320 },
                select: true,
                label: "Notification group" ,
                InputLabelProps: { shrink: true },
                ...field,}
              
                , NotificationGroups.map(({ name, id }) => (
                  React.createElement(MenuItem, { key: id, value: name,}
                    , name
                  )
                ))
              )
            ),
            control: control,}
          )

          , React.createElement(TextField, {
            value: period,
            onChange: (event) => {
              const cronParsed = getValues('expression').split(' ');
              setSelectedDate(
                new Date(0, 0, 0, +cronParsed[1] ?? 0, +cronParsed[0] ?? 0) 
              );
              setPeriod(event.target.value );
            },
            style: { width: 180 },
            select: true,
            label: "Period",
            InputLabelProps: { shrink: true },}
          
            , Object.entries(PeriodOptions).map(([key, value]) => (
              React.createElement(MenuItem, { key: key, value: value,}
                , value
              )
            ))
          )
          , period === PeriodOptions.CRON ? (
            React.createElement(Controller, {
              name: "expression",
              render: ({ field }) => (
                React.createElement(TextField, { InputLabelProps: { shrink: true }, label: "Cron Expression" , ...field,} )
              ),
              control: control,}
            )
          ) : (
            React.createElement(TimePicker, {
              format: "hh:mm",
              showTodayButton: true,
              todayLabel: "now",
              label: "Daily",
              value: selectedDate,
              onChange: handleDateChange,}
            )
          )

          , React.createElement(Button, {
            variant: "contained",
            color: "primary",
            endIcon: loading && React.createElement(CircularProgress, { size: 16,} ),
            disabled: loading,
            onClick: handleSubmit(onSubmit),}
          , "Save"

          )
        )
        , React.createElement(Box, null
          , Object.keys(errors).length ? (
            React.createElement(FormHelperText, { error: true,}, errors?.expression?.message || errors?.name?.message)
          ) : (
            React.createElement(Box, null, getValues('expression') && cronstrue.toString(getValues('expression')))
          )
        )
      )
    )
  );
};
