import React, { useCallback } from 'react';
import { Table } from '@backstage/core-components';
import { TableTags } from '../../types';
import { Box, Checkbox, TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { MTableToolbar } from 'material-table';





const cellStyle = { padding: '0.5rem 16px' };





export const FieldsTable = ({ editMode }) => {
  const disabledForm = editMode ? false : true;
  const { getValues, setValue } = useFormContext();
  const fields = getValues(`table.metadata.schema_metadata`);

  const noPIIData = getValues(`table.metadata.checkbox`);

  const fieldsWithIndex = useCallback(
    () => fields.map((field, index) => ({ ...field, index } )),
    [fields]
  );

  const handleCheckbox = (index) => (_, checked) => {
    const actualValues = getValues(`table.metadata.schema_metadata[${index}].tags`) || [];
    if (checked) {
      setValue(`table.metadata.schema_metadata[${index}].tags`, [...actualValues, TableTags.PII_DATA], {
        shouldValidate: true
      });
    } else {
      setValue(
        `table.metadata.schema_metadata[${index}].tags`,
        actualValues.filter((tag) => tag !== TableTags.PII_DATA),
        { shouldValidate: true }
      );
    }
  };

  return (
    React.createElement(Table, {
      data: fieldsWithIndex(),
      columns: [
        {
          title: 'PII',
          type: 'boolean',
          sorting: false,
          cellStyle: { ...cellStyle, textAlign: 'center' },
          width: '10%',
          render: ({ tags, index }) => (
            React.createElement(Checkbox, {
              style: { marginLeft: '-1rem' },
              checked: tags?.includes(TableTags.PII_DATA),
              onChange: handleCheckbox(index),
              disabled: disabledForm && noPIIData,}
            )
          )
        },
        {
          field: 'name',
          title: 'name',
          editable: 'never',
          sorting: false,
          cellStyle,
          width: '35%'
        },
        {
          title: 'description',
          sorting: false,
          cellStyle,
          width: '55%',
          render: ({ index }) => {
            return (
              React.createElement(TextField, {
                placeholder: "Add description" ,
                fullWidth: true,
                multiline: true,
                minRows: 1,
                defaultValue: getValues(`table.metadata.schema_metadata[${index}].description`),
                onChange: e => {
                  setValue(`table.metadata.schema_metadata[${index}].description`, e.target.value);
                },
                disabled: disabledForm,}
              )
            );
          }
        }
      ],
      options: {
        loadingType: 'overlay',
        paging: false,
        padding: 'dense',
        showSelectAllCheckbox: false,
        maxBodyHeight: '500px'
      },
      localization: {
        body: {
          emptyDataSourceMessage: 'This table has no fields'
        }
      },
      title: 
        React.createElement(Box, { whiteSpace: "nowrap", width: "300px", textAlign: "left",}, "Column information"

        )
      ,
      components: {
        Toolbar: props => {
          return React.createElement(MTableToolbar, { ...props, selectedRows: [],} );
        }
      },}
    )
  );
};
