

import axios from 'axios';

export class MixpanelApiClient  {
    identityApi;
    discoveryApi;

  constructor(identityApi, discoveryApi) {
    this.identityApi = identityApi;
    this.discoveryApi = discoveryApi;
  }

  async track(name, props) {
    const token = await this.identityApi.getIdToken();
    const baseUrl = await this.discoveryApi.getBaseUrl('mixpanel');
    const url = `${baseUrl}/track`;

    await axios({
      method: 'post',
      url,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` })
      },
      data: { name, props }
    });

    return;
  }
}
