import React from 'react';
import { Box } from '@material-ui/core';

import { FavouriteApplication } from '../FavouriteApplication/FavouriteApplication';

export const ApplicationPageTitle = ({
  application,
  title
}


) => (
  React.createElement(Box, { display: "inline-flex", alignItems: "center", height: "1em",}
    , title
    , application && React.createElement(FavouriteApplication, { application: application,} )
  )
);
