import React from 'react';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { SidebarItem, SidebarScrollWrapper } from '@backstage/core-components';
import { alertApiRef, useApi, featureFlagsApiRef } from '@backstage/core-plugin-api';
import { useAsync, useCopyToClipboard } from 'react-use';
import { identityApiRingRef } from '@ring/plugin-common';



export const CopyTokenBtn = ({}) => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const identityApi = useApi(identityApiRingRef);
  const alertApi = useApi(alertApiRef);
  const featureFlags = useApi(featureFlagsApiRef);
  const { value } = useAsync(() => identityApi.getIdToken());

  const handleSessionBtn = () => {
    copyToClipboard(value || '');
    alertApi.post({ message: 'Token copied to clipboard!' });
  };

  return (
    React.createElement(SidebarScrollWrapper, null
      , featureFlags.isActive('enable-session-btn') && (
        React.createElement(SidebarItem, { icon: FingerprintIcon, onClick: handleSessionBtn, text: "Copy session token"  ,} )
      )
    )
  );
};
