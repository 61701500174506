import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import React, { Fragment } from 'react';

import { Alert } from '@material-ui/lab';







export const Versioning = ({ versions, selectedVersion, onChange }) => {
  const handleChangeVersion = (event) => {
    onChange(event.target.value );
  };

  return (
    React.createElement(Grid, { container: true, spacing: 3, style: { marginBottom: '1rem' },}
      , selectedVersion !== undefined && versions?.versions[0]?.version !== undefined && (
        React.createElement(Fragment, null
          , selectedVersion !== versions?.versions[0]?.version && (
            React.createElement(Grid, { item: true, sm: 12,}
              , React.createElement(Alert, { severity: "warning",}, "You are creating a new version based on data from Version "
                           , selectedVersion, ". The current version is Version "
                 , versions?.versions[0]?.version, " and a new one will be created."
              )
            )
          )
          , React.createElement(Grid, { item: true, sm: 4,}
            , React.createElement(FormControl, { required: true,}
              , React.createElement(Select, { value: selectedVersion, onChange: handleChangeVersion,}
                , Object.values(versions.versions).map(o => (
                  React.createElement(MenuItem, { key: o.version, value: o.version,}
                    , `Version ${o.version}`
                  )
                ))
              )
            )
          )
        )
      )
    )
  );
};
