import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import useAsyncFn, { } from 'react-use/lib/useAsyncFn';
import { dataObservabilityApiRef } from '../../../dataObservabilityApi';


export function useDataFilters(
  databaseName,
  tableName
)


 {
  const dataObservabilityClient = useApi(dataObservabilityApiRef);
  const filterListStatus = useAsync(
    () => dataObservabilityClient.getDataFilters(databaseName, tableName),
    [dataObservabilityClient]
  );

  const addFilterFn = useAsyncFn(
    dataFilter =>
      dataObservabilityClient.createDataFilter({
        ...dataFilter,
        table_name: tableName,
        database_name: databaseName
      }),
    [dataObservabilityClient]
  );

  return { filterListStatus, addFilterFn };
}
