import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getRuleName } from '../../utils';


const ERROR_COLOR = '#E72318';

export const IssueItem = ({ column_name, validation_name, value, datetime }) => {
  return (
    React.createElement(Box, { borderLeft: `${ERROR_COLOR} 2px solid`, pl: "1rem",}
      , React.createElement(Typography, null, "Column: "
         , React.createElement('span', { style: { color: ERROR_COLOR },}, `${column_name}`)
      )
      , React.createElement(Typography, null, `Validation: ${getRuleName(validation_name)}`)
      , React.createElement(Typography, null, `Value: ${value}`)
      , React.createElement(Typography, null, `Date: ${datetime}`)
    )
  );
};
