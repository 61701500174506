/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from 'react';
import { makeStyles, Grid, List, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { CatalogResultListItem } from '@backstage/plugin-catalog';
import { SearchBar, SearchFilter, SearchResult, SearchType, DefaultResultListItem } from '@backstage/plugin-search';
import { Content, Header, Lifecycle, Page } from '@backstage/core-components';
import { DocsResultListItem } from '@backstage/plugin-techdocs';


const useStyles = makeStyles((theme) => ({
  bar: {
    padding: theme.spacing(1, 0)
  },
  filters: {
    padding: theme.spacing(2)
  },
  filter: {
    marginTop: theme.spacing(2.5)
  }
}));

// TODO: Move this into the search plugin once pagination is natively supported.
// See: https://github.com/backstage/backstage/issues/6062
const SearchResultList = ({ results }) => {
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const changePage = (_, pageIndex) => {
    setPage(pageIndex);
  };
  const pageAmount = Math.ceil((results.length || 0) / pageSize);
  return (
    React.createElement(React.Fragment, null
      , React.createElement(List, null
        , results.slice(pageSize * (page - 1), pageSize * page).map(({ type, document }) => {
          switch (type) {
            case 'software-catalog':
              return React.createElement(CatalogResultListItem, { key: document.location, result: document,} );
            case 'techdocs':
              return React.createElement(DocsResultListItem, { key: document.location, result: document,} );
            default:
              return React.createElement(DefaultResultListItem, { key: document.location, result: document,} );
          }
        })
      )
      , React.createElement(Pagination, { count: pageAmount, page: page, onChange: changePage, showFirstButton: true, showLastButton: true,} )
    )
  );
};

const SearchPage = () => {
  const classes = useStyles();
  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, { title: "Search", subtitle: React.createElement(Lifecycle, { alpha: true,} ),} )
      , React.createElement(Content, null
        , React.createElement(Grid, { container: true, direction: "row",}
          , React.createElement(Grid, { item: true, xs: 12,}
            , React.createElement(Paper, { className: classes.bar,}
              , React.createElement(SearchBar, { debounceTime: 100,} )
            )
          )
          , React.createElement(Grid, { item: true, xs: 3,}
            , React.createElement(Paper, { className: classes.filters,}
              , React.createElement(SearchType, { values: ['techdocs', 'software-catalog'], name: "type", defaultValue: "software-catalog",} )
              , React.createElement(SearchFilter.Select, { className: classes.filter, name: "kind", values: ['Component', 'Template'],} )
              , React.createElement(SearchFilter.Checkbox, {
                className: classes.filter,
                name: "lifecycle",
                values: ['experimental', 'production'],}
              )
            )
          )
          , React.createElement(Grid, { item: true, xs: 9,}
            , React.createElement(SearchResult, null, ({ results }) => React.createElement(SearchResultList, { results: results,} ))
          )
        )
      )
    )
  );
};

export const searchPage = React.createElement(SearchPage, null );
