
import React from 'react';
import { FavouriteEntity } from '../../../common/components/FavouriteEntity';
import { useStarredApplications } from '../../hooks/useStarredApplications';





export const FavouriteApplication = ({ application }) => {
  const { isStarredApplication, toggleStarredApplication } = useStarredApplications();
  return (
    React.createElement(FavouriteEntity, {
      entity: application,
      isStarred: isStarredApplication(application),
      toggleStarredEntity: toggleStarredApplication ,}
    )
  );
};
