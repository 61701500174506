import {
    createApiFactory,
    createPlugin,
    discoveryApiRef,
    createRoutableExtension,
} from '@backstage/core-plugin-api';
import { identityApiRingRef } from '@ring/plugin-common';
import { SdkCommitmentToolApi, sdkCommitmentToolApiRef } from './api';

import { rootRouteRef } from './routes';

export const sdkCommitmentToolPlugin = createPlugin({
    id: 'sdk-commitment-tool',
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: sdkCommitmentToolApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                identityApiRing: identityApiRingRef,
            },
            factory: ({ discoveryApi, identityApiRing }) =>
                new SdkCommitmentToolApi({ identityApi: identityApiRing, discoveryApi }),
        }),
    ],
});

export const SdkCommitmentToolPage = sdkCommitmentToolPlugin.provide(
    createRoutableExtension({
        component: () => import('./components/MainPage').then(m => m.MainPage),
        mountPoint: rootRouteRef,
    }),
);
