

export function serializeFilter(filter) {
  if (!filter || filter.isEmpty()) return '';

  const stringArr = [];

  if (filter?.names) stringArr.push(`names=${filter.names.join(',')}`);

  if (!stringArr.length) return '';

  return stringArr.join(',');
}
