import React from 'react';
import { Button, Typography, makeStyles, } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import emptyImage from '../../assets/empty-table.png';
import AddIcon from '@material-ui/icons/Add';






const useStyles = makeStyles(theme => ({
  container: {
    padding: '5% 25%',
    gap: '4rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: theme.palette.background.default,
    alignItems: 'end'
  },
  image: {
    width: '100%'
  },
  body: {
    maxWidth: '22rem',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
  button: {
    transform: 'scale(1.2) translateY(5px)'
  }
}));

export const EmptyBanner = ({ loading, url }) => {
  const classes = useStyles();
  return loading ? (
    React.createElement(React.Fragment, null)
  ) : (
    React.createElement('div', { className: classes.container,}
      , React.createElement('img', { className: classes.image, src: emptyImage, alt: "feedback",} )
      , React.createElement('div', { className: classes.body,}
        , React.createElement(Typography, { variant: "h5", style: { lineHeight: 1.2, marginBottom: '.5rem' },}, "Wow! Such an empty place."

        )
        , React.createElement(Typography, { variant: "body2",}, "Create your databases to conncect them to your app."        )

        , React.createElement(Button, { className: classes.button, component: RouterLink, color: "primary", to: url, startIcon: React.createElement(AddIcon, null ),}, "Create Database"

        )
      )
    )
  );
};
