import React from 'react';
import {

  Typography,
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles,
  Link
} from '@material-ui/core';

const patterns = [
  {
    date: 'Year',
    letter: 'y',
    examples: 'yyyy',
    result: '2022'
  },
  {
    date: 'Month in year',
    letter: 'M',
    examples: 'MM',
    result: '12'
  },
  {
    date: 'Day in week',
    letter: 'E',
    examples: 'EEE',
    result: 'Tue'
  },
  {
    date: 'Day in month',
    letter: 'd',
    examples: 'dd',
    result: '15'
  },
  {
    date: 'Hour in day (00-23)',
    letter: 'H',
    examples: 'HH',
    result: '23'
  },
  {
    date: 'Minute in hour',
    letter: 'm',
    examples: 'mm',
    result: '12'
  },
  {
    date: 'Second in minute',
    letter: 's',
    examples: 'ss',
    result: '56'
  }
];

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '2rem'
  },
  exampleItem: {
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.grey[300]}`,
    margin: '1rem 0'
  },
  exampleItemField: {
    margin: '0 1rem'
  }
}));






const ExamplesDialog = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    React.createElement(Dialog, { fullWidth: true, maxWidth: "sm", open: open, onClose: onClose,}
      , React.createElement(DialogContent, { className: classes.dialog,}
        , React.createElement(Typography, { variant: "h5",}, "Patterns Examples" )
        , React.createElement(Table, { size: "small", 'aria-label': "",}
          , React.createElement(TableHead, null
            , React.createElement(TableRow, null
              , React.createElement(TableCell, null, "Date")
              , React.createElement(TableCell, { align: "right",}, "Letter")
              , React.createElement(TableCell, { align: "right",}, "Example")
              , React.createElement(TableCell, { align: "right",}, "Result")
            )
          )
          , React.createElement(TableBody, null
            , patterns.map(row => (
              React.createElement(TableRow, { key: row.date,}
                , React.createElement(TableCell, { component: "th", scope: "row",}
                  , row.date
                )
                , React.createElement(TableCell, { align: "right",}, row.letter)
                , React.createElement(TableCell, { align: "right",}, row.examples)
                , React.createElement(TableCell, { align: "right",}, row.result)
              )
            ))
            , React.createElement(TableRow, null
              , React.createElement(Typography, { style: { paddingTop: '5px' },}
                , React.createElement(Link, {
                  target: "_blank",
                  rel: "noopener",
                  href: "https://spark.apache.org/docs/latest/sql-ref-datetime-pattern.html",}
                , "Click here"
                   , ' '
                ), "for more examples"

              )
            )
          )
        )
      )
    )
  );
};

export default ExamplesDialog;
