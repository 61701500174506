import React, { useRef } from 'react';
import { TableStatusLabel } from '../Common/TableStatusLabel';
import { Link, Table, } from '@backstage/core-components';
import { TableHeader } from './TableHeader';

import { DateTime } from 'luxon';
import { EtaStatus } from '../../types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

const cellStyle = { padding: '0.5rem 16px' };

const LabelFormat = ({ title, tooltipMsg }) => {
  return (
    React.createElement(Box, { fontWeight: "bolder", color: "#000000de", display: "flex", alignItems: "center", style: { gap: 4 },}
      , title
      , tooltipMsg ? (
        React.createElement(Tooltip, { placement: "top", title: tooltipMsg,}
          , React.createElement(InfoOutlinedIcon, { color: "primary", fontSize: "small",} )
        )
      ) : null
    )
  );
};

const dateFormat = (value) => {
  return (
    React.createElement(React.Fragment, null
      , React.createElement('span', { style: { fontWeight: 500 },}, DateTime.fromISO(value, { zone: 'utc' }).toFormat('hh:mm a')), ' '
      , React.createElement('span', { style: { fontWeight: 700, fontSize: '12px' },}, "(UTC)")
    )
  );
};

const columns = [
  {
    title: React.createElement(LabelFormat, { title: "table",} ),
    render: row => `${row.database_name}.${row.table_name}`,
    cellStyle,
    customSort: (a, b) => {
      if (a.database_name > b.database_name) {
        return -1;
      }
      if (a.database_name < b.database_name) {
        return 1;
      }
      return 0;
    }
  },

  {
    title: React.createElement(LabelFormat, { title: "setting",} ),
    render: row => React.createElement(Link, { to: `/observability/${row.setting_id}/overview`,}, `${row.setting_name}`),
    cellStyle,
    customSort: (a, b) => {
      if (a.database_name > b.database_name) {
        return -1;
      }
      if (a.database_name < b.database_name) {
        return 1;
      }
      return 0;
    }
  },

  {
    title: (
      React.createElement(LabelFormat, {
        title: "eta",
        tooltipMsg: "The ETA represents when it is expected  for the table to finish running."
         ,}
      )
    ),
    field: 'eta',
    cellStyle,
    render: ({ eta, eta_updated_at, eta_status }) => (
      React.createElement(Box, { display: "flex", flexDirection: "column", style: { gap: 8 },}
        , React.createElement(Box, null, "Expected:"
          , ' '
          , React.createElement(Typography, {
            variant: "inherit",
            color: 
              eta_status && [EtaStatus.success, EtaStatus.delayed_success].includes(eta_status)
                ? 'initial'
                : 'secondary'
            ,
            display: "inline",}
          
            , eta ? dateFormat(eta) : '', ' '
          )
        )
        , React.createElement(Box, null, "Completed:"
          , ' '
          , eta_updated_at && eta_status && [EtaStatus.success, EtaStatus.delayed_success].includes(eta_status)
            ? dateFormat(eta_updated_at)
            : '-'
        )
      )
    )
  },
  {
    title: (
      React.createElement(LabelFormat, {
        title: "ETA Status" ,
        tooltipMsg: "Indicates what's the status  of your table's run"
       ,}
      )
    ),
    field: 'eta_status',
    editable: 'never',
    cellStyle,
    render: rowData => React.createElement(TableStatusLabel, { status: rowData.eta_status,} )
  },
  {
    title: React.createElement(LabelFormat, { title: "ETA Checked at"  ,} ),
    field: 'eta_updated_at',
    cellStyle,
    render: ({ eta_updated_at }) => (eta_updated_at ? dateFormat(eta_updated_at) : '')
  },
  {
    title: React.createElement(LabelFormat, { title: "DQ Started at"  ,} ),
    field: 'dq_started_at',
    cellStyle,
    render: ({ dq_started_at }) => (dq_started_at ? dateFormat(dq_started_at) : '')
  },
  {
    title: React.createElement(LabelFormat, { title: "Dq Status" ,} ),
    field: 'dq_status',
    editable: 'never',
    cellStyle,
    render: rowData => React.createElement(TableStatusLabel, { status: rowData.dq_status,} )
  },
  {
    title: React.createElement(LabelFormat, { title: "DQ issues" ,} ),
    field: 'dq_failed_validations',
    cellStyle,
    render: ({ dq_failed_validations }) =>
      dq_failed_validations
        ? dq_failed_validations.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        : '-'
  }
];








export const TableStatus = ({ data, type, onAddTable, onDeleteTable }) => {
  const materialTableRef = useRef(null);

  const actions = [
    (row) => ({
      icon: () => React.createElement(DeleteIcon, { color: "secondary",} ),
      tooltip: 'Remove table from dashboard',
      onClick: () => {
        if (typeof onDeleteTable === 'function') {
          onDeleteTable(row);
        }
      }
    })
  ];

  return (
    React.createElement(Table, {
      tableRef: materialTableRef,
      title: React.createElement(TableHeader, { title: "Status", onAddTable: onAddTable, type: type,} ),
      columns: columns,
      data: data ?? [],
      actions: type === 'dashboardTables' ? actions : undefined,
      localization: {
        body: { emptyDataSourceMessage: 'No tables found' }
      },
      options: {
        paging: false,
        thirdSortClick: false,
        actionsColumnIndex: -1,
        actionsCellStyle: {
          minWidth: '100px'
        }
      },}
    )
  );
};
